<template>
  <div class="top-header ">
<!--    <img class="headerImg" src="@/assets/login/headSplit_02.png" />-->
    <div class="sub_tit">
      <span style="margin-right: 0.5vw;">-</span>
      <span style="right:4.7vw;letter-spacing: 2.4px;font-size:0.8vw;">生鲜副食品供应链管理平台</span>
      <span style="margin-left: 0.5vw;">-</span>
    </div>
    <Main-header v-bind="$attrs"/>
  </div>
</template>

<script>
import MainHeader from "./main-header.vue";

export default {
  name: "Header",
  components: {
    MainHeader,
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.top-header {
  position: relative;
  width: 100%;
  height: #{$headerHeight};
  z-index: 999;
  backdrop-filter: blur(10px);

  background-image: url('~@/assets/login/headSplit_02.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  display: flex;
  justify-content: center;
  align-items: center;

  .headerImg {
    width: 100%;
    height: 100%;
  }

  .sub_tit {
    //border: 1px solid black;
    position: relative;
    top: 3.4vh;
    color: #01eef6;
  }
}
</style>
