<template>
  <div class="qualityTabs">
    <Border1 borderTitle="质量监控">
      <div class="quality">
        <div class="quality-box">
          <div v-for="item in cardData" :key="item.name" class="quality-item">
            <div class="item-top">
              {{ item.name }}
            </div>
            <div class="item-bottom">
              <span class="value">{{ item.value | Format }}</span>
              {{ item.unit }}
            </div>
          </div>
        </div>
        <div class="leftBottom">
          <span @click="openDetails">查看详情</span>
        </div>
      </div>
    </Border1>
    <Details
      ref="Details"
      v-if="showDetails"
      :dialogTitle="'质量监控详情'"
      :stripe="false"
      :loading="false"
      :typeTable="typeTable"
      :columns="columns"
      :tableData="tableData"
      :pagelist="pagelist"
      :getDetailsPage="getFocalPoint"
      :sortChange="sortChange"
      :defaultSort="defaultSort"
      :sortDing="sortDing"
      :amountSummaryObj="amountSummaryObj"
      :marginTop="`calc((100vh - 750Px) / 2)`"
      :orderInfoType="orderInfoType"
      @clos:closeDetails="closeDetails">
      <div slot="left-top" class="left-top">
        <div class="right-tab-box">
          <div
            class="right-tab-item"
            :class="orderInfoType === 1 ? 'right-tab-active' : ''"
            @click="orderInfoType = 1">
            订购金额
          </div>
          <div
            class="right-tab-item"
            :class="orderInfoType === 2 ? 'right-tab-active' : ''"
            @click="orderInfoType = 2">
            入库金额
          </div>
          <div
            class="right-tab-item"
            :class="orderInfoType === 3 ? 'right-tab-active' : ''"
            @click="orderInfoType = 3">
            出库金额
          </div>
          <!-- <div
            class="right-tab-item"
            :class="orderInfoType === 4 ? 'right-tab-active' : ''"
            @click="orderInfoType = 4">
            签收率
          </div> -->
          <div
            class="right-tab-item"
            :class="orderInfoType === 5 ? 'right-tab-active' : ''"
            @click="orderInfoType = 5">
            今日总退货
          </div>
          <el-date-picker
            v-if="orderInfoType !== 5"
            class="month-icon-style"
            v-model="monthValue"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            :picker-options="monthPickerOptions"
            @change="monthChange"
          >
          </el-date-picker>
        </div>
        <div class="right-tab-box">
          <!-- <el-select
              v-if="orderInfoType === 5"
              class="selectAlarm"
              style="margin-right:6px;width:130px;"
              v-model="refundStatus"
              placeholder="目的城市"
              :popper-append-to-body="false"
              @change="refundStatusChange"
              clearable>
              <el-option label="全部" value="" />
              <el-option label="待审核" value="0" />
              <el-option label="审核通过" value="1" />
              <el-option label="已驳回" value="-1" />
            </el-select> -->
<!--          <el-button
            v-permission="'isCanDownload'"
            class="BlueCustomButton searchBottom"
            @click.native="exportTion()">导出</el-button>-->
        </div>
      </div>
    </Details>
  </div>
</template>

<script>
import Border1 from "@/components/Border1";
import watchMonth from "@/mixins/watchMonth";
import Details from "../viewsComponents/Details/OrderReviewTDetails.vue";
import { mapState } from "vuex";
import { formatDate,formatDateHym } from "@/utils";
import { Format } from "@/filters"
import { exportQualityMonitoring,exportTodayRefund } from "@/api/home";

export default {
  name: "Quality",
  mixins: [watchMonth],
  components: {
    Border1,
    Details
  },
  data() {
    return {
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
      ],
      timerRefurn:null,
      timerRefurnMin:null,
      timerDate:null,
      refundStatus:'',
      timer:null,
      timerMin:null,
      defaultSort: '1',
      sortDing: 'descending',
      sortTag: false,
      showDetails: false,
      orderInfoType: 1,
      typeTable: 1,
      columns: [
        {
          label: "灶点编码",
          prop: "focalPointNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true
        },
        {
          label: "订购金额(元)",
          prop: "ddAmt",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          align: "right",
          width: 120
        },
        {
          label: "入库金额(元)",
          prop: "amt",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          width: 100
        },
        {
          label: "出库金额(元)",
          prop: "thJsAmt",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          width: 120
        },
        {
          label: "签收率",
          prop: "ratio",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
        }
      ],
      columnsTwo: [
        {
          label: "灶点编码",
          prop: "focalPointNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true
        },
        {
          label: "订购金额(元)",
          prop: "orderAmt",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          align: "right",
          width: 120
        },
        {
          label: "入库金额(元)",
          prop: "inboundAmt",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          width: 100
        },
        {
          label: "出库金额(元)",
          prop: "outboundAmt",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          width: 120
        },
        {
          label: "签收率",
          prop: "ratio",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: false,
          align: "right",
        }
      ],
      columnsThree: [
        {
          label: "灶点编码",
          prop: "focalPointNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:80
        },
        {
          label: "订单编号",
          prop: "soid",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:100
        },
        {
          label: "退货单号",
          prop: "rmaId",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:100
        },
        {
          label: "订购金额(元)",
          prop: "orderAmount",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          align: "right",
          width:120
        },
        {
          label: "入库金额(元)",
          prop: "signAmount",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: false,
          align: "right",
          width:100
        },
        {
          label: "退货金额(元)",
          prop: "refundAmount",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          width:100
        },
        {
          label: "退货状态",
          prop: "refundStatus",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
        },
        {
          label: "发起退货时间",
          prop: "refundDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width:160
        }
      ],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => {
            return `灶点编号：<span style="font-weight: bold;">${params[0].data[0]
              }</span> <br/>
            ${this.orderInfoType === 1
                ? "订购金额"
                : this.orderInfoType === 2
                  ? "入库金额"
                  : this.orderInfoType === 3
                    ? "出库金额"
                    : this.orderInfoType === 4
                    ? "签收率":"退单金额"
              }: <span style="font-weight: bold;">${Format(params[0].data[1])}</span> ${this.orderInfoType === 4 ? "%" : "元"}`;
          }
        },
        dataZoom: [
          {
            show: true,
            showDataShadow: false,
            showDetail: false,
            handleStyle: {
              color: "#17F1EC"
            },
            backgroundColor: "rgba(0,0,0,0.20)",
            borderColor: "rgba(0, 0, 0, 0)",
            yAxisIndex: [0],
            start: 0,
            end: 100
          },
          {
            type: "inside",
            color: "#fff",
            yAxisIndex: [0],
            start: 1,
            end: 80
          }
        ],
        xAxis: {
          type: "value",
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.8)"
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)" // 分割线颜色
            }
          }
        },
        yAxis: {
          type: "category",
          axisLabel: {
            show: true,
            textStyle: {
              color: "#FFF"
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)"
            }
          }
        },
        grid: {
          left: "0%",
          // right: "5%",
          bottom: "5%",
          top: "5%",
          containLabel: true
        },
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: 13,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(110, 193, 244, 0.2)"
            },
            avoidLabelOverlap: false,
            itemStyle: {
              label: {
                show: true
              },
              labelLine: {
                show: false
              },
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "rgba(23,241,236,0)" },
                { offset: 1, color: "rgba(23,241,236,1)" }
              ]),
              borderColor: "#99E3FF",
              shadowBlur: 16,
              shadowColor: "rgba(0,186,255,1)"
            }
          }
        ]
      },
      moneyOption:{
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(
              params.value / 10000
            )}${this.type === "ddamt" ? "万元" : "万斤"} (${params.percent}%)`;
          },
          confine: true,
        },
      },
      tableData: [],
      pagelist: {
        page: 1,
        rows: 20,
        count: 0
      },
      cardData: [
        { name: "订购金额", value: "0", unit: "万" },
        { name: "入库金额", value: "0", unit: "万" },
        { name: "出库金额", value: "0", unit: "万" },
        // { name: "签收率", value: "0", unit: "%" },
        // { name: "准点率", value: "0", unit: "%" },
        { name: "今日退货总额", value: "0", unit: "万" }
      ],
      amountSummaryObj:{},
      amountSummaryList:[
        {
          value:0,
          RefundStatus:0,
          name:'退货待审核'
        },
         {
          value:0,
          RefundStatus:1,
          name:'退货审核通过'
        },
         {
          value:0,
          RefundStatus:-1,
          name:'退货已驳回'
        }
      ],
      deliveryTimeList:[],
      asyncNum:0,
      monthValue: '',
      dayVal: '',
      demoMonth: '202407',
      demoDay: '20240701',
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
    };
  },
  computed: {
    ...mapState("global", ["month",'dateModel'])
  },
  watch: {
    orderInfoType() {
      this.pagelist = {
        page: 1,
        rows: 20
      };
      if(this.showDetails) this.getFocalPoint({});
    },
    orderType(val) {
      this.setDetailsOption(this.conversion(this.tableData, val));
      this.columns[1].label = val === "orderAmt" ? "订单金额" : "订单数量";
      this.columns[1].prop = val === "orderAmt" ? "orderAmt" : "orderNum";
      this.columns[2].label =
        val === "orderAmt" ? "订单金额环比" : "订单数量环比";
      this.columns[2].prop =
        val === "orderAmt" ? "orderAmtRatio" : "orderNmuRatio";
    },
    /* month: {
      handler(val) {
        if (val) {
          this.getDataApi()
        }
      },
      immediate: true,
    } */
  },
  created() {
    this.init();
    this.timer = setInterval(() => {
        let min = this.minDate()
        this.getData();
        clearTimeout(this.timerMin);
        if(min === 0){
          this.init();
          this.getData();
        }else{
          this.timerMin = setTimeout(()=>{
            this.init();
          }, 1000 * 60 * min);
        }
    }, 1000 * 60);
  },
  methods: {
    monthChange(val){
      this.getFocalPoint()
    },
    refundStatusChange(){
      this.pagelist.page = 1
      this.todayRefund()
    },
    minDate(){
      let a = new Date().getMinutes() + ''
      let b = a.slice(1)
      let c = Number(b)
      if(c === 3||c === 8){
        return 0
      }else if(c < 3){
        return 3 - c
      }else if(c >3 && c < 8){
        return 8 - c
      }else if(c > 8){
        return 4
      }
    },
    init(){
      this.$getData({
        type: "todayRefundSummary",
        warehouseCode: this.warehouseCode
      }).then(res => {
        this.cardData[3].value = res.data.refundSummary
      });
    },
    exportTion() {
      let date = new Date();
      let month = date.getMonth() + 1
      let day = date.getDate()
      if(month < 10){
        month = '0'+month
      }
      if(day < 10){
        day = '0'+day
      }
      let myDate= `${date.getFullYear()}${month}${day}`
      if(this.orderInfoType !== 5){
        let mothod = exportQualityMonitoring
        /* let obj = {
          sortType: this.orderInfoType + "",
          date: formatDate(this.month),
        } */
        let obj = {
          day: this.demoDay,
          month: this.demoMonth,
          sortType: this.orderInfoType + "",
          warehouseCode: this.warehouseCode
        }
        mothod(
          obj
        ).then((res) => {
          const link = document.createElement('a')
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          link.style.display = 'none'
          link.href = window.URL.createObjectURL(blob)
          let typeName = ''
          if(this.orderInfoType === 1){
            typeName = '订购金额'
          }else if(this.orderInfoType === 2){
            typeName = '入库金额'
          }else if(this.orderInfoType === 3){
            typeName = '出库金额'
          }else if(this.orderInfoType === 4){
            typeName = '签收率'
          }

          link.download = `质量监控详情-${typeName}_${myDate}.xlsx`
          document.body.appendChild(link)
          link.click()
          // 兼容火狐浏览器
          document.body.removeChild(link)
        })
      }else{
        let mothod = exportTodayRefund
         mothod(
          {refundStatus:this.refundStatus}
        ).then((res) => {
          const link = document.createElement('a')
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
          link.style.display = 'none'
          link.href = window.URL.createObjectURL(blob)
          link.download = `质量监控详情-今日总退货_${myDate}.xlsx`
          document.body.appendChild(link)
          link.click()
          // 兼容火狐浏览器
          document.body.removeChild(link)
        })
      }

    },
    sortChange(val) {
      let obj = {
        type: "focalPoint",
        date: formatDate(this.month),
        sortType: this.orderInfoType + "",
        page: this.pagelist.page,
        rows: this.pagelist.rows
      }
      if (val.order === 'ascending') { //升
        obj.isAsc = true
        this.sortTag = true
        this.sortDing = 'ascending'
      } else if (val.order === 'descending') { //降
        obj.isAsc = false
        this.sortTag = false
        this.sortDing = 'descending'
      }
      this.$getData(obj).then(res => {
        if(res.data){
          let i = 0
          res.data.map(item => {
            item.focalPointNo = item.focalPointNo.substr(0,2)+ '***' + item.focalPointNo.substr(7, item.focalPointNo.split('').length) + i++
          })
        }
        this.tableData = res.data;
        this.pagelist.count = res.count;
        this.setDetailsOption();
      });
    },
    setDetailsOption() {
      this.$nextTick(() => {
        let option
        if (this.$refs.Details) {
          if (this.orderInfoType === 3) {
            let source = []
            // if (this.tableData.every((item) => !item.thJsAmt)) {
            //   // 当退换货金额全是0时， 需要重新倒叙才能让表格和图表的顺序一致
            //   source = this.getParams(this.tableData).reverse()
            // } else {
            //   // 当退换货金额部分是0时(不全是0,有些是负数)， 需要拆分排序才能 让表格和图表的顺序一致
            //   if (this.getParams(this.tableData).some(item => item[1] === 0)) {
            //     let D = this.getParams(this.tableData).filter((item) => item[1] === 0)
            //     let d = this.getParams(this.tableData).filter((item) => item[1] < 0)
            //     source = D.reverse().concat(d.reverse())
            //   } else {
            //     source = this.getParams(this.tableData).sort((a, b) => b[1] - a[1])
            //   }
            // }
            source = this.getParams(this.tableData).reverse()
            option = {
              dataset: {
                source
              }
            };
          } else if (this.orderInfoType === 4) {
            // let arr2 = this.getParams(this.tableData).sort((a, b) => a[1] - b[1])
            let arr = this.getParams(this.tableData).reverse()
            option = {
              dataset: {
                source: arr
              }
            };
          } else {
            option = {
              dataset: {
                source: this.getParams(this.tableData).sort((a, b) => a[1] - b[1])
              }
            };
          }
          if(this.orderInfoType !== 5){
            this.$refs.Details.setDetailsOption(
              Object.assign(this.option, option)
            );
          }else{
            let option = {
              tooltip: {
                trigger: "item",
                formatter: (params) => {
                  return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(
                    params.value / 10000
                  )}万元`;
                },
                confine: true,
              },
              legend: {
                orient: 'vertical',
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                top: "28%",
                right: "0",
                itemGap: 25,
                formatter: function(name) {
                  let data = option.series[0].data
                  let tarValue;
                  data.forEach(v=>{
                    if(name === v.name){
                      tarValue = `${name}: {b|${Format(v.value / 10000)}} 万元`
                    }
                  })
                  return tarValue
                },
                textStyle: {
                  color: '#fff',
                  fontSize: 11,
                  rich: {
                    b: {
                      fontSize: 12,
                      color: '#17fff4',
                      padding:[0,12],
                    },
                  },
                }
              },
              series: [
                {
                  name: "供应商占比",
                  type: "pie",
                  radius: ["40%", "80%"],
                  center: ["29%", "50%"],
                  label: {
                    show: false,
                  },
                  // data: this.amountSummaryList
                  data: this.amountSummaryList.map((item, index) => ({
                    value: item.value,
                    name: item.name,
                    itemStyle: {
                      color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: this.colors[index > 9 ? 9 : index][0] },
                        { offset: 1, color: this.colors[index > 9 ? 9 : index][1] },
                      ]),
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: this.colors[index > 9 ? 9 : index][1],
                      borderColor: this.colors[index > 9 ? 9 : index][2],
                    },
                  })),
                }
              ]
            };
            this.$refs.Details.setMoneyOption(option);
            let approvedAmountList = this.deliveryTimeList.map(v=>{
                    return v.approvedAmount
                  })
            let toApprovalAmountList = this.deliveryTimeList.map(v=>{
              return v.toApprovalAmount
            })
            let rejectAmountList = this.deliveryTimeList.map(v=>{
              return v.rejectAmount
            })
            let optionTwo = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: "shadow",
                },
                formatter: (params) => {
                  let date = new Date();
                  let myDate = `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
                  return `<span style="font-weight: bold;">${myDate} ${params[0].axisValue}</span><br/>
                  退货审核通过：<span style="font-weight: bold;">${params[0].value}</span>万元<br/>
                    退货未审核：<span style="font-weight: bold;">${params[1].value}</span>万元<br/> 
                    退货驳回：<span style="font-weight: bold;">${params[2].value}</span>万元<br/>`;
                },
              },
              legend: {
                icon: "rect",
                itemWidth: 10,
                itemHeight: 10,
                right:'0',
                top:'0',
                data: ['退货审核通过', '退货未审核', '退货驳回'],
                textStyle: {
                  color: '#fff',
                  fontSize: 11,
                  rich: {
                    b: {
                      fontSize: 12,
                    },
                  },
                }
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: "category",
                boundaryGap: true,
                axisLine: {
                  lineStyle: {
                    color: "rgba(255,255,255,0.3)",
                  },
                },
                axisLabel: {
                  color: "rgba(255,255,255,0.7)",
                  interval:11,
                  rotate: 30 // 倾斜30°
                },
                axisTick: {
                  alignWithLabel: true,
                },
                data: this.deliveryTimeList.map(v=>{
                  return v.recordTime.substr(11,5)
                })
              },
              yAxis: {
                type: 'value',
                scale: true, //自适应
                // minInterval: 0.1, //分割刻度
                splitNumber: 4,
                axisLine: {
                  lineStyle: {
                    color: "rgba(255,255,255,0.7)",
                  },
                },
                splitLine: {
                  lineStyle: {
                    color: "rgba(255,255,255,0.3)", // 分割线颜色
                  },
                },
              },
              series: [
                {
                  name: '退货审核通过',
                  type: 'line',
                  color: "#A9FAF7", //折线颜色
                  smooth: true, //折线是否带有弧度
                  data: approvedAmountList
                },
                {
                  name: '退货未审核',
                  type: 'line',
                  color: "#07A5FF", //折线颜色
                  smooth: true, //折线是否带有弧度
                  data: toApprovalAmountList
                },
                {
                  name: '退货驳回',
                  type: 'line',
                  color: "#AA55FF", //折线颜色
                  smooth: true, //折线是否带有弧度
                  data:  rejectAmountList
                }
              ]
            };
            this.$refs.Details.setTimeOption(optionTwo);
          }
        }
      });
    },
    getDayVal(val){
      this.dayVal = val.replace(/-/g, '')
    },
    getData(month) {
      const paramsObj = {
        type: "qualityMonitorNew",
        day: this.dayVal,
        warehouseCode: this.warehouseCode
      }
      /* const paramsObj = {
        type: "qualityMonitorSearch",
        date: month
      } */
      this.$getData(paramsObj).then(res => {
        const { orderAmt, inboundAmt, tempInboundAmt, outboundAmt } = res.data[0] || {}
        this.cardData[0].value = orderAmt / 10000;
        // this.cardData[1].value = inboundAmt / 10000;
        this.cardData[1].value = tempInboundAmt / 10000;
        this.cardData[2].value = outboundAmt / 10000;
        // this.cardData[3].value = signRatio * 100;
      });
    },
    getDataApi() {
      this.$getData({
        type: "onTimeRateMonitor",
        date: formatDate(this.month)
      }).then((res) => {
        this.cardData[4].value = res.data.onTimeRate * 100;
      })
    },
    getFocalPoint(_, v = 1) {
      if (typeof (_) !== "undefined") {
        this.$refs.Details.refreshTable = false
      }
      if (v == 1) {
        this.pagelist.page = 1
      }
      if(this.orderInfoType !== 5){
        clearInterval(this.timerRefurn);
        clearInterval(this.timerDate);
        this.timerDate = null
        clearTimeout(this.timerRefurnMin);
        this.refundStatus = ''
        this.asyncNum = 0
        this.columns = this.columnsTwo
        /* let obj = {
          type: "focalPoint",
          // date: formatDate(this.month),
          date: this.monthValue,
          sortType: this.orderInfoType + "",
          page: this.pagelist.page,
          rows: this.pagelist.rows
        } */
        let obj = {
          type: "focalPointNew",
          page: this.pagelist.page,
          rows: this.pagelist.rows,
          day: this.demoDay,// 日期
          month: this.demoMonth,// 月份（查询条件有月份优先月份查询）
          sortType: this.orderInfoType + "",// 排序类型【1-订购金额】【2-入库金额】【3-出库金额】
          warehouseCode: this.warehouseCode
        }
        if (this.orderInfoType == 4) {
          this.columns[4].sortable = "custom",
            this.defaultSort = '4'
          if (this.sortTag) {
            this.sortTag = true
            obj.isAsc = true
            this.sortDing = 'ascending'
          } else {
            this.sortTag = false
            obj.isAsc = false
            this.sortDing = 'descending'
          }
          this.$forceUpdate()
        } else {
          this.defaultSort = '1'
          delete this.columns[4].sortable
          this.sortTag = false
          this.$forceUpdate()
        }
        this.$nextTick(() => {
          this.$refs.Details.refreshTable = true
        })
        this.$getData(obj).then(res => {
          // if (this.orderInfoType === 3 && res.data.every((item) => !item.thJsAmt)) {
          //   this.tableData = res.data.reverse()
          // } else {
          //   this.tableData = res.data;
          // }
          if(res.data){
            let i = 0
            res.data.map(item => {
              item.focalPointNo = item.focalPointNo.substr(0,2)+ '***' + item.focalPointNo.substr(7, item.focalPointNo.split('').length) + i++
            })
          }
          this.tableData = res.data;
          this.pagelist.count = res.count;
          this.setDetailsOption();
        });
      }else{
          this.columns = this.columnsThree
          this.defaultSort = '1'
          delete this.columns[4].sortable
          this.sortTag = false
          this.$nextTick(() => {
            this.$refs.Details.refreshTable = true
          })
          this.$forceUpdate()
          clearInterval(this.timerRefurn);
          clearTimeout(this.timerRefurnMin);
          this.todayRefund()
          if(typeof (_) === "undefined"||JSON.stringify(_) === '{}'){
            this.getRefundTodayTotalSummary()
            // this.todayRefundTrend()
          }
          this.timerRefurn = setInterval(() => {
              let min = this.minDate()
              clearTimeout(this.timerRefurnMin);
              if(min === 0){
                this.todayRefund()
                this.getRefundTodayTotalSummary()
                // this.todayRefundTrend()
              }else{
                this.timerRefurnMin = setTimeout(()=>{
                  this.todayRefund()
                  this.getRefundTodayTotalSummary()
                  // this.todayRefundTrend()
                }, 1000 * 60 * min);
              }
          }, 1000 * 60 * 5);
      }
    },
    //系统时间
    serverTime(){
      let myDate = new Date();
      this.amountSummaryObj.systemDate= `${myDate.getFullYear()}年${myDate.getMonth() + 1}月${myDate.getDate()}日`
      let myTime = Date.now()
      this.amountSummaryObj.systemTime = formatDateHym(myTime).substr(11)
      clearInterval(this.timerDate);
      this.timerDate = null
      this.timerDate = setInterval(() =>{
          myTime += 1000
          this.amountSummaryObj.systemTime = formatDateHym(myTime).substr(11)
          this.$forceUpdate()
        }, 1000);
    },
    //今日总退货列表
    todayRefund(){
      let obj = {
            type: "todayRefund",
            // refundStatus:this.refundStatus,
            refundStatus: '1',
            page: this.pagelist.page,
            rows: this.pagelist.rows,
            warehouseCode: this.warehouseCode
          }
          this.$getData(obj).then(res => {
            if(res.data){
              let i = 0
              res.data.map(item => {
                item.focalPointNo = item.focalPointNo.substr(0,2)+ '***' + item.focalPointNo.substr(7, item.focalPointNo.split('').length) + i++
              })
            }
            this.tableData = res.data
            this.pagelist.count = res.count;
          });
    },
    //今日总退货折线图
    todayRefundTrend(){
      this.asyncNum++
      this.$getData({"type": "todayRefundTrend"}).then(res => {
        this.deliveryTimeList = res.data
        if(this.asyncNum > 1){
           this.setDetailsOption();
        }
      });
    },
    //今日总退货饼图
    getRefundTodayTotalSummary(){
      this.asyncNum++
      this.$getData({
            type: "crfRequestForward",
            url: `/ICRFS/GetRefundTodayTotalSummary?queryStatus=100&warehouseSysNo=0`,
            method: "GET",
          }).then((res) => { 
            let data = JSON.parse(res.data)
            let order
            if(Number(data.AmountRate) > 0){
              order = '1'
            }else if(Number(data.AmountRate) < 0){
              order = '2'
            }else{
              order = '0'
            }
            this.amountSummaryObj = {
              ...data,
              order:order
            }
            const rfa1 = data.Items.find(item => item.RefundStatus === 1) || {RefundAmount: ''}
            this.amountSummaryObj.RefundAmount = rfa1.RefundAmount
            clearInterval(this.timerDate);
            this.timerDate = null
            this.serverTime()
            this.amountSummaryList.forEach(v=>{
              data.Items.forEach(v2=>{
                if(v.RefundStatus === v2.RefundStatus){
                  v.value = v2.RefundAmount
                }
              })
            }) 
            if(this.asyncNum > 1){
              this.setDetailsOption();
            }
          })
    },
    openDetails() {
      this.showDetails = true;
      // this.monthValue = formatDate(this.dateModel)
      this.getFocalPoint();
    },
    closeDetails(pageList) {
      this.showDetails = false;
      this.orderInfoType = 1
      this.refundStatus = ''
      this.asyncNum = 0
      this.monthValue = ''
      if (pageList.page !== 0) {
        pageList.page = 1;
        pageList.rows = 20;
      }
    },
    getParams(arr) {
      return arr.map(item => {
        let { focalPointNo, ddAmt, amt, thJsAmt, ratio,refundAmount,orderAmt, inboundAmt, outboundAmt } = item;
        if (this.orderInfoType === 1) {
          return [focalPointNo, orderAmt];
        } else if (this.orderInfoType === 2) {
          return [focalPointNo, inboundAmt];
        } else if (this.orderInfoType === 3) {
          return [focalPointNo, outboundAmt];
        } else if (this.orderInfoType === 4) {
          return [focalPointNo, ratio];
        }else if (this.orderInfoType === 5) {
          return [focalPointNo, refundAmount];
        }
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearTimeout(this.timerMin);
    clearInterval(this.timerRefurn);
    clearInterval(this.timerDate);
    clearTimeout(this.timerRefurnMin);
    this.timer = null
    this.timerMin = null
    this.timerRefurn = null
    this.timerRefurnMin = null
    this.timerDate = null
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.qualityTabs {
  .quality {
    height: 100%;
    padding-top: calc((100vh - #{$headerHeight}) / 18);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .quality-box {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
      .quality-item {
        .item-top {
          font-size: 13px;
          font-weight: 100;
        }
        .item-bottom {
          text-align: center;
          margin-top: 0.25vw;
          .value {
            // font-size: 34px;
            font-family: Impact;
            font-size: 17px;
            font-weight: 400;
            color: #17f1ec;
          }
        }
      }
    }
    .leftBottom {
      display: flex;
      justify-content: flex-end;
      // position: relative;
      // top: 5px;
      // right: 10px;
      padding: 0 10px 2px 0;
      color: #17f1ec;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .left-top {
    display: flex;
    // width: 100%;
    justify-content: space-between;
  }
  .right-tab-box {
    display: flex;
    margin: 10PX;
    .right-tab-item {
      min-height: 32PX;
      color: rgba(255, 255, 255, 0.75);
      border: 1px solid #87d2ff;
      padding: 0 6PX;
      font-size: 14PX;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: rgba(62, 100, 172, 0.3);
    }
    .right-tab-item:first-child {
      border-right: none;
    }
    .right-tab-active {
      color: #17fff4;
      background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
    }
    .month-icon-style{
      width: 120px;
      margin-left:90px;
      ::v-deep .el-input__prefix{
        margin-top: -3px;
      }
      ::v-deep .el-input__suffix{
          top: -3px;
        }
    }
  }
  .searchBottom {
    width: 72PX;
    padding: 6PX;
    color: #fff;
    font-size: 14PX;
  }
}
</style>
