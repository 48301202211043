<template>
  <div class="echarts">
    <Charts
      v-show="indexShow"
      :option="option"
      class="time-charts"
      v-loading="loading"
      element-loading-background="rgba(0,0,0,0.2)"
    />
    <div class="timeText" v-show="!indexShow">
      <img :src="imgUrl" alt="" width="32px" height="32px" />
      <div style="padding-left:5px;">暂无温度信息</div>
    </div>
  </div>
</template>

<script>
import { StatisticsReport } from "@/api/e6yun.js";
import Charts from "@/components/Charts";
// import { QueryTemperature } from "@/utils/index.js";

export default {
  name: "timecharst",
  data() {
    return {
      imgUrl: require("@/assets/image/wendujiankong.png"),
      loading: true,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let data = params[0].data;
            return `时间：<span style="font-weight: bold;">${data.GPSTime.slice(
              10
            )}</span> <br/>  温度：<span style="font-weight: bold;">
            ${data.Temperature}℃</span>`;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        grid: {
          top: "8%",
          left: "3%",
          right: "4%",
          bottom: "15%",
          containLabel: true,
        },
        series: [
          {
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: false, //折线是否带有弧度
            areaStyle: {
              color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(41,244,236,1)",
                },
                {
                  offset: 1,
                  color: "rgba(41,244,236,0.1)",
                },
              ]),
            },
          },
        ],
      },
      indexShow: true,
      timeId: null,
      first: true,
    };
  },
  components: {
    Charts,
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  watch: {
    vehicle: {
      handler(value) {
        if (Object.keys(value).length !== 0) {
          if (value.plateNo){
            if (this.first) {
              this.StatisticsReport(value.plateNo);
              if (this.timeId) {
                clearInterval(this.timeId);
              }
              this.timeId = setInterval(() => {
                this.StatisticsReport(value.plateNo);
              }, 1000 * 60 * 5);
              if (!this.first) {
                this.first = true;
              }
            }
          }else{
            this.indexShow = false;
             this.option = {
              dataset: {
                source: []
              }
            };
          }

        }
      },
      immediate: true,
    },
  },
  methods: {
    StatisticsReport(vehicle) {
      this.loading = true;
      StatisticsReport({ vehicle }).then((res) => {
        this.loading = false;
        if (res.result.code !== '1') {
          this.$message({
            message:
              "当前车辆没有安装车载视频温控定位设备，或者设备被关闭，请联系业务运营人员。",
            type: "error",
            duration: 2 * 1000,
          });
          this.indexShow = false;
        } else {
          if (res.result.data.length <= 0) {
            this.$message({
              // message: "车辆不在线或者温度数据为空",
              message: "温度数据为空",
              type: "info",
              duration: 5 * 1000,
            });
            this.indexShow = false;
          } else {
            this.indexShow = true;
            let arr = res.result.data.map((item) => {
              return {
                // GPSTime: item.GPSTime.split(",")[1],
                GPSTime: item.GPSTime,
                Temperature: item.Temperature,
              };
            });
            this.option = {
              dataset: {
                source: arr
              }
            };
          }
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timeId);
  },
};
</script>
<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: calc(100% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  .time-charts {
    width: 100%;
   height: 100%;
  }
  .timeText {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
