<template>
  <div class="dialog">
    <el-dialog
      :title="categoryTypeTitle + '供应商占比'"
      width="1184px"
      :visible="CategorySuppliersProps.show"
      @close="closeDetail">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <Charts :option="option" />
        </div>
        <div class="right">
          <Table
            ref="tableComponent"
            :columns="columns"
            :tableData="tableData"
            :total="pageList.count"
            :loading="false"
            :searchShow="false"
            :stripe="false"
            :height="600"
            :paginationShow="false"
            :page.sync="pageList.page"
            :rows.sync="pageList.rows"
            @pagination="getDetailsPage"
            @searchList="getDetailsPage">
            <template
              slot="columnEspecial"
              slot-scope="{ column, row }"
              resizable>
              <span v-if="['ratio'].includes(column.prop)">
                {{ formatFloat(row[column.prop]*100, 2) + "%" }}
              </span>
              <span v-else-if="['amount'].includes(column.prop)">
                {{ row[column.prop] | Format }}
              </span>
              <span v-else-if="['Dds'].includes(column.prop)">
                {{ row[column.prop] | Format(0) }}
              </span>
              <span v-else>{{ row[column.prop] }}</span>
            </template>
            <template slot="columnRight">
              <el-table-column
                v-if="$store.getters.isCanDownload"
                label="操作"
                fixed="right"
                width="100"
                align="center">
                <template slot-scope="{ row }">
                  <div ref="columnRight">
                    <el-button
                      v-permission="'isCanDownload'"
                      type="text"
                      size="mini"
                      @click="exportGoodsDetail(row)">
                      下载订单详情
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </template>
          </Table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatDateVal, formatFloat, deepClone, formatDate } from "@/utils";
import Charts from "@/components/Charts";
import { Format } from "@/filters";

export default {
  name: "CategorySuppliers",
  components: {
    Table,
    Charts,
  },
  props: {
    CategorySuppliersProps: {
      default: function () {
        return {}
      }
    },
    rows: {
      type: Object,
    },
    categoryType: {
      type: String
    },
    dayVal: {
      type: String,
    },
    monthValue: {
      type: String,
    }
  },
  data() {
    return {
      selectOptions: [],
      trendChart: undefined,
      lineDetailTitle: "",
      showLineDetail: false,
      columns: [],
      myChart: undefined,
      chartData: [],
      tableData: [],
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
        ["rgba(93,112,146,0)", "rgba(93,112,146,1)", "#BAD2FF"],
      ],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      option: {},
      selectFood: [null],
      trendOptions: {},
      categoryTypeTitle: ''
    };
  },
  watch: {
    rows: {
      handler(val) {
        this.categoryTypeTitle = val.categoryName
        if (this.categoryType === 'categorySupplyAmount') {
          this.getDetailsPage() //金额
        } else {
          this.getPieData() // 数量
        }
      },
      immediate: false,
    },
    categoryType: {
      handler(value) {
        if (value === "categorySupplyAmount") {
          this.columns = [
            {
              prop: "venderName",
              label: "供应商名称",
              width: 200,
              align: "left"
            },
            {
              prop: "amount",
              label: "订单金额(元)",
              align: "right",
              width: 120,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
              width: 100,
            },
            // {
            //   prop: "operation",
            //   label: "操作",
            // },
          ];
        } else {
          this.columns = [
            {
              prop: "venderName",
              label: "供应商名称",
              width: 200,
              align: "left"
            },
            {
              prop: "quantity",
              label: "订单数量(单)",
              align: "right",
              width: 100,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
              width: 120,
            },
            // {
            //   prop: "operation",
            //   label: "操作",
            // },
          ];
        }
      },
      immediate: true,
    },
    selectFood: {
      handler() {
        // this.getPieData();
      },
    },
  },
  methods: {
    closeDetail() {
      this.$emit("update:showCategorySuppliersProps", false);
    },
    formatFloat(num, n = 2) {
      // if (formatFloat(num, n).split(".")[1] === "00") {
      //   return formatFloat(num, n).split(".")[0];
      // } else {
      return formatFloat(num, n);
      // }
    },
    getLastDay(){
      /* const month = formatDate(this.month);
      let date = new Date();
      let monthDay = month.slice(-2)
      let currentMonth = parseFloat(monthDay)
      let nextMonthFirstDay = new Date(date.getFullYear(), currentMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = '0' + day
      } */
      
      let year = this.monthValue.slice(0,4)
      let monthDay = this.monthValue.slice(-2)
      let currentMonth = parseFloat(monthDay)
      let nextMonthFirstDay = new Date(year, currentMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = '0' + day
      }
      return this.monthValue + day
    },
    // 下载订单详情
    exportGoodsDetail(row) {
      /* const global = this.$store.getters.global;
      const month = formatDate(global.month);
      let date = new Date();
      let monthDay = month.slice(-2)
      let currentMonth = parseFloat(monthDay)
      let nextMonthFirstDay = new Date(date.getFullYear(), currentMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = '0' + day
      } */
      const startTime = this.monthValue ? this.monthValue + '01' : this.dayVal
      const endTime = this.monthValue ? this.getLastDay() : this.dayVal
      this.$getData({
        type: "crfRequestForward",
        url: `/ICRFS/GetCrfsSOlistbyVendor?c1name=${this.rows.categoryName}&vendorname=${row.venderName}&start=${startTime}&end=${endTime}`,
        method: "GET",
        download: 1
      }).then((res) => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '下载路径为空!',
            center: true
          })
        }
      })
    },
    // 获取金额
    async getDetailsPage() {
      // const global = this.$store.getters.global;
      // const dateId = formatDateVal(global.month);
      const paramsObj = {
        type: "categorySupplyVendorNew",
        queryType: 'amount',
        day: this.dayVal,
        month: this.monthValue,
        superGroupName: this.rows.categoryName,
        warehouseCode: this.warehouseCode
      }
      /* const paramsObj = {
        method: "GET",
        type: "crfRequestForward",
        url: `/ICRFS/GetCrfsbiPieCategoryVendorAmt?monthid=${dateId}&sgroupname=${this.rows.categoryName}`,
      } */
      let res = await this.$getData(paramsObj);
      // this.tableData = JSON.parse(res.data);
      // this.chartData = JSON.parse(res.data);
      if(res.data){
        res.data.map(item => {
          item.venderName = '武汉市***有限公司' + (parseInt(item.venderId)+32)
        })
      }
      this.tableData = res.data
      this.chartData = res.data
      this.chartData.reduce((o, v) => {
        v.delta1 = o;
        v.delta2 = v.ratio;
        // v.delta2 = v.amount / v.Total_amt;
        return v.delta2;
      }, 0);
      // 添加渐变
      this.chartData.forEach((v) => {
        var ops = this.calc(v.delta1 * 2 * Math.PI, v.delta2 * 2 * Math.PI);
        if (v.venderName) {
          v.itemStyle = {
            borderWidth: 2,
            borderColor: v.color1 || "#ADABFFFF", // 边框颜色
            shadowColor: v.color0 || "#ADABFF00", // 投影样式
            shadowBlur: 10, // 投影模糊
            color: {
              type: "radial",
              x: ops.center[0],
              y: ops.center[1],
              r: ops.radius,
              colorStops: [
                { offset: 0, color: v.color0 },
                { offset: 0.5, color: v.color0 },
                { offset: 0.3, color: v.color1 },
                { offset: 1, color: v.color1 },
              ],
              globalCoord: false, // 缺省为 falses
            },
          };
          // 引线
          v.label = {
            normal: {
              show: false,
              formatter: "{b}\n\n{d}%",
              textStyle: {
                fontSize: 12,
                color: v.color1,
              },
              position: "outside",
            },
            emphasis: {
              show: true,
            },
          };
        }
      });
      this.option = {
        // 金额
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(params.value)}${this.categoryType === "categorySupplyAmount" ? "元" : "单"} (${Format(params.data.percent)
              }%)`;
          },
          confine: true,
        },
        legend: {
          orient: "vertical",
          type: "scroll",
          // x: "60%",
          // y: 0,
          right: "5%",
          bottom: "65%",
          icon: "Rect",
          itemWidth: 14,
          itemHeight: 14,
          // align: "left",
          selectedMode: false,
          textStyle: {
            color: "#fff",
            fontSize: 11,
            rich: {
              b: {
                fontSize: 12,
              },
            },
          },
          pageTextStyle: {
            color: '#fff', //翻页数字颜色
          },
          // pageIconColor: '#6495ed', //翻页下一页的三角按钮颜色
          // pageIconInactiveColor: '#aaa', //翻页（即翻页到头时）
        },
        series: [
          {
            name: "品类供应商占比",
            type: "pie",
            radius: ["35%", "55%"],
            center: ["30%", "50%"],
            label: {
              show: false,
            },
            data: this.chartData.map((item, index) => ({
              value:
                this.categoryType === "categorySupplyAmount"
                  ? item.amount
                  : item.Dds,
              name: item.venderName,
              percent: item.ratio,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: this.colors[index > 10 ? 10 : index][0],
                    // color: this.colors[index][0],
                  },
                  {
                    offset: 1,
                    color: this.colors[index > 10 ? 10 : index][1],
                    // color: this.colors[index][1],
                  },
                ]),
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: this.colors[index > 10 ? 10 : index][1],
                borderColor: this.colors[index > 10 ? 10 : index][2],
                // shadowColor: this.colors[index][1],
                // borderColor: this.colors[index][2],
              },
            })),
          },
        ],
      };
    },
    //数量
    async getPieData() {
      // const global = this.$store.getters.global;
      // const dateId = formatDateVal(global.month);
      const paramsObj = {
        type: "categorySupplyVendorNew",
        queryType: 'quantity',
        day: this.dayVal,
        month: this.monthValue,
        superGroupName: this.rows.categoryName,
        warehouseCode: this.warehouseCode
      }
      /* const paramsObj = {
        type: "crfRequestForward",
        url: `/ICRFS/GetCrfsbiPieCategoryVendorDds?monthid=${dateId}&sgroupname=${this.rows.categoryName}`,
        method: "GET",
      } */
      let res = await this.$getData(paramsObj);
      // this.tableData = JSON.parse(res.data);
      // this.chartData = JSON.parse(res.data);
      if(res.data){
        res.data.map(item => {
          item.venderName = '武汉市***有限公司' + (parseInt(item.venderId)+32)
        })
      }
      this.tableData = res.data
      this.chartData = res.data
      // 计算每一个data的起始角度和末了角度 θ1和θ2
      this.chartData.reduce((o, v) => {
        v.delta1 = o;
        // v.delta2 = o - v.Dds / v.Total_amt;
        v.delta2 = o - v.ratio;
        return v.delta2;
      }, 0);
      // 添加渐变
      this.chartData.forEach((v) => {
        var ops = this.calc(v.delta1 * 2 * Math.PI, v.delta2 * 2 * Math.PI);
        if (v.venderName) {
          v.itemStyle = {
            borderWidth: 2,
            borderColor: v.color1 || "#ADABFFFF", // 边框颜色
            shadowColor: v.color0 || "#ADABFF00", // 投影样式
            shadowBlur: 10, // 投影模糊
            color: {
              type: "radial",
              x: ops.center[0],
              y: ops.center[1],
              r: ops.radius,
              colorStops: [
                { offset: 0, color: v.color0 },
                { offset: 0.5, color: v.color0 },
                { offset: 0.3, color: v.color1 },
                { offset: 1, color: v.color1 },
              ],
              globalCoord: false, // 缺省为 falses
            },
          };
          // 引线
          v.label = {
            normal: {
              show: false,
              formatter: "{b}\n\n{d}%",
              textStyle: {
                fontSize: 12,
                color: v.color1,
              },
              position: "outside",
            },
            emphasis: {
              show: true,
            },
          };
        }
      });
      this.option = {
        // 数量
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(params.value, 0)}${this.categoryType === "categorySupplyAmount" ? "元" : "单"} (${params.percent
              }%)`;
          },
          confine: true,
        },
        legend: {
          orient: "vertical",
          type: "scroll",
          // x: "60%",
          // y: 0,
          bottom: "65%",
          right: "5%",
          // top: "40",
          icon: "Rect",
          itemWidth: 14,
          itemHeight: 14,
          selectedMode: false,
          textStyle: {
            color: "#fff",
            fontSize: 11,
            rich: {
              b: {
                fontSize: 12,
              },
            },
          },
        },
        series: [
          {
            name: "品类供应商占比",
            type: "pie",
            radius: ["35%", "55%"],
            center: ["30%", "50%"],
            label: {
              show: false,
            },
            data: this.chartData.map((item, index) => ({
              value: item.quantity,
              name: item.venderName,
              percent: item.ratio,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: this.colors[index > 10 ? 10 : index][0],
                    // color: this.colors[index][0],
                  },
                  {
                    offset: 1,
                    color: this.colors[index > 10 ? 10 : index][1],
                    // color: this.colors[index][1],
                  },
                ]),
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: this.colors[index > 10 ? 10 : index][1],
                borderColor: this.colors[index > 10 ? 10 : index][2],
                // shadowColor: this.colors[index][1],
                // borderColor: this.colors[index][2],
              },
            })),
          },
        ],
      };
    },
    // 计算渐变中心以及渐变半径
    calc(delta1, delta2) {
      var r = 0.5; // 半径是0.5 其实表示0.5个直径
      var inner = 0.6; // 里面镂空部分占60%  option中radius为[33%, 55%]  55 * 0.6 == 33

      var cos = Math.cos;
      var sin = Math.sin;
      var PI = Math.PI;
      var min = Math.min;
      var max = Math.max;

      var bottom = 0;
      var left = 2 * r;
      var right = 0;

      // y0: θ1对应的外部点的y值
      // y1: θ2对应的外部点的y值
      // _y0: θ1对应的内部点的y值
      // _y1: θ2对应的内部点的y值
      // x0: θ1对应的外部点的x值
      // x1: θ2对应的外部点的x值
      // _x0: θ1对应的内部点的x值
      // _x1: θ2对应的内部点的x值
      var y0 = r * (1 - cos(delta1));
      var y1 = r * (1 - cos(delta2));

      var _y0 = r * (1 - inner * cos(delta1));
      var _y1 = r * (1 - inner * cos(delta2));

      // 如果这个弧经过θ == PI的点  则bottom = 2PI
      // bottom用于之后的max计算中
      if (delta1 < PI && delta2 > PI) {
        bottom = 2 * r;
      }
      // 计算这几个点的最大最小值
      var ymin = min(_y0, _y1, y0, y1);
      var ymax = max(_y0, _y1, y0, y1, bottom);

      var x0 = r * (1 + sin(delta1));
      var x1 = r * (1 + sin(delta2));

      var _x0 = r * (1 + inner * sin(delta1));
      var _x1 = r * (1 + inner * sin(delta2));

      // 如果这个弧经过θ == PI / 2的点  则right = 2PI
      if (delta1 < PI / 2 && delta2 > PI / 2) {
        right = 2 * r;
      }
      // 如果这个弧经过θ == PI / 2 * 3的点  则left = 0
      if (delta1 < (PI / 2) * 3 && delta2 > (PI / 2) * 3) {
        left = 0;
      }
      var xmin = min(_x0, _x1, x0, x1, left);
      var xmax = max(_x0, _x1, x1, x0, right);

      return {
        // 计算圆心以及半径
        center: [(r - xmin) / (xmax - xmin), (r - ymin) / (ymax - ymin)],
        radius: r / min(xmax - xmin, ymax - ymin),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";

.dialog {
  ::v-deep .el-button + .el-button {
    margin-left: 12px;
  }
  .dialog-content {
    display: flex;
    width: 100%;
    height: 90%;

    .left {
      width: 40%;
      height: 100%;
    }

    .right {
      width: 60%;
      padding-top: 0;
      .table {
        height: 100%;
      }
    }
  }

  .el-table::before {
    height: 0;
  }

  ::v-deep .el-table thead {
    color: #ffffff;
  }

  .left-top {
    display: flex;
    height: 33px;
    align-items: center;
    .selected {
      width: 200px;
      ::v-deep .el-cascader .el-input__inner {
        height: 33px;
      }
    }

    .buttons {
      display: flex;
      margin-top: 1px;
      .tab-item {
        width: 100px;
        cursor: pointer;
        margin: 4px 0;
        padding: 15px;
        line-height: 1px;
        border: 1px solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.75);
        background: rgba(62, 100, 172, 0.3);
      }

      .tab-item:first-child {
        border-right: none;
      }

      .tab-active {
        color: #17fff4;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: #5f97ff 0 0 10px inset;
      }
    }
  }
  .DEchart {
    width: 100%;
    height: 100%;
    // border: 1px solid #ff0000;
  }
  .lineDetail {
    z-index: 999;
    ::v-deep .el-dialog .el-dialog__title {
      line-height: 2.5;
      font-size: 16px;
      color: #17f1ec;
    }
  }
  .trendChart {
    padding: 10px;
    width: 100%;
    height: 100%;
  }
}
</style>
