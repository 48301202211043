<template>
  <div class="container">
    <Charts-border
      borderTitle="供应商占比"
      :option="option"
      :style="{ width: width, height: height }"
    >
      <div class="right-tab-box" slot="rightButton">
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 'ddamt' }"
          @click="type = 'ddamt'"
        >
          订购金额
        </div>
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 'sweight' }"
          @click="type = 'sweight'"
        >
          订购数量
        </div>
      </div>
      <template slot="leftBottom">
        <span @click="openDetails">查看详情</span>
      </template>
    </Charts-border>
    <SupplierDetail v-if="showDetail" :show.sync="showDetail" :type="type" />
  </div>
</template>

<script>
import ChartsBorder from "../viewsComponents/ChartsBorder";
import SupplierDetail from "../viewsComponents/Details/SupplierDetail.vue";
import watchMonth from "@/mixins/watchMonth";
import { mapGetters } from "vuex";
import { formatDate } from "@/utils";
import { Format } from "@/filters";

export default {
  name: "Supplier",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
    SupplierDetail,
  },
  data() {
    return {
      width: "100%",
      height: "100%",
      percentList: [],
      type: "ddamt",
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
      ],
      showDetail: false,
      option: {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(
              params.value / 10000
            )}${this.type === "ddamt" ? "万元" : "万斤"}`;
          },
          confine: true,
        },
      },
      dayVal: ''
    };
  },
  computed: {
    ...mapGetters(["global"]),
  },
  watch: {
    type(value) {
      this.getData(formatDate(this.global.month), value);
    },
  },
  created() {
    this.timer = setInterval(() => {
      this.getData(formatDate(this.global.month));
    }, 1000 * 60 * 5);
  },
  methods: {
    formatLegend(params){
      var newParamsName = ""; // 最终拼接成的字符串
      var paramsNameNumber = params.length; // 实际标签的个数
      var provideNumber = 20; // 每行能显示的字的个数
      var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 换行的话，需要显示几行，向上取整
      // 条件等同于rowNumber>1
      if (paramsNameNumber > provideNumber) {
        for (var p = 0; p < rowNumber; p++) {
          var tempStr = ""; // 表示每一次截取的字符串
          var start = p * provideNumber; // 开始截取的位置
          var end = start + provideNumber; // 结束截取的位置
          // 此处特殊处理最后一行的索引值
          if (p == rowNumber - 1) {
            // 最后一次不换行
            tempStr = params.substring(start, paramsNameNumber);
          } else {
            // 每一次拼接字符串并换行
            tempStr = params.substring(start, end) + "\n";
          }
          newParamsName += tempStr; // 最终拼成的字符串
        }
      } else {
        // 将旧标签的值赋给新标签
        newParamsName = params;
      }
      //将最终的字符串返回
      return newParamsName;
    },
    getDayVal(val){
      this.dayVal = val.replace(/-/g, '')
    },
    getData(month) {
      const propVal = this.type === 'ddamt' ? 'amount': 'weight'
      const paramsObj = {
        type: "vendorRatioTop5",
        day: this.dayVal,
        orderBy: propVal // tab切换字段(amount:订购金额/weight:订购重量)
      }
      this.$getData(paramsObj).then((res) => {
        if (!res.data) return;
        const data = res.data.sort((pre, next) => {
          return next[propVal] - pre[propVal];
        });
        data.map(item => {
          item.vendorName = item.vendorName = "武汉市******有限公司" + (parseInt(item.vendorId)+32)
        })
        let ChartsBorderParmas = data.slice(0, 5);
        this.option = {
          legend: {
            icon: "rect",
            itemWidth: 8,
            itemHeight: 8,
            top: "35%",
            right: "3%",
            left: "48%",
            formatter: (params)=> {
              return this.formatLegend(params)
            },
            selectedMode: false,
            textStyle: {
              color: "#fff",
              fontSize: 11,
              rich: {
                b: {
                  fontSize: 12,
                },
              },
            },
            data: ChartsBorderParmas.map(item => item.vendorName)
          },
          series: [
            {
              name: "供应商占比",
              type: "pie",
              radius: ["30%", "60%"],
              center: ["22%", "58%"],
              label: {
                show: false,
                formatter: (params) => {
                  this.percentList = ChartsBorderParmas.map((p) => {
                    if (p.vendorName === params.name) {
                      p.percent = params.percent;
                    }
                    return p;
                  });
                },
              },
              data: ChartsBorderParmas.map((item, index) => ({
                value: item[propVal],
                name: item.vendorName,
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: this.colors[index > 9 ? 9 : index][0] },
                    { offset: 1, color: this.colors[index > 9 ? 9 : index][1] },
                  ]),
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: this.colors[index > 9 ? 9 : index][1],
                  borderColor: this.colors[index > 9 ? 9 : index][2],
                },
              })),
            },
          ],
        };
      });
    },
    // 查看详情
    openDetails() {
      this.showDetail = true;
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  },
};
</script>
<style lang="scss" scoped>
.right-tab-box {
  display: flex;
  margin: 10px;
  .right-tab-item {
    color: rgba(255, 255, 255, 0.75);
    border: 1px solid #87d2ff;
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    background: rgba(62, 100, 172, 0.3);
  }
  .right-tab-item:first-child {
    border-right: none;
  }
  .right-tab-active {
    color: #17fff4;
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  }
}
</style>
