<template>
  <div class="container">
    <Charts-border borderTitle="品类供应占比" :option="option">
      <div class="right-tab-box" slot="rightButton">
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 1 }"
          @click="type = 1"
        >
          订单金额
        </div>
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 0 }"
          @click="type = 0"
        >
          订单数量
        </div>
      </div>
      <template slot="leftBottom">
        <span @click="openDetails">查看详情</span>
      </template>
    </Charts-border>
    <CategoryDetail
      v-if="showDetail"
      ref="categoryDetail"
      :show.sync="showDetail"
      :goryType="type"
    >
    </CategoryDetail>
  </div>
</template>
<script>
import ChartsBorder from "../viewsComponents/ChartsBorder/index.vue";
import watchMonth from "@/mixins/watchMonth";
import CategoryDetail from "../viewsComponents/Details/CategoryDetail.vue";
import { mapState } from "vuex";
import { formatDate, formatInt, format, formatFloat } from "@/utils";

export default {
  name: "Category",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
    CategoryDetail,
  },
  data() {
    return {
      type: 1,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let [name, data, ratio] = params[0].data;
            return `品类：<span style="font-weight: bold;">${name}</span> <br/>
            ${this.type ? "金额" : "数量"}：<span style="font-weight: bold;">${this.type ? format(formatFloat(data/10000, 2)) : format(data) } ${this.type ? "万元" : "单"} (${formatFloat(ratio*100)}%)</span>`;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
            // interval: 0,
            rotate: 35, // 倾斜30°
            // 竖状排序
            // formatter: function(value) {
            //   return value.split('').join('\n')
            // }
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          min: function(value) {
            //取最小值向下取整为最小刻度
            return formatInt(Math.floor(value.min), 2, false);
          },
          scale: true, //自适应
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
          axisLabel: {
            formatter: (value) => {
              return `${this.type ? format(formatFloat(value/10000, 2)) : format(value) }`
            }
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8,
          selectedMode: false,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "2%",
          containLabel: true,
        },
        series: [
          {
            type: "bar",
            color: "#00f2f1", // 折线颜色
            smooth: true, // 折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "rgba(23,241,236,1)" },
                { offset: 0, color: "rgba(23,241,236,0.1)" },
              ]),
            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: "rgba(23,241,236,0.1)" },
                { offset: 1, color: "rgba(23,241,236,1)" }
              ]),
              borderColor: "#99E3FF",
              shadowBlur: 4,
              shadowColor: "rgba(0,186,255,1)"
            }
          },
        ],
      },
      showDetail: false,
      dayVal: ''
    };
  },
  computed: {
    ...mapState("global", ["month"]),
  },
  watch: {
    type(val) {
      if (val) {
        // 获取订单金额数据
        this.getData(formatDate(this.month));
      } else {
        // 获取订单数量数据
        this.getData(formatDate(this.month), 2, "quantity");
      }
    },
  },
  created() {
    this.timer = setInterval(() => {
      // 0:订单数量 1:订单金额
      if (this.type === 1) {
        this.getData(formatDate(this.month));
      } else {
        this.getData(formatDate(this.month), 2, "quantity");
      }
    }, 1000 * 60 * 5);
  },
  methods: {
    // 查看详情
    openDetails() {
      this.showDetail = true;
    },
    getDayVal(val){
      this.dayVal = val.replace(/-/g, '')
    },
    // 获取订单金额数据 & 获取订单数量数据
    getData(month, type = 1, Interface = 'amount') {
      const paramsObj = {
          type: "categorySupplyMonitorNew",
          day: this.dayVal,
          queryType: Interface,
          warehouseCode: this.warehouseCode,
          // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
          filterType: '1'
      }
      this.$getData(paramsObj).then((res) => {
        if (res && res.data) {
          let data = res.data
          let map = new Map();
          let p = [];
          let params = data.map((item) => {
            map.set(item.categoryName, true);
            if (type === 1) {
              return [item.categoryName,item.amount, item.ratio];
            } else {
              return [item.categoryName,item.quantity, item.ratio];
            }
            
          }).sort((a, b) => b[1] - a[1]);
          params.forEach((item) => {
            if (map.has(item[0])) {
              map.delete(item[0]);
              p.push(item);
            }
          });
          this.option = {
            dataset: {
              source: p || [],
            },
          };
        }
      });
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  },
};
</script>
<style lang="scss" scoped>
.right-tab-box {
  display: flex;
  margin: 10px;
  .right-tab-item:first-child {
    border-right: none;
  }
  .right-tab-item {
    padding: 6px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #87d2ff;
    color: rgba(255, 255, 255, 0.75);
    background: rgba(62, 100, 172, 0.3);
  }
  .right-tab-active {
    color: #17fff4;
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  }
}
</style>
