<template>
  <div class="charts-border">
    <component v-bind:is="border" v-bind="$attrs">
      <div class="rightButton">
        <slot name="rightButton" />
      </div>
      <Charts v-bind="$attrs" />
      <div class="leftBottom">
        <slot name="leftBottom" />
      </div>
    </component>
  </div>
</template>

<script>
import Border1 from "@/components/Border1";
import Charts from "@/components/Charts";

export default {
  name: "ChartsBorder",
  props: {
    border: {
      type: String,
      default: "Border1",
    },
  },
  components: {
    Border1,
    Charts,
  },
  watch: {
    border: {
      handler(val) {
        console.log(11, val)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.charts-border {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  .rightButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
  .leftBottom {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: rgba(23, 241, 236, 1);
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
