var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unitBusinessTable"},[_c('div',{staticClass:"row-box"},[_c('div',{staticClass:"dialogTitle"},[_vm._v("订单金额")]),_c('el-table-admin-page',_vm._b({ref:"tableOrder",staticClass:"tableTwo",attrs:{"search-show":false,"columns":_vm.columnsOrder,"table-data":_vm.tableData,"isLoading":_vm.loadingOrder,"pageSize":_vm.pageOrder.pageSize,"paginationShow":_vm.paginationShow2,"highlight-current-row":"","height":_vm.height},on:{"size-change":_vm.sizeChangeOrder,"pagination":_vm.currentChangeOrder},scopedSlots:_vm._u([{key:"columnEspecial",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.prop === 'operation')?_c('div',[_c('span',{staticClass:"clickButton",on:{"click":function($event){return _vm.download(row)}}},[_vm._v("下载")])]):(column.prop === 'ringRatio')?_c('span',[_vm._v(" "+_vm._s(row[column.prop].toFixed(2))+"% "),_c('img',{attrs:{"src":row.ringRatio >= 0 ? _vm.imgSrc1:_vm.imgSrc2,"alt":""}})]):(column.prop === 'ddAmt')?_c('span',[_vm._v(" "+_vm._s(_vm._f("Format")(row[column.prop]))+" ")]):(column.prop === 'ddAmtL')?_c('span',[_vm._v(" "+_vm._s(_vm._f("Format")(row[column.prop]))+" ")]):_c('span',[_vm._v(_vm._s(row[column.prop]))])]}}])},'el-table-admin-page',_vm.pageOrder,false,true))],1),_c('div',{staticClass:"row-box"},[_vm._m(0),_c('el-table-admin-page',_vm._b({ref:"tableOrder",staticClass:"tableTwo categoryCss",attrs:{"search-show":false,"columns":_vm.columnsCategory,"isLoading":_vm.loadingCategory,"table-data":_vm.tableDataCategory,"pageSize":_vm.pageCategory.pageSize,"paginationShow":_vm.paginationShow2,"highlight-current-row":"","height":_vm.height},on:{"size-change":_vm.sizeChangeCategory,"pagination":_vm.currentChangeCategory},scopedSlots:_vm._u([{key:"columnEspecial",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row[column.prop]? row[column.prop]:'-'))])]}}])},'el-table-admin-page',_vm.pageCategory,false,true))],1),_c('div',{staticClass:"row-box"},[_vm._m(1),_c('el-table-admin-page',_vm._b({ref:"tableOrder",staticClass:"tableTwo commodityCss",attrs:{"search-show":false,"columns":_vm.columnsVendor,"isLoading":_vm.loadingVendor,"table-data":_vm.tableDataVendor,"pageSize":_vm.pageVendor.pageSize,"paginationShow":_vm.paginationShow2,"highlight-current-row":"","height":_vm.height},on:{"size-change":_vm.sizeChangeVendor,"pagination":_vm.currentChangeVendor},scopedSlots:_vm._u([{key:"columnEspecial",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row[column.prop]? row[column.prop]:'-'))])]}}])},'el-table-admin-page',_vm.pageVendor,false,true))],1),_c('el-dialog',{staticClass:"detail",attrs:{"title":'确认下载',"width":"480px","append-to-body":"","visible":_vm.downloadDetail,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.downloadDetail=$event}}},[_c('span',{staticClass:"downloadText"},[_vm._v("请确认下载当前单位下属灶点销售单明细")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.downloadDetail = false}}},[_vm._v("取 消")]),_c('el-button',{staticClass:"button",attrs:{"type":"primary"},on:{"click":_vm.confirmDown}},[_vm._v("确 定")])],1)]),_c('el-dialog',{staticClass:"detail",attrs:{"title":'确认下载',"width":"480px","append-to-body":"","show-close":false,"visible":_vm.downloadtrip},on:{"update:visible":function($event){_vm.downloadtrip=$event}}},[_c('span',{staticClass:"el-icon-loading load"}),_c('span',{staticClass:"downloadText"},[_vm._v("当前单位下属灶点销售单明细数据正在整理中，最长预计耗时3-5分钟，感谢你的耐心等待。")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialogTitles"},[_vm._v("品类占比"),_c('span',{staticClass:"litle"},[_vm._v("(金额/占比)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialogTitles"},[_vm._v("供应商占比"),_c('span',{staticClass:"litle"},[_vm._v("(金额/占比)")])])}]

export { render, staticRenderFns }