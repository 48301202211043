<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1384px"
      top="calc((100vh - 693Px) / 2)"
      @close="closeDialog">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <slot name="left-top"></slot>
          <div class="left-content">
            <div class="left_content-amount">
              <slot name="foodPage"></slot>
            </div>
            <el-table
              :height="500"
              :data="tableData"
              :loading="false"
              @sort-change="sortChange"
              class="tableForm">
              <el-table-column v-for="(column,index) in columns"
                :key="index"
                :prop="column.prop"
                :width="column.width"
                :label="column.label"
                :align="column.align || 'center'"
                :sortable="column.sortable">
                <template slot-scope="scope">
                  <span v-if="column.prop === 'signRate'" class="Ratio">
                    <!-- {{ getAmtHb(scope.row) }}% -->
                    {{ scope.row.signRate }}%
                    <!-- <img :src="scope.row.signRate>0?imgSrc1:imgSrc2" alt> -->
                  </span>
                  <span v-else-if="column.prop === 'orderQuantityHb'">
                    {{ scope.row.orderQuantityHb | format}}%
                    <img :src="scope.row.orderQuantityHb >= 0 ? imgSrc1:imgSrc2" alt>
                  </span>
                  <span v-else-if="column.prop === 'orderAmountHb'">
                    {{ scope.row.orderAmountHb | format }}%
                    <img :src="scope.row.orderAmountHb >= 0 ? imgSrc1:imgSrc2" alt>
                  </span>
                  <span v-else-if="column.prop === 'skuQtyHb'">
                    {{ scope.row.skuQtyHb}}
                  </span>
                  <span v-else-if="column.prop === 'orderAmount'">
                    {{ scope.row.orderAmount | format}}
                  </span>
                  <span v-else-if="column.prop === 'signAmount'">
                    {{ scope.row.signAmount | format}}
                  </span>
                  <span v-else>{{ scope.row[column.prop] }}</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="$store.getters.isCanDownload"
                label="操作"
                fixed="right"
                align="center"
                width="230">
                <template slot-scope="scope">
                  <div class="clickButton_box">
                    <div class="clickButton" @click="handleClick(scope.row,1)">下载订单详情
                    </div>
                    <div  class="clickButton" @click="handleClick(scope.row,2)">下载商品复购
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <el-pagination
                background
                :current-page.sync="pageList.page"
                :page-size.sync="pageList.rows"
                :page-sizes="[20, 30, 50]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :small="false"
                :pager-count="5"
                v-bind="$attrs"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Table from "@/components/Table";
import { handleEntitys, formatDate, formatFloat } from "@/utils";
import { mapState } from 'vuex';

export default {
  name: "detailsPopup",
  components: {
    // Table,
  },
  props: {
    pageList: {
      default: function () {
        return {};
      }
    },
    radioHandleCurrentChange: {
      default: function () {
        return function () { }
      }
    },
    dialogTitle: {
      type: String
    },
    typeFood: {
      type: String
    },
    showDetails: {
      type: Boolean,
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    total: {
      type: Number,
      default() {
        return 0;
      }
    },
    rows: {
      type: Number,
      default: 20
    },
    columns_r: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableData_r: {
      type: Array,
      default: function () {
        return [];
      }
    },
    total_r: {
      type: Number,
      default() {
        return 0;
      }
    },
    page_r: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    stripe: {
      type: Boolean,
      default: true
    },
    downLoadMonth: {
      type: String,
      require: true
    }
  },
  computed: {
    ...mapState('global', ['month']),
    // 下面是pagination
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.rows
      },
      set(val) {
        this.$emit('update:rows', val)
      }
    }
  },
  data() {
    return {
      pageSizes: [20],
      imgSrc1: require('@/assets/image/arrowup.svg'),
      imgSrc2: require('@/assets/image/down.svg'),
      dialogVisible: true,
      value: "",
      entitys: [
        {
          querySelect: {},
          queryValue: "",
          show: false
        }
      ],
      loadingData: this.loading,
      loadingData_r: this.loading,
      chart: null,
    };
  },
  methods: {
    getAmtHb(row){
      if(row.ddAmt===0){
        return 0
      }
      const num = (row.amt / row.ddAmt) * 100
      return formatFloat(num, 2)
    },
    FomatFloat(num, n = 2) {
      let m = formatFloat(num, n)
      let res = ''
      if (m && m.split('.')[1] === '00') {
        res = m.split('.')[0]
      } else {
        res = m
      }
      return res
    },
    closeDialog() {
      this.$emit('close:closeDetails');
    },
    handleClick(row,v) {
      let url = ''
      if(v === 1){
        url = `/ICRFS/GetCrfsMonthOrderDetails?monthid=${this.downLoadMonth}&focalpointno=${row.focalPointNo}`
      }else if(v === 2){
        url = `/ICRFS/GetCrfsbiFocalpointEvaluationMonthByExcel?monthid=${this.downLoadMonth}&focalpointno=${row.focalPointNo}`
      }
      this.$getData({
        type: "crfRequestForward",
        url:url,
        method: "GET",
        download: 1
      }).then(async res => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '下载路径为空!',
            center: true
          })
        }
      })
    },
    getEntitys(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent.entitys)
      ).entity;

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          if (key === "date") {
            params[key] = formatDate(params[key]);
          }
        }
      }
      return { params, searchButton };
    },
    getEntitys_r(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent2.entitys)
      ).entity;
      return { params, searchButton };
    },
    handleSizeChange(val) {
      this.pageList.rows = val
      this.$emit('getFocalPointApi', this.pageList.rows,2)
    },
    handleCurrentChange(val) {
      this.pageList.page = val
      this.$emit('getFocalPointApi', this.pageList.page,1)
    },
    sortChange(sortData){
      this.$emit('sortChange', sortData);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
// .dialog-content .left .left-content .left_content-amount{
//   display: block;
// }
.tableForm {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 24PX 0 9PX 0;
  .clickButton_box{
    display: flex;
    .clickButton {
      margin: 0 6PX;
      color: #17fff4;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-dialog {
    height: inherit !important;
  }
  .el-table thead {
    color: #fff;
  }
}
::v-deep .el-icon-close:before {
  color: #17f1ec;
}
//  ::v-deep .minHeigth300 .el-table__body-wrapper {
//     min-height: 300px !important;
//     height: 88% !important;
//   }
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: rgba(62, 100, 172, 0.3);
}
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table {
  background: rgba(0, 0, 0, 0.2);
  .cell{
    line-height: 22PX !important;
  }
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: rgba(0, 0, 0, 0.2);
}
::v-deep .el-table td,
::v-deep .el-table th.is-leaf {
  // border-bottom: none;
}

::v-deep .el-select .el-input .el-select__caret {
  color: aqua;
}
::v-deep .el-input__inner {
  &::placeholder {
    color: #17fff4;
  }
}
::v-deep .el-table thead tr th {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2) !important;
  min-height: 80%;
  border-bottom: 1px solid #5473bf;
}
::v-deep .el-pagination {
  display: flex;
  justify-content: flex-end;
}
::v-deep .el-pagination__total {
  color: rgba(255, 255, 255, 0.8);
}
::v-deep .el-pagination__jump {
  color: rgba(255, 255, 255, 0.8);
}
::v-deep .el-pagination button {
  background-color: rgba(62, 100, 172, 0.8);
}
::v-deep .el-pager li.active {
  background-color: rgba(62, 100, 172, 0.8);
}
::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent;
  color: #17fff4;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(62, 100, 172, 0.3);
  color: #17fff4;
  border: 1px solid #87d2ff;
  border-radius: 1px;
}
::v-deep .el-pagination .el-pager li {
  background-color: rgba(62, 100, 172, 0.3);
  color: #fff;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner {
  border-radius: 1px;
  height: 30px;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .el-pagination.is-background .el-pager li:hover {
  background-color: rgba(62, 100, 172, 0.25);
}
.pagination-container {
  ::v-deep .el-input__inner,
  ::v-deep .el-pagination__jump {
    color: rgba(255, 255, 255, 0.8);
    // border: none !important;
    position: relative;
  }
}
::v-deep .el-input__inner {
  // border: none !important;
  border-radius: 1px;
}

// ::v-deep .linkedDialog {
//   overflow: hidden !important;
// }
</style>
