<template>
  <div style="height:100%;">
    <el-table-admin-page
      v-if="type === 'somasterInfo'"
      class="tableTwo"
      ref="tableOrder"
      :search-show="false"
      :columns="columns"
      :table-data="tableData"
      v-bind.sync="page"
      :isLoading="loadingOrder"
      :pageSize="page.pageSize"
      @size-change="sizeChangeOrder"
      @pagination="currentChangeOrder"
      :paginationShow="paginationShow2"
      :height="height"
      highlight-current-row>
      <template slot="columnEspecial" slot-scope="{ column, row }">
        <div v-if="column.prop === 'operation'">
          <span class="clickButton" @click="download(row)">下载</span>
        </div>
        <span v-else-if="column.prop === 'itemQuantity'">
          {{ row[column.prop] / 500 | Format(2) }}
        </span>
        <span v-else-if="column.prop === 'itemWeight'">
          {{ row[column.prop] | Format(2) }}
        </span>
        <span v-else-if="column.prop === 'soAmt'">
          {{ row[column.prop] | Format}}
        </span>
        <span v-else-if="column.prop === 'isComplete'">
          {{ row[column.prop]? "是" : "否"}}
        </span>
        <span v-else>{{ row[column.prop] }}</span>
      </template>
    </el-table-admin-page>
    <el-table-admin-page
      v-if="type === 'externalRoute'"
      class="tableTwo"
      ref="tableOrder"
      :search-show="false"
      :columns="columnsRount"
      :table-data="routTableDate"
      v-bind.sync="pageRout"
      :isLoading="loadingOrder"
      :pageSize="pageRout.pageSize"
      @size-change="sizeChangeRout"
      @pagination="currentChangeRout"
      :paginationShow="paginationShow2"
      :height="height"
      highlight-current-row>
      <template slot="columnEspecial" slot-scope="{ column, row }">
        <span>{{ row[column.prop] }}</span>
      </template>
    </el-table-admin-page>
  </div>
</template>

<script>
export default {
  name: "tableindex",
  props: {
    vehicle: {
      type: Object,
    },
    type: {
      type: String,
      default: ''
    },
    state: {
      type: String
    }
  },
  data() {
    return {
      height: `calc(100% - 48px)`,
      columns: [
        {
          label: "灶点编号",
          prop: "focalPointNo",
          align: "center",
        },
        {
          label: "灶点名称",
          prop: "focalPointName",
          align: "center",
        },
        {
          label: "订单编号",
          prop: "soid",
          align: "center",
        },
        {
          label: "下单日期",
          prop: "orderDate",
          align: "center",
        },
        {
          label: "订单金额(元)",
          prop: "soAmt",
          align: "center",
        },
        {
          label: "商品重量(斤)",
          prop: "itemWeight",
          align: "center",
        },
        {
          label: "是否签收",
          prop: "isComplete",
          align: "center",
        },
        {
          label: "操作",
          prop: "operation",
          align: "center",
        },
      ],
      columnsRount: [
        {
          label: "运单号",
          prop: "waybillNo",
          align: "center",
        },
        {
          label: "操作时间",
          prop: "barScanTm",
          align: "center",
        },
        {
          label: "操作类型",
          prop: "opName",
          align: "center",
        },
        {
          label: "操作描述",
          prop: "remark",
          align: "center",
        },
      ],
      tableData: [],
      routTableDate: [],
      loadingOrder: false,
      paginationShow2: true,
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageRout: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      }
    }
  },
  watch: {
    vehicle: {
      handler(value) {
        // if (!value.plateNo) return;
        if (Object.keys(value).length !== 0) {
          this.page.currentPage = 1
          this.page.pageSize = 10
          this.pageRout.currentPage = 1
          this.pageRout.pageSize = 10
          this.paginationShow2 = false
          this.$nextTick(() => {
            this.paginationShow2 = true
          })
          this.getPointTable()
          this.getRoutTableData()
        }
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    //灶点详情分页
    sizeChangeOrder(val) {
      this.page.pageSize = val
      this.getPointTable()
    },
    currentChangeOrder(val) {
      this.page.currentPage = val
      this.getPointTable()
    },
    getPointTable() {
      this.$getData({
        type: "queryFocalPointDetailByPlateNo",
        page: this.page.currentPage,
        rows: this.page.pageSize,
        plateNo: this.vehicle.plateNo,
        driverName: this.vehicle.driverName,
        phone: this.vehicle.phone,
        departureTime: this.state === 'unreceipted' ? this.vehicle.departureTime.substring(0, 13) : null
      }).then((res) => {
        this.tableData = res.data
        this.page.total = res.count
      })
    },
    getRoutTableData() {
      this.$getData({
        type: "queryExternalRoutesByPlateNo",
        page: this.pageRout.currentPage,
        rows: this.pageRout.pageSize,
        plateNo: this.vehicle.plateNo || undefined,
        driverName: this.vehicle.driverName || undefined,
        phone: this.vehicle.phone || undefined,
        departureTime: this.state === 'unreceipted' ? this.vehicle.departureTime.substring(0, 13) : null
      }).then((res) => {
        this.routTableDate = res.data
        this.pageRout.total = res.count
      })
    },
    //路由详情分页
    sizeChangeRout(val) {
      this.pageRout.pageSize = val
      this.getRoutTableData()
    },
    currentChangeRout(val) {
      this.pageRout.currentPage = val
      this.getRoutTableData()
    },
    //下载
    download(row) {
      console.log(row)
      this.$getData({
        type: "crfRequestForward",
        url: `/ICRFS/GetCrfsOrderDetails?soid=${row.soid}`,
        method: "GET",
        download: 1
      }).then(async res => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '下载路径为空!',
            center: true
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.tableTwo {
  height: 100%;

  .clickButton {
    cursor: pointer;
    color: #17fff4;
  }
}
// 无数据背景色
::v-deep .el-table__empty-block,
.el-table__body-wrapper {
  background: rgba(0, 0, 0, 0.2) !important;
}
</style>