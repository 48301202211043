<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1284px"
      :append-to-body="true"
      custom-class="Dialog"
      top="calc((100vh - 750PX)/2)"
      @close="closeDialog">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <slot name="left-top"></slot>
          <div class="left-content">
            <Table
              ref="tableComponent"
              :columns="columns"
              :tableData="tableData"
              :total="pageList.count"
              :page.sync="pageList.page"
              :rows.sync="pageList.rows"
              :loading="loading"
              :stripe="stripe"
              :showOverflowTooltip="true"
              :align="'left'"
              :maxHeight="550"
              :indexWidth="70"
              :entitysShow="entitysShow"
              :searchShow="searchShow"
              @pagination="getDetailsPage">
              <template
                slot="columnEspecial"
                slot-scope="{ column, row }"
                resizable>
                <span v-if="column.prop === 'alarmDate'">{{
                  row[column.prop] | formatUTCTime
                }}</span>
                <span v-else-if="column.prop === 'handleDate'">{{
                  row[column.prop] | formatUTCTime(true)
                }}</span>
                <span v-else-if="column.prop === 'status'" class="status">{{
                  filterStatus(row[column.prop])
                }}</span>
                <span v-else-if="column.prop === 'type'">{{
                  row[column.prop] | alarmType
                }}</span>
                <span v-else-if="column.prop === 'alarmContent'">
                  <span v-if="row.type === 3" class="preString" @click="openRejection(row)">{{row.preString}}</span>
                  {{row.alarmContent}}
                  <div v-if="row.urls" style="display:flex;">
                    <img class="img-item" v-if="index < 3" v-for="(item,index) in row.urls" :key="index" @click="lookImg(row,index)" :src="item" alt="">
                    <div class="img-box" @click="lookImg(row,0)">
                      <img class="img" v-if="row.urls.length > 3" :src="imageSrc" alt="">
                    </div>
                  </div>
                </span>
                <span v-else>{{ row[column.prop] }}</span>
              </template>

              <template slot="columnRight" v-if="!alarmStatus">
                <el-table-column
                  label="操作"
                  fixed="right"
                  width="160"
                  align="center">
                  <template slot-scope="{ row }">
                    <div ref="columnRight">
                      <el-button type="text" size="mini" @click="treated(row)" class="btnZoom">处理</el-button>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title=""
      width="600PX"
      :append-to-body="true"
      :visible.sync="signForPictureDia">
      <div class="imgTitle">
        <div class="title">{{focalPointName}}</div>
        <div class="text">{{alarmContent}}</div>
      </div>
      <div class="image" v-loading='fullscreenLoading' element-loading-background="rgba(0, 0, 0)">
        <div v-if="signingPics.length > 0">
          <el-carousel class="imgList" :autoplay="false" indicator-position="none" ref="carousel" @change="carouselChange">
            <el-carousel-item v-for="(item,index) in signingPics" :key="index">
              <el-image class="imgs" :src="item" height="300PX" alt :preview-src-list="signingPics" />
              <!-- <img class="imgs" :src="item" height="300px"> -->
            </el-carousel-item>
          </el-carousel>
          <div class="picList">
            <div v-for="(item, index) in signingPics" :key="index" class="indicator">
              <img :src="item" alt :class="imgActiveIndex == index ? 'img-br' : ''" @click="handleImgClick(index)" />
            </div>
          </div>
        </div>
        <div class="noPic" v-else>
          <!-- <el-image> -->
            <div slot="error" class="image-slot">
              <img class="img" :src="imageSrc">
            </div>
          <!-- </el-image> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatFloat } from "@/utils";
import bus from "@/utils/bus";

export default {
  name: "detailsPopup",
  components: {
    Table
  },
  props: {
    entitysShow: {
      type: Array,
      default: function () {
        return []
      }
    },
    WarningProcessingProp: {
      default: function () {
        return {};
      }
    },
    alarmStatus: {
      default: 0
    },
    searchShow: {
      type: Boolean
    },
    modal: {
      type: Boolean,
      default: true
    },
    pageList: {
      default: function () {
        return {};
      }
    },
    getDetailsPage: {
      type: Function
    },
    dialogTitle: {
      type: String
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    total: {
      type: Number,
      default() {
        return 0;
      }
    },
    page: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 20
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    stripe: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    alarmType(val) {
      if (val == 0) {
        return '订单'
      } else if(val == 1){
        return '运输'
      }else if(val == 2){
        return '温度'
      }else if(val == 3){
        return '拒收'
      }
    }
  },
  data() {
    return {
      focalPointName: '',
      alarmContent: '',
      dialogVisible: true,
      signForPictureDia: false,
      fullscreenLoading: false,
      signingPics: [],
      imgActiveIndex: 0,
      imageSrc: require('@/assets/image/noTitlePic.png'),
    };
  },
  methods: {
    openRejection(v){
      bus.$emit('confirmRejection', v)
    },
    lookImg(row, v) {
      this.signingPics = row.urls
      this.focalPointName = row.focalPointName
      this.alarmContent = row.alarmContent
      this.$nextTick(() => {
        this.signForPictureDia = true
        this.imgActiveIndex = v;
        setTimeout(() => {
          this.$refs.carousel.setActiveItem(v);
        }, 100)
      })
    },
    handleImgClick(index) {
      this.imgActiveIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    carouselChange(v) {
      this.imgActiveIndex = v
    },
    closeDialog() {
      this.$emit("clos:closeDetails");
    },
    FomatFloat(num, n = 2) {
      let m = formatFloat(num, n);
      let res = "";
      if (m && m.split(".")[1] === "00") {
        res = m.split(".")[0];
      } else {
        res = m;
      }
      return res;
    },
    async treated(row) {
      if(row.type === 3){
        this.$emit("getAlarmID", row.remark,row.type);
      }else{
        this.$emit("getAlarmID", row.id,row.type);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.btnZoom{
  font-size: 14PX;
  padding: 4PX 6PX;
}

::v-deep .el-dialog__wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
}
::v-deep .el-table__body-wrapper {
  height: 510px !important;
}

::v-deep .el-dialog {
  // height: 75%;
  height: 750px;
  border: 2px solid #5473bf;
  background: linear-gradient(
    180deg,
    rgba(0, 15, 40, 0.5),
    rgba(0, 82, 218, 0.5) 100%
  );
}

//分页样式
::v-deep .el-pagination__total {
  color: rgba(255, 255, 255, 0.8);
}

.pagination-container {
  padding: 12px 0 0 0;

  ::v-deep .el-input__inner,
  ::v-deep .el-pagination__jump {
    color: rgba(255, 255, 255, 0.8);
    border: none;
    position: relative;
  }
}

// 下面是pagination
.pagination-container {
  background: transparent;
  padding: 32px 16px;
  display: flex;
  flex-direction: row-reverse;
}

.pagination-container.hidden {
  display: none;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent;
  color: #17fff4;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(62, 100, 172, 0.3);
  color: #17fff4;
  border: 1px solid #87d2ff;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .el-pagination.is-background .el-pager li:hover,
::v-deep .el-table__body tr.hover-row > td {
  background-color: rgba(62, 100, 172, 0.25);
}

.imgTitle {
  margin-top: -54PX;
  margin-bottom: 16PX;
  .title {
    color: #17f1ec;
    font-size: 14PX;
    line-height: 20PX;
    font-weight: 500;
    padding-right: 20PX;
  }
  .text {
    font-size: 14PX;
    line-height: 20PX;
    font-weight: 500;
    color: #ffffff;
    padding-right: 20PX;
  }
}

.image {
  // width: 568px;
  // height: 426px;
  .imgs {
    height: 300PX;
    width: 100%;
  }
  .imgList{
    height: 300PX;
    ::v-deep .el-carousel__container{
      height: 300PX;
    }
  }
  .picList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .indicator {
      padding: 20PX 5PX 0 0;
      img {
        width: 60PX;
        height: 50PX;
      }
      .img-br {
        border: 2PX solid #17f1ec;
      }
    }
  }
  .noPic {
    width: 500PX;
    height: 380PX;
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 40PX;
    .img {
      padding: 120PX 190PX;
    }
  }
}

::v-deep {
  .el-dialog__header {
    //  height: 0 !important;
    //  padding: 0 !important;
  }
  .el-dialog {
    height: inherit !important;
  }
  .el-dialog__body {
    padding: 16px !important;
  }
}

.img-item {
  width: 32PX;
  height: 32PX;
  margin-right: 4PX;
}
.img-box {
  width: 32PX;
  height: 32PX;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    width: 16PX;
    height: 16PX;
  }
}
.preString{
  color: #17FFF4;
  font-weight: 500;
  cursor: pointer;
}
</style>
