<template>
  <div class="dialog">
    <el-dialog
      width="1284px"
      title="供应商占比详情"
      :visible="show"
      top="calc((100vh - 720Px) / 2)"
      @close="closeDetail">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <div class="left-top">
            <div class="buttons">
              <el-button
                class="tab-item"
                type="mini"
                :class="{ 'tab-active': orderType === 'amt' }"
                @click="orderType = 'amt'">
                订购金额
              </el-button>
              <el-button
                class="tab-item"
                type="mini"
                :class="{ 'tab-active': orderType === 'orderNum' }"
                @click="orderType = 'orderNum'">
                订购数量
              </el-button>
<!--              <el-button
                v-permission="'isCanDownload'"
                class="btnZoom"
                type="mini"
                @click="exportDetail">导出</el-button>-->
            </div>
            <el-date-picker
              class="month-icon-style"
              v-model="monthValue"
              value-format="yyyyMM"
              type="month"
              placeholder="选择月"
              :picker-options="monthPickerOptions"
              @change="changMonthValue">
            </el-date-picker>
          </div>
          <Charts :option="option" />
        </div>
        <div class="right">
          <Table
            ref="tableComponent"
            :columns="columns"
            :tableData="tableData"
            :total="pageList.count"
            :loading="false"
            :searchShow="false"
            :height="570"
            :stripe="false"
            :page.sync="pageList.page"
            :rows.sync="pageList.rows"
            @pagination="getDetailsPage(arguments, false)"
            @searchList="getDetailsPage">
            <template
              slot="columnEspecial"
              slot-scope="{ column, row }"
              resizable>
              <span v-if="['amountRatio', 'weightRatio'].includes(column.prop)">
                {{ (row[column.prop]) | Format }}%
              </span>
              <span v-else-if="['amount', 'weight'].includes(column.prop)">
                {{ row[column.prop] | Format }}
              </span>
              <span v-else>{{ row[column.prop] }}</span>
            </template>
          </Table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatFloat, formatDate } from "@/utils";
import Charts from "@/components/Charts";
import { Format } from "@/filters";
import { exportSupplier } from "@/api/home";

export default {
  name: "categoryDetails",
  components: {
    Table,
    Charts,
  },
  props: {
    show: {
      type: Boolean,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      columns: [],
      orderType: "amt",
      option: {},
      chartData: [],
      tableData: [],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
        ["rgba(93,112,146,0)", "rgba(93,112,146,1)", "#BAD2FF"],
      ],
      monthValue: '',
      demoMonth: '',
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
    };
  },
  watch: {
    type: {
      handler(value) {
        if (value === "ddamt") {
          this.orderType = "amt";
        } else {
          this.orderType = "orderNum";
        }
      },
      immediate: true,
    },
    orderType: {
      handler(value) {
        this.pageList.page = 1;
        // this.drawCharts();
        this.getDetailsPage();
        if (value === "amt") {
          this.columns = [
            {
              prop: "vendorName",
              label: "供应商名称",
              align: "left",
              width: 200,
            },
            {
              prop: "amount",
              label: "订购金额(元)",
              width: 100,
              align: "right",
            },
            {
              prop: "amountRatio",
              label: "订购占比",
            },
          ];
        } else {
          this.columns = [
            {
              prop: "vendorName",
              label: "供应商名称",
              align: "left",
              width: 200,
            },
            {
              prop: "weight",
              label: "订购数量(斤)",
              width: 100,
              align: "right",
            },
            {
              prop: "weightRatio",
              label: "订购占比",
            },
          ];
        }
      },
      immediate: true,
    },
  },
  methods: {
    changMonthValue(){
      this.getDetailsPage([this.demoMonth,1])
    },
    closeDetail() {
      this.$emit("update:show", false);
    },
    formatFloat(num, n = 2) {
      // if (formatFloat(num, n).split(".")[1] === "00") {
      //   return formatFloat(num, n).split(".")[0];
      // } else {
      return formatFloat(num, n);
      // }
    },
    // 切换分类
    selectChange(value) {
      this.selectValue = value;
    },
    //导出
    exportDetail() {
      const global = this.$store.getters.global;
      // const month = formatDate(global.month);
      const paramsObj = {
        month: this.demoMonth,
        day: global.dateModel.replace(/-/g,''),
        orderBy: this.orderType === 'amt' ? 'amount' : "weight"
      }
      /* const paramsObj = {
        date: month,
        orderInfoType: this.orderType
      } */
      exportSupplier(paramsObj).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(blob)
        link.download = '供应商占比导出.xlsx'
        document.body.appendChild(link)
        link.click()
        // 兼容火狐浏览器
        document.body.removeChild(link)
        // console.log(res)
      })
    },
    // 获取详情数据
    async getDetailsPage(args,reloadPie=true) {
      if(args){
        if(args[1] == 1){
          this.pageList.page = 1
        }
      }
      const global = this.$store.getters.global;
      // const month = formatDate(global.month);
      /* let params = Object.assign({
        type: "vendorRatioDetail",
        date: month,
        orderBy: this.orderType,
        page: this.pageList.page,
        rows: this.pageList.rows,
      }); */
      let params = Object.assign({
          page: this.pageList.page,
          rows: this.pageList.rows,
          type: "vendorRatioChart",
          day: global.dateModel.replace(/-/g, ''),
          month: this.demoMonth,
          orderBy: this.orderType === 'amt' ? 'amount' : "weight" // tab切换字段(amount:订购金额/weight:订购重量)
      });
      let res = await this.$getData(params);
      if(res.code!==0) return
      if(res.data.page.data){
        res.data.page.data.map(item =>{
          item.vendorName = "武汉市******有限公司" + (parseInt(item.vendorId)+32)
        })
      }
      if(res.data.pie){
        res.data.pie.map(item => {
          item.vendorName = "武汉市******有限公司" + (parseInt(item.vendorId)+32)
        })
      }
      this.tableData = res.data.page.data;
      this.pageList.count = res.data.page.count
      this.chartData = res.data.pie;
      reloadPie && this.drawCharts()
    },
    async drawCharts() {
      /* const res = await this.$getData({
        type: "vendorRatioDetail",
        orderBy: this.orderType,
        date: formatDate(this.$store.getters.global.month),
        parm: 1,
      }); */
      // this.chartData = res.data;

      // 计算总数
      var total = this.chartData.map((v) => v.value).reduce((o, n) => o + n,0);

      // 计算每一个data的起始角度和末了角度 θ1和θ2
      this.chartData.reduce((o, v) => {
        v.delta1 = o;
        v.delta2 = o + v.value / total;
        return v.delta2;
      }, 0);
      // 添加渐变
      this.chartData.forEach((v) => {
        var ops = this.calc(v.delta1 * 2 * Math.PI, v.delta2 * 2 * Math.PI);
        if (v.value) {
          v.itemStyle = {
            borderWidth: 2,
            borderColor: v.color1 || "#ADABFFFF", // 边框颜色
            shadowColor: v.color0 || "#ADABFF00", // 投影样式
            shadowBlur: 10, // 投影模糊
            color: {
              type: "radial",
              x: ops.center[0],
              y: ops.center[1],
              r: ops.radius,
              colorStops: [
                {
                  offset: 0,
                  color: v.color0,
                },
                {
                  offset: 0.5,
                  color: v.color0,
                },
                {
                  offset: 0.3,
                  color: v.color1,
                },
                {
                  offset: 1,
                  color: v.color1,
                },
              ],
              globalCoord: false, // 缺省为 false
            },
          };
          // 引线
          v.label = {
            normal: {
              show: false,
              formatter: "{b}\n\n{d}%",
              textStyle: {
                fontSize: 12,
                color: v.color1,
              },
              position: "outside",
            },
            emphasis: {
              show: true,
            },
          };
        }
      });
      this.option = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(
              params.value
            )}${this.orderType === "amt" ? "元" : "斤"} (${Format(
              (this.orderType === "amt"
                ? params.data.amountRatio
                : params.data.weightRatio)
            )}%)`;
          },
          confine: true,
        },
        legend: {
          data: this.chartData.map(item => item.vendorName),
          orient: "vertical",
          type: "scroll",
          x: "52%",
          y: 0,
          icon: "Rect",
          itemWidth: 10,
          itemHeight: 10,
          align: "left",
          // formatter: (p) => {
          //   const index = this.chartData.findIndex(item => item.vendorName === p);
          //   if(index > 10){
          //     return
          //   }else {
          //     return p
          //   }
          //   // const leng = this.chartData
          //   //   .concat(this.percentList)
          //   //   .find((val) => p === val.vendorName);
          //   // let { vendorName } = leng;
          //   // return `${vendorName}`;
          // },
          selectedMode: false,
          textStyle: {
            color: "#fff",
            fontSize: 11,
            rich: {
              b: {
                fontSize: 12,
              },
            },
          },
          pageTextStyle:{
              color: '#fff', //翻页数字颜色
          },
            // pageIconColor: '#6495ed', //翻页下一页的三角按钮颜色
            // pageIconInactiveColor: '#aaa', //翻页（即翻页到头时）
        },
        series: [
          {
            name: "供应商占比",
            type: "pie",
            radius: ["40%", "60%"],
            center: ["25%", "50%"],
            label: {
              show: false,
              formatter: (params) => {
                this.percentList = this.chartData.map((p) => {
                  if (p.vendorName === params.name) {
                    p.percent = params.percent;
                  }
                  return p;
                });
              },
            },
            data: this.chartData.map((item, index) => ({
              ...item,
              value: this.orderType === "amt" ? item.amount : item.weight,
              name: item.vendorName,
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: this.colors[index > 10 ? 10 : index][0] },
                  { offset: 1, color: this.colors[index > 10 ? 10 : index][1] },
                ]),
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: this.colors[index > 10 ? 10 : index][1],
                borderColor: this.colors[index > 10 ? 10 : index][2],
              },
            })),
          },
        ],
      };
    },
    // 计算渐变中心以及渐变半径
    calc(delta1, delta2) {
      var r = 0.5; // 半径是0.5 其实表示0.5个直径
      var inner = 0.6; // 里面镂空部分占60%  option中radius为[33%, 55%]  55 * 0.6 == 33

      var cos = Math.cos;
      var sin = Math.sin;
      var PI = Math.PI;
      var min = Math.min;
      var max = Math.max;

      var bottom = 0;
      var left = 2 * r;
      var right = 0;

      // y0: θ1对应的外部点的y值
      // y1: θ2对应的外部点的y值
      // _y0: θ1对应的内部点的y值
      // _y1: θ2对应的内部点的y值
      // x0: θ1对应的外部点的x值
      // x1: θ2对应的外部点的x值
      // _x0: θ1对应的内部点的x值
      // _x1: θ2对应的内部点的x值
      var y0 = r * (1 - cos(delta1));
      var y1 = r * (1 - cos(delta2));

      var _y0 = r * (1 - inner * cos(delta1));
      var _y1 = r * (1 - inner * cos(delta2));

      // 如果这个弧经过θ == PI的点  则bottom = 2PI
      // bottom用于之后的max计算中
      if (delta1 < PI && delta2 > PI) {
        bottom = 2 * r;
      }
      // 计算这几个点的最大最小值
      var ymin = min(_y0, _y1, y0, y1);
      var ymax = max(_y0, _y1, y0, y1, bottom);

      var x0 = r * (1 + sin(delta1));
      var x1 = r * (1 + sin(delta2));

      var _x0 = r * (1 + inner * sin(delta1));
      var _x1 = r * (1 + inner * sin(delta2));

      // 如果这个弧经过θ == PI / 2的点  则right = 2PI
      if (delta1 < PI / 2 && delta2 > PI / 2) {
        right = 2 * r;
      }
      // 如果这个弧经过θ == PI / 2 * 3的点  则left = 0
      if (delta1 < (PI / 2) * 3 && delta2 > (PI / 2) * 3) {
        left = 0;
      }
      var xmin = min(_x0, _x1, x0, x1, left);
      var xmax = max(_x0, _x1, x1, x0, right);

      return {
        // 计算圆心以及半径
        center: [(r - xmin) / (xmax - xmin), (r - ymin) / (ymax - ymin)],
        radius: r / min(xmax - xmin, ymax - ymin),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";

.dialog {
  .dialog-content {
    display: flex;
    width: 100%;
    height: 90%;

    .left {
      width: 54%;
      height: 100%;
    }

    .right {
      padding-top: 0;
      width: 46%;
      .table {
        height: 100%;
      }
    }
  }

  .el-table::before {
    height: 0;
  }
  .btnZoom {
    width: 72PX;
    height: 32PX;
    margin-left:10PX;
    font-size:14PX;
    padding:0PX 0PX;
    background: rgba(62, 100, 172, 0.3);
    border: 1PX solid #87d2ff;
    border-radius: 0;
    color: rgba(255, 255, 255, 0.75);
  }

  .left-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 40px;

    .buttons {
      display: flex;
      .tab-item {
        width: 100PX;
        height: 32PX;
        font-size: 14PX;
        padding: 0PX 0PX;
        cursor: pointer;
        margin: 0;
        border: 1PX solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.75);
        background: rgba(62, 100, 172, 0.3);
      }

      .tab-item:first-child {
        border-right: none;
      }

      .tab-active {
        color: #17fff4;
        font-size: 14PX;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: #5f97ff 0 0 10px inset;
      }
    }
  }
}

::v-deep {
  .el-dialog {
    height: 720px;
  }
  .el-table thead {
    color: #fff;
  }
}
</style>
