<template>
  <div>
    <Charts-border
      borderTitle="订单变化趋势"
      titleBgWidth="200PX"
      :option="option"
    >
      <div class="right-tab-boxs" slot="rightButton">
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 1 }"
          @click="type = 1"
        >
          订单金额
        </div>
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 0 }"
          @click="type = 0"
        >
          订单数量
        </div>
      </div>
      <div slot="leftBottom">
        <span @click="openDetails">查看详情</span>
      </div>
    </Charts-border>
    <Details
      ref="Details"
      v-if="showDetails"
      :dialogTitle="'订单变化趋势详情'"
      :stripe="false"
      :loading="false"
      :columns="columns"
      :tableData="tableData"
      :pagelist="pagelist"
      :orderType="orderType"
      :getDetailsPage="getDetailsPage"
      :marginTop="`calc((100vh - 750Px) / 2)`"
      @clos:closeDetails="closeDetails"
    >
      <div slot="top-tab" class="left-top">
        <div class="right-tab-box">
          <div
            class="right-tab-item"
            :class="orderInfoType === 1 ? 'right-tab-active' : ''"
            @click="orderInfoType = 1"
          >
            按月展示
          </div>
          <div
            class="right-tab-item"
            :class="orderInfoType === 2 ? 'right-tab-active' : ''"
            @click="orderInfoType = 2"
          >
            按日展示
          </div>
          <div
            class="right-tab-item"
            :class="orderInfoType === 3 ? 'right-tab-active' : ''"
            @click="orderInfoType = 3"
          >
            近3月数据
          </div>
        </div>
<!--        <div class="right-tab-box">
          <el-button
            v-permission="'isCanDownload'"
            class="BlueCustomButton searchBottom"
            @click.native="exportTion()"
            >导出</el-button
          >
        </div>-->
      </div>
      <div slot="bottom-tab" class="left-top">
        <div class="right-tab-box">
          <div
            class="right-tab-item"
            :class="type ? 'right-tab-active' : ''"
            @click="type = 1"
          >
            订单金额
          </div>
          <div
            class="right-tab-item"
            :class="!type ? 'right-tab-active' : ''"
            @click="type = 0"
          >
            订单数量
          </div>
        </div>
      </div>
    </Details>
  </div>
</template>
<script>
import ChartsBorder from "../viewsComponents/ChartsBorder/index.vue";
import watchMonth from "@/mixins/watchMonth";
import { formatInt, formatFloat, formatDate } from "@/utils";
import Details from "../viewsComponents/Details/OrderChangeDetails.vue";
import { exportOrderQuantity } from "@/api/home";
import { mapState } from "vuex";
export default {
  name: "orderReview",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
    Details,
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let { date, orderAmt, orderNum } = params[0].data;
            return `日期：<span style="font-weight: bold;">${date}</span> <br/> ${
              orderAmt ? "订单金额" : "数量"
            }：<span style="font-weight: bold;">${
              orderAmt ? formatFloat(orderAmt) : formatFloat(orderNum, 0)
            }</span> ${orderAmt ? "万元" : "单"}`;
          },
        },
        xAxis: {
          // name: '日期',
          // nameLocation: 'end',
          type: "category",
          boundaryGap: true,
          nameTextStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          // data: ["2021-01", "2021-02", "2021-03", "2021-04", "2021-05", "2021-06"],
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
            interval: 0,
            rotate: 30, // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          // name: '金额',
          type: "value",
          min: function(value) {
            //取最小值向下取整为最小刻度
            return formatInt(Math.floor(value.min), 2, false);
          },
          // max: function(value) {//取最大值向上取整为最大刻度
          //     return  Math.ceil(value.max)
          // },
          scale: true, //自适应
          // minInterval: 0.1, //分割刻度
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        grid: {
          top: "35%",
          left: "3%",
          right: "10%",
          bottom: "8%",
          containLabel: true,
        },
        series: [
          {
            // data: [740, 1000, 500, 300, 450, 700],
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(23,241,236,1)",
                },
                {
                  offset: 0,
                  color: "rgba(23,241,236,0.1)",
                },
              ]),
            },
          },
        ],
      },
      type: 1,
      items: [],
      items2: [],
      showDetails: false,
      columns: [
        {
          label: "日期",
          prop: "orderDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width: 50,
        },
        {
          label: "订单金额(万元)",
          prop: "orderAmt",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 50,
          align: "right",
        },
        {
          label: "订单金额环比",
          prop: "orderAmtRatio",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 95,
          align: "right",
        },
         {
          label: "订单数量(单)",
          prop: "orderNum",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 50,
          align: "right",
        },
        {
          label: "订单数环比",
          prop: "orderNmuRatio",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 95,
          align: "right",
        },
      ],
      tableData: [],
      detailsData: [],
      pagelist: {
        page: 1,
        rows: 20,
        count: 0,
      },
      date: "",
      orderInfoType: 3,
      orderType: "orderAmt",
    };
  },
  computed: {
    ...mapState("global", ["month"]),
  },
  watch: {
    orderInfoType(val) {
      this.pagelist = {
        page: 1,
        rows: 20,
        count: 0,
      };
      this.columns[0].label = val === 1 ? "月份" : "日期";
      this.getDetailsData(val);
      this.getDetailsPage();
    },
    type(val) {
      this.option = {
        // yAxis: {
        //   name: val ? '金额':'数量'
        // },
        dataset: {
          source:
            this.items.length === 0 ? [] : this.Judging(this.type, this.items),
        },
      };
      this.orderType = val ? "orderAmt" : "orderNum";
      // this.columns[1].label = val ? "订单金额(万元)" : "订单数量(单)";
      // this.columns[1].prop = val ? "orderAmt" : "orderNum";
      // this.columns[2].label = val ? "订单金额环比" : "订单数量环比";
      // this.columns[2].prop = val ? "orderAmtRatio" : "orderNmuRatio";
      if (this.$refs.Details) {
        // (this.pagelist = {
        //   page: 1,
        //   rows: 20,
        //   count: 0,
        // }),
          // this.getDetailsPage(); // 获取详情表格数据
        this.setDetailsOption(
          this.conversion(this.detailsData, val ? "orderAmt" : "orderNum")
        );
      }
    },
  },
  created() {
    // 订单趋势 --- 订单金额、订单数量 只需调用一次接口，切换标签不调用接口
    this.timer = setInterval(() => {
      // 获取订单金额、订单数量
      this.getData(formatDate(this.month));
    }, 1000 * 60);
  },
  methods: {
    exportTion() {
      const mothod = exportOrderQuantity;
      let exportType;
      if (this.type == "1") {
        exportType = "amt";
      } else {
        exportType = "weight";
      }
      let obj = {
        orderInfoType: this.orderInfoType + "",
        exportType: exportType,
        warehouseCode: this.warehouseCode
      };
      if(this.orderInfoType !== 3){
        obj.date = formatDate(this.month)
      }
      mothod(obj).then((res) => {
        let title = res.headers["content-disposition"].split("=");
        let title2 = decodeURI(title[1]);
        const link = document.createElement("a");
        const blob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        link.style.display = "none";
        link.href = window.URL.createObjectURL(blob);
        link.download = title2 + "";
        document.body.appendChild(link);
        link.click();
        // 兼容火狐浏览器
        document.body.removeChild(link);
        // console.log(res)
      });
    },
    getData(month) {
      this.date = month;
      const paramsObj = {
        type: "orderInfoDaily",
        date: month,
        warehouseCode: this.warehouseCode
      }
      /* const paramsObj ={ // 原先接口参数
        type: "crfRequestForward",
        url: `/ICRFS/GetOrders?type=${1}&dateid=${month}`,
        method: "GET",
      } */
      this.$getData(paramsObj).then((res) => {
        if(res.code !== 0) return
        this.items2 = res.data;
        this.items = res.data.slice(-7);

        /* if (!res.data) return;
        let data = JSON.parse(res.data);
        this.items2 = data.items;
        
        // this.items = data.items.slice(0, 7);
        this.items = data.items.slice(-7); */
        this.option = {
          dataset: {
            source:
              this.items.length === 0
                ? []
                : this.Judging(this.type, this.items),
          },
        };
      });
    },
    Judging(type, data) {
      // 大屏：订单金额和订单数量按钮切换事件
      return data.map((item) => {
        let { date, orderAmt, orderNum } = item;
        let obj = {};
        if (type) {
          obj = {
            date,
            orderAmt,
          };
        } else {
          obj = {
            date,
            orderNum,
          };
        }
        return obj;
      });
    },
    openDetails() {
      //打开详情弹窗
      this.showDetails = true;
      this.pagelist.page = 1;
      this.pagelist.rows = 20;
      this.getDetailsData(this.orderInfoType); // 获取详情图表数据
      this.getDetailsPage(); // 获取详情表格数据
    },
    async getDetailsData(orderInfoType) {
      let obj ={
        type: "orderInfo",
        date: this.date,
        orderInfoType,
        rows: 51, // 大于或等于50时 返回从小到大的倒叙数据
        warehouseCode: this.warehouseCode
      }
      if(orderInfoType === 3){
        obj.rows = 99
        delete obj.date
      }
      // 获取详情数据
      let res = await this.$getData(obj);

      if (orderInfoType === 1) {
        // 将 2021年08月 的日期格式处理成 2021-08 的格式
        this.detailsData = res.data.map((item) => {
          item.orderDate = item.orderDate.replace(/年/, "-").replace(/月/, "");
          return item;
        });
      } else {
        // 将 2021年08月12号 的日期格式处理成 08-12 的格式
        this.detailsData = res.data.map((item) => {
          item.orderDate = item.orderDate
            .slice(5, item.orderDate.length - 1)
            .replace(/月/, "-");
          return item;
        });
      }

      this.$nextTick(async () => {
        this.setDetailsOption(
          this.conversion(this.detailsData, this.orderType)
        );
      });
    },
    conversion(data, orderType) {
      if (!data.length) return [];
      return data.map((item) => {
        return {
          orderDate: item.orderDate,
          data: item[orderType],
        };
      });
    },
    setDetailsOption(source) {
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let { data, orderDate } = params[0].data;
            return `${
              this.orderInfoType === 1 ? "月份" : "日期"
            }：<span style="font-weight: bold;">${orderDate}</span> <br/>
              ${
                this.orderType === "orderAmt" ? "订单金额" : "订单数量"
              }：<span style="font-weight: bold;">${formatFloat(
              data,
              this.orderType === "orderAmt" ? 2 : 0
            )}</span> ${this.orderType === "orderAmt" ? "万元" : "单"}<br/> `;
          },
        },
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 85,
        //     end: 105
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 65,
        //     end: 85
        //   }
        // ],
        xAxis: {
          // name: '日期',
          type: "category",
          boundaryGap: true,
          // data: ["2021-01", "2021-02", "2021-03", "2021-04", "2021-05", "2021-06"],
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
            interval: 0,
            // rotate: 30 // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          min: function(value) {
            //取最小值向下取整为最小刻度
            return formatInt(Math.floor(value.min), 2, false);
          },
          // max: function(value) {//取最大值向上取整为最大刻度
          //     return  Math.ceil(value.max)
          // },
          scale: true, //自适应
          // minInterval: 0.1, //分割刻度
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          containLabel: true,
        },
        series: [
          {
            // data: [740, 1000, 500, 300, 450, 700],
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(23,241,236,1)",
                },
                {
                  offset: 0,
                  color: "rgba(23,241,236,0.1)",
                },
              ]),
            },
          },
        ],
        dataset: {
          source,
        },
      };
      // 按日期展示
      if (this.orderInfoType === 2 && source.length >= 5) {
        let start;
        if (source.length > 5 && source.length <= 10) {
          start = 50;
        } else if (source.length > 10 && source.length <= 15) {
          start = 60;
        } else if (source.length > 15 && source.length <= 22) {
          start = 70;
        } else if (source.length > 22 && source.length < 32) {
          start = 80;
        } else {
          start = 0;
        }
        option.dataZoom = [
          {
            show: true,
            showDataShadow: false,
            showDetail: false,
            handleStyle: {
              color: "#17F1EC",
            },
            backgroundColor: "rgba(0,0,0,0.20)",
            borderColor: "rgba(0, 0, 0, 0)",
            start,
            end: 100,
          },
        ];
      } else if(this.orderInfoType === 3){
        option.dataZoom = [
          {
            show: false,
            start: 0,
            end: 100,
          }
        ]
        option.xAxis.axisLabel.interval = 4
        option.xAxis.axisLabel.rotate = 30
      }else {
        option.dataZoom = [
          {
            show: true,
            showDataShadow: false,
            showDetail: false,
            handleStyle: {
              color: "#17F1EC",
            },
            backgroundColor: "rgba(0,0,0,0.20)",
            borderColor: "rgba(0, 0, 0, 0)",
            start: 0,
            end: 100,
          },
        ];
      }
      this.$refs.Details.setDetailsOption(option);
    },
    closeDetails() {
      this.showDetails = false;
      this.orderInfoType = 3
    },
    getDetailsPage() {
      this.$nextTick(async () => {
        let params = Object.assign(
          {
            type: "orderInfo",
            date: this.date,
            orderInfoType: this.orderInfoType,
            page: this.pagelist.page,
            rows: this.pagelist.rows,
            warehouseCode: this.warehouseCode
          },
          this.$refs && this.$refs.Details.getEntitys().params
        );
        if(this.orderInfoType === 3){
          delete params.date
        }
        let res = await this.$getData(params);
        this.tableData = res.data;
        this.pagelist.count = res.count;
      });
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  },
};
</script>
<style lang="scss" scoped>
.right-tab-box {
  display: flex;
  margin: 10PX;
  .right-tab-item {
    color: rgba(255, 255, 255, 0.75);
    border: 1PX solid #87d2ff;
    padding: 0 6PX;
    height: 32PX;
    line-height: 32PX;
    font-size: 14PX;
    cursor: pointer;
    background: rgba(62, 100, 172, 0.3);
  }
  .right-tab-item:first-child {
    border-right: none;
  }
  .right-tab-active {
    color: #17fff4;
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  }
}
.right-tab-boxs {
  display: flex;
  margin: 10px;
  .right-tab-item {
    color: rgba(255, 255, 255, 0.75);
    border: 1px solid #87d2ff;
    padding: 0 6px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    cursor: pointer;
    background: rgba(62, 100, 172, 0.3);
  }
  .right-tab-item:first-child {
    border-right: none;
  }
  .right-tab-active {
    color: #17fff4;
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  }
}
.searchBottom {
  width: 72PX;
  height: 32PX;
  font-size: 14PX;
  // line-height: 32PX;
  padding: 6PX;
  color: #fff;
}
.left-top {
  display: flex;
  justify-content: space-between;
}
</style>
