<template>
  <div class="unitBusinessEcharts">
    <div class="mian">
      <div class="row-box">
        <div class="col money1" ref="money1" :style="{width: width,height: height}"></div>
        <div class="col money2" ref="money2" :style="{width: width,height: height}"></div>
        <div class="col money3" ref="money3" :style="{width: width,height: height}"></div>
      </div>
      <div class="row-box">
        <div class="cols makeUp" ref="makeUp" :style="{width: width2,height: height}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, formatInt, formatFloat, format } from "@/utils";
export default {
  name: "unitBusinessEcharts",
  props: {
    bunitTime: {
      type: String,
      default: ''
    },
    money1Two: {
      type: Object,
      default: () => {
        return {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: "line",
            },
          ],
        };
      },
    },
    money2Two: {
      type: Object,
      default: () => {
        return {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: "line",
            },
          ],
        };
      },
    },
    money3Two: {
      type: Object,
      default: () => {
        return {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: "line",
            },
          ],
        };
      },
    },
    makeUpTwo: {
      type: Object,
      default: () => {
        return {
          xAxis: {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: [150, 230, 224, 218, 135, 147, 260],
              type: "line",
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      width: '31%',
      height: '100%',
      width2: '100%',
      money1: null,
      money2: null,
      money3: null,
      makeUp: null,
      $_resizeHandler: null,
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
      ],
    }
  },
  watch: {
    money1Two: {
      handler(value) {
        if (value.dataset.source.length > 0) {
          this.$nextTick(() => {
            this.money1.setOption(value);
          });
        } else {
          let obj = {
            dataset: {
              source: [
                {
                  date: "0",
                  orderAmt: "0"
                }
              ]
            }
          }
          this.$nextTick(() => {
            this.money1.setOption(obj);
          });
        }
      },
      deep: true,
      immediate: false,
    },
    money2Two: {
      handler(value) {
        this.$nextTick(() => {
          this.money2.setOption(value);
        });
      },
      deep: true,
      immediate: false,
    },
    money3Two: {
      handler(value) {
        this.$nextTick(() => {
          this.money3.setOption(value);
        });
      },
      deep: true,
      immediate: false,
    },
    makeUpTwo: {
      handler(value) {
        if (value.dataset.source.length > 0) {
          this.$nextTick(() => {
            this.makeUp.setOption(value);
          });
        } else {
          let obj = {
            dataset: {
              source: [
                ['', 0, 0]
              ]
            }
          }
          this.$nextTick(() => {
            this.makeUp.setOption(obj);
          });
        }
      },
      deep: true,
      immediate: false,
    },
    bunitTime: {
      handler() {
        this.initCharts()
      },
      deep: true,
      immediate: false,
    }
  },
  created() { },
  mounted() {
    // this.width = this.$refs.money1.clientWidth;
    // this.height = this.$refs.money1.clientHeight;
    this.money1 = this.$echarts.init(document.querySelector(".money1"));
    this.money2 = this.$echarts.init(document.querySelector(".money2"));
    this.money3 = this.$echarts.init(document.querySelector(".money3"));
    this.makeUp = this.$echarts.init(document.querySelector(".makeUp"));
    this.initCharts()
    // 监听window的resize
    this.initListener();
  },
  methods: {
    initCharts() {
      this.money1 = this.$echarts.init(this.$refs.money1, 'default');
      this.money2 = this.$echarts.init(this.$refs.money2, 'default');
      this.money3 = this.$echarts.init(this.$refs.money3, 'default');
      this.makeUp = this.$echarts.init(this.$refs.makeUp, 'default');
      let money1One = {
        title: {
          text: '订单金额趋势',
          subtext: this.bunitTime,
          left: '3%',
          top: '2%',
          textStyle: {
            color: '#17fff5',
            fontSize: 14
          },
          subtextStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let { date, orderAmt } = params[0].data;
            return `日期：<span style="font-weight: bold;">${date}</span> <br/> ${"订单金额"
              }：<span style="font-weight: bold;">${formatFloat(orderAmt)
              }</span> ${"万元"}`;
          },
        },
        xAxis: {
          // name: '日期',
          // nameLocation: 'end',
          type: "category",
          boundaryGap: true,
          nameTextStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          // data: ["2021-01", "2021-02", "2021-03", "2021-04", "2021-05", "2021-06"],
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "#ffffff",
            interval: 0,
            rotate: 30, // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          // name: '金额',
          type: "value",
          min: function (value) {
            //取最小值向下取整为最小刻度
            return formatInt(Math.floor(value.min), 2, false);
          },
          // max: function(value) {//取最大值向上取整为最大刻度
          //     return  Math.ceil(value.max)
          // },
          scale: true, //自适应
          // minInterval: 0.1, //分割刻度
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "#ffffff",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "10%",
          bottom: "8%",
          containLabel: true,
        },
        series: [
          {
            // data: [740, 1000, 500, 300, 450, 700],
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(23,241,236,1)",
                },
                {
                  offset: 0,
                  color: "rgba(23,241,236,0.1)",
                },
              ]),
            },
          },
        ],
      }
      this.money1.setOption(money1One);
      this.money1.setOption(this.money1Two);

      let money2One = {
        title: {
          text: '订单状态趋势',
          subtext: this.bunitTime,
          left: '3%',
          top: '2%',
          textStyle: {
            color: '#17fff5',
            fontSize: 14
          },
          subtextStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter(params) {
            if (params && params.length > 0) {
              return `${params[0].name}<br/><span style="font-weight: bold;">${format(params[0].value[1])}</span> 单`;
            } else {
              return "";
            }
          },
          confine: true
        },
        grid: {
          left: "3%",
          // top:"-10%",
          // right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          // name:"单",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,1)"
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)" // 分割线颜色
            }
          }
        },
        yAxis: {
          // name:"伙食单位",
          type: "category", // 不设置类目轴，抽离的dataset数据展示不出来
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,1)"
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: "bar",
            // name: "",
            barWidth: 15,
            label: {
              show: true,
              position: "center",
              offset: [15, 3],
              color: "#fff",
              style: {
                fill: "#fff"
              },
              formatter: params => {
                return format(params.data[1]);
              }
            },
            itemStyle: {
              label: {
                show: true
              },
              labelLine: {
                show: false
              },
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "rgba(23,241,236,0)" },
                { offset: 1, color: "rgba(23,241,236,1)" }
              ]),
              borderColor: "#a2f9f7",
              shadowBlur: 16,
              shadowColor: "rgba(23,241,236,1)"
            }
          }
        ]
      }
      this.money2.setOption(money2One);
      this.money2.setOption(this.money2Two);


      this.money3.setOption(this.money3Two);

      let makeUpOne = {
        title: {
          text: '品类占比',
          subtext: this.bunitTime,
          left: '1%',
          top: '2%',
          textStyle: {
            color: '#17fff5',
            fontSize: 14
          },
          subtextStyle: {
            color: '#ffffff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let [name, data, data_] = params[0].data;
            return `品类：<span style="font-weight: bold;">${name}</span> <br/>  金额：<span style="font-weight: bold;">
            ${format(formatFloat(data_ / 10000, 2))}万元 (${formatFloat(data)}%)</span>`;
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "#FFFFFF",
            interval: 0,
            rotate: 0, // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          min: function (value) {
            //取最小值向下取整为最小刻度
            return formatInt(Math.floor(value.min), 2, false);
          },
          scale: true, //自适应
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8,
          selectedMode: false,
          textStyle: {
            color: "#fff",
          },
        },
        grid: {
          top: "18%",
          left: "1%",
          right: "2%",
          bottom: "10%",
          containLabel: true,
        },
        series: [
          {
            type: "line",
            color: "#00f2f1", // 折线颜色
            smooth: true, // 折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "rgba(23,241,236,1)" },
                { offset: 0, color: "rgba(23,241,236,0.1)" },
              ]),
            },
          },
        ],
      }
      this.makeUp.setOption(makeUpOne);
      this.makeUp.setOption(this.makeUpTwo);
    },
    initListener() {
      this.$_resizeHandler = debounce(() => {
        this.money1.resize();
        this.money2.resize();
        this.money3.resize();
        this.makeUp.resize();
      }, 100);
      window.addEventListener("resize", this.$_resizeHandler, { passive: false });
    },
    destroyListener() {
      window.removeEventListener("resize", this.$_resizeHandler);
      this.$_resizeHandler = null;
    },
  },
  destroyed() {
    this.money1 && this.money1.dispose();
    this.money2 && this.money2.dispose();
    this.money3 && this.money3.dispose();
    this.makeUp && this.makeUp.dispose();
    this.destroyListener();
  }
}
</script>
<style lang="scss" scoped>
.unitBusinessEcharts {
  height: 100%;
  .mian {
    padding-top: 26px;
    height: 100%;
    .row-box {
      display: flex;
      justify-content: space-between;
      // height: 363px;
      margin-bottom: 24px;
      height: calc(50% - 24px);
      .col {
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
      .cols {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>