import { mapGetters } from "vuex";
import { formatDate } from "@/utils/";

export default {
  data() {
    return {
      $_resizeHandler: null,
    };
  },
  computed: {
    ...mapGetters(["global"]),
  },
  created() {
    this.$watch(
      () => {
        // return this.global.month;
        return this.global.dateModel;
      },
      (value) => {
        // this.getData(formatDate(value));
        const val = value.replace(/-/g, '').slice(0,6)
        this.getDayVal && this.getDayVal(value)
        this.getData(val);
      },
      {
        immediate: true,
      }
    );
  },
};
