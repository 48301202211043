<template>
  <div class="content">
    <div class="content-left">
      <Nav v-bind="$attrs" v-on="$listeners" @vehicleDatas="vehicleDatas" />
    </div>
    <div class="content-right">
      <div style="height:calc(25% - 8px);background: rgba(0,0,0,0.20);">
        <TableInex v-bind="$attrs" v-on="$listeners" :vehicle="vehiclePlateNo" :type="type" />
      </div>
      <div style="height:50%;margin:8px 0;">
        <Monitoring :vehicle="vehiclePlateNo" />
      </div>
      <div style="height:calc(25% - 8px);padding-top:8px; background: rgba(0,0,0,0.20);">
        <span class="titleText">温度明细</span>
        <TimeCharst :vehicle="vehiclePlateNo" />
      </div>
    </div>
  </div>
</template>
<script>
import TableInex from "./tableIndex.vue";
import Monitoring from "./monitoring.vue";
import TimeCharst from "./timeCharst.vue";
import Nav from "./navIndex.vue";

export default {
  components: {
    TableInex,
    Monitoring,
    TimeCharst,
    Nav
  },
  
  data() {
    return {
      vehiclePlateNo: {},
      vehicleParmas: {},
      type: 'somasterInfo'
    };
  },
  methods: {
    vehicleDatas(v) {
      this.vehiclePlateNo = {}
      this.$nextTick(()=>{
        this.vehiclePlateNo = v
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  .content-left {
    width: 340px;
    height: 100%;
    margin-right: 10px;
  }
  .content-right {
    width: calc(100% - 350px);
    height: 100%;
    .titleText {
      color: #17FFF4;
      margin-top:-18px;
      padding: 16px 0 0 10px;
    }
  }
}
</style>
