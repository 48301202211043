<template>
  <div ref="totalChart" class="totalChart"
    v-loading="loadingProce"
    element-loading-background="rgba(0,0,0,0.2)"></div>
</template>

<script>
import { debounce } from "@/utils";
export default {
  name: 'proceTotalCharts',
  props: {
    echartsProce: {
      type: Array
    },
    loadingProce: {
      type: Boolean
    }
  },
  data() {
    return {
      ref: "totalChart",
      myChart: null,
      colorIndex: 0,
      arr3: [],
      arr4: [],
      colors: [
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        // ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        // ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        // ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        // ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        // ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        // ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        // ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
      ],
    }
  },
  watch: {
    echartsProce: {
      handler(val) {
        if (val) {
          this.echartApi()
        }
      }
    },
    loadingProce: {
      handler(val) {
        this.loadingProce = val
      }
    }
  },
  mounted() {
    this.myChart = this.$echarts.init(document.querySelector(".totalChart"));

    this.initListener()
  },
  methods: {
    initListener() {
      this.$_resizeHandler = debounce(() => {
        this.myChart.resize();
      }, 100);
      window.addEventListener("resize", this.$_resizeHandler, { passive: false });
    },
    echartApi() {
      this.myChart = this.$echarts.init(this.$refs.totalChart, 'default');
    //  let echartsProceTwo = this.echartsProce.map(v=>{
    //         if(v.sort === 2){
    //             v.sort = 6
    //         }else if(v.sort === 6){
    //             v.sort = 2
    //         }
    //          return v
    //  })
    //  echartsProceTwo.sort((x,y)=>{
    //      return x.sort - y.sort
    //  })
    //  echartsProceTwo.pop()
     
      // let arr = [...this.echartsProce].reverse()
      // let arr2 = arr.map((item, index) => {
      //   let len = arr.length - 1
      //   if (index === 0 || index === len) {
      //     return 0
      //   } else {
      //     let sum = 0
      //     if (item.qty === 0) {
      //       sum = 0
      //     } else {
      //       for (let i = 0; i <= index - 1; i++) {
      //         sum += arr[i].qty;
      //       }
      //     }
      //     return sum
      //   }
      // })
      // this.arr3 = [...arr2].reverse()
      // this.arr4 = []
      // this.arr3.forEach((sub, index) => {
      //   this.echartsProce.forEach((sub2, index2) => {
      //     if (index == index2) {
      //       let all = sub + sub2.qty
      //       this.arr4.push({
      //         coord: [index, all],
      //         ratio: sub2.ratio
      //       })
      //     }
      //   })
      // })
      // this.arr4.shift()
       let echartsProce = [
        {
          qty: 1200,
          ratio: 0,
          sort: 1,
          states: 0,
          statesName: "总计值",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
        {
          qty: 900,
          ratio: 0.4875,
          sort: 2,
          states: 40,
          statesName: "未入库",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
         {
          qty: 900,
          ratio: 0.4875,
          sort: 3,
          states: 40,
          statesName: "已入库",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
        {
          qty: 300,
          ratio: 0.25,
          sort: 4,
          states: 20,
          statesName: "已上架",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
         {
          qty: 225,
          ratio: 0.1875,
          sort: 5,
          states: 10,
          statesName: "已分拣",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
        {
          qty: 90,
          ratio: 0.075,
          sort: 6,
          states: 30,
          statesName: "已出库",
          totalQty: 1200,
          updateTime: "2022-01-14 11:00"
        },
      ]
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            var tar = params[0];
            return tar.name + ' : ' + tar.value + '(斤)';
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          splitLine: { show: false },
          data: ["总计值", "未入库","已入库", "已上架", "已分拣", "已出库",],
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255)"
            }
          }
        },
        yAxis: {
          type: 'value',
          splitNumber: 3,
          axisTick: {
            show: false
          },
          axisLine: {
            // show: true,
            lineStyle: {
              color: "rgba(255,255,255)"
            }
          }
        },
        series: [
          // {
          //   name: 'Placeholder',
          //   type: 'bar',
          //   // stack: 'Total',
          //   // itemStyle: {
          //   //   borderColor: 'transparent',
          //   //   color: 'transparent'
          //   // },
          //   // emphasis: {
          //   //   itemStyle: {
          //   //     borderColor: 'transparent',
          //   //     color: 'transparent'
          //   //   }
          //   // },
          //    barWidth : 100,//柱图宽度
          //   data: arr
          // },
          {
            name: 'Life Cost',
            type: 'bar',
            stack: 'Total',
            label: {
              show: true,
              position: 'top',
              color: "rgba(255,255,255)",
            },
            barWidth : 100,//柱图宽度
            data: this.echartsProce.map((item, index) => ({
              value: index === 0 ? item.totalQty : item.qty,
              name: item.statesName,
              itemStyle: {
                label: {
                  show: true
                },
                labelLine: {
                  show: false
                },
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: this.colors[index > 1 ? 1 : index][1] },
                  { offset: 1, color: this.colors[index > 1 ? 1 : index][0] },
                ]),
                borderColor: index === 0 ? '#99E3FF' : '#A2F9F7',
                shadowBlur: 16,
                shadowColor: index === 0 ? 'rgba(0,186,255,1)' : 'rgba(23,241,236,1)',
              }
            })),
            // markPoint: {
            //   symbolSize: 0,
            //   // data: [{ coord: [1, 2000000] }, { coord: [2, 40] }, { coord: [3, 60] }, { coord: [4, 91] }, { coord: [5, 20] }],
            //   data: this.arr4,
            //   label: {
            //     fontSize: 12,
            //     color: 'rgba(255,255,255,1)',
            //     fontWeight: "bold",
            //     show: true,
            //     // position: 'insideTop'
            //     position:[-17,-35],
            //     formatter: (a) => {
            //       let num = a.data.ratio * 100
            //       return num.toFixed(2) + '%'
            //     },
            //   }
            // }
          },
        ]
      };
      this.myChart.setOption(option);
    },
    destroyListener() {
      window.removeEventListener("resize", this.$_resizeHandler);
      this.$_resizeHandler = null;
    },
  },
  destroyed() {
    this.myChart && this.myChart.dispose();
    this.destroyListener();
  },
}
</script>


<style lang="scss" scoped>
.totalChart {
  width: 100%;
  height: 558PX;
}
</style>