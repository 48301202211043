<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1284px"
      @close="closeDialog"
      :top="marginTop"
    >
          <slot name="left-top"></slot>
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left" v-show="!orderInfoTypeShow">
          <div style="width:100%;height:100%;">
            <div class="DEcharts"></div>
          </div>
          <!-- <div v-show="orderInfoTypeShow" class="returnsAmount">
            <div class="returnsAmount-head">
              <div class="returnsAmount-left">
                <div class="title">今日退货总金额</div>
                <div style="margin:8px 0">{{amountSummaryObj.systemDate}}</div>
                <div>{{amountSummaryObj.systemTime}}</div>
              </div>
              <div class="returnsAmount-right">
                <div class="money-top">
                  <div>今日: <span class="text">{{amountSummaryObj.ToDayRefundAmount|Format}}</span> 元</div>
                  <div class="yesterday">较昨日: <span class="text" style="color: #ffffff;">{{amountSummaryObj.AmountRate}}</span>  %
                    <img v-if="amountSummaryObj.order === '1'" style="margin-left:4px" :src="imgSrc1">
                    <img v-if="amountSummaryObj.order === '2'" style="margin-left:4px" :src="imgSrc2">
                  </div>
                </div>
                <div class="money-bottom">
                  昨日: <span class="text" style="color: #ffffff;">{{amountSummaryObj.YesterdayRefundAmount|Format}}</span> 元
                </div>
              </div>
            </div>
            <div class="moneyCharts"></div>
          </div>
          <div v-show="orderInfoTypeShow" class="deliveryTime">
            <div class="title">今日退货时间</div>
            <div class="timeCharts"></div>
          </div> -->
        </div>
        <div :class="['right', orderInfoTypeShow ? 'max-width-100' : '']">
          <div class="left-text" v-show="orderInfoTypeShow">
            <span class="time">时间：{{amountSummaryObj.systemDate}} {{amountSummaryObj.systemTime}}</span>审核通过：<span class="num">{{amountSummaryObj.RefundAmount| Format(2)}}</span> 元
          </div>
          <Table
            ref="tableComponent"
            :columns="columns"
            :tableData="tableData"
            :total="pagelist.count"
            :page.sync="pagelist.page"
            :rows.sync="pagelist.rows"
            :loading="loading"
            :stripe="stripe"
            :searchShow="false"
            :indexShow="false"
            :show-overflow-tooltip="false"
            :defaultSort="defaultSort"
            :sortDing="sortDing"
            :refreshTable="refreshTable"
            @pagination="getDetailsPage"
            @searchList="getDetailsPage"
            @sortChange="sortChange"
          >
            <template
              slot="columnEspecial"
              slot-scope="{ column, row }"
              resizable
            >
              <span v-if="column.prop === 'deliveryDate'">{{
                row[column.prop] | formatUTCTime
              }}</span>
              <span v-else-if="column.prop === 'orderDate'">{{
                row[column.prop]
              }}</span>
              <span
                v-else-if="
                  column.prop === 'orderAmt' ||
                    column.prop === 'signAmt' ||
                    column.prop === 'returnAmt' ||
                    column.prop === 'ddAmt' ||
                    column.prop === 'amt' ||
                    column.prop === 'thJsAmt'||
                    column.prop === 'inboundAmt'||
                    column.prop === 'outboundAmt'
                "
                >{{ row[column.prop] | Format }}</span
              >
              <!-- <span v-else-if="column.prop === 'signAmt'">{{
                row[column.prop] | Format
              }}</span>
              <span v-else-if="column.prop === 'returnAmt'">{{
                row[column.prop] | Format
              }}</span> -->
              <span v-else-if="column.prop === 'orderNum'">{{
                row[column.prop] | Format(0)
              }}</span>
              <span v-else-if="column.prop === 'ratio'"
                >{{ row[column.prop] | Format }}%</span
              >
              <span v-else-if="column.prop === 'refundStatus'"
                >{{ row[column.prop] | refundStatus }}</span
              >
              <span
                v-else-if="
                  column.prop === 'orderAmtRatio' ||
                    column.prop === 'orderNmuRatio'
                "
                class="Ratio"
              >
                {{
                  (orderType === "orderAmt"
                    ? row.orderAmtDiff
                    : row.orderNumDiff) | Format
                }}
                {{ orderType === "orderAmt" ? "万元" : "单" }} ({{
                  ((orderType === "orderAmt"
                    ? row.orderAmtRatio
                    : row.orderNumRatio) *
                    100)
                    | Format
                }}
                %)
                <img
                  :src="
                    (orderType === 'orderAmt'
                      ? row.orderAmtDiff
                      : row.orderNumDiff) > 0
                      ? imgSrc1
                      : imgSrc2
                  "
                  alt
                  class="orderImg"
                />
              </span>
              <span v-else-if="['orderAmount','signAmount','refundAmount'].includes(column.prop)">{{
                row[column.prop] | Format(2)
              }}</span>
              <span v-else>{{ row[column.prop] }}</span>
            </template>
            <!-- <template slot="columnRight" v-if="typeTable === 1">
              <el-table-column
                label="签收率"
                align="center">
                <template slot-scope="{ row }">
                  <div ref="columnRight">
                   <span> {{ row.ratio }}%</span>
                  </div>
                </template>
              </el-table-column>
            </template> -->
          </Table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatFloat, handleEntitys, formatDate } from "@/utils";

export default {
  name: "detailsPopup",
  components: {
    Table,
  },
  props: {
    // refreshTable:{
    //   //强制刷新table
    //   type: Boolean,
    //   default: true,
    // },
    amountSummaryObj:{
      default: function() {
        return {};
      },
    },
    orderInfoType:{
      type:Number,
      default:1
    },
    marginTop:{
      type: String,
      default: "calc((100vh - 720Px) / 2)",
    },
    sortDing:{
      type: String,
      default: "descending",
    },
    defaultSort:{
      type: String,
      default: "1",
    },
    orderType: {
      type: String,
    },
    entitysShow: {
      type: Array,
      default: function() {
        return [];
      },
    },
    pagelist: {
      default: function() {
        return {};
      },
    },
    getDetailsPage: {
      type: Function,
    },
    sortChange:{
      type: Function,
      default: function() {
        return {};
      },
    },
    dialogTitle: {
      type: String,
    },
    columns: {
      type: Array,
      default: function() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Number,
      // default() {
      //   return 0;
      // }
    },
    typeTable: {
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    stripe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      refreshTable:true,
      dialogVisible: true,
      orderInfoTypeShow:false,
      entitys: [
        {
          querySelect: {},
          queryValue: "",
          show: false,
        },
      ],
      loadingData: this.loading,
      chart: null,
      moneyChart:null,
      timeChart:null,
      timer:null,
      imgSrc1: require("@/assets/image/arrowup.svg"),
      imgSrc2: require("@/assets/image/down.svg"),
    };
  },
  filters:{
    refundStatus(val){
      if(val === -1){
        return '已驳回'
      }else if(val === 1){
        return '审核通过'
      }else{
        return '待审核'
      }
    }
  },
  watch: {
  orderInfoType(val) {
    if(val !== 5){
      this.orderInfoTypeShow = false
      this.$nextTick(() => {
        this.chart = this.$echarts.init(document.querySelector(".DEcharts"));
        window.onresize = () => {
          this.chart.resize();
        };
      });
    }else{
      this.orderInfoTypeShow = true
      /* this.$nextTick(() => {
        this.moneyChart = this.$echarts.init(document.querySelector(".moneyCharts"));
        window.onresize = () => {
          this.moneyChart.resize();
        };
      });
      this.$nextTick(() => {
        this.timeChart = this.$echarts.init(document.querySelector(".timeCharts"));
        window.onresize = () => {
          this.timeChart.resize();
        };
      }); */
    }
  },
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = this.$echarts.init(document.querySelector(".DEcharts"));
      window.onresize = () => {
        this.chart.resize();
      };
    });
    /* this.$nextTick(() => {
      this.moneyChart = this.$echarts.init(document.querySelector(".moneyCharts"));
      window.onresize = () => {
        this.moneyChart.resize();
      };
    });
    this.$nextTick(() => {
      this.timeChart = this.$echarts.init(document.querySelector(".timeCharts"));
      window.onresize = () => {
        this.timeChart.resize();
      };
    }); */
  },
  methods: {
    flitterData(arr){
        let spanOneArr = []
        let concatOne = 0
        arr.forEach((item,index)=>{
            if(index === 0){
                spanOneArr.push(1);
            }else{
                //name 修改
                if(item.focalPointNo === arr[index - 1].focalPointNo){ //第一列需合并相同内容的判断条件
                    spanOneArr[concatOne] += 1;
                    spanOneArr.push(0);
                }else{
                    spanOneArr.push(1);
                    concatOne = index;
                }
            }
        });
        return  {
            one: spanOneArr,
        }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if(columnIndex === 0 ) {
            // this.tableData  修改
            const _row = (this.flitterData(this.tableData).one)[rowIndex];
            const _col = _row > 0 ? 1 : 0;
            return {
              rowspan: _row,
              colspan: _col
            };
          }
    },
    setTimeOption(option){
        this.timer = setTimeout(()=>{
            this.$nextTick(() => {
              this.timeChart.setOption(option);
            });
          }, 50);
    },
    setMoneyOption(option){
        this.timer = setTimeout(()=>{
            this.$nextTick(() => {
              this.moneyChart.setOption(option);
            });
          }, 50);
    },
    setDetailsOption(option) {
      this.$nextTick(() => {
        this.chart.setOption(option);
      });
    },
    closeDialog() {
      this.$emit("clos:closeDetails", this.pagelist);
    },
    FomatFloat(num, n = 2) {
      let m = formatFloat(num, n);
      let res = "";
      if (m && m.split(".")[1] === "00") {
        res = m.split(".")[0];
      } else {
        res = m;
      }
      return res;
    },
    getEntitys(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent.entitys)
      ).entity;

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          if (key === "date") {
            params[key] = formatDate(params[key]);
          }
        }
      }
      return { params, searchButton };
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);

    this.timer = null
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.dialog {
  .dialog-content {
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      flex: 4;
      height: 86%;
      padding: 0;
      padding-right: 20px;
      // max-width: 635px;
      // max-width: 56%;
      max-width: 45%;
      .returnsAmount{
        // background: rgba(0,0,0,0.20);
        width: 100%;
        background-color: #031331;
        padding: 12px 24px;
        .returnsAmount-head{
          display: flex;
          justify-content: space-between;
          .returnsAmount-left{
            // width: 35%;
            color: #ffffff;
            font-size: 12px;
            .title{
              font-size: 14px;
              font-weight: 500;
              color: #17fff4;
            }
          }
          .returnsAmount-right{
            // width: 65%;
            .money-top{
              display: flex;
              font-size: 12px;
              color: #ffffff;
              align-items: center;
              .yesterday{
                display: flex;
                align-items: center;
                margin-left: 14px;
              }
              .text{
                font-weight: 500;
                color: #17fff4;
                display: inline-block;
                margin: 0 2px;
              }
            }
            .money-bottom{
              margin: 6px 0;
              font-size: 12px;
              color: #ffffff;
              .text{
                font-weight: 500;
                color: #17fff4;
                display: inline-block;
                margin: 0 2px;
              }
            }
          }
        }
      }
      .deliveryTime{
        width: 100%;
        background-color: #031331;
        padding: 12px 24px;
        margin-top:28px;
        position: relative;
        .title{
          position: absolute;
          top: 15px;
          left: 24px;
          font-size: 14px;
          font-weight: 500;
          color: #17fff4;
        }
      }


    }
    .right {
      flex: 6;
      // max-width: 550px;
      // max-width: 43%;
      max-width: 53%;
      &.max-width-100{
        max-width: 100%;
        .left-text{
          padding: 24px 0 10px 10PX;
          color: #fff;
          .time{
            margin-right: 16px;
          }
          .num{
            color: #17fff4;
          }
        }
        .table {
          height: 80%;
        }
      }
      .el-table::before {
        height: 0;
      }
      ::v-deep .el-table thead {
        color: #fff;
      }
      padding: 0;
    }
  }
  .Ratio {
    display: inline-block;
    width: 100%;
  }
  .table {
    height: 86%;
  }
}
  ::v-deep .minHeigth500 .el-table__body-wrapper {
    min-height: 300px;
    height: 89%;
  }
.moneyCharts{
  width: 480PX;
  height: 200PX;
}
.timeCharts{
  width: 480PX;
  height: 232PX;
}
</style>
