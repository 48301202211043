<template>
  <div class="unitBusinessTable">
    <div class="row-box">
      <div class="dialogTitle">订单金额</div>
      <el-table-admin-page
        class="tableTwo"
        ref="tableOrder"
        :search-show="false"
        :columns="columnsOrder"
        :table-data="tableData"
        v-bind.sync="pageOrder"
        :isLoading="loadingOrder"
        :pageSize="pageOrder.pageSize"
        @size-change="sizeChangeOrder"
        @pagination="currentChangeOrder"
        :paginationShow="paginationShow2"
        highlight-current-row
        :height="height">
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <div v-if="column.prop === 'operation'">
            <span class="clickButton" @click="download(row)">下载</span>
          </div>
          <span v-else-if="column.prop === 'ringRatio'">
            {{ row[column.prop].toFixed(2) }}%
            <img :src="row.ringRatio >= 0 ? imgSrc1:imgSrc2" alt>
          </span>
          <span v-else-if="column.prop === 'ddAmt'">
            {{ row[column.prop] | Format}}
          </span>
          <span v-else-if="column.prop === 'ddAmtL'">
            {{ row[column.prop] | Format}}
          </span>
          <span v-else>{{ row[column.prop] }}</span>
        </template>
      </el-table-admin-page>
    </div>
    <div class="row-box">
      <div class="dialogTitles">品类占比<span class="litle">(金额/占比)</span></div>
      <el-table-admin-page
        class="tableTwo categoryCss"
        ref="tableOrder"
        :search-show="false"
        :columns="columnsCategory "
        :isLoading="loadingCategory"
        :table-data="tableDataCategory"
        v-bind.sync="pageCategory"
        :pageSize="pageCategory.pageSize"
        @size-change="sizeChangeCategory"
        @pagination="currentChangeCategory"
        :paginationShow="paginationShow2"
        highlight-current-row
        :height="height">
        <!-- v-if="show" -->
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <span>{{ row[column.prop]? row[column.prop]:'-'  }}</span>
        </template>
      </el-table-admin-page>
    </div>
    <div class="row-box">
      <div class="dialogTitles">供应商占比<span class="litle">(金额/占比)</span></div>
      <el-table-admin-page
        class="tableTwo commodityCss"
        ref="tableOrder"
        :search-show="false"
        :columns="columnsVendor"
        :isLoading="loadingVendor"
        :table-data="tableDataVendor"
        v-bind.sync="pageVendor"
        :pageSize="pageVendor.pageSize"
        @size-change="sizeChangeVendor"
        @pagination="currentChangeVendor"
        :paginationShow="paginationShow2"
        highlight-current-row
        :height="height">
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <span>{{ row[column.prop]? row[column.prop]:'-' }}</span>
        </template>
      </el-table-admin-page>
    </div>
    <el-dialog
      class="detail"
      :title="'确认下载'"
      width="480px"
      append-to-body
      :visible.sync="downloadDetail"
      :before-close="handleClose">
      <span class="downloadText">请确认下载当前单位下属灶点销售单明细</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadDetail = false">取 消</el-button>
        <el-button class="button" type="primary" @click="confirmDown">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="detail"
      :title="'确认下载'"
      width="480px"
      append-to-body
      :show-close="false"
      :visible.sync="downloadtrip">
      <span class="el-icon-loading load"></span>
      <span class="downloadText">当前单位下属灶点销售单明细数据正在整理中，最长预计耗时3-5分钟，感谢你的耐心等待。</span>
    </el-dialog>
  </div>
</template>

<script>
// import { Format } from "@/filters";

export default {
  name: "unitBusinessTable",
  props: {
    tableData: {
      type: Array
    },
    columnsCategory: {
      type: Array
    },
    columnsVendor: {
      type: Array
    },
    tableDataCategory: {
      type: Array
    },
    tableDataVendor: {
      type: Array
    },
    pageOrder: {
      type: Object
    },
    pageCategory: {
      type: Object
    },
    pageVendor: {
      type: Object
    },
    loadingOrder: {
      type: Boolean
    },
    loadingCategory: {
      type: Boolean
    },
    loadingVendor: {
      type: Boolean
    },
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      height: `calc(100% - 32px)`,
      paginationShow2: true,
      unitMonth: 202108,
      downloadDetail: false,
      downloadtrip: false,
      rowData: {},
      pageLeft: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      imgSrc1: require('@/assets/image/arrowup.svg'),
      imgSrc2: require('@/assets/image/down.svg'),
      columnsOrder: [
        {
          type: 'index',
        },
        {
          label: "下级单位编号",
          prop: "focalName",
          align: "center",
        },
        {
          label: "月份",
          prop: "monthId",
          align: "center",
        },
        {
          label: "订单金额(元)",
          prop: "ddAmt",
          align: "right",
          headerAlign: "right",
        },
        {
          label: "上月订单金额(元)",
          prop: "ddAmtL",
          align: "right",
          headerAlign: "right",
        },
        {
          label: "订单金额环比",
          prop: "ringRatio",
          align: "right",
          headerAlign: "right",

        },
        {
          label: "操作",
          prop: "operation",
          align: "center",
        },

      ]
    }
  },
  methods: {
    //订单金额分页
    sizeChangeOrder(val) {
      this.pageOrder.pageSize = val;
      this.paginationShow2 = false
      this.$nextTick(() => {
        this.paginationShow2 = true
      })
      this.$emit('get-sizes', this.pageOrder.pageSize, 2)
    },
    currentChangeOrder(val) {
      if (typeof type === "number") {
        this.pageOrder.currentPage = val
      }
      this.$emit('get-sizes', this.pageOrder.currentPage, 1)
    },
    sizeChangeCategory(val) {
      this.paginationShow2 = false
      this.$nextTick(() => {
        this.paginationShow2 = true
      })
      this.pageCategory.pageSize = val
      this.$emit('size-category', this.pageCategory.pageSize, 2)
    },
    currentChangeCategory(val) {
      if (typeof type === "number") {
        this.pageCategory.currentPage = val
      }
      this.$emit('size-category', this.pageCategory.currentPage, 1)
    },
    sizeChangeVendor(val) {
      this.paginationShow2 = false
      this.$nextTick(() => {
        this.paginationShow2 = true
      })
      this.pageVendor.pageSize = val;
      this.$emit('size-vendor', this.pageVendor.pageSize, 2)
    },
    currentChangeVendor(val) {
      if (typeof type === "number") {
        this.pageVendor.currentPage = val;
      }
      this.$emit('size-vendor', this.pageVendor.currentPage, 1)
    },
    download(row) {
      this.rowData = row
      this.downloadDetail = true
    },
    handleClose() {
      this.downloadDetail = false
    },
    confirmDown() {
      this.downloadtrip = true
      this.downloadDetail = false
      this.$getData({
        type: "crfRequestForward",
        url: `/ICRFS/GetSODetailsByDepartment?monthid=${this.rowData.monthId.replace('-', '')}&focalname=${this.rowData.focalName}&focallevel=${this.rowData.focalLevel}`,
        method: 'GET',
        download: 1
      }).then((res) => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '当前文件不存在，请与系统管理员联系!',
            center: true
          })
        }
        setTimeout(() => {
          this.downloadtrip = false
        }, 1000)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.unitBusinessTable{
  height: 100%;
  .row-box{
    height: 33%;
    .tableTwo{
      height: calc(100% - 30px);
    }
  }
}
.dialogTitle {
  color: #17fff4;
  font-size: 14px;
  padding: 8px 0;
}
.clickButton {
  color: #17fff4;
  cursor: pointer;
}
.dialogTitles {
  color: #17fff4;
  font-size: 14px;
  padding: 0 0 8px 0;
}
.litle {
  color: rgb(206, 204, 204);
  padding-left: 2px;
}
.detail {
  .downloadText {
    color: #fff;
    line-height: 30px;
  }
  .load {
    line-height: 30px;
    color: #17fff4;
  }
  ::v-deep .el-dialog__body {
    padding: 40px 40px;
  }
  ::v-deep .el-dialog {
    background: linear-gradient(
      0deg,
      rgba(10, 28, 57, 0.2) 0%,
      rgba(10, 28, 57, 0.8) 95%
    );
    border: 1px solid #17fff5;
    height: 264px;
  }
  ::v-deep .el-dialog__title {
    color: #17fff4;
  }
  ::v-deep .el-dialog__footer {
    padding: 40px 40px;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #17fff4;
  }
  ::v-deep .el-button {
    // line-height: 32px;
    width: 100px;
    border: 1px solid #87d2ff !important;
    border-radius: 0;
    background: rgba(62, 100, 172, 0.3);
    color: #fff;
  }
  .button {
    color: #17fff4;
    background: linear-gradient(
      0deg,
      #5f97ff 0%,
      rgba(95, 151, 255, 0)
    ) !important;
  }
}
::v-deep .el-table-admin-page .pagination {
  justify-content: flex-end;
  padding: 12px !important;
  // height: 31px !important;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: rgba(62, 100, 172, 0.3);
}
// ::v-deep .categoryCss {
//   .el-table {
//     background: #031331 !important;
//   }
//   .is-scrolling-right {
//     height: 170px !important;
//   }
//   .is-scrolling-left {
//     height: 170px !important;
//   }
//   .is-scrolling-middle {
//     height: 170px !important;
//   }
// }
// ::v-deep .commodityCss {
//   .el-table {
//     background: #031331 !important;
//   }
//   .is-scrolling-right {
//     height: 150px !important;
//   }
//   .is-scrolling-left {
//     height: 150px !important;
//   }
//   .is-scrolling-middle {
//     height: 150px !important;
//   }
// }
// 修改表头过高
// ::v-deep .el-table__header tr,
// .el-table__header th {
//   padding: 0 !important;
//   height: 20px !important;
// }
</style>