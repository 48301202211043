import request from "@/utils/request";

//天气区域分页
export function weatherAreaGetPage(data) {
  return request({
    url: `/weatherArea/getPage`,
    method: "post",
    data,
  });
}

//天气区域新增
export function weatherAreaAdd(data) {
  return request({
    url: `weatherArea/add`,
    method: "post",
    data,
  });
}

//天气区域更新
export function weatherAreaUpdate(data) {
  return request({
    url: `weatherArea/update`,
    method: "post",
    data,
  });
}

//天气关注区域与用户新增/修改
export function weatherAreaRefUser(data) {
  return request({
    url: `weatherAreaRefUser/add`,
    method: "post",
    data,
  });
}

//获取当前用户所关注区域
export function getInfoByUser() {
  return request({
    url: `weatherAreaRefUser/getInfoByUser`,
    method: "get",
  });
}

//拒收订单获取分页
export function rmaOrderGetPage(data) {
  return request({
    url: `rmaOrder/getPage`,
    method: "post",
    data
  });
}

//拒收订单获取分页
export function rmaOrderGetByRmaId(data) {
  return request({
    url: `rmaOrder/getByRmaId/${data}`,
    method: "get",
  });
}

//拒收订单获取分页
export function rmaOrderSetHandled(data) {
  return request({
    url: `rmaOrder/setHandled`,
    method: "post",
    data
  });
}

//导出上架率
export function exportPutaway() {
  return request({
    url: `/excel/导入上架率模板.xlsx`,
    method: "get",
    responseType: "blob",
  });
}



