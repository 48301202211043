<template>
  <div>
    <Charts-border
      borderTitle="客户订单金额TOP10"
      titleBgWidth="260PX"
      :option="option">
      <div slot="leftBottom">
        <span @click="openDetails">查看详情</span>
      </div>
    </Charts-border>
    <Details
      ref="Details"
      v-if="showDetails"
      @close:closeDetails="closeDetails"
      @getFocalPointApi="getFocalPointApi"
      :leftShow="false"
      :dialogTitle="'客户订单详情'"
      :stripe="false"
      :loading="false"
      :total="total"
      :columns="columns"
      :tableData="tableData"
      :pageList="pageList"
      :typeFood="typeFood"
      :downLoadMonth="monthValue"
      @sortChange="sortChange">
      <!-- <el-input placeholder="请选择伙食单位" :clearable="true" v-model="foodUnit" @click.native="foodUnitListPage"></el-input>  28行下面的-->
      <!-- <div slot="foodPage" class="foodLeft">
        <div class="foodLeft">
          <input
            type="text"
            class="input"
            v-model="foodUnit"
            placeholder="请选择伙食单位"
            @click="foodUnitListPage" />
          <div class="close">
            <img v-if="foodUnit === null" :src="imgUrl" alt="" width="16" height="16">
            <i v-else class="el-icon-close" @click="append"></i>
          </div>
        </div>
        <div class="foodLeft">
          <div class="qty">
            总订单金额：
            <span class="qtSpan">{{
              (totalList.ddAmt / 10000) | FormatToFixed
            }}</span><span class="unit">万元</span>
          </div>
          <div class="qty">
            总签收金额：
            <span class="qtSpan">{{
              (totalList.amt / 10000) | FormatToFixed
            }}</span><span class="unit">万元</span>
          </div>
          <div class="qty">
            总订单数量： <span class="qtSpan">{{ totalList.dds || 0 }}</span><span class="unit">单</span>
          </div>
        </div>
      </div> -->
      <div slot="foodPage" class="food_content_wrap">
        <div class="search_wrap">
          <div class="s_left">
            <el-select class="s_unit" v-model="unitValue" clearable placeholder="请选择" @change="changeUnitValue">
              <el-option
                v-for="item in unitOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input
              class="s_input"
              placeholder="请输入内容"
              v-model="inputVal"
              clearable>
            </el-input>
            <div class="range_money mgl8">
              <span style="margin-right:8px">订单金额: </span>
              <el-input
                class="money_input"
                v-model.trim="moneyValMin"
                @input="limitInput($event,'moneyValMin')"
                clearable>
              </el-input> 元 
              <span class="text">-</span>
              <el-input
                class="money_input"
                v-model.trim="moneyValMax"
                @input="limitInput($event,'moneyValMax')"
                clearable>
              </el-input> 元
            </div>
            <el-button class="BlueCustomButton s_btn mgl8" type="primary" @click="checkData">查询</el-button>
            <el-button class="BlueCustomButton r_btn mgl8" @click="resetData">重置</el-button>
          </div>
          <div class="s_right">
            <el-date-picker
              class="month-icon-style"
              v-model="monthValue"
              value-format="yyyyMM"
              type="month"
              placeholder="选择月"
              :picker-options="monthPickerOptions"
              @change="changeMonthValue">
            </el-date-picker>
          </div>
        </div>
        <div class="show_text_wrap">
          <div class="qty">
            总订单金额：
            <span class="qtSpan">{{
              (totalList.amount / 10000) | FormatToFixed
            }}</span><span class="unit">万元</span>
          </div>
          <div class="qty">
            总签收金额：
            <span class="qtSpan">{{
              (totalList.sign / 10000) | FormatToFixed
            }}</span><span class="unit">万元</span>
          </div>
          <div class="qty">
            总订单数量： <span class="qtSpan">{{ totalList.quantity || 0 }}</span><span class="unit">单</span>
          </div>
        </div>
      </div>
    </Details>
    <div class="dialogBox">
      <el-dialog
        class="linkedDialog"
        title="请选择伙食单位"
        @close="dialogClose"
        width="990PX"
        :visible.sync="showAllocation">
        <div class="table">
          <div class="tabaleHeader">
            <el-select
              v-model="entitys[0].querySelect"
              placeholder="请选择单位层级"
              value-key="prop"
              @change="selectChange"
              :popper-append-to-body="false"
              clearable>
              <el-option
                v-for="item in entitysSelection"
                :key="item.prop"
                :label="item.label"
                :value="item.prop" />
            </el-select>
            <el-input
              v-model.trim="entitys[0].queryValue"
              placeholder="请输入内容"
              style="margin:0 1PX;"
              clearable />
            <el-button
              class="BlueCustomButton searchBottom"
              @click.native="foodUnitList(entitys)"                                        
              style="height: 40PX">查询</el-button>
            <el-button
              class="BlueCustomButton searchBottom"
              style="height: 40PX"
              @click.native="elTableRefs">重置</el-button>
          </div>
          <el-table
            ref="multipleTable"
            @selection-change="handleTableChange"
            @select="select"
            @row-click="rowClick"
            :data="tableDataFood"
            height="500PX"
            class="tableForm">
            <el-table-column type="selection" align="center" width="80">
            </el-table-column>
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="80"
              :index="index => index + (pageOther.page - 1) * pageOther.rows + 1">
            </el-table-column>
            <el-table-column
              v-for="(column, index) in columnsFood.filter(
         (item) => item.form !== false
          )"
              :key="index"
              :prop="column.prop"
              :label="column.label"
              :align="column.align || 'center'">
            </el-table-column>
          </el-table>
          <div class="elPagination">
            <el-pagination
              background
              :page-sizes="[20, 30, 50]"
              :page-size="pageOther.rows"
              :current-page.sync="pageOther.page"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageOther.count"
              :small="false"
              :pager-count="5"
              v-bind="$attrs"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange" />
          </div>
          <div class="footer">
            <el-button @click="closeFoodDetails">取 消</el-button>
            <el-button class="button" type="primary" @click="comfirm">确 认</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import ChartsBorder from "../viewsComponents/ChartsBorder";
import watchMonth from "@/mixins/watchMonth";
import { formatFloat } from "@/utils";
import Details from "../viewsComponents/Details/fooDetails.vue";
import { formatDate } from "@/utils";
export default {
  name: "Food",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
    Details,
  },
  computed: {
    // 下面是pagination
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      }
    },
    pageSize: {
      get() {
        return this.rows;
      },
      set(val) {
        this.$emit("update:rows", val);
      }
    },
    entitysSelection() {
      const result = [];
      const rec = (arr) => {
        arr.map((i) => {
          if (i.children) {
            rec(i.children);
          } else {
            result.push(i);
          }
        });
      };
      rec(
        this.columnsFood.filter(
          (item) =>
            item.search !== false &&
            !["selection", "index", "expand"].includes(item.type)
        )
      );
      return result;
    },
  },
  watch: {
    // entitys: {
    //   handler(val) {
    //     if (val) {
    //       this.entitys[0].queryValue = ''
    //     } 
    //   }
    // }
  },
  filters: {
    FormatToFixed(val) {
      if (val) {
        let result = (Math.round(val * 100) / 100).toString();
        let dotPosition = result.indexOf(".");
        if (dotPosition < 0) {
          dotPosition = result.length;
          result += ".";
        }
        while (result.length <= dotPosition + 2) {
          result += "0";
        }
        return result;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      imgUrl: require('@/assets/image/filesearch.png'),
      currentRow: {},
      typeFood: "1",
      foodUnit: null,
      monthData: "",
      totalList: {},
      list: {},
      input: '',
      showDetails: false,
      showAllocation: false,
      tableData: [],
      checkedUnit: [],
      tableDataFood: [],
      entitys: [
        {
          querySelect: "",
          queryValue: "",
          show: false,
        },
      ],
      columnsFood: [
        /* {
          label: "一级单位编码",
          prop: "focalName1",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
        {
          label: "二级单位编码",
          prop: "focalName2",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
        {
          label: "三级单位编码",
          prop: "focalName3",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        }, */
        {
          label: "伙食单位编码",
          prop: "customerKey",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true
        },
        {
          label: "伙食单位名称",
          prop: "customerName",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true
        }
      ],
      columnsFrom: "",
      // pageSizes: [20, 30, 50],
      pageList: {
        page: 1,
        rows: 20
      },
      columns: [
        {
          label: "客户账号编码",
          prop: "customerAccount",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          // width:110
        },
        {
          label: "灶点编码",
          prop: "focalPointNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          // width:110
        },
        /*{
          label: "灶点名称",
          prop: "focalPointName",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width:110
        },*/
        {
          label: "订单金额(元)",
          prop: "orderAmount",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          align: "right",
          // width:140,
          sortable: 'custom'
        },
        {
          label: "订单金额环比",
          prop: "orderAmountHb",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          // width:120
        },
        {
          label: "签收金额(元)",
          prop: "signAmount",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          align: "right",
          // width:110
        },
        {
          label: "签收率",
          prop: "signRate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          // width:110
        },
        {
          label: "订单数量(单)",
          prop: "orderQuantity",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          // width:110
        },
        {
          label: "订单数环比",
          prop: "orderQuantityHb",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          align: "right",
          // width:110
        }
      ],
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter(params) {
            if (params && params.length > 0) {
              return `${params[0].seriesName}${params[0].data[2].slice(
                3,
                params[0].data[2].length
              )}<br/>${params[0].name}: ${formatFloat(params[0].value[1])}元`;
            } else {
              return "";
            }
          },
          confine: true
        },
        grid: {
          left: "3%",
          // top:"-10%",
          // right: "4%",
          bottom: "10%",
          containLabel: true
        },
        xAxis: {
          // name:"单",
          type: "value",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)"
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)" // 分割线颜色
            }
          }
        },
        yAxis: {
          // name:"伙食单位",
          type: "category", // 不设置类目轴，抽离的dataset数据展示不出来
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.5)"
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            type: "bar",
            name: "客户订单金额TOP",
            barWidth: 12,
            label: {
              show: true,
              position: "center",
              offset: [15, 0],
              color: "#fff",
              style: {
                fill: "#fff"
              },
              formatter: params => {
                return formatFloat(params.data[1]);
              }
            },
            itemStyle: {
              label: {
                show: true
              },
              labelLine: {
                show: false
              },
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "rgba(23,241,236,0)" },
                { offset: 1, color: "rgba(23,241,236,1)" }
              ]),
              borderColor: "#a2f9f7",
              shadowBlur: 16,
              shadowColor: "rgba(23,241,236,1)"
            }
          }
        ]
      },
      total: 0,
      fooKey: "",
      pageOther: {
        page: 1,
        rows: 20
      },
      unitOptions: [
        {
          value: 'customerAccount',
          label: '客户账号编码'
        },
        {
          // value: 'customerKey',
          value: 'focalPointNo',
          label: '灶点编号'
        },
        /*{
          // value: 'customerName',
          value: 'focalPointName',
          label: '灶点名称'
        },*/
      ],
      unitValue: '',
      inputVal: '',
      moneyValMin: '',
      moneyValMax: '',
      monthValue: '',
      dayVal: '',
      demoMonth: '202407',
      demoDay: '20240701',
      orderBy: 'down',
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },

    };
  },
  created() {
    this.timer = setInterval(() => {
      this.getData(formatDate(this.global.month));
    }, 1000 * 60 * 5);
  },
  methods: {
    changeUnitValue(option){
      if(!option){
        this.inputVal = ''
      }
    },
    /**
     * @param {string} value - 输入的值
     * @param {string} name - 匹配的对象属性 [mkPrice | slPrice]
     */
    limitInput(value, name) {
      this[name] =
        ("" + value) // 第一步：转成字符串
          // eslint-disable-next-line no-useless-escape
          .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
    },
    changeMonthValue(date){
      this.monthValue = date
      this.getFocalPointApi()
    },
    checkData(){
      if(!this.unitValue && this.inputVal){
        return this.$message({
          message: '请选择下拉搜索项',
          type: 'warning'
        })
      }
      this.pageList.page = 1
      this.getFocalPointApi()
    },
    resetData(){
      this.unitValue = ''
      this.inputVal = ''
      this.moneyValMin = ''
      this.moneyValMax = ''
      this.pageList.page = 1
      this.pageList.rows = 20
      this.monthValue = ''
      this.getFocalPointApi()
    },
    selectChange() {
      this.entitys[0].queryValue = ''
    },
    inputClear() {
    },
    dialogClose() {
      this.pageOther.page = 1
      this.pageOther.rows = 20
      this.entitys[0].querySelect = undefined
      this.entitys[0].queryValue = ''
    },
    getDayVal(val){
      this.dayVal = val.replace(/-/g, '')
    },
    getData(month) {
      this.monthData = month;
      // this.monthValue = month
      const paramsObj = {
        type: "caterUnitOrderAmountTop10",
        day: this.demoDay,
        warehouseCode: this.warehouseCode
      }
      /* const paramsObj = { // 旧接口参数
        // type: "foodUnitOrderQuantity",
        type: "focalPointDdAmtHbTop10",
        date: month
      } */
      this.$getData(paramsObj).then(res => {
        let source = [];
        let i = 0
        source = res.data.map(item => {
          // return [item.focalPointNo, item.ddAmt, item.index];
          item.focalPointNo = item.focalPointNo.substr(0,2)+ '***' + item.focalPointNo.substr(7, item.focalPointNo.split('').length) + i++
          return [item.focalPointNo, item.orderAmount, item.index];
        });
        this.option = {
          dataset: {
            source: source
          }
        };
      });
    },
    append() {
      this.foodUnit = "";
      this.list.customerKey = ""
      this.fooKey = ""
      this.getFocalPointApi();
      // this.getTotalApi();
    },
    foodUnitList(entitys, searchButton = 0) {
      //初始 0
      //查询 0
      //重置 0
      //换页 1
      //换条 2
      const params = {
        entity: undefined,
      }
      entitys.map((item) => {
        let aa = item.querySelect || undefined
        params[aa] = item.queryValue || undefined;
      })
      if(searchButton === 0){
        this.pageOther.page = 1;
        this.pageOther.rows = 20;
      }
      // this.pageOther.count = 0
      //伙食单位分页接口
      this.$getData({
        ...params || undefined,
        page: this.pageOther.page,
        rows: this.pageOther.rows,
        type: "foodUnitList",
        date: this.monthData,
      }).then(res => {
        this.tableDataFood = res.data;
        this.pageOther.count = res.count;
      });
    },
    openDetails() {
      // this.foodUnitList(this.entitys);
      // this.getTotalApi();
      this.getFocalPointApi();
      this.showDetails = !this.showDetails;
    },
    closeDetails() {
      this.foodUnit = "";
      this.pageOther.count = 0
      this.resetData()
      // this.pageList.page = 1
      // this.pageList.rows = 20
      // this.getFocalPointApi();
      this.showDetails = false;
      this.fooKey = "";
    },
    closeFoodDetails() {
      this.showAllocation = false;
    },
    foodUnitListPage() {
      this.showAllocation = true;
      this.foodUnitList(this.entitys);
    },
    //汇总数据
    getTotalApi(date) {
      this.$getData({
        type: "foodUnitSummary",
        date: date || this.monthData,
        customerKey: this.list.customerKey || null
      }).then(res => {
        this.totalList = {};
        for (var i in res.data) {
          this.totalList = res.data[i];
        }
      });
    },
    //对应灶点详情
    getFocalPointApi(v,f) {
      if(f == 2){
        this.pageList.page = 1
      }
     /*  let paramsObj = { // 旧接口的参数
        ...this.pageList,
        type: "focalPointDetail",
        date: this.monthData,
        customerKey: this.list.customerKey || this.fooKey
      } */
      let paramsObj = {
        ...this.pageList,
        type: "caterUnitOrderDetail",
        day: this.demoDay,
        month: this.demoMonth,
        // customerAccount: "000",   //客户账号编码
        // customerKey: "7",         //伙食单位编码
        // customerName: "7",        //伙食单位名称
        // orderAmount: `${this.moneyValMin}~${this.moneyValMax}`,   //订单金额区间筛选(起始和终止数字需用 ~ 连接)
        orderBy: this.orderBy, //订单金额排序(为空或其他时降序，up时升序)
        warehouseCode: this.warehouseCode
      }
      if(this.unitValue){
        paramsObj[this.unitValue] = this.inputVal
      }
      if(this.moneyValMin || this.moneyValMax){
        const splitStr = this.moneyValMax ? '~' : ''
        paramsObj.orderAmount = `${this.moneyValMin}${splitStr}${this.moneyValMax}`
      }
      this.$getData(paramsObj).then(res => {
        if(res.data){
          let i = 0;
          res.data.page.data.map(item => {
            item.focalPointName = item.focalPointName.substr(0,2) + '***' + i
            item.focalPointNo = item.focalPointNo.substr(0,2) + '***' + i
            item.customerAccount = item.customerAccount.substr(0,2) + '***' + i
            i++
          })
        }
        this.tableData = res.data;
        this.total = res.count;
        if(res.code !== 0) return
          this.tableData = res.data.page.data
          this.total = res.data.page.count
          this.totalList = res.data.summary[0]
      });
    },
    comfirm() {
      this.showAllocation = false;
      this.foodUnit = this.list.customerName;
      this.fooKey = this.list.customerKey;
      if (this.pageList.page !== 0) {
        this.pageList.page = 1;
        this.pageList.rows = 20
      }
      this.$nextTick(() => {
        // this.getTotalApi();
        this.getFocalPointApi();
        this.$refs.multipleTable.clearSelection();
      });
    },
    select(selection, row) {
      this.$refs.multipleTable.clearSelection();
      if (selection.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    rowClick(row) {
      this.$refs.multipleTable.clearSelection();
      if (row.length == 0) return;
      this.$refs.multipleTable.toggleRowSelection(row, true);
    },
    handleTableChange(val) {
      this.list = {};
      for (var key in val) {
        this.list = val[key];
      }
    },
    handleCurrentChange(val) {
      this.pageOther.page = val;
      if (val) {
        this.foodUnitList(this.entitys,1);
      }
    },
    handleSizeChange(val) {
      this.pageOther.rows = val;
      this.pageOther.page = 1;
      if (val) {
        this.foodUnitList(this.entitys,2);
      }
    },
    elTableRefs() {
      this.entitys[0].querySelect = undefined
      this.entitys[0].queryValue = ''
      this.pageOther.page = 1
      this.pageOther.rows = 20
      this.pageOther.count = 0
      this.foodUnitList(this.entitys);
    },
    sortChange(sortData){
      if(sortData.order === 'ascending'){
        this.orderBy  = 'up'
      }else{
        this.orderBy  = 'down'
      }
      this.pageList.page = 1
      this.getFocalPointApi(null,2)

    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  }
};
</script>
<style lang="scss" scoped>
.searchBottom{
  font-size: 14PX;
  padding: 6PX 12PX;
}
.foodLeft {
  display: flex;
  // flex-direction: row;
  .input {
    border: 1PX solid #87d2ff;
    background: rgba(62, 100, 172, 0.3);
    color: #fff;
  }
  .close {
    position: relative;
    top: 12PX;
    left: -25PX;
  }
  ::v-deep .input,
  input::-webkit-input-placeholder {
    color: #fff;
    padding-left: 10PX;
    height: 36PX;
  }
  .qty {
    font-size: 16PX;
    color: #fff;
    padding-left: 35PX;
    line-height: 39PX;
    .qtSpan {
      color: #17fff5;
      font-size: 20PX;
    }
    .unit {
      padding-left: 8PX;
    }
  }
}
.dialogBox {
  ::v-deep .el-dialog__wrapper {
    overflow: hidden !important;
  }
}
.linkedDialog {
  // width: 990PX;
  .tabaleHeader {
    display: flex;
    .el-input {
      width: 200PX;
    }
    .el-button {
      margin-left: 10PX;
      width: 80PX;
    }
    ::v-deep .el-input--suffix .el-input__inner {
      height: 40PX;
      border-radius: 0PX;
      border: 1PX solid #87d2ff;
      color: aqua;
    }
  }
  ::v-deep .el-dialog {
    border: 2PX solid #5473bf;
    background: #04132d;
    .el-table__body-wrapper {
      height: 500PX;
      overflow: auto;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 10PX 20PX 10PX 20PX;
  }

  ::v-deep .el-dialog__title {
    padding: 16PX;
    z-index: 1;
    color: #17f1ec;
  }
  ::v-deep .el-icon-close:before {
    color: #17f1ec;
  }
  .el-table {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 10PX;
  }
  .el-table::before {
    height: 0;
  }
  .table {
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: rgba(62, 100, 172, 0.3);
    }

    ::v-deep .el-table th > .cell .el-checkbox {
      display: none;
    }
    ::v-deep .el-table thead tr th {
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.2) !important;
      min-height: 80%;
      border-bottom: 1px solid #5473bf !important;
    }
    ::v-deep .el-table th,
    ::v-deep .el-table tr,
    ::v-deep .el-table {
      background: #061125;
    }
    ::v-deep .el-table td,
    ::v-deep .el-table th.is-leaf {
      border-bottom: none;
    }
    ::v-deep .el-table th.is-leaf {
      border-bottom: 1PX solid #5473bf !important;
    }
    ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
      background-color: #061125;
    }
    ::v-deep .el-checkbox__inner::after {
      width: 5PX;
      height: 6PX;
      background-color: #17f1ec;
      border-bottom: 0;
      position: absolute;
      top: 3PX;
      left: 3PX;
      border-radius: 50%;
    }
    ::v-deep .el-checkbox__inner {
      border-radius: 25PX;
      border: 2PX solid #5473bf;
      background-color: #061125;
      width: 16PX;
      height: 16PX;
    }
    ::v-deep .is-checked{
      .el-checkbox__inner {
        border-radius: 25PX;
        border: 2PX solid rgba(23,241,236,1);;
        width: 16PX;
        height: 16PX;
      }
    }

    ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
      width: 10PX;
    }
    // 设置滚动条的背景色和圆角
    ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: rgba(62, 100, 172, 0.3);
    }
  }
}
.elPagination {
  display: flex;
  justify-content: flex-end;
  padding: 20PX 20PX;
  ::v-deep .el-pagination__total {
    color: rgba(255, 255, 255, 0.8);
  }
  ::v-deep .el-pagination button {
    background-color: rgba(62, 100, 172, 0.3);
  }
  ::v-deep .el-pager li.active {
    background-color: rgba(62, 100, 172, 0.3);
  }
  ::v-deep .el-pagination.is-background .btn-prev,
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: transparent;
    color: #17fff4;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgba(62, 100, 172, 0.3);
    color: #17fff4;
    border: 1PX solid #87d2ff;
    border-radius: 1PX;
  }
  ::v-deep .el-pagination .el-pager li {
    background-color: rgba(62, 100, 172, 0.3);
    color: #fff;
  }
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: rgba(62, 100, 172, 0.3);
    color: rgba(255, 255, 255, 0.7);
  }
  ::v-deep .el-pagination.is-background .el-pager li:hover {
    background-color: rgba(62, 100, 172, 0.25);
  }
  ::v-deep .el-input__inner,
  ::v-deep .el-pagination__jump {
    color: rgba(255, 255, 255, 0.8);
    border: none;
    position: relative;
  }
  ::v-deep .el-pagination .el-select .el-input .el-input__inner {
    border: 1PX solid #87d2ff;
    border-radius: 1PX;
    height: 28PX;
  }
}
.footer {
  display: flex;
  justify-content: center;
  .el-button {
    background: rgba(62, 100, 172, 0.3);
    border: 1PX solid #87d2ff;
    color: #ffffff;
    border-radius: 0;
    height: 40PX;
    width: 74PX;
    font-size: 14PX;
    padding: 6PX 12PX;
  }
  .button {
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
    color: #17fff4;
    margin-left: 10PX;
  }
}
.right-tab-box {
  display: flex;
  margin: 10PX;
  .right-tab-item {
    border: 1PX solid #87d2ff;
    padding: 5PX;
    font-size: 14PX;
    cursor: pointer;
  }
}
::v-deep .el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__wrap {
      .el-select-dropdown__list {
        .el-select-dropdown__item {
          color: #fff;
        }
        .selected {
          color: #17fff5 !important;
        }
        .el-select-dropdown__item:hover {
          color: #17fff5;
        }
      }
    }
  }
}

.food_content_wrap{
  width: 100%;
  .search_wrap{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .s_left{
      display: flex;
      .mgl8{
        margin-left: 16px;
      }
      .s_unit{
        width: 160px;
      }
      .s_input{
        width: 160px;
      }
      .range_money{
        display: flex;
        align-items: center;
        color: #fff;
        .money_input{
          width: 80px;
        }
        .text{
          padding: 0 8px;
        }
      }
      .s_btn,
      .r_btn{
        height: 32PX;
        width: 72PX;
        font-size: 14PX;
        padding: 0PX 0PX;
        line-height: 32PX;
      }

    }
    .s_right{
      .month-icon-style{
        width: 160PX;
        ::v-deep .el-input__prefix{
          margin-top: -3px;
        }
      }
    }
  }
  .show_text_wrap{
    display: flex;
    .qty {
      font-size: 16PX;
      color: #fff;
      padding-right: 35PX;
      line-height: 39PX;
      .qtSpan {
        color: #17fff5;
        font-size: 20PX;
      }
      .unit {
        padding-left: 8PX;
      }
    }
  }
}
</style>
