<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1284px"
      @close="closeDialog"
      :top="marginTop"
    >
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="top">
          <slot name="top-tab"></slot>
          <Table
            ref="tableComponent"
            :columns="columns"
            :tableData="tableData"
            :total="pagelist.count"
            :page.sync="pagelist.page"
            :rows.sync="pagelist.rows"
            :loading="loading"
            :stripe="stripe"
            :searchShow="false"
            :indexShow="false"
            :show-overflow-tooltip="false"
            :defaultSort="defaultSort"
            :sortDing="sortDing"
            :tableHeight="200"
            :refreshTable="refreshTable"
            @pagination="getDetailsPage"
            @searchList="getDetailsPage"
            @sortChange="sortChange"
          >
            <template
              slot="columnEspecial"
              slot-scope="{ column, row }"
              resizable
            >
              <span v-if="column.prop === 'deliveryDate'">{{
                row[column.prop] | formatUTCTime
              }}</span>
              <span v-else-if="column.prop === 'orderDate'">{{
                row[column.prop]
              }}</span>
              <span
                v-else-if="
                  column.prop === 'orderAmt' ||
                    column.prop === 'signAmt' ||
                    column.prop === 'returnAmt' ||
                    column.prop === 'ddAmt' ||
                    column.prop === 'amt' ||
                    column.prop === 'thJsAmt'
                "
                >{{ row[column.prop] | Format }}</span
              >
              <!-- <span v-else-if="column.prop === 'signAmt'">{{
                row[column.prop] | Format
              }}</span>
              <span v-else-if="column.prop === 'returnAmt'">{{
                row[column.prop] | Format
              }}</span> -->
              <span v-else-if="column.prop === 'orderNum'">{{
                row[column.prop] | Format(0)
              }}</span>
              <span v-else-if="column.prop === 'ratio'"
                >{{ row[column.prop] | Format }}%</span
              >
              <span
                v-else-if="
                  column.prop === 'orderAmtRatio'"
                class="Ratio"
              >
                {{
                  row.orderAmtDiff| Format
                }}
                {{ "万元"}} ({{
                  ((row.orderAmtRatio) *100)| Format
                }}
                %)
                <img
                  :src="
                    row.orderAmtDiff > 0
                      ? imgSrc1
                      : imgSrc2
                  "
                  alt
                  class="orderImg"
                />
              </span>
              <span
                v-else-if="
                  column.prop === 'orderNmuRatio'
                "
                class="Ratio"
              >
                {{
                  row.orderNumDiff| Format
                }}
                {{ "单" }} ({{
                  (row.orderNumRatio*
                    100)
                    | Format
                }}
                %)
                <img
                  :src="
                    (row.orderNumDiff) > 0
                      ? imgSrc1
                      : imgSrc2
                  "
                  alt
                  class="orderImg"
                />
              </span>
              <span v-else>{{ row[column.prop] }}</span>
            </template>
            <!-- <template slot="columnRight" v-if="typeTable === 1">
              <el-table-column
                label="签收率"
                align="center">
                <template slot-scope="{ row }">
                  <div ref="columnRight">
                   <span> {{ row.ratio }}%</span>
                  </div>
                </template>
              </el-table-column>
            </template> -->
          </Table>
        </div>
        <div class="bottom">
          <slot name="bottom-tab"></slot>
          <div class="DEcharts"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatFloat, handleEntitys, formatDate } from "@/utils";

export default {
  name: "detailsPopup",
  components: {
    Table,
  },
  props: {
    // refreshTable:{
    //   //强制刷新table
    //   type: Boolean,
    //   default: true,
    // },
    marginTop:{
      type: String,
      default: "calc((100vh - 720Px) / 2)",
    },
    sortDing:{
      type: String,
      default: "descending",
    },
    defaultSort:{
      type: String,
      default: "1",
    },
    orderType: {
      type: String,
    },
    entitysShow: {
      type: Array,
      default: function() {
        return [];
      },
    },
    pagelist: {
      default: function() {
        return {};
      },
    },
    getDetailsPage: {
      type: Function,
    },
    sortChange:{
      type: Function,
      default: function() {
        return {};
      },
    },
    dialogTitle: {
      type: String,
    },
    columns: {
      type: Array,
      default: function() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Number,
      // default() {
      //   return 0;
      // }
    },
    typeTable: {
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
    loading: {
      type: Boolean,
      default: function() {
        return true;
      },
    },
    stripe: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      refreshTable:true,
      dialogVisible: true,
      entitys: [
        {
          querySelect: {},
          queryValue: "",
          show: false,
        },
      ],
      loadingData: this.loading,
      chart: null,
      imgSrc1: require("@/assets/image/arrowup.svg"),
      imgSrc2: require("@/assets/image/down.svg"),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.chart = this.$echarts.init(document.querySelector(".DEcharts"));
      window.onresize = () => {
        this.chart.resize();
      };
    });
  },
  methods: {
    setDetailsOption(option) {
      this.$nextTick(() => {
        this.chart.clear() // 防止快速切换tab时数据叠加渲染在一起
        this.chart.setOption(option);
      });
    },
    closeDialog() {
      this.$emit("clos:closeDetails", this.pagelist);
    },
    FomatFloat(num, n = 2) {
      let m = formatFloat(num, n);
      let res = "";
      if (m && m.split(".")[1] === "00") {
        res = m.split(".")[0];
      } else {
        res = m;
      }
      return res;
    },
    getEntitys(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent.entitys)
      ).entity;

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          if (key === "date") {
            params[key] = formatDate(params[key]);
          }
        }
      }
      return { params, searchButton };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.dialog {
  .dialog-content {
    display: inline;
    width: 100%;
    height: 100%;
    .top {
      height: 287PX;
      padding-right: 20px;
      // max-width: 635px;
      // max-width: 56%;
      // max-width: 51%;
    }
    .bottom {
      height: 280PX;
      // max-width: 550px;
      // max-width: 43%;
      // max-width: 48%;
      .el-table::before {
        height: 0;
      }
      ::v-deep .el-table thead {
        color: #fff;
      }
    }
  }
  .Ratio {
    display: inline-block;
    width: 100%;
  }
  .table {
    // height: 94%;
  }
}
  ::v-deep .minHeigth500 .el-table__body-wrapper {
    min-height: 138PX;
    height: 138PX !important;
  }
</style>
