<template>
  <div class="echarts_map_wrap">
    <div class="btn">
      <span @click="backMap()">湖北省</span>
      <span v-show="cityName">/ {{cityName}}</span>
    </div>
    <div ref="mapRef" class="echarts_map"></div>
    <RegionFocalPoint
      :title="dialogTitle"
      :isShowDialog.sync="isShowDialog"
      :zdTotal="zdTotal"
      :timeVal="dateModel"
      :divisionCode="divisionCode"
    ></RegionFocalPoint>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { debounce, format } from '@/utils'
import hubeiJson from '@/assets/json/湖北省.json'
import RegionFocalPoint from './details/RegionFocalPoint.vue'
import { mapState } from 'vuex'

const hubeiCityMap = {
  武汉市: 420100,
  黄石市: 420200,
  十堰市: 420300,
  宜昌市: 420500,
  襄阳市: 420600,
  鄂州市: 420700,
  荆门市: 420800,
  孝感市: 420900,
  荆州市: 421000,
  黄冈市: 421100,
  咸宁市: 421200,
  随州市: 421300,
  恩施土家族苗族自治州: 422800,
  仙桃市: 429004,
  潜江市: 429005,
  天门市: 429006,
  神农架林区: 429021
}

export default {
  name: 'EchartsMap',
  components: {
    RegionFocalPoint
  },
  data() {
    return {
      divisionCode: '',
      zdTotal: 0,
      dataList: [],
      dialogTitle: '',
      isShowDialog: false,
      cityName: '',
      myChart: null,
      mapChartOption: {
        geo: {
          map: 'hubei',
          aspectScale: 0.86,
          zoom: 1,
          roam: true,
          scaleLimit: {
            min: 0.7,
            max: 2
          },
          layoutCenter: ['50%', '40%'],
          layoutSize: '100%',
          itemStyle: {
            areaColor: {
              type: 'radial',
              x: 0.5,
              y: 0.5,
              r: 0.8,
              colorStops: [
                {
                  offset: 0,
                  color: '#003159' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#007ABF' // 100% 处的颜色
                }
              ],
              globalCoord: true // 缺省为 false
            },
            borderColor: 'rgba(61,255,251,1)',
            shadowColor: 'rgba(0,72,131,1)',
            shadowOffsetX: 0,
            shadowOffsetY: 20
          },
          label: {
            show: true,
            color: 'white'
          },
          emphasis: {
            itemStyle: {
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: '#003159' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#007ABF' // 100% 处的颜色
                  }
                ],
                globalCoord: true // 缺省为 false
              },
              borderColor: 'rgba(61,255,251,1)'
            },
            label: {
              show: true,
              color: 'white'
            }
          },
          select: {
            itemStyle: {
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [
                  {
                    offset: 0,
                    color: '#003159' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#007ABF' // 100% 处的颜色
                  }
                ],
                globalCoord: true // 缺省为 false
              },
              borderColor: 'rgba(61,255,251,1)'
            },
            label: {
              show: true,
              color: 'white'
            }
          }
        },
        series: [
          {
            type: 'map',
            name: 'map',
            map: 'hubei',
            // data: [],
            roam: true,
            aspectScale: 0.86,
            geoIndex: 0,
            zoom: 1,
            layoutCenter: ['50%', '40%'],
            layoutSize: '100%'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('global', ['month', 'dateModel']),
    dayVal() {
      return this.dateModel.replace(/-/g, '')
    }
  },
  mounted() {
    // 初始化加载
    this.mapChart()
    window.addEventListener('resize', debounce(this.resizeMapChart))
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', debounce(this.resizeMapChart))
      this.myChart.dispose()
    })
    // 点击tooltip中查看详情
    window.toDetailDialog = () => {
      this.isShowDialog = true
    }
  },
  methods: {
    resizeMapChart() {
      this.myChart.resize()
    },
    // 返回湖北视图
    backMap() {
      if (!this.cityName) return
      this.cityName = ''
      this.mapChart()
    },
    // 配置渲染map
    mapChart() {
      this.myChart = echarts.init(this.$refs.mapRef)
      echarts.registerMap('hubei', hubeiJson)
      this.initEcharts('hubei')
      const that = this
      // 点击触发
      this.myChart.on('click', (param) => {
        console.log(param, 'click-myChart')
        if (!param.value && param.name in hubeiCityMap) {
          // 处理市模块
          let names = param.name
          for (let key in hubeiCityMap) {
            if (names == key) {
              that.showCitys(hubeiCityMap[key], key)
              break
            }
          }
        }
      })
      // 点击空白区域
      this.myChart.getZr().on('click', (event) => {
        // 没有 target 意味着鼠标/指针不在任何一个图形元素上，它是从“空白处”触发的。
        if (!event.target) {
          // 点击在了空白处，做些什么。
          if (that.mapChartOption.geo.map !== 'hubei') {
            this.cityName = ''
            echarts.registerMap('hubei', hubeiJson)
            that.initEcharts('hubei')
          }
        }
      })
    },
    initEcharts(map) {
      this.mapChartOption.geo.zoom = 0.68
      this.mapChartOption.geo.map = map
      this.mapChartOption.series[0].map = map
      this.myChart.setOption(this.mapChartOption, true)
      setTimeout(() => {
        this.mapChartOption.geo.zoom = 1
        this.myChart.setOption(this.mapChartOption, true)
      }, 150)
    },
    //展示对应市
    showCitys(cName, param) {
      // console.log(cName, param) // 420100 武汉市
      try {
        this.cityName = param
        // 显示区/县级地图
        const data = require(`@/assets/json/city/${cName}.json`)
        echarts.registerMap(param, data)
        // 组装数据
        this.mapChartOption.series[0].data = data.features.map((item) => {
          return {
            name: item.properties.name,
            value: item.properties.adcode,
            ...item
          }
        })
        this.clickDistrict()
        this.initEcharts(param)
      } catch (error) {
        console.log(error, 'error')
      }
    },
    clickDistrict() {
      this.mapChartOption.geo.tooltip = this.mapChartOption.tooltip = {
        trigger: 'item',
        triggerOn: 'click',
        enterable: true,
        className: 'self_tooltip_class',
        backgroundColor: 'rgba(6, 22, 49, 0.8)',
        borderColor: '#17fff5',
        textStyle: {
          color: 'rgba(255, 255, 255, 0.8)'
        },
        extraCssText: 'z-index: 99;',
        alwaysShowContent: true,
        formatter: (params, ticket, callback) => {
          // console.log(params, 'tooltip-params')
          this.dialogTitle = params.name + '详情'
          params.value && this.getTooltipData(params, ticket, callback)
          return 'Loading'
        }
      }
      // this.mapChartOption.geo.selectedMode = 'single'
      // this.mapChartOption.geo.select.itemStyle.areaColor = '#17F1EC'
    },
    // 区域汇总数据tooltip
    async getTooltipData(params, ticket, callback) {
      const paramsObj = {
        type: 'regionFocalPointSummary',
        day: this.dayVal,
        divisionCode: params.value // 区划代码
      }
      const res = await this.$getData(paramsObj)
      // console.log(res, '区域汇总数据tooltip')
      this.zdTotal = res.data.focalPointQuantity
      this.divisionCode = params.value
      callback(ticket, this.getHtmlStr(params, res.data[0]))
    },
    formatUndefinedVal(data){
      return data === undefined ? '暂无数据' : format(data)
    },
    getHtmlStr(params, data) {
      const htmlStr = `
        <div class="self_tooltip_wrap">
          <h3 class="title">${params.name}灶点情况（${this.dateModel}）</h3>
          <div class="content">
            <div class="c_item">
              <div class="name">订单总数</div>
              <div class="value">${
                this.formatUndefinedVal(data.orderQuantity)
                } <span class="unit">单</span></div>
            </div>
            <div class="c_item">
              <div class="name">商品总数</div>
              <div class="value">${
                this.formatUndefinedVal(data.productQuantity)
              } <span class="unit">件</span></div>
            </div>
            <div class="c_item">
              <div class="name">品类总数</div>
              <div class="value">${
                this.formatUndefinedVal(data.categoryQuantity)
              } <span class="unit">类</span></div>
            </div>
            <!-- <div class="c_item">
              <div class="name">应收金额</div>
              <div class="value">
              <span class="unit">万</span></div>
            </div> -->
          </div>
          <div class="to_detail" onclick="toDetailDialog()">查看详情</div>
        </div>
      `
      return htmlStr
    },
  }
}
</script>

<style lang="scss" scoped>
.echarts_map_wrap {
  width: 100%;
  height: 100%;
  .btn {
    position: absolute;
    top: 123px;
    right: 495px;
    z-index: 10;
    padding: 10px;
    background: rgba(0, 24, 69, 0.3);
    border: 1px solid #5473bf;
    backdrop-filter: blur(4px);
    color: #ffffff;
    > span:nth-child(1) {
      cursor: pointer;
    }
  }
  .echarts_map {
    width: 100%;
    height: 100%;
  }
  ::v-deep .self_tooltip_class {
    .self_tooltip_wrap {
      .content {
        display: flex;
        flex-wrap: wrap;
        margin: 16px 0;
        .c_item {
          width: 50%;
          margin-bottom: 16px;
          .value {
            color: #17f1ec;
            font-weight: 600;
            .unit {
              color: rgba(255, 255, 255, 0.8);
              font-weight: 300;
            }
          }
        }
      }
      .to_detail {
        color: #17f1ec;
        text-align: right;
        cursor: pointer;
      }
    }
  }
}
</style>