<template>
  <div class="letter-spacing">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1384PX"
      :append-to-body="true"
      top="calc((100vh - 714Px) / 2)"
      @close="closeDialog">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <slot name="left-top"></slot>
          <div class="left-content">
            <Table
              ref="tableComponent"
              :columns="columns"
              :tableData="tableData"
              :total="pageList.count"
              :page.sync="pageList.page"
              :rows.sync="pageList.rows"
              :loading="loading"
              :stripe="stripe"
              :maxHeight="'510'"
              :indexShow="false"
              :entitys-show="entitysShow"
              @pagination="getPage"
              @searchList="getPage"
              @radioHandleCurrentChange="radioHandleCurrentChange">
              <template slot="searchLeftButtonList" slot-scope="{ entitys }">
                <el-button
                  class="BlueCustomButton searchBottom return-order-margin"
                  :class="{ 'tab-active': orderType === 'allOrder' }"
                  @click.native="allOrderBtn()">全量订单</el-button>
<!--                  <el-button
                  class="BlueCustomButton searchBottom return-order-margin"
                  :class="{ 'tab-active': orderType === 'returnOrder' }"
                  style="margin-right: 10px;"
                  @click.native="returnOrderBtn()">退货订单</el-button>-->
              </template>
              <template slot="searchLeft" slot-scope="{ entitys, index }">
                <el-select
                  v-if="entitys[index].querySelect.type === 'select'"
                  v-model="entitys[index].queryValue"
                  clearable>
                  <el-option
                    v-for="item in entitys[index].querySelect.options"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">{{ item.name }}</el-option>
                </el-select>
                <el-date-picker
                  v-else-if="entitys[index].querySelect.type === 'time'"
                  v-model="entitys[index].queryValue"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyyMMdd"
                  clearable
                  :picker-options="pickerOptions" />
                  <el-date-picker
                  v-else-if="entitys[index].querySelect.type === 'timeTwo'"
                    v-model="entitys[index].queryValue"
                    range-separator="~"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    :clearable="false"
                    >
                  </el-date-picker>
                <el-date-picker
                  v-else-if="entitys[index].querySelect.type === 'time_month'"
                  v-model="entitys[index].queryValue"
                  type="month"
                  placeholder="选择月份"
                  format="yyyy-MM"
                  clearable
                  value-format="timestamp" />
                <el-input
                  v-else
                  v-model.trim="entitys[index].queryValue"
                  placeholder="请输入内容"
                  style="margin:0 1PX;"
                  clearable
                  @keyup.native="proving()" />
              </template>
              <template slot="searchRight">
                  <el-select
                    v-if="orderType === 'allOrder'"
                    @change="deliveryChange"
                    placeholder="配送状态"
                    :popper-append-to-body="false"
                    class="shipmentStatus"
                    v-model="deliveryStatus"
                    clearable>
                    <el-option label="未配送" value="0" />
                    <el-option label="已配送" value="1" />
                  </el-select>
                  <el-date-picker
                    class="month-icon-style"
                    v-model="monthValue"
                    value-format="yyyyMM"
                    type="month"
                    placeholder="选择月"
                    :picker-options="monthPickerOptions"
                    @change="monthChange">
                  </el-date-picker>
                  <!-- <div class="timeScreen">
                    <span class="timeTitle">配送时间：</span>
                    <el-date-picker
                      @change="datePicker"
                      v-model="deliveryTime"
                      :clearable="false"
                      class="dateMain"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      prefix-icon="-"
                      type="date"
                      :picker-options="pickerOptionsTwo"
                      placeholder="选择日期">
                      </el-date-picker>
                    <el-time-picker
                    class="timeMain"
                    @change="timePicker"
                    prefix-icon="-"
                      v-model="timeHorizon"
                      is-range
                      value-format="HH:mm"
                      format="HH:mm"
                      range-separator="~"
                      :clearable="false"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                      placeholder="选择时间范围">
                    </el-time-picker>
                    <div class="dateIcon-box">
                      <img class="dateIcon" src="@/assets/image/dateIcon.svg" alt="">
                      <i @click="dateIconreset" class="el-icon-circle-close dateIconTow"></i>
                    </div>
                  </div> -->
              </template>

              <template
                slot="columnEspecial"
                slot-scope="{ column, row }"
                resizable>
                <span v-if="column.prop === 'status'">{{
                  filterStatus2(row[column.prop]+'')
                }}</span>
                 <span v-else-if="column.prop === 'monitorStatus'">{{
                  filterStatus(row[column.prop])
                }}</span>
                <span v-else-if="column.prop === 'focalPointNo'">{{
                    row[column.prop].substr(0,2) + '***'
                }}</span>
                <span v-else-if="column.prop === 'deliveryDate'">{{
                  row[column.prop]&&row[column.prop].substring(0, 17)
                }}</span>
                <span v-else-if="column.prop === 'soAmt'">{{
                  row[column.prop] | Format
                }}</span>
                <span v-else-if="column.prop === 'tWeight'">{{
                  row[column.prop] | Format(2)
                }}</span>
                <span v-else-if="column.prop === 'rmaAmt'">{{
                  row[column.prop] | Format(2)
                }}</span>
                <span v-else-if="column.prop === 'itemQuantity'">{{
                  row[column.prop] / 500 | Format(2)
                }}</span>
                <span v-else-if="column.prop === 'deliveryStatus'">{{
                  row[column.prop] === 1?'已配送':'未配送'
                }}</span>
                <span v-else-if="column.prop === 'signStatus'">{{
                  row[column.prop] | signStatus
                }}</span>
                <span v-else>{{ row[column.prop] }}</span>
              </template>

              <template slot="columnRight">
                <el-table-column
                  label="操作"
                  fixed="right"
                  width="220"
                  align="center">
                  <template slot-scope="{ row }">
                    <div v-if="orderType === 'allOrder'" ref="columnRight">
                      <el-button
                        v-permission="'isCanDownload'"
                        type="text"
                        size="mini"
                        class="btnZoom"
                        @click="excelExportPlan(row)">下载订单详情</el-button>
                      <el-button
                        type="text"
                        size="mini"
                        class="btnZoom"
                        @click="viewQualityReport(row)">查看质检报告</el-button>
                      <!-- <el-button
                        class="btnZoom"
                        type="text"
                        size="mini"
                        v-if="row.status=== '50'"
                        @click="signForPicture(row)">签收图片</el-button> -->
                      <el-button
                        class="btnZoom"
                        type="text"
                        size="mini"
                        v-if="row.status=== '50'&&row.evaluationStatus === 1"
                        @click="orderEvaluation(row)">订单评价</el-button>
                    </div>
                    <div v-else ref="columnRight">
                      <el-button
                        type="text"
                        size="mini"
                        class="btnZoom"
                        @click="returnDetail(row)">查看明细</el-button>
                      <!-- <el-button
                        class="btnZoom"
                        type="text"
                        size="mini"
                        v-if="row.monitorStatus !== 1"
                        @click="returnDetailReduce(row)">处理</el-button> -->
                    </div>
                  </template>
                </el-table-column>
              </template>
<!--              <template slot="export" slot-scope="{ entitys }">
                <el-button
                  v-permission="'isCanDownload'"
                  v-if="orderType === 'allOrder'"
                  class="BlueCustomButton searchBottom"
                  @click.native="exportBtn(entitys)">导出</el-button>
              </template>-->
            </Table>
          </div>
        </div>
      </div>
    </el-dialog>
    <Dialog
      ref="viewsQuality"
      :title="titleQuality"
      :show.sync="showViewQuality"
      @clearValue="clearValue"
      :marginTop="`calc((100vh - 734Px) / 2)`"
      v-if="showViewQuality">
      <div v-bind="$attrs" v-on="$listeners">
        <el-select class="select" v-model="valueView" clearable @change="getViewQualityImg()" placeholder="请选择">
          <el-option
            v-for="item in selectOption"
            :key="item.productId"
            :label="item.productName"
            :value="item.productId">
          </el-option>
        </el-select>
        <div class="pic" v-loading="qualityLoading" element-loading-background="rgba(0, 0, 0)">
          <template v-if="qualityImage.length > 0">
            <div v-for="item in qualityImage" :key="item" class="indicatorList">
              <el-button v-if="item.endsWith('.pdf')" type="text" @click="openPDF(item)">此商品质检报告为pdf格式,请点击查看或下载</el-button>
              <el-image v-else class="imgs" :src="item" alt :preview-src-list="qualityImage" />
            </div>
          </template>
          <div class="noRepPic" v-else>
            <!-- <el-image> -->
              <div slot="error" class="image-slot">
                <img v-if="valueView" class="imgs" :src="imageSrc2">
              </div>
            <!-- </el-image> -->
          </div>
        </div>
      </div>
    </Dialog>
    <el-dialog
      title="订单评价"
      width="600PX"
      top="calc((100vh - 526Px) / 2)"
      :visible.sync="orderEvaluationDia">
      <div class="order" v-loading="orderLoading" element-loading-background="rgba(62, 100, 172, 0.1)">
        <div class="orderTo">
          <span class="orderTitle">物流评价：</span>
          <el-rate
            v-model="logisticsScoreVal"
            disabled
            :max="3"
            :colors="['#17F1EC', '#17F1EC', '#17F1EC']"
            text-color="#17F1EC">
          </el-rate>
          <span class="numberRate">{{ logisticsScoreVal | FormatToFixed }}</span>
        </div>
        <div class="orderTo">
          <span class="orderTitle">服务评价：</span>
          <el-rate
            v-model="serviceScoreVal"
            disabled
            :max="3"
            :colors="['#17F1EC', '#17F1EC', '#17F1EC']"
            text-color="#17F1EC">
          </el-rate>
          <span class="numberRate">{{ serviceScoreVal | FormatToFixed }}</span>
        </div>
        <div class="orderTo">
          <span class="orderTitle">质量评价：</span>
          <el-rate
            class="rate"
            :max="3"
            v-model="qualityScoreVal"
            disabled
            :colors="['#17F1EC', '#17F1EC', '#17F1EC']"
            text-color="#17F1EC">
          </el-rate>
          <span class="numberRate">{{ qualityScoreVal | FormatToFixed }}</span>
        </div>
        <div class="orderTo">
          <span class="orderTitle">评价内容：</span>
          <p class="contentText">{{ evaluationContent }} </p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="签收图片查看"
      width="600PX"
      top="calc((100vh - 498Px) / 2)"
      :visible.sync="signForPictureDia">
      <div class="image" v-loading='fullscreenLoading' element-loading-background="rgba(0, 0, 0)">
        <div v-if="signingPics.length > 0">
          <el-carousel :autoplay="true" indicator-position="none" ref="carousel">
            <el-carousel-item v-for="item in signingPics" :key="item">
              <el-image :previewSrcList="signingPics" class="imgs" :src="item" height="300PX" />
            </el-carousel-item>
          </el-carousel>
          <div class="picList">
            <div v-for="(item, index) in signingPics" :key="item" class="indicator">
              <img :src="item" alt :class="imgActiveIndex == index ? 'img-br' : ''" @click="handleImgClick(index)" />
            </div>
          </div>
        </div>
        <div class="noPic" v-else>
          <!-- <el-image> -->
            <div slot="error" class="image-slot">
              <img class="img" :src="imageSrc">
            </div>
          <!-- </el-image> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="退货明细"
      width="800PX"
      class="return-detail-dialog"
      top="calc((100vh - 620Px) / 2)"
      :visible.sync="returnDetailDia">
      <div class="row-box">
        <el-table-admin-page
          class="tableTwo"
          ref="tableOrder"
          :search-show="false"
          :columns="returnDetailColumns"
          :table-data="returnDetailTable"
          v-bind.sync="pageDetailList"
          :isLoading="loadingOrder"
          :pageSize="pageDetailList.pageSize"
          @size-change="sizeChangeOrder"
          @pagination="currentChangeOrder"
          :paginationShow="paginationShow2"
          highlight-current-row
          :height="height">
          <template slot="columnEspecial" slot-scope="{ column, row }">
            <div v-if="column.prop === 'operation'">
              <span class="clickButton" @click="download(row)">下载</span>
            </div>
            <span v-else-if="column.prop === 'ringRatio'">
              {{ row[column.prop].toFixed(2) }}%
              <img :src="row.ringRatio >= 0 ? imgSrc1:imgSrc2" alt>
            </span>
            <span v-else-if="column.prop === 'ddAmt'">
              {{ row[column.prop] | Format}}
            </span>
            <span v-else-if="column.prop === 'ddAmtL'">
              {{ row[column.prop] | Format}}
            </span>
            <span v-else-if="column.prop === 'rmaQty'">
              {{ row[column.prop]}}
            </span>
            <span v-else-if="column.prop === 'rmaItemAmt'">
              {{ row[column.prop] | Format(2)}}
            </span>
            <span v-else-if="column.prop === 'focalPointNo'">
              {{ row[column.prop].substr(0,2) + '***' }}
            </span>
            <span v-else>{{ row[column.prop] }}</span>
          </template>
        </el-table-admin-page>
      </div>
      <div class="image" v-loading='fullscreenLoading' element-loading-background="rgba(0, 0, 0)">
        <div v-if="returnDetailPics.length > 0" class="carousel-image">
          <el-carousel :autoplay="true" indicator-position="none" ref="carousel"  @change="carouselChange">
            <el-carousel-item v-for="(item,index) in returnDetailPics" :key="item+index">
              <el-image :previewSrcList="returnDetailPics" class="imgs" :src="item" height="300PX" />
            </el-carousel-item>
          </el-carousel>
          <div class="picList">
            <div v-for="(item, index) in returnDetailPics" :key="item+index" class="indicator">
              <img :src="item" alt :class="imgActiveIndex == index ? 'img-br' : ''" @click="handleImgClick(index)" />
            </div>
          </div>
        </div>
        <div class="noPic" v-else>
          <!-- <el-image> -->
            <div slot="error" class="image-slot">
              <img class="img" :src="imageSrc">
            </div>
          <!-- </el-image> -->
        </div>
      </div>
    </el-dialog>
    <WarningProcessing
      :WarningProcessingProp="WarningProcessingProp"
      v-if="WarningProcessingProp.showDetails"
      @setAlarmInfoHandle="setAlarmInfoHandle" />

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Table from "@/components/Table";
import WarningProcessing from "../warningProcessing";
import { handleEntitys, formatDate } from "@/utils";
import { rmaOrderSetHandled } from "@/api/weather";
import Dialog from "@/components/Dialog/index.vue";
let initColumns = {
  allOrderColumns: [
    {
      label: "订单编号",
      prop: "orderNo",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true,
      width: 100
    },
    {
      label: "下单时间",
      prop: "orderDate",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      type: "time",
      form: true,
      width: 180
    },
    {
      label: "订单金额(元)",
      prop: "soAmt",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 120
    },
    {
      label: "状态",
      prop: "status",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      type: "select",
      form: true,
      options: [
        { name: "待审核", value: "0" },
        { name: "已审核", value: "6" },
        // { name: "入库中", value: "20" },
        // { name: "待出库", value: "30" },
        { name: "已出库", value: "4" },
        // { name: "已签收", value: "50" },
        { name: "部分退货", value: "-5" },
      ]
    },
    {
      label: "灶点编号",
      prop: "focalPointNo",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true
    },
    /*{
      label: "灶点名称",
      prop: "focalPointName",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true
    },*/
    {
      label: "配送时间",
      prop: "deliveryDate",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      type: "time",
      form: true,
      width: 130
    },
    /* {
      label: "一级单位编码",
      prop: "focalName1",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: false,
      width: 130
    },
    {
      label: "二级单位编码",
      prop: "focalName2",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: false,
      width: 130
    },
    {
      label: "三级单位编码",
      prop: "focalName3",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: false,
      width: 130
    }, */
    {
      label: "商品重量(斤)",
      prop: "tWeight",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 120
    },
    {
      label: "配送状态",
      prop: "deliveryStatus",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 100
    },
    {
      label: "签收状态",
      prop: "signStatus",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 100
    }
  ],
  returnOrderColumns: [
    {
      label: "订单编号",
      prop: "soid",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true,
      width: 100
    },
    {
      label: "拒收单号",
      prop: "rmaId",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true,
      width: 100
    },
    {
      label: "拒收时间",
      prop: "createTime",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      type: "time",
      form: true,
      width: 140
    },
    {
      label: "下单时间",
      prop: "orderDate",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      type: "time",
      form: true,
      width: 140
    },
    {
      label: "灶点编号",
      prop: "focalPointNo",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true
    },
    /*{
      label: "灶点名称",
      prop: "focalPointName",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: true
    },*/
    {
      label: "订单金额(元)",
      prop: "soAmt",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 100
    },
    {
      label: "拒收商品金额(元)",
      prop: "rmaAmt",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      form: true,
      align: 'right',
      width: 120
    },
    {
      label: "状态",
      prop: "monitorStatus",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: false,
      type: "select",
      form: false,
      options: [
        { name: "已处理", value: "1" },
        { name: "未处理", value: "0" },
      ]
    },
    {
      label: "下单时间",
      prop: "timeTwo",
      feildType: "STRING",
      queryType: "LIKE",
      isnull: false,
      query: true,
      form: false,
      type: "timeTwo",
      width: 1
    },
  ]
}
export default {
  name: "detailsPopup",
  props: {
    textMap: {
      default: function () {
        return {};
      },
    },
    entitysShow: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // pageList: {
    //   default: function () {
    //     return {};
    //   },
    // },
    // getDetailsPage: {
    //   type: Function,
    // },
    radioHandleCurrentChange: {
      default: function () {
        return function () { };
      },
    },
    dialogTitle: {
      type: String,
    },
    // columns: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    // tableData: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    total: {
      type: Number,
      default() {
        return 0;
      },
    },
    page: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
    columns_r: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableData_r: {
      type: Array,
      default: function () {
        return [];
      },
    },
    total_r: {
      type: Number,
      default() {
        return 0;
      },
    },
    page_r: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    stripe: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    Table,
    WarningProcessing,
    Dialog
  },
  filters: {
    FormatToFixed(val) {
      if (val) {
        let result = (Math.round(val * 100) / 100).toString()
        let dotPosition = result.indexOf('.')
        if (dotPosition < 0) {
          dotPosition = result.length
          result += '.'
        }
        while (result.length <= dotPosition + 1) {
          result += '0'
        }
        return result
      } else {
        return '0'
      }
    },
    signStatus(val){
      return val === 0 ? '未签收':'已签收'
    }
  },
  computed: {
    ...mapGetters(["global"]),
    ...mapState('global', ['month', 'dateModel'])
  },
  data() {
    return {
      rmaId:null,
      deliveryStatus:null,
      deliveryTime:'',
      // timeHorizon: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      timeHorizon: ['00:00', '23:59'],
      pickerOptions: {
        // disabledDate:(time) =>{
        //   let date = new Date(this.global.month)
        //   let month = parseInt(date.getMonth() + 1)
        //   let year = date.getFullYear()
        //   let end_time = new Date(year, month, 0);
        //     return date.setDate(0)>time.getTime()||time.getTime() > end_time.getTime();
        //   }
      },
      pickerOptionsTwo: {
        disabledDate(time) {
          // return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }]
      },
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
      titleQuality: '质检报告',
      valueView: '',
      selectOption: [],
      qualityImage: [],
      dialogVisible: true,
      fullscreenLoading: false,
      qualityLoading: false,
      orderLoading: false,
      imgActiveIndex: 0,
      orderEvaluationDia: false,
      signForPictureDia: false,
      showViewQuality: false,
      signingPics: [],
      logisticsScoreVal: 0,
      serviceScoreVal: 0,
      qualityScoreVal: 0,
      evaluationContent: '',
      entitys: [
        {
          querySelect: {},
          queryValue: "",
          show: false,
        },
      ],
      loadingData: this.loading,
      loadingData_r: this.loading,
      chart: null,
      imageSrc: require('@/assets/image/noPicTips.png'),
      imageSrc2: require('@/assets/image/noRepTips.png'),
      pages: {
        page: 1,
        rows: 20
      },
      prodcutID: '',
      initColumns: initColumns,
      columns: [],
      orderType: 'allOrder',
      tableData: [],
      pageList: {
        page: 1,
        rows: 20,
        count: 0
      },
      height: `calc(100% - 32px)`,
      returnDetailDia: false,
      returnDetailPics: [],
      loadingOrder: false,
      paginationShow2: false,
      returnDetailTable: [],
      pageDetailList: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      WarningProcessingProp: {
        title: "拒收处理",
        showDetails: false,
      },
      returnDetailColumns: [
        {
          type: 'index',
        },
        {
          label: "商品名称",
          prop: "productName",
          align: "center",
        },
        {
          label: "订购数量",
          prop: "orderQty",
          align: "center",
        },
        {
          label: "退货数量",
          prop: "rmaQty",
          align: "right",
          headerAlign: "right",
        },
        {
          label: "退货金额(元)",
          prop: "rmaItemAmt",
          align: "right",
          headerAlign: "right",
        }
      ],
      monthValue: '',
      demoMonth: ''
    };
  },
  created() {
    // this.initDate()
    // this.allOrderBtn(1)
  },
  methods: {
    monthChange(d){
      if(this.orderType === 'allOrder'){
        this.getDetailsPage('',0)
      }else{
        this.getReturnOrderDetailsPage('',0)
      }
    },
    openPDF(path){
      window.open(path,'_blank')
    },
    rejection(v){
      this.orderType = 'returnOrder'
       this.tableData = []
      this.columns = []
      this.columns = initColumns.returnOrderColumns
      this.$nextTick(()=>{
        this.$refs.tableComponent.entitys[0].querySelect = {
                                                            feildType: "STRING",
                                                            form: true,
                                                            isnull: false,
                                                            label: "拒收单号",
                                                            prop: "rmaId",
                                                            query: true,
                                                            queryType: "LIKE",
                                                            width: 100,
                                                          }
          this.$refs.tableComponent.entitys[0].queryValue = v.remark
        setTimeout(() => {
          this.$refs.tableComponent.entitys[0].queryValue = v.remark
        }, 50);
      })
      this.getReturnOrderDetailsPage()
    },
    carouselChange(v) {
      this.imgActiveIndex = v
    },
    dateIconreset(){
      // this.$refs.tableComponent.entitys.map((item) => {
      //   item.querySelect = "";
      //   item.queryValue = "";
      // });
      this.deliveryTime = ''
      this.timeHorizon = ['00:00', '23:59']
      this.getDetailsPage('',0)
    },
    deliveryChange(v){
      // this.$refs.tableComponent.entitys.map((item) => {
      //   item.querySelect = "";
      //   item.queryValue = "";
      // });
      this.getDetailsPage('',0)
    },
    initDate(){
      let now = new Date()
      let year = now.getFullYear() // 得到年份
      let month = now.getMonth() // 得到月份
      let date = now.getDate() // 得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      let defaultDate = `${year}-${month}-${date}`
      // this.$set(this, 'deliveryTime', defaultDate)
      this.deliveryTime = defaultDate
    },
    datePicker(v){
      // this.$refs.tableComponent.entitys.map((item) => {
      //   item.querySelect = "";
      //   item.queryValue = "";
      // });
      this.getDetailsPage('',0)
    },
    timePicker(v){
      // this.$refs.tableComponent.entitys.map((item) => {
      //   item.querySelect = "";
      //   item.queryValue = "";
      // });
      this.getDetailsPage('',0)
    },
    allOrderBtn(v=0) {
        this.orderType = 'allOrder'
        this.tableData = []
        this.columns = []
      if(v === 0){
       this.$refs.tableComponent.entitys.map((item) => {
        item.querySelect = "";
        item.queryValue = "";
      });
      }
      this.columns = initColumns.allOrderColumns
      this.getDetailsPage()
    },
    returnOrderBtn() {
      this.orderType = 'returnOrder'
      this.tableData = []
      this.columns = []
      this.$refs.tableComponent.entitys.map((item) => {
        item.querySelect = "";
        item.queryValue = "";
      });
      this.columns = initColumns.returnOrderColumns
      setTimeout(() => {
        this.getReturnOrderDetailsPage()
      }, 300)
    },
    getPage(_,v) {
      if(this.orderType === 'allOrder'){
        return this.getDetailsPage(_,v)
      } else {
        return this.getReturnOrderDetailsPage(_,v)
      }
    },
    getDetailsPage(entitys, searchButton = 0) {
      //查询 0
      //重置 0
      //换页 2
      //换条 1
      if (searchButton == 0) {
        this.pageList.page = 1
        this.pageList.rows = 20
      }else if (searchButton == 1){
         this.pageList.page = 1
      }
      /* let deliveryTime = ''
      if(this.deliveryTime){
        deliveryTime = this.deliveryTime.replaceAll('-','')
      } */
      this.$nextTick(async () => {
        if(Object.keys(this.getEntitys().params).includes('undefined')){
          return this.$message({
            message: '请选择搜索项',
            type: 'warning'
          })
        }
        let params = Object.assign(
          {
            // type: "orderStatusMonitorDetail",
            // type: "orderStatusMonitorDetailNew",
            // date: formatDate(this.month),
            type: "orderOnProcessDetail",
            day: this.dateModel.replace(/-/g, ''),
            page: this.pageList.page,
            rows: this.pageList.rows,
            warehouseCode: this.warehouseCode
            // deliveryTimeStart: this.deliveryTime?deliveryTime+' '+this.timeHorizon[0]:'',
            // deliveryTimeEnd: this.deliveryTime?deliveryTime+' '+this.timeHorizon[1]:'', 
            // organizationDebug: 1
          },
          this.getEntitys().params
        );
        if(this.deliveryStatus){
          params.deliveryStatus = this.deliveryStatus
        }
        if(this.demoMonth){
          params.month = this.demoMonth
        }
        let res = await this.$getData(params);
        this.tableData = res.data;
        this.pageList.count = res.count;
      });
    },
    // 待改接口
    getReturnOrderDetailsPage(entitys, searchButton = 0) {
      if (searchButton == 0) {
        this.pageList.page = 1
        this.pageList.rows = 20
      }else if (searchButton == 1){
         this.pageList.page = 1
      }
      this.$nextTick(async () => {
        let params = Object.assign(
          {
            type: "rmaOrderMonitor",
            page: this.pageList.page,
            rows: this.pageList.rows,
            // date: formatDate(this.month),
            day: this.dateModel.replace(/-/g, ''),
            warehouseCode: this.warehouseCode
          },

          this.getEntitys().params
        );
        if(this.demoMonth){
          params.month = this.demoMonth
        }
        let res = await this.$getData(params);
        this.tableData = res.data;
        this.pageList.count = res.count;
      });
    },
    //订单金额分页
    sizeChangeOrder(size) {
      this.pageDetailList.pageSize = size;
      this.paginationShow2 = false
      this.$nextTick(() => {
        this.paginationShow2 = true
      })
    },
    currentChangeOrder(page) {
      this.pageDetailList.currentPage = page;
      this.paginationShow2 = false
      this.$nextTick(() => {
        this.paginationShow2 = true
      })
    },
    // 退货明细接口  待改接口
    getReturnDetailsPage() {
      this.$getData({
        ...this.params,
        type: "subOrganizationOrderAmountSummary",
        date: formatDate(this.month),
        page: this.pageDetailList.currentPage,
        rows: this.pageDetailList.pageSize
      }).then((res) => {
        this.loadingOrder = false
        this.pageDetailList.total = res.count
        this.returnDetailTable = res.data
      })
    },
    // 退货明细接口 待改接口
    returnDetailPicture(row) {
      this.fullscreenLoading = true
      this.returnDetailPics = []
      this.$getData({
        type: "rmaOrderDetail",
        rmaId: row.rmaId
      }).then((res) => {
        this.returnDetailTable = res.data.items
        if(res.data.imageItems.length){
          res.data.imageItems.forEach(v=>{
            this.returnDetailPics.push(v.path)
          })
        }else{
          this.returnDetailPics = []
        }
        this.fullscreenLoading = false
      })
    },
    // 查看明细
    returnDetail(row) {
      this.returnDetailDia = true
      this.returnDetailPicture(row)
      // this.getReturnDetailsPage()
    },
    // 处理按钮
    returnDetailReduce(row) {
      this.WarningProcessingProp.showDetails = true;
      this.rmaId = row.rmaId
    },
    // 处理 待改接口
    async setAlarmInfoHandle(msg) {
      rmaOrderSetHandled({
        rmaId: this.rmaId,
        handleResult: msg,
      }).then(res=>{
        if (res.code === 0) {
          this.$message({
            message: res.messages,
            type: "success",
          });
          this.getReturnOrderDetailsPage()
        }else{
           this.$message({
            message: res.messages,
            type: "error",
          });
        }
      })
    },
    proving() {
      let entitys = this.$refs.tableComponent.entitys
      if (entitys[0].queryValue) {
        entitys[0].queryValue = entitys[0].queryValue.replace(/[%]/g, '').replace(/\s/g, "");
      }
    },
    clearValue() {
      this.valueView = ''
      this.selectOption = []
      this.qualityImage = []
    },
    exportBtn() {
      this.$emit("exportTion", this.entitys);
    },
    handleImgClick(index) {
      this.imgActiveIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    closeDialog() {
      this.$parent.closeDetails()
    },
    handleCloses() {
      this.selectOption = []
      this.qualityImage = []
      this.valueView = ''
    },
    getEntitys(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent.entitys)
      ).entity;

      for (const key in params) {
        if (Object.hasOwnProperty.call(params, key)) {
          if (key === "date") {
            params[key] = params[key][0]
          }
           if (key === "timeTwo") {
            params['orderDateStart'] = params[key][0]+' 00:00:00'
            params['orderDateEnd'] = params[key][1]+' 23:59:59'
            delete params.timeTwo;
          }
        }
      }
      return { params, searchButton };
    },
    getEntitys_r(entity, searchButton) {
      const params = handleEntitys(
        entity || (this.$refs && this.$refs.tableComponent2.entitys)
      ).entity;
      return { params, searchButton };
    },
    filterStatus(val) {
      return this.textMap[val];
    },
    filterStatus2(val) {
      const textMap = {
        ['-5']: '部分退货',
        ['0']: '待审核',
        ['4']: '已出库',
        ['6']: '已审核'
      }
      return textMap[val];
    },
    excelExportPlan(row) {
      this.$getData({
        type: "crfRequestForward",
        url: `/ICRFS/GetCrfsOrderDetails?soid=${row.orderNo}`,
        method: "GET",
        download: 1
      }).then(async res => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '下载路径为空!',
            center: true
          })
        }
      })
    },
    // 获取质检报告的下拉框字段
    getOrderProductInfo() {
      this.$getData({
        ...this.pages,
        type: "orderProductInfo",
        productIds: this.prodcutID.toString()
      }).then((res) => {
        this.selectOption = res.data
      })
    },
    //查看质检报告
    viewQualityReport(row) {
      this.showViewQuality = true
      this.$getData({
        type: "crfRequestForward",
        method: "GET",
        url: `/ICRFS/GetCrfsbiSoReports?soid=${row.orderNo}`
      }).then((res) => {
        this.prodcutID = JSON.parse(res.data).map((item) => item.ProdcutID)
        if (this.prodcutID) {
          this.getOrderProductInfo()
        }
      })
    },
    //获取质检报告图片
    getViewQualityImg() {
      this.qualityLoading = true
      this.$getData({
        type: 'crfRequestForward',
        url: `/ICRFS/GetCrfsbiProductReports?productid=${this.valueView}`,
        method: "GET"
      }).then((res) => {
        this.qualityLoading = false
        this.qualityImage = JSON.parse(res.data) || []
      })
    },
    signForPicture(row) {
      this.signForPictureDia = true
      this.fullscreenLoading = true
      this.signingPics = ''
      // this.imageSrc = ''
      this.$getData({
        type: "orderEvaluateSigning",
        soid: row.orderNo
        // soid: '1000006844'
      }).then((res) => {
        this.signingPics = res.data.signingPics
        this.fullscreenLoading = false
      })
    },
    orderEvaluation(row) {
      this.orderEvaluationDia = true
      this.orderLoading = true
      this.$getData({
        type: "orderEvaluateSigning",
        soid: row.orderNo
      }).then((res) => {
        this.orderLoading = false
        this.logisticsScoreVal = res.data.logisticsScore >= 3?3:res.data.logisticsScore
        this.serviceScoreVal = res.data.serviceScore >= 3?3:res.data.serviceScore
        this.qualityScoreVal = res.data.qualityScore >= 3?3:res.data.qualityScore
        this.evaluationContent = res.data.evaluationContent
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";

::v-deep {
  .el-dialog {
    // height: inherit !important;
  }
  .el-table thead {
    color: #fff;
  }
}
.btnZoom {
  padding: 0PX 0PX;
}
.searchBottom {
  margin: 1PX 0;
  font-size: 14PX;
  padding: 0PX 0PX;
}
.return-order-margin {
  margin-left: -1px !important;
}
::v-deep .el-dialog__title {
  color: #ffffff;
  font-size: 14PX;
}
.left {
  .tab-active {
    font-weight: bold;
    position: relative;
    opacity: 1 !important;
    color: #17fff4 !important;
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  }
  .month-icon-style{
    width: 120PX;
    ::v-deep .el-input__prefix{
      margin-top: -1px;
    }
  }
}
.order {
  display: flex;
  flex-direction: column;
  .orderTo {
    display: flex;
    flex-direction: row;
    .orderTitle {
      color: #ffffff;
      font-size: 14PX;
      line-height: 36PX;
      margin-bottom: 40PX;
    }
    .contentText {
      margin-bottom: 70PX;
      color: #fff;
      font-size: 14PX;
      line-height: 36PX;
      width: 420PX;
      height: 150PX;
      border: 1PX solid #5473bf;
    }
    .el-rate {
      padding-left: 10PX;
    }
    .numberRate {
      color: #17f1ec;
      font-size: 28PX;
      font-family: Impact, Impact-Regular;
      line-height: 36PX;
    }
    // .rate {
    //   padding-left: 25PX;
    // }
  }

  ::v-deep .el-rate__icon {
    font-size: 36PX;
  }
  // ::v-deep .el-rate__icon.el-icon-star-on {
  //   color: #17f1ec !important;
  // }
  // ::v-deep .el-rate__decimal.el-icon-star-on {
  //   color: #17f1ec !important;
  // }
  ::v-deep .el-rate__text {
    font-size: 28PX;
    font-family: Impact, Impact-Regular;
  }
}
.image {
  // width: 568PX;
  // height: 426PX;
  .imgs {
    width: 100%;

  }
  .picList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .indicator {
      padding: 20PX 5PX 0 0;
      img {
        width: 60PX;
        height: 50PX;
      }
      .img-br {
        border: 1PX solid #17f1ec;
      }
    }
  }
  .noPic {
    width: 500PX;
    // height: 380PX;
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 40PX;
    .img {
      width: 100%;
      padding: 120PX 190PX;
    }
  }
}
.select {
  margin: 25PX 0 0 30PX;
}
.pic {
  width: 95%;
  height: 475PX;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-wrap: wrap;
  margin: 40PX auto;
  .indicatorList,
  .noRepPic {
    .imgs {
      margin: 20PX 16PX;
      width: 200PX;
      height: 200PX;
    }
  }
}
.return-detail-dialog{
  ::v-deep .el-dialog {
    height: 620PX !important;
  }
  .el-dialog__body {
    padding: 10px 30px;
  }
  .row-box{
    height: 35%;
    .tableTwo{
      height: calc(100% - 1px);
    }
  }
  .image {
    margin-top: 12px;
    .carousel-image {
      display: flex;
      .el-carousel {
        flex: 8;
        .el-carousel__container {
          width: 98%;
          height: 300px;
        }
        .el-image {
          .el-image__inner {
            height: 300px !important;
          }
        }
      }
      .picList {
        flex: 2;
        height: 300px;
        overflow: hidden;
        overflow-y: auto;
        padding-right: 0PX;
        .indicator {
          margin-left: 10px;
          padding: 10PX 0;
          img {
            width: 110PX;
            height: 80PX;
          }
          .img-br {
            border: 1PX solid #17f1ec;
          }
        }
      }
    }
    .noPic {
      width: 200PX;
      // height: 150PX;
      background: rgba(255, 255, 255, 0.2);
      margin-bottom: 40PX;
      .img {
        width: 100%;
        padding: 10PX 20PX;
      }
    }
  }
}
::v-deep .el-input__inner {
  &::placeholder {
    font-size: 14PX;
    padding: 0PX 10PX;
  }
}
::v-deep .el-carousel__item h3 {
  color: #475669;
  font-size: 18PX;
  opacity: 0.75;
  line-height: 300PX;
  margin: 0;
}
.shipmentStatus{
  width: 120PX;
  margin-right: 12PX;
}
.timeScreen{
  position: relative;
  border: 1px solid #87d2ff;
  height: 32PX;
  padding-left: 6PX;
  margin-top: -1PX;
  .timeTitle{
    font-size: 12px;
    // color: #fff;
    color: rgba(255, 255, 255, 0.7);
  }
  .dateMain{
    width: 100PX;
    height: 32PX;
    padding: 0;
    ::v-deep .el-input__inner{
      padding: 0 0 0 12PX;
      border: none;
    }
  }
  .timeMain{
    width: 150PX;
    padding: 0;
    height: 32PX;
    border: none;
    ::v-deep .el-range__icon{
      display: none;
    }
    ::v-deep .el-range-input{
      width: 38%;
      color: #17F1EC;
    }
    ::v-deep .el-input__inner {
      margin-top: 0;
    }
  }
 
  .dateIcon-box{
    position: absolute;
    right: 0;
    top: 0;
    height: 26PX;
    width: 26PX;
    display: flex;
    text-align: center;
    align-items: center;
    .dateIcon{
      height: 16PX;
      width: 16PX;
    }
    .dateIconTow{
      display: none;
      color: #ffffff;
    }
    &:hover .dateIcon{
      display: none;
    }
    &:hover .dateIconTow{
      display: block;
    }
  }
  // .dateIcon:hover+.dateIconTow {
  //   display: block;
  // }
  
}
.searchContent{
  margin-top: -3PX;
}

::v-deep{
  .el-input__prefix{
    margin-top: -3PX;
  }
}

::v-deep .searchRight{
  .el-input__inner {
        margin-top: -1PX !important;
      }
} 
::v-deep {
  .el-range-editor.el-input__inner {
    // border: 1px solid #87d2ff;
    border-radius: 1px;
    height: 32px;
    width: 250px;
  }

  .el-date-editor .el-range-input {
    width: 100%;
    color: #17f1ec;
  }
  .el-date-editor .el-range-separator {
    line-height: 25px !important;
    padding: 0;
  }
  .el-date-table tr th {
    color: #fff !important;
  }
}
::v-deep .minHeigth500 .el-table__body-wrapper {
  height: 462PX !important;
}
::v-deep .el-table__empty-block{
  height: 462PX !important;
}
// ::v-deep .el-carousel__item {
//   width: 500PX;
//   height: 300PX;
// }

</style>
