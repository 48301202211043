<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1284px"
      :append-to-body="true"
      custom-class="Dialog"
      top="calc((100vh - 750PX)/2)"
      @close="closeDialog">
      <div class="dialog-content business-analysis">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <div class="left-top">
            <div class="left-tab-box">
              <!-- <div
                class="left-tab-item"
                :class="{ 'tab-active': orderType === 'goodReBuy' }"
                @click="orderType = 'goodReBuy'">
                商品复购
              </div> -->
              <div
                class="left-tab-item"
                :class="{ 'tab-active': orderType === 'upNum' }"
                @click="orderType = 'upNum'">
                上架率
              </div>
            </div>
            <div v-if="orderType === 'goodReBuy'" class="left-top-box left-top-middle">
              <el-select
                v-model="entitys[0].querySelect"
                placeholder="请选择单位层级"
                value-key="prop"
                :popper-append-to-body="false"
                clearable
                @change="selectChange">
                <el-option
                  v-for="item in selectOptions"
                  :key="item.prop"
                  :label="item.label"
                  :value="item.prop" />
              </el-select>
              <el-input
                v-model.trim="entitys[0].queryValue"
                placeholder="请输入单位代码"
                style="margin: 0 12PX 0 0"
                :disabled="inputDisab"
                @keyup.native="proving(1)"
                clearable />
              <el-button
                style="line-height: 10PX;"
                class="BlueCustomButton searchBottom"
                @click.native="seachList(entitys, true)">查询</el-button>
              <el-button
                style="line-height: 10PX;"
                class="BlueCustomButton searchBottom"
                @click.native="elTableRefs">重置</el-button>
            </div>
            <div class="left-top-right">
              <div v-if="orderType === 'goodReBuy'" class="timeUpdate">更新时间：{{updateTime?updateTime:'---'}}</div>
              <el-button
                v-if="orderType === 'upNum'"
                class="BlueCustomButton searchBottom"
                style="margin-right:12PX;"
                type="mini"
                @click="importDetail">导出上架率模板</el-button>
                <el-upload
                  v-if="orderType === 'upNum'"
                  class="container"
                  action="/vendorOnShelfRatio/import"
                  :on-success="handleSuccess"
                  :multiple="false"
                  :limit="limit"
                  :headers="uploadHeaders"
                  :before-upload="beforeUpload"
                  :on-exceed="exceedHandle"
                  :on-error="errorHandle"
                  :show-file-list="false"
                  :file-list="fileList">
                    <el-button class="BlueCustomButton searchBottom" type="mini">
                        导入
                    </el-button>
                </el-upload>
            </div>
          </div>
          <div class="left-content">
            <Table
              ref="tableComponent"
              :columns="columns"
              :tableData="tableData"
              :total="pageList.count"
              :page.sync="pageList.page"
              :rows.sync="pageList.rows"
              :loading="false"
              :stripe="false"
              :showOverflowTooltip="true"
              :align="'left'"
              :maxHeight="550"
              :indexShow="orderType==='goodReBuy'"
              :rowClassName="tableRowClassName"
              :indexWidth="70"
              :entitysShow="entitysShow"
              :searchShow="searchShow"
              :spanMethod="spanMethod"
              @pagination="getPage">
              <template
                slot="columnEspecial"
                slot-scope="{ column, row }"
                resizable>
                <span v-if="column.prop === 'one_actualOnShelfNumber'">{{
                  row[column.prop]
                }}</span>
                <span v-else-if="gradePropArr.includes(column.prop)" class="table-cell-business" style="">
                  <span v-for="obj in gradeObj" :key="obj" class="table-cell-business-grade">
                    <span v-if="row[column.prop]=== obj" :class="colorObj[obj]">
                      {{ obj }}
                    </span>
                  </span>
                  <span class="table-cell-business-number">{{ row[column.prop] | filterGrade(row, column.prop, column.prop) }}</span>
                </span>
                <span v-else-if="column.prop === 'type'">{{
                  row[column.prop]
                }}</span>
                <span v-else>{{ row[column.prop] }}</span>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getToken } from "@/utils/auth";
import { formatFloat, formatDate } from "@/utils";
import { exportPutaway } from "@/api/weather";

export default {
  name: "detailsPopup",
  components: {
    Table
  },
  props: {
    entitysShow: {
      type: Array,
      default: function () {
        return []
      }
    },
    alarmStatus: {
      default: 0
    },
    searchShow: {
      type: Boolean
    },
    dialogTitle: {
      type: String
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  filters: {
    filterGrade(column, row, prop) {
      let num = prop.split('_')[0]
      let actualOnShelfProp = num + '_actualOnShelfNumber'
      let onShelfRatioProp = num + '_onShelfRatio'
      return row[actualOnShelfProp]+ ' ('+`${parseInt(Number(row[onShelfRatioProp])*100)}`+'%) '
    }
  },
  data() {
    return {
      uploaDing:null,
      dialogVisible: true,
      params: {},
      inputDisab: true,
      columns: [],
      tableData: [],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      colorObj: {
        '优': 'aaa',
        '良': 'bbb',
        '中': 'ccc',
        '差': 'ddd'
      },
      gradeObj: [
        '优', '良', '中', '差'
      ],
      gradePropArr: [
        'one_grade', 'two_grade', 'three_grade', 'four_grade', 'five_grade', 'month_grade'
      ],
      filterTableindexArr: [],
      orderType: 'upNum',
      selectOptions: [
        {
          label: "一级单位编码",
          prop: "focalName1",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
          align: "center",
        },
        {
          label: "伙食单位编码",
          prop: "customerKey",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
        {
          label: "灶点编号",
          prop: "focalPointNo",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false
        },
      ],
      entitys: [
        {
          querySelect: '',
          queryValue: '',
        }
      ],
      updateTime: '2021-12-14 17:01', // 待改
      fileList: [],
      uploadFile: {},
      limit: 1,
      uploadHeaders: { // 导入时的header权限认证
        Authorization: `${getToken()}`,
        token: `${getToken()}`
      }
    };
  },
  watch: {
    orderType: {
      handler(value) {
        this.pageList.page = 1;
        this.getPage();
        if (value === "goodReBuy") {
          this.columns = [
            {
              label: "灶点编号",
              prop: "focalPointNo",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: true,
              form: true,
              align: "center",
              width: 100,
            },
            {
              label: "伙食单位",
              prop: "customerName",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              width: 100
            },
            {
              label: "商品名称",
              prop: "skuName",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "商品编码",
              prop: "skuCode",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "供应商名称",
              prop: "venderName",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "总订购数量",
              prop: "allName",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "总订购数量/件",
              prop: "venderName1",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "一级品类",
              prop: "venderName2",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "复购频次",
              prop: "rebuyNum",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
            {
              label: "满意度",
              prop: "one_grade",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100
            },
          ];
        } else {
          this.columns = [
            {
              label: "货源商名称",
              prop: "vendorName",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: true,
              form: true,
              align: "center",
              width: 100,
            },
            {
              label: "考核包段",
              prop: "categoryName",
              feildType: "STRING",
              queryType: "query",
              isnull: false,
              query: true,
              form: true,
              align: "center",
              width: 100,
            },
            {
              label: "满上架数",
              prop: "month_fullOnShelfNumber",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 100,
            },
            {
              label: "第一周",
              prop: "one_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: true,
              align: "center",
              width: 120
            },
            {
              label: "第二周",
              prop: "two_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 120
            },
            {
              label: "第三周",
              prop: "three_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 120
            },
            {
              label: "第四周",
              prop: "four_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 120
            },
            {
              label: "第五周",
              prop: "five_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 120
            },
            {
              label: "月度",
              prop: "month_grade",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: "center",
              width: 120
            },
          ];
        }
      },
      immediate: true
    }
  },
  methods: {
    spanMethod(data) {
      if (data.columnIndex === 0 && this.orderType=='upNum') {
        if (this.filterTableindexArr.includes(data.rowIndex)) {
          let num = this.filterTableindexArr.indexOf(data.rowIndex)
          let nextNum = Number(this.filterTableindexArr[num+1])-Number(this.filterTableindexArr[num])
          let filterNum = data.rowIndex===0?this.filterTableindexArr[num+1]: nextNum
          return [filterNum, 1];
        } else {
          return [0, 0];
        }
      }
    },
    filterTableSpanMethod(table) {
      var indexArr = []
      var _vname=''
      table.forEach((i,index) =>{
        if(!_vname){
          _vname = i['vendorName']
          indexArr.push(index)
        }
        if(i['vendorName'] !==_vname){
          _vname = i['vendorName']
          indexArr.push(index)
        }
      })
      indexArr.push(table.length)
      this.filterTableindexArr = indexArr
    },
    tableRowClassName({row, rowIndex}) {
      let calss = ''
      if(this.orderType=='upNum') {
        let arr = [...this.filterTableindexArr]
        for(let i =0; i< arr.length;i++) {
          if (i !== 0) {
            let vindex = arr[i]-1
            if (rowIndex === arr[i]) {
              calss = 'table_one';
            }
            if (rowIndex === vindex) {
              calss = 'table_tr';
            }
          } else {
            if (arr[1]  == 1) {
              if (rowIndex === arr[i]) {
                calss = 'table_one table_tr';
              }
            } else {
              if (rowIndex === arr[i]) {
                calss = 'table_one';
              }
            }
          }
        }
      }
      return calss
    },
    getPage(_,v) {
      if(this.orderType === 'upNum'){
        return this.getUpNumDetailsPage(_,v)
      } else {
        return this.getDetailsPage(_,v)
      }
    },
    // 获取上架率详情数据
    async getUpNumDetailsPage(_,v) {
      if(v){
        if(v == 1){
          this.pageList.page = 1
        }
      }
      const global = this.$store.getters.global;
      const month = formatDate(global.month);
      console.log(global, 'month', formatDate(global.month), month.slice(-2))
      let params = Object.assign({
        type: "vendorOnShelfRatio",
        year: month.slice(0, 4),
        month: month.slice(-2),
        page: this.pageList.page,
        rows: this.pageList.rows,
      });
      let res = await this.$getData(params);
      this.tableData = res.data;
      this.pageList.count = res.count;
      this.filterTableSpanMethod(res.data);
    },
    // 获取详情数据
    async getDetailsPage(_,v) {
      if(v){
        if(v == 1){
          this.pageList.page = 1
        }
      }
      const global = this.$store.getters.global;
      const month = formatDate(global.month);
      // 待改接口
      let params = Object.assign({
        type: "vendorRatioDetail",
        date: month,
        orderBy: 'amt',
        page: this.pageList.page,
        rows: this.pageList.rows,
      });
      let res = await this.$getData(params);
      this.tableData = res.data;
      this.pageList.count = res.count;
    },
    closeDialog() {
      this.$emit('close:closeBusinessAnalysis');
    },
    seachList() {
      this.proving(2)
    },
    proving(v) {
      if (this.entitys[0].querySelect) {
        if (this.entitys[0].querySelect == "customerKey") {
          let regu = /[^\d]/
          if (regu.test(this.entitys[0].queryValue) && v == 2) {
            this.$message({
              message: '请输入正确的单位编码',
              type: 'warning'
            });
            return
          } else {
            this.entitys[0].queryValue = this.entitys[0].queryValue.replace(/[^\d]/g, '');
            this.entitys[0].queryValue = this.entitys[0].queryValue.replace('.', '');
          }
        } else if (this.entitys[0].querySelect == "focalPointNo") {
          let regu = /[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/
          if (regu.test(this.entitys[0].queryValue) && v == 2) {
            this.$message({
              message: '请输入正确的灶点编号',
              type: 'warning'
            });
            return
          } else {
            this.entitys[0].queryValue = this.entitys[0].queryValue.replace(/[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, "");
          }
        }
      }
      if (v == 2) {
        let params = {}
        if (this.entitys[0].querySelect) {
          if (this.entitys[0].querySelect == "customerKey") {
            let num = Number(this.entitys[0].queryValue)
            if (num > 2147483647) {
              this.$message({
                message: '请输入正确的单位编码',
                type: 'warning'
              });
              this.entitys[0].queryValue = undefined
              return
            }
          }
          params[this.entitys[0].querySelect] = this.entitys[0].queryValue || ''
        }
        this.params = params
        this.getPage()
      }
      this.$forceUpdate()
    },
    selectChange() {
      this.inputDisab = false
      this.entitys[0].queryValue = ''
      this.$forceUpdate()
    },
    elTableRefs() {
      this.entitys[0].querySelect = undefined
      this.entitys[0].queryValue = undefined
      this.inputDisab = true
      this.seachList(this.entitys)
    },
    // 导出上架率模板
    importDetail() {
      // window,open(`/excel/导入上架率模板.xlsx`,'_blank')
       exportPutaway().then(res=>{
          const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', `上架率模板.xlsx`)
          document.body.appendChild(link)
          link.click()
       })
    },
    // 超出文件限制时的勾子
    exceedHandle (files, fileList) {
      this.uploaDing.close();
      this.$message({ type: 'error', message: `最多只能上传 ${this.limit} 个文件` })
    },
    errorHandle (data, file) {
      this.uploaDing.close();
      this.$message({ type: 'error', message: `文件 ${file.name} 导入失败` })
    },
    beforeUpload(file) {
      console.log('file111', file)
      let type = ['xls', 'xlsx'];
      this.openFullScreen2()
      let flag = type.some(val => {
        return file.name.toUpperCase().endsWith(`.${val.toUpperCase()}`)
      });
      if (!flag) {
        this.uploaDing.close();
        this.$message({ type: 'warning', message: `请上传以${type.join(',')}结尾文件` })
        return false
      }
    },
    handleSuccess (res) { // 上传成功回调
      console.log('上传成功回调res', res)
      this.uploaDing.close();
      if (res.code === false) return this.$message({ message: res.data, type: 'warning' })
      this.$message({ type: 'success', message: res.data })
      this.getPage()
    },
     openFullScreen2() {
        this.uploaDing = this.$loading({
          customClass:'uploaDing',
          lock: true,
          text: '上传中，请稍后...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.btnZoom{
  font-size: 14PX;
  padding: 4PX 6PX;
}

::v-deep .el-dialog__wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
}
::v-deep .el-table__body-wrapper {
  height: 510px !important;
}

::v-deep .el-dialog {
  // height: 75%;
  height: 750px;
  border: 2px solid #5473bf;
  background: linear-gradient(
    180deg,
    rgba(0, 15, 40, 0.5),
    rgba(0, 82, 218, 0.5) 100%
  );
}

//分页样式
::v-deep .el-pagination__total {
  color: rgba(255, 255, 255, 0.8);
}

.pagination-container {
  padding: 12px 0 0 0;

  ::v-deep .el-input__inner,
  ::v-deep .el-pagination__jump {
    color: rgba(255, 255, 255, 0.8);
    border: none;
    position: relative;
  }
}

// 下面是pagination
.pagination-container {
  background: transparent;
  padding: 32px 16px;
  display: flex;
  flex-direction: row-reverse;
}

.pagination-container.hidden {
  display: none;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent;
  color: #17fff4;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(62, 100, 172, 0.3);
  color: #17fff4;
  border: 1px solid #87d2ff;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .el-pagination.is-background .el-pager li:hover,
::v-deep .el-table__body tr.hover-row > td {
  background-color: rgba(62, 100, 172, 0.25);
}
.business-analysis {
  .left {
    position: relative;
    .left-top {
      display: flex;
      flex-direction: row;
      .left-tab-box {
        display: flex;
        .left-tab-item {
          display: flex;
          padding: 0 12px;
          border: 1px solid #87d2ff;
          cursor: pointer;
          backdrop-filter: blur(2px);
          line-height: 32px;
          color: #ffffff;
          opacity: 0.75;
        }
        .tab-active {
          font-weight: bold;
          position: relative;
          opacity: 1 !important;
          color: #17fff4 !important;
          background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        }
      }
      .left-top-middle {
        margin-left: 10px;
      }
      .left-top-right {
        position: absolute;
        top: 7px;
        right: 10px;
        .timeUpdate {
          line-height: 36px;
          color: #17f1ec;
          font-size: 12px;
        }
      }
      ::v-deep {
        .el-input {
          width: 250px;
        }
        .el-input--suffix .el-input__inner {
          border-radius: 0;
          height: 36px;
          border: 1px solid #87d2ff;
          color: #17fff5;
          background-color: transparent;
        }
        .el-input--prefix .el-input__inner {
          height: 36px;
          font-size: 14px;
          border: 1px solid #87d2ff;
          border-radius: 0;
        }
      }
    }
    .button {
      color: #17fff4;
      line-height: 16px;
      background: linear-gradient(
        0deg,
        #5f97ff 0%,
        rgba(95, 151, 255, 0)
      ) !important;
    }
  }
  .container {
    display: inline-block;
    margin-right: 10px;
  }
}
::v-deep .el-dialog{
  .table {
    // .el-table__header thead tr th{
    //   text-align: left;
    // }
    .el-table__cell {
      text-align: left;
      .table-cell-business {
        display: inline-block;
        width: 100%;
        .table-cell-business-number {
          // display: inline-block;
          // text-align: right;
        }
        .table-cell-business-grade {
          // position: absolute;
          // right: 5%;
        }
      }
     
    }
  }
}
  
 .aaa {
        color: #3cc947;
      }
      .bbb {
        color: #13c6c8;
      }
      .ccc {
        color: #e5c441;
      }
      .ddd {
        color: #c93965;
      }
</style>
