<template>
  <div
    class="home"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/image/homebg.jpeg') + ')',
    }"
    @click="closePopover"
  >
    <Header v-bind="$attrs" />
    <Main />
  </div>
</template>

<script>
import Header from "./components/header.vue";
// import Main from "./main.vue";
import Main from "../warehouse/hubei/main.vue";
import bus from "@/utils/bus";

export default {
  name: "Home",
  components: {
    Header,
    Main,
  },
  methods: {
    closePopover() {
      bus.$emit("closePopover");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
}
</style>
