<template>
  <Charts-border borderTitle="商品价格" :option="option">
    <div class="right-tab-box" slot="rightButton">
      <el-cascader
        ref="cascaderRef"
        popper-class="price_cascader_wrap_self"
        v-model="selectValue"
        :options="selectOptions"
        :props="{ expandTrigger: 'hover', checkStrictly: true }"
        clearable
        @change="handleChangeSelectValue">
          <template slot-scope="{ node, data }">
            <el-tooltip :disabled="data.label.length < 7" class="item" effect="dark" :content="data.label" placement="right">
              <span>{{ data.label }}</span>
            </el-tooltip>
          </template>
      </el-cascader>

      <div class="right-tab-box-b" slot="rightButton">
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 1 }"
          @click="type = 1"> 月 </div>
        <div
          class="right-tab-item"
          :class="{ 'right-tab-active': type === 2 }"
          @click="type = 2"> 日 </div>
      </div>
    </div>
  </Charts-border>
</template>
<script>
import ChartsBorder from "../viewsComponents/ChartsBorder";
import { treeFunc2, format, deepClone, formatDate } from "@/utils";
import watchMonth from "@/mixins/watchMonth";
import { Format } from "@/filters"
import { mapState } from "vuex";

export default {
  name: "Price",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
  },

  data() {
    return {
      datasetParmas: [],
      dateMonth: "",
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter: (params) => {
            const data = params[0].data
            /*return `${this.type === 1 ? '月份' : '日期'}：<span style="font-weight: bold;">${format(data[0])}</span> <br/>
            筹措平台价格：<span style="font-weight: bold;">${Format(data[1])}</span>元<br/>
            平台指导价格：<span style="font-weight: bold;">${Format(data[2])}</span>元`*/
            return `${this.type === 1 ? '月份' : '日期'}：<span style="font-weight: bold;">${format(data[0])}</span> <br/>
            筹措平台价格：<span style="font-weight: bold;">${Format(data[1])}</span>元<br/>`
          },
          confine: true
        },
        legend: {
          top: "22%",
          left: "24",
          textStyle: {
            color: "#fff",
          },
          itemWidth: 8,
          itemHeight: 8,
          icon: "rect",
          // data: ['筹措平台价格', '平台指导价格'],
          data: ['筹措平台价格'],
          selected: {
            // 选中'系列1'
            '筹措平台价格': true,
            // 不选中'系列2'
            // '平台指导价格': false
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
            rotate: 30 // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
          /* min: function (value) {//取最小值向下取整为最小刻度
            return Math.floor(value.min)
          }, */
          max: function (value) {//取最大值向上取整为最大刻度
            return Math.ceil(value.max)
          },
          scale: false, //自适应
          minInterval: 0.1, //分割刻度
        },
        grid: {
          top: "40%",
          left: "24",
          right: "24",
          bottom: "8%",
          containLabel: true,
        },
        series: [
          {
            name: "筹措平台价格",
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(23,241,236,1)"
                },
                {
                  offset: 0,
                  color: "rgba(23,241,236,0.1)"
                }
              ])
            }
          },
          {
            name: "平台指导价格",
            type: "line",
            color: "rgba(208,218,0)", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(208,218,0,1)"
                },
                {
                  offset: 0,
                  color: "rgba(208,218,0,0.1)"
                }
              ])
            }
          }
        ],
      },
      type: 1,
      selectOptions: [],
      selectValue: ["猪肉"],
      // selectValue: "绿叶菜",
    };
  },
  computed: {
    ...mapState('global', ['month', 'dateModel'])
  },
  mounted() {
    // this.getSelectOption(this.dateMonth)
  },
  watch: {
    month() {
      this.selectValue = ["猪肉"]
      this.getData(this.dateMonth)
    },
    type() {
      this.selectValue = ["猪肉"]
      this.getData(this.dateMonth);
    }
  },
  methods: {
    // 级联框选择改变
    handleChangeSelectValue(val){
      // const obj = this.$refs['cascaderRef'].getCheckedNodes() // 获取当前选中节点对象
      // const optionItem = obj[0].data
      // console.log(optionItem, 'optionItem',val)
      this.getData(this.dateMonth, val)

    },
    getData(month, value = ["猪肉"],) {
      if (value.length) {
        this.dateMonth = month;
        const [c1Name='',c2Name='',c3Name='',skuName=''] = value
        let othersParamsObj = this.type===1 ? {month} : {day: this.dateModel.replace(/-/g, '')}
        let paramsObj = {
          type: "categoryAveragePriceNew",
          c1Name,  // 一级品类名称
          c2Name,  //  二级品类名称
          c3Name,  //  三级品类名称
          skuName,  // skuName  
          ...othersParamsObj
        }
        this.$getData(paramsObj).then((res) => {
          document.querySelector('.el-popper.el-cascader__dropdown').style.display = 'none'
          let source = [];
          let legendFlag = { time: 0, value: false}
          if (this.type === 1) {
            source = this.getSource(res.data,legendFlag)
          } else {
            if (res.data.length > 7) {
              source = this.getSource(res.data.slice(res.data.length - 7),legendFlag)
            } else {
              source = this.getSource(res.data,legendFlag)
            }
          }
          this.option.legend.selected['平台指导价格'] = legendFlag.value
          this.option = {
            ...this.option,
            dataset: {
              source
            },
          };
        });
      }
      else {
        this.option = {
          ...this.option,
          dataset: {
            source: []
          },
        };
      }
      this.getSelectOptions(month)
    },
    getSource(data,legendFlag){
      return data.map((item) => {
        if(!legendFlag.time&&item.maxPrice !== 0){
          legendFlag.value = true
          legendFlag.time++
        }
        return [item.dateId, item.avgPrice, item.maxPrice];
      })
    },
    getSelectOptions(month){
      let paramsObj = this.type===1 ? {month} : {day: this.dateModel.replace(/-/g, '')}
      this.$getData({
        type: "categoryAveragePriceTreeNew",
        // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
        filterType: '1',
        ...paramsObj
      })
        .then((res) => {
          let source = res.data
          treeFunc2(source);
          this.selectOptions = source;
          this.$nextTick(() => {
            this.setElPopperClick()
          })
        })
    },
    // 获取参数
    getSuperGroupName(arr) {
      let arr2 = deepClone(arr)
      if (arr2.length <= 1) {
        return null;
      } else {
        return arr2[arr2.length - 2]
      }
    },
    setElPopperClick() {
      setInterval(function () {
        document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function () {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
        });
      }, 0);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-cascader-panel .el-radio {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 10px;
}
::v-deep .el-cascader-panel .el-radio__input {
  visibility: hidden;
}

::v-deep .el-cascader-panel .el-cascader-node__postfix {
  top: 10px;
}
.right-tab-box {
  display: flex;
  margin: 5px 10px;
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  
  .right-tab-item {
    border: 1px solid #87d2ff;
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
  }
  .right-tab-item:first-child {
    border-right: none;
  }
  .right-tab-box-b {
    display: flex;
    align-content: center;
    margin-left: 10px;
    position: relative;
    top: 1px;
  }
  ::v-deep .el-cascader {
    // min-width: 260px;
    min-width: 190px;
  }
  ::v-deep .el-cascader .el-input .el-input__inner {
    border-radius: 0;
    border: 1px solid #87d2ff;
    padding: 0 10px;
    height: 2.2rem;
    line-height: 2.2rem;
    color: #17fff5;
  }
}
</style>
<style lang="scss">
html,body{
  overflow: hidden;
}
.price_cascader_wrap_self{
  .el-cascader-node{
    max-width: 180px;
  }
}
</style>
