import axios from "axios";
import { monitorGetData } from "./collect.js";
import moment from "moment";
import { Message } from "element-ui";

const e6yun = window.global.e6yun;

const service = axios.create({
  baseURL: ["development"].includes(process.env.NODE_ENV)
    ? "/e6yun"
    : window.global.ngProxy,
});

service.interceptors.request.use(async (config) => {
  return config;
});

service.interceptors.response.use(
  (response) => {
    // console.log(response);
    return response.data;
  },
  (error) => {
    Message({
      message:
        error.message ||
        (error.response && error.response.data && error.response.data.messages),
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);
// 第一步向后台请求签名
const getSign = (data) => {
  return monitorGetData({
    type: "e6SignUrl",
    args: {
      ...data,
    },
    origin: "1"
  });
};
/**
 *
 * @param {vehicle} 需要查询的车牌
 * @returns 打开视频链接
 */
export const getOpenVideo = async (data) => {
  const res = await getSign({
    channelIds: "1,2,3",
    method: "getOpenVideo",
    videoQuality: 1, //视频质量信息（0高清,1标清），默认高清
    ...data,
  });
  return service({
    url: `/public/v4/BUSINESS/api/media/video/getOpenVideo?${res.data}`,
    method: "get",
  });
};

/**
 *
 * @param {vehicle} 需要查询的车牌
 * @returns 页面形式返回车辆实时视频
 */
// export const VideoMonit = (data) => {
//   return service({
//     url: `/public/v3/ViewApi/VideoMonit`,
//     method: "get",
//     params: {
//       channelIds: "1,2", // 视频通道支持多路如1,2,3或者2
//       effectivetime: 120, // 连接有效时长(分钟)
//       ...data,
//     },
//   });
// };
/**
 *
 * 以地图模式获取用户车辆最新位置信息
 */
// export const Orientation = (data) => {
//   return service({
//     url: `/public/v3/MapServices/Orientation.aspx`,
//     method: "get",
//     params: {
//       channelIds: "1,2", // 视频通道支持多路如1,2,3或者2
//       effectivetime: 120, // 连接有效时长(分钟)
//       ...data,
//     },
//   });
// };

// 获取车辆有多少通道（也就是有几个监控）channelList
export const getVehicleACCStatusAndOnLineStatus = async (data) => {
  const res = await getSign({
    method: "GetVehcileInfo",
    ...data,
  });
  return service({
    url: `/public/v3/Inface/Call?${res.data}`,
    method: "get",
  });
};
/**
 *
 * @param {vehicle} 需要查询的车牌
 * @returns 查询车辆的温度信息
 */
export const StatisticsReport = async (data) => {
  const res = await getSign({
    method: "GetVehicleTemperatureInfo",
    format: "json",
    second: 600,
    btime: moment()
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss"), // 开始时间默认当天凌晨
    etime: moment().format("YYYY-MM-DD HH:mm:ss"), // 结束时间是现在
    ...data,
  });
  return service({
    url: `/public/v3/StatisticsReport/Call?${res.data}`,
    method: "get",
  });
};
// 生成mapIframeSrc
export const createMapIframeSrc = async (data) => {
  const res = await getSign({
    ...data,
    zoom: 8,
    effectivetime: 5256000,
    isdark: 1,
    isshowtitle: 0,
    showcon: 0,
    initcenter: 1,
    center: "84.58745|32.800947",
  });
  // return `${e6yun}/public/v3/MapServices/Orientation.aspx?${res.data}`;
  return `${e6yun}/public/v3/MapServices/monit.aspx?${res.data}`;
};
// 生成mapIframeSrc
export const createMonitorIframeSrc = async (data) => {
  const res = await getSign({
    ...data,
    expiry:'600'
  });
  // return `${e6yun}/public/v3/ViewApi/VideoMonit?${res.data}`;
  return `https://webgispro.e6yun.com/share-h5/v3/#/ViewApi/VideoMonit?${res.data}`;
  
};
// 获取视频提取列表
export const getVideoList = async (data) => {
  const res = await getSign({
    method: "GetVehicleBasicsInfo",
    format: "json",
    ...data,
  });
  return service({
    url: `/public/v3/Inface/Call?${res.data}`,
    method: "get",
  });
};
