var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogVisible,"width":"1284px","top":_vm.marginTop},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.closeDialog}},[_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"dialog-title-bg"}),_c('div',{staticClass:"dialog-border-TL"}),_c('div',{staticClass:"dialog-border-B"}),_c('div',{staticClass:"top"},[_vm._t("top-tab"),_c('Table',{ref:"tableComponent",attrs:{"columns":_vm.columns,"tableData":_vm.tableData,"total":_vm.pagelist.count,"page":_vm.pagelist.page,"rows":_vm.pagelist.rows,"loading":_vm.loading,"stripe":_vm.stripe,"searchShow":false,"indexShow":false,"show-overflow-tooltip":false,"defaultSort":_vm.defaultSort,"sortDing":_vm.sortDing,"tableHeight":200,"refreshTable":_vm.refreshTable},on:{"update:page":function($event){return _vm.$set(_vm.pagelist, "page", $event)},"update:rows":function($event){return _vm.$set(_vm.pagelist, "rows", $event)},"pagination":_vm.getDetailsPage,"searchList":_vm.getDetailsPage,"sortChange":_vm.sortChange},scopedSlots:_vm._u([{key:"columnEspecial",fn:function(ref){
var column = ref.column;
var row = ref.row;
return [(column.prop === 'deliveryDate')?_c('span',[_vm._v(_vm._s(_vm._f("formatUTCTime")(row[column.prop])))]):(column.prop === 'orderDate')?_c('span',[_vm._v(_vm._s(row[column.prop]))]):(
                column.prop === 'orderAmt' ||
                  column.prop === 'signAmt' ||
                  column.prop === 'returnAmt' ||
                  column.prop === 'ddAmt' ||
                  column.prop === 'amt' ||
                  column.prop === 'thJsAmt'
              )?_c('span',[_vm._v(_vm._s(_vm._f("Format")(row[column.prop])))]):(column.prop === 'orderNum')?_c('span',[_vm._v(_vm._s(_vm._f("Format")(row[column.prop],0)))]):(column.prop === 'ratio')?_c('span',[_vm._v(_vm._s(_vm._f("Format")(row[column.prop]))+"%")]):(
                column.prop === 'orderAmtRatio')?_c('span',{staticClass:"Ratio"},[_vm._v(" "+_vm._s(_vm._f("Format")(row.orderAmtDiff))+" "+_vm._s("万元")+" ("+_vm._s(_vm._f("Format")(((row.orderAmtRatio) *100)))+" %) "),_c('img',{staticClass:"orderImg",attrs:{"src":row.orderAmtDiff > 0
                    ? _vm.imgSrc1
                    : _vm.imgSrc2,"alt":""}})]):(
                column.prop === 'orderNmuRatio'
              )?_c('span',{staticClass:"Ratio"},[_vm._v(" "+_vm._s(_vm._f("Format")(row.orderNumDiff))+" "+_vm._s("单")+" ("+_vm._s(_vm._f("Format")((row.orderNumRatio* 100)))+" %) "),_c('img',{staticClass:"orderImg",attrs:{"src":(row.orderNumDiff) > 0
                    ? _vm.imgSrc1
                    : _vm.imgSrc2,"alt":""}})]):_c('span',[_vm._v(_vm._s(row[column.prop]))])]}}])})],2),_c('div',{staticClass:"bottom"},[_vm._t("bottom-tab"),_c('div',{staticClass:"DEcharts"})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }