<template>
  <div class="main">
    <div class="main-box">
      <div class="main-left">
        <OrderReview class="charts-border-item" />
        <Price class="charts-border-item" />
        <!-- <OrderAll class="charts-border-item" /> -->
        <order-scroll class="charts-border-item"/>
<!--        <Category class="charts-border-item" />-->
      </div>
      <div class="main-center">
        <Search class="charts-border-search" />
         <Category class="charts-border-price" />
<!--        <Price class="charts-border-price" />-->
      </div>
      <div class="main-right">
        <Quality class="charts-border-quality" />
        <Food class="charts-border-food" />
        <Supplier class="charts-border-item" />
      </div>
    </div>
    <Map class="charts-border-map" />
    <div class="lg-foot ft-12 ft-oppo">
      <span>湖北农业发展集团有限公司</span>
      <span><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="color: #00D2C9">鄂公安网备:42011102004083号</a></span>
    </div>
  </div>
</template>

<script>
// import Map from "./map.vue";
import Map from "./EchartsMap.vue";
import Quality from "./components/quality.vue";
import OrderReview from "./components/orderReview.vue";
import Category from "./components/category.vue";
import Search from "./components/search.vue";
import Price from "./components/price.vue";
import OrderAll from "./components/orderAll.vue";
import Food from "./components/food.vue";
import Supplier from "./components/supplier.vue";
import OrderScroll from "./components/orderScroll.vue";
export default {
  name: "Main",
  components: {
    Map,
    OrderReview, // 订单总览
    Category, // 品类供应占比
    // OrderAll, // 订单全过程
    OrderScroll,// 今日下单播报
    Search,
    Price, // 品类周平均价格
    Quality, // 质量监控
    Food, // 伙食单位订单量
    Supplier, // 供应商占比
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.main {
  padding: 10px;
  // position: relative;
  .main-box {
    display: flex;
    .main-left,
    .main-right {
      flex: 1;
      .charts-border-quality {
        height: calc((100vh - #{$headerHeight} + 40px) / 6);
      }
      .charts-border-food {
        height: calc((100vh - #{$headerHeight} - 40px) / 2);
      }
    }
    .main-center {
      flex: 2;
      .charts-border-search {
        height: calc((100vh - #{$headerHeight} - 20px) / 3 * 2);
      }
      .charts-border-price {
        // 80
        height: calc((100vh - #{$headerHeight} - 20px) / 3);
      }
    }
    .charts-border-item {
      width: 100%;
      height: calc((100vh - #{$headerHeight} - 20px) / 3);
    }
  }
  .charts-border-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.lg-foot {
  width: 100%;
  position: fixed;
  bottom: 0.2vw;
  display: flex;
  justify-content: center;

  span {
    margin-right: 5px;
  }
}
</style>
