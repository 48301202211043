import { render, staticRenderFns } from "./fooDetails.vue?vue&type=template&id=5413eb24&scoped=true&"
import script from "./fooDetails.vue?vue&type=script&lang=js&"
export * from "./fooDetails.vue?vue&type=script&lang=js&"
import style0 from "./fooDetails.vue?vue&type=style&index=0&id=5413eb24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5413eb24",
  null
  
)

export default component.exports