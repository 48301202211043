<template>
  <div class="nav-box">
    <div
      class="nav-auto"
      v-loading="loading"
      element-loading-background="rgba(0,0,0,0.2)">
      <div
        :class="[
          'nav-item',
          { activate: activate == index },
          { mouseTag: mouseTag },
        ]"
        v-for="(item, index) in vehicleList"
        :key="index"
        @click="vehicleClick(item, index)">
        <div
          class="title"
          :title="
            `${item.plateNo} - ${item.carTypeName} - ${item.capacity} - ${item.vehicleLong}`
          ">
          {{ item.plateNo }} - {{ item.carTypeName }} -
          {{ item.capacity | capacity }} - {{ item.vehicleLong | vehicleLong }}
        </div>
        <div class="text">
          {{ item.driverName }}/{{ item.phone }} |
          {{ item.orderQuantity }}件订单 | {{ item.unitQuantity }}个灶点
        </div>
        <div class="time">
          {{ item.departureTime }}
          <img
            v-if="activate == index"
            :src="arrowsTwo"
            style="margin:0 8px;width:40px;height:10px;" />
          <img v-else :src="arrowsOne" style="margin:0 8px;width:40px;height:10px;" />
          预计{{ item.estimateArriveTime}}
        </div>
        <img
            class="accImg"
            v-if="item.accSwitch === 1"
            :src="openImg"
           />
           <img
           class="accImg"
            v-else
            :src="shutImg"
           />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    vehicleList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activate: 0,
      timer: null,
      mouseTag: false,
      arrowsOne: require("@/assets/image/arrowsOne.png"),
      arrowsTwo: require("@/assets/image/arrowsTwo.png"),
      openImg: require("@/assets/image/open.png"),
      shutImg: require("@/assets/image/shut.png"),
    };
  },
  watch: {
    vehicleList: {
      handler(val) {
        if (val.length > 0) {
          this.$emit("vehicleDatas", val[0]);
          // this.$emit("vehicleDatas", {
          //   plateNo: "藏A6U668",
          // });
        } else {
          this.$emit("vehicleDatas", {
            plateNo: '',
            driverName: '',
            phone: '',
            departureTime: ''
          });
        }
        this.activate = 0;
      },
      deep: true,
      immediate: false,
    },
  },
  filters: {
    capacity(val) {
      let reg = /^-?\d+$/;
      let regs = /^(-?\d+)(\.\d+)?$/;
      if (reg.test(val) || regs.test(val)) {
        return val + "T";
      } else {
        return val;
      }
    },
    vehicleLong(val) {
      let reg = /^-?\d+$/;
      let regs = /^(-?\d+)(\.\d+)?$/;
      if (reg.test(val) || regs.test(val)) {
        return val + "m";
      } else {
        return val;
      }
    },
    estimateArriveTime(val) {
      // let time = val.replaceAll('/','-')
      return val
    }
  },
  methods: {
    vehicleClick(v, f) {
      if (!this.mouseTag) {
        this.activate = f;
        this.mouseTag = true;
        this.$emit("vehicleDatas", v);
        this.timer = setTimeout(() => {
          this.mouseTag = false;
          // this.$emit("vehicleDatas", v);
          clearTimeout(this.timer);
        }, 3000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-box {
  height: 100%;
  overflow-y: auto;
  .nav-auto {
    height: 100%;
    .nav-item {
      cursor: pointer;
      height: 96px;
      border: 1px solid #5473bf;
      padding: 16px 0 0 16px;
      background: rgba(0, 0, 0, 0.2);
      position: relative;
      

      &.mouseTag {
        cursor: wait;
      }
      .title {
        height: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #ffffff;
        opacity: 0.75;
        line-height: 24px;
        // width:300px; /*限制元素宽度*/
        overflow: hidden; /*文本超出隐藏*/
        text-overflow: ellipsis; /*文本超出显示省略号*/
        white-space: nowrap; /*超出的空白区域不换行*/
      }
      .text {
        width: 200%;
        margin-left: -50%;
        font-size: 24px;
        transform: scale(0.5);
        font-weight: 400;
        color: #ffffff75;
        opacity: 0.75;
        line-height: 20px;
        // width:300px; /*限制元素宽度*/
        // overflow: hidden; /*文本超出隐藏*/
        // text-overflow: ellipsis; /*文本超出显示省略号*/
        // white-space: nowrap; /*超出的空白区域不换行*/
      }
      .time {
        width: 200%;
        margin-left: -50%;
        font-size: 24px;
        transform: scale(0.5);
        height: 30px;
        font-weight: 400;
        color: #ffffff;
        opacity: 0.75;
        line-height: 30px;
        display: flex;
        align-items: center;
        z-index: 99;
        // justify-content: space-between;
      }
      &.activate {
        border: 2px solid #17fff4;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        .title {
          color: #17fff4;
          opacity: 1;
        }
        .text {
          opacity: 1;
        }
        .time {
          opacity: 1;
        }
      }
      .accImg{
        width:71px;
        height:20px;
        position: absolute;
        bottom:0; 
        right:0;
        transform: scale(1);
      }
    }
  }
}
* {
  /* 滑块颜色  滚动条背景颜色 */
  scrollbar-color: rgba(62, 100, 172, 0.3) #011434;
  /* 滚动条宽度有三种：thin、auto、none */
  scrollbar-width: thin;
}
// 设置滚动条的宽度
.nav-box::-webkit-scrollbar {
  width: 10px;
}
// 设置滚动条的背景色和圆角
.nav-box::-webkit-scrollbar-thumb {
  background-color: rgba(62, 100, 172, 0.3);
}
</style>
