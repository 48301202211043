<template>
  <div class="search">
    <!-- <div class="search-box">
      <el-select v-model="querySelect" :popper-append-to-body="false" clearable placeholder="状态筛选">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
      <el-input
        v-model.trim="queryValue"
        placeholder="请输入"
        @keyup.enter.native="change"
        :disabled="!querySelect"
      />
      <i v-show="querySelect" class="el-icon-search queryValue" @click.stop="change"></i>
    </div> -->
    <div class="toDay_box">
      <div class="toDay_head">
        <div class="left">
          <span
            @click="toDay(1)"
            style="opacity: 0.75"
            :class="{ toActiv: toDays === 1 }"
            >今日金额</span
          >
          <img src="@/assets/image/exchange.png" alt="" />
          <span
            @click="toDay(2)"
            style="opacity: 0.75"
            :class="{ toActiv: toDays === 2 }"
            >今日订单</span
          >
        </div>
        <div class="right">
          <span class="text" v-if="toDays === 1">{{
            toDaySummary | Format
          }}</span>
          <span class="text" v-if="toDays === 2">{{ toDayOrder }}</span>
          <span>{{ toDays === 1 ? "元" : "单" }}</span>
        </div>
      </div>
      <div
        v-for="(item, index) in orderAmountList"
        :key="index"
        :class="['toDayList', { 'border-no': index === orderAmountList.length-1 }]"
      >
        <div class="left">
          <span>{{ item.ReportOSTType | ReportOSTType }}</span
          >：<span v-if="toDays === 1" class="text">{{
            item.OrderAmount | Format
          }}</span
          ><span v-if="toDays === 2" class="text">{{ item.OrderCount }}</span>
          <span v-if="toDays === 1">元</span>
        </div>
        <div class="right">
          较昨日:
          <span
            :class="{
              right_mg:
                (item.order === '0' && toDays === 1) ||
                (item.orderQty === '0' && toDays === 2),
            }"
            >{{ toDays === 1 ? item.Rate : item.QtyRate }}%
          </span>
          <img v-if="item.order === '1' && toDays === 1" :src="imgSrc1" />
          <img v-if="item.order === '2' && toDays === 1" :src="imgSrc2" />
          <img v-if="item.orderQty === '1' && toDays === 2" :src="imgSrc1" />
          <img v-if="item.orderQty === '2' && toDays === 2" :src="imgSrc2" />
        </div>
      </div>
    </div>
    <!-- <div class="temperature">
      <div class="temperatureHead">
        <div class="tempe-left">
          <span class="text colr">
            {{ systemTime }}
          </span>
        </div>
        <div class="tempe-right">
          <span class="text">
            发车数量: <span class="unt">{{ totalVehicle }}</span> 辆
          </span>
          <img class="img" :src="imageSrc" />
        </div>
      </div>
      <div class="temperatureMain">
        <div>
          <div class="temperature_div">
            <p class="temperature_p">拉萨仓常温库：</p>
            <p>
              <span class="span_color">{{ lasachangwen }}</span
              >℃
            </p>
          </div>
          <div class="temperature_div">
            <p class="temperature_p">拉萨仓冷藏库：</p>
            <p>
              <span class="span_color">{{ lasalingcang }}</span
              >℃
            </p>
          </div>
        </div>
        <div>
          <div class="temperature_div">
            <p class="temperature_p">成都仓冷冻库：</p>
            <p>
              <span class="span_color">{{ chengdulingdong }}</span
              >℃
            </p>
          </div>
          <div class="temperature_div">
            <p class="temperature_p">成都仓冷藏库：</p>
            <p>
              <span class="span_color">{{ chengdulingcang }}</span
              >℃
            </p>
          </div>
        </div>
      </div>
      <div class="weather">
        <div class="weatherHead" @click="weatherDetails()">
          <div class="tempe-left">
            <span class="text">
              {{ weatherInfos.county }}：<span
                :class="`weatherImgs d${weatherInfos.textCode}`"
              ></span
              >{{ weatherInfos.text }}
            </span>
          </div>
          <div class="tempe-right">
            <span class="colr"> {{ weatherInfos.lowTemperature }}</span
            >℃ ~ <span class="colr">{{ weatherInfos.highTemperature }}</span
            >℃
            <img class="img" :src="imageSrc" />
          </div>
        </div>
      </div>
      <div class="weatherLegend">
        <div class="title" @click="legendDetails()">
          <img
            src="@/assets/image/weather/legend.svg"
            alt=""
            style="margin-right: 4px"
          />
          天气图例
        </div>
      </div>
    </div> -->
    <!-- <orderScore></orderScore> -->
    <Vehicle
      v-if="showVehicle"
      :show.sync="showVehicle"
      :title="`${systemDate}车辆详情`"
    >
      <div class="vehicleHead">
        <div class="head-left">
          <el-radio-group v-model="state" class="point-type">
            <el-radio-button adio-button label="operation"
              >发运中（{{ operationLength }}）</el-radio-button
            >
            <el-radio-button label="unreceipted"
              >未签收（{{ ounreceiptedLength }}）</el-radio-button
            >
          </el-radio-group>
          <el-select
            class="selectAlarm"
            v-model="endAddress"
            placeholder="目的城市"
            :popper-append-to-body="false"
            clearable
          >
            <el-option label="拉萨市" value="拉萨市" />
            <el-option label="林芝市" value="林芝市" />
            <el-option label="日喀则市" value="日喀则市" />
            <el-option label="那曲市" value="那曲市" />
            <el-option label="山南市" value="山南市" />
            <el-option label="昌都市" value="昌都市" />
            <el-option label="阿里地区" value="阿里地区" />
          </el-select>
          <el-input
            placeholder="请输入灶点编号"
            style="margin: 0 12px 0 12px; width: 340px"
            v-model.trim="pointVlue"
            @keyup.native="proving(1)"
            clearable
          />
          <el-button class="BlueCustomButton btn" @click.native="proving(2)"
            >查询</el-button
          >
          <el-button class="BlueCustomButton btn" @click.native="elTableRefs"
            >重置</el-button
          >
        </div>
        <div class="head-right">
          <el-radio-group v-model="type" class="point-type">
            <el-radio-button adio-button label="somasterInfo"
              >灶点详情</el-radio-button
            >
            <el-radio-button label="externalRoute">路由详情</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <Content
        ref="content"
        :state="state"
        :vehicleList="vehicleList"
        :loading="loading"
      />
    </Vehicle>
    <Legend
      v-if="showLegend"
      :show.sync="showLegend"
      :title="`天气图例`"
      @clearValue="handleCloses"
      :marginTop="`calc((100vh - 730Px) / 2)`"
      :dialogWidth="dialogWidth"
      :append-to-body="true"
    >
      <div class="legendBox">
        <div
          v-for="(item, index) in legendList"
          :key="index"
          class="legendList"
        >
          <div
            v-for="(item2, index2) in item.list"
            :key="index2"
            class="legend-item"
          >
            <div class="item-main">
              <span class="weatherImg" :class="item2.img"></span>
              <span class="weatherName">
                {{ item2.name }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Legend>
    <!-- <WeatherDetails
      ref="WeatherDetails"
      v-if="showWeatherDetails"
      :dialogTitle="'天气详情'"
      :getDetailsPage="getAlarmInfoList"
      @clos:closeDetails="closeWeatherDetails"
      @refresh="refreshWeather"
    ></WeatherDetails> -->
  </div>
</template>

<script>
import bus from "@/utils/bus";
import Vehicle from "@/components/sreenDialog/index.vue";
import Content from "../details/vehicleDetails/content.vue";
import { formatDateHym } from "@/utils";
import Legend from "@/components/Dialog/index.vue";
// import WeatherDetails from "../viewsComponents/Details/weatherDetails.vue"; // 禁用接口
// import orderScore from "./orderScore.vue";

export default {
  name: "Search",
  components: {
    Vehicle,
    Content,
    Legend,
    // WeatherDetails,
    // orderScore,
  },
  computed: {
    month() {
      return this.$store.getters.global.month;
    },
  },
  data() {
    return {
      toDaySummary: 0,
      toDayOrder: 0,
      orderAmountList: [
        {
          ReportOSTType: 0,
          OrderAmount: 0,
          OrderCount: 0,
          order: "0",
          orderQty: "0",
          Rate: "0",
          QtyRate: "0",
        },
        {
          ReportOSTType: 1,
          OrderAmount: 0,
          OrderCount: 0,
          order: "0",
          orderQty: "0",
          Rate: "0",
          QtyRate: "0",
        },
        // {
        //   ReportOSTType: 2,
        //   OrderAmount: 0,
        //   OrderCount: 0,
        //   order: "0",
        //   orderQty: "0",
        //   Rate: "0",
        //   QtyRate: "0",
        // },
        {
          ReportOSTType: 3,
          OrderAmount: 0,
          OrderCount: 0,
          order: "0",
          orderQty: "0",
          Rate: "0",
          QtyRate: "0",
        },
      ],
      toDays: 1,
      dialogWidth: "1280PX",
      endAddress: "",
      weatherInfos: {},
      showWeatherDetails: false,
      legendList: [
        {
          list: [
            {
              name: "晴",
              img: "d00",
            },
            {
              name: "多云",
              img: "d01",
            },
            {
              name: "阴",
              img: "d02",
            },
            {
              name: "阵雨",
              img: "d03",
            },
            {
              name: "雷阵雨",
              img: "d04",
            },
            {
              name: "雷阵雨伴有冰雹",
              img: "d05",
            },
            {
              name: "雨夹雪",
              img: "d06",
            },
            {
              name: "小雨",
              img: "d07",
            },
            {
              name: "中雨",
              img: "d08",
            },
          ],
        },
        {
          list: [
            {
              name: "大雨",
              img: "d09",
            },
            {
              name: "暴雨",
              img: "d10",
            },
            {
              name: "大暴雨",
              img: "d11",
            },
            {
              name: "特大暴雨",
              img: "d12",
            },
            {
              name: "阵雪",
              img: "d13",
            },
            {
              name: "小雪",
              img: "d14",
            },
            {
              name: "中雪",
              img: "d15",
            },
            {
              name: "大雪",
              img: "d16",
            },
          ],
        },
        {
          list: [
            {
              name: "暴雪",
              img: "d17",
            },
            {
              name: "雾",
              img: "d18",
            },
            {
              name: "冻雨",
              img: "d19",
            },
            {
              name: "沙尘暴",
              img: "d20",
            },
            {
              name: "小雨到中雨",
              img: "d21",
            },
            {
              name: "中雨到大雨",
              img: "d22",
            },
            {
              name: "大雨到暴雨",
              img: "d23",
            },
            {
              name: "暴雨到大暴雨",
              img: "d24",
            },
          ],
        },
        {
          list: [
            {
              name: "大暴雨到特大暴雨",
              img: "d25",
            },
            {
              name: "小雪到中雪",
              img: "d26",
            },
            {
              name: "中雪到大雪",
              img: "d27",
            },
            {
              name: "大雪到暴雪",
              img: "d28",
            },
            {
              name: "沙尘",
              img: "d29",
            },
            {
              name: "扬沙",
              img: "d30",
            },
            {
              name: "强沙尘暴",
              img: "d31",
            },
            {
              name: "霾",
              img: "d53",
            },
          ],
        },
      ],
      showLegend: false,
      operationLength: 0,
      ounreceiptedLength: 0,
      timerDate: null,
      systemTime: null,
      systemDate: null,
      totalVehicle: 0,
      vehicleList: [],
      loading: false,
      pointVlue: null,
      type: "somasterInfo",
      state: "operation",
      showVehicle: false,
      imageSrc: require("@/assets/image/leftArrows.png"),
      timer: null,
      timerMin: null,
      options: [
        {
          name: "灶点编号",
          value: "focalPointNo",
        },
        {
          name: "灶点名称",
          value: "focalPointName",
        },
        {
          name: "订单号",
          value: "orderNo",
        },
        {
          name: "运单号",
          value: "waybillNo",
        },
      ],
      querySelect: "",
      queryValue: "",
      chengdulingdong: 0,
      chengdulingcang: 0,
      lasachangwen: 0,
      lasalingcang: 0,
      imgSrc1: require("@/assets/image/arrowup.svg"),
      imgSrc2: require("@/assets/image/down.svg"),
    };
  },
  filters: {
    ReportOSTType(val) {
      if (val === 0) {
        return "正常订单";
      } else if (val === 1) {
        return "补货订单";
      } else if (val === 2) {
        return "紧急订单";
      } else if (val === 3) {
        return "临时订单";
      }
    },
  },
  watch: {
    month: {
      handler() {
        this.querySelect = this.options[0].value;
        this.queryValue = "";
      },
      deep: true,
      immediate: false,
    },
    showVehicle: {
      handler(value) {
        if (!value) {
          this.endAddress = "";
          this.pointVlue = null;
          this.type = "somasterInfo";
          this.state = "operation";
          this.vehicleList = [];
        }
      },
      deep: true,
      immediate: false,
    },
    type: {
      handler(value) {
        this.$refs.content.type = value;
      },
      deep: true,
      immediate: false,
    },
    state: {
      handler() {
        this.proving(2);
      },
      deep: true,
      immediate: false,
    },
  },
  created() {
    this.querySelect = this.options[0].value;
    // this.tempreature();
    // this.getVehicleDate();
    this.getToDay();
    this.timer = setInterval(() => {
      // this.tempreature();
      // this.getVehicleDate();
      this.dateCompare();
      let min = this.minDate();
      clearTimeout(this.timerMin);
      if (min === 0) {
        this.getToDay();
      } else {
        this.timerMin = setTimeout(() => {
          this.getToDay();
        }, 1000 * 60 * min);
      }
    }, 1000 * 60);
  },
  mounted() {
    // 根据ui视觉工程师要求 希望这里设置成固定宽度
    // let e = document.querySelector('.search-box .el-input__inner[autocomplete="off"][placeholder="请输入"]')
    // e.style.width = '172px'
    // this.weatherInfo(); // 禁用接口
  },
  methods: {
    minDate() {
      let a = new Date().getMinutes() + "";
      let b = a.slice(1);
      let c = Number(b);
      if (c === 3 || c === 8) {
        return 0;
      } else if (c < 3) {
        return 3 - c;
      } else if (c > 3 && c < 8) {
        return 8 - c;
      } else if (c > 8) {
        return 4;
      }
    },
    getToDay() {
      const warehouseCode = this.$store.getters.global.warehouseCode;
      let param = {
          type: "crfRequestForward",
          method: "GET",
      }
      if (typeof warehouseCode == 'string' && warehouseCode.length > 0) {
          param.url= `/ICRFS/GetOrderTodaySummary?warehouseCode=${warehouseCode}`
      }else {
          param.url= `/ICRFS/GetOrderTodaySummary?`
      }
      this.$getData(param).then((res) => {
        // let data = res.data ? JSON.parse(res.data) : [];
        let data = res.data ? JSON.parse(res.data) : [{"ReportOSTType":0,"OrderCount":938,"OrderAmount":2185188.500000,"Rate":"0.08","QtyRate":"8.4"},{"ReportOSTType":3,"OrderCount":1,"OrderAmount":435.000000,"Rate":"-93","QtyRate":"-95.45"},{"ReportOSTType":100,"OrderCount":939,"OrderAmount":2185623.500000,"Rate":"-46.46","QtyRate":"-43.53"}];
        let arr = data.filter((v) => v.ReportOSTType === 100);
        if (arr.length) {
          this.toDaySummary = arr[0].OrderAmount;
          this.toDayOrder = arr[0].OrderCount;
        } else {
          this.toDaySummary = 0;
          this.toDayOrder = 0;
        }
        this.orderAmountList.forEach((v) => {
          data.forEach((v2) => {
            if (v.ReportOSTType === v2.ReportOSTType) {
              let sum = Number(v2.Rate);
              if (sum > 0) {
                v.order = "1";
              } else if (sum < 0) {
                v.order = "2";
              } else {
                v.order = "0";
              }
              let sumQty = Number(v2.QtyRate);
              if (sumQty > 0) {
                v.orderQty = "1";
              } else if (sumQty < 0) {
                v.orderQty = "2";
              } else {
                v.orderQty = "0";
              }
              v.OrderAmount = v2.OrderAmount;
              v.OrderCount = v2.OrderCount;
              v.Rate = v2.Rate;
              v.QtyRate = v2.QtyRate;
            }
          });
        });
      });
    },
    toDay(v) {
      this.toDays = v;
    },
    refreshWeather() {
      this.weatherInfo();
    },
    weatherInfo() {
      this.$getData({
        type: "weatherInfoOfLaSa",
      }).then((res) => {
        this.weatherInfos = res.data;
      });
    },
    closeWeatherDetails() {
      this.showWeatherDetails = false;
    },
    weatherDetails() {
      this.showWeatherDetails = true;
    },
    getAlarmInfoList() {},

    handleCloses() {
      this.showLegend = false;
    },
    legendDetails() {
      this.showLegend = true;
    },
    dateCompare() {
      let dateTime;
      let dateTime2;
      let dateTime3;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      dateTime2 =
        yy + "-" + mm + "-" + dd + " " + "00" + ":" + "00" + ":" + "00";
      dateTime3 =
        yy + "-" + mm + "-" + dd + " " + "00" + ":" + "06" + ":" + "00";
      let oDate1 = new Date(dateTime);
      let oDate2 = new Date(dateTime2);
      let oDate3 = new Date(dateTime3);
      // let oDate2 = new Date('2021-10-28 16:45:00');
      // let oDate3 = new Date('2021-10-28 16:51:00');
      if (
        oDate1.getTime() > oDate2.getTime() &&
        oDate1.getTime() < oDate3.getTime()
      ) {
        location.reload();
      }
    },
    getVehicleDate() {
      this.$getData({
        type: "vehicleInfoOfCurrentDay",
        dataType: "vehicleInfoAndDateTime",
      }).then((res) => {
        clearInterval(this.timerDate);
        this.timerDate = null;
        // this.totalVehicle = res.data.totalVehicle;
        let time = res.data.systemTime;
        this.systemTime = formatDateHym(time);
        this.systemDate = this.systemTime.substr(0, 11);
        this.timerDate = setInterval(() => {
          time += 1000;
          this.systemTime = formatDateHym(time);
        }, 1000);
      });
    },
    getVehicleList() {
      this.vehicleList = [];
      this.loading = true;
      //发运中
      this.$getData({
        type: "vehicleInfoOfCurrentDay",
        focalPointNo: this.pointVlue || undefined,
        endAddress: this.endAddress ? this.endAddress : "",
      }).then((res) => {
        this.operationLength = res.data.length;
        if (this.state == "operation") {
          this.vehicleList = res.data;
          // this.vehicleList = res.data.filter(v=> v.plateNo == '浙A449SF'||v.plateNo == '藏A5D635'||v.plateNo == '藏A4129V'||v.plateNo == '藏A3T179'||v.plateNo == '藏A1L569'||v.plateNo == '藏A9A273'||v.plateNo == '藏A9A367')
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      });
      //未签收
      this.$getData({
        type: "runningVehicleInfoMonitor",
        focalPointNo: this.pointVlue || undefined,
        endAddress: this.endAddress ? this.endAddress : "",
      }).then((res) => {
        this.ounreceiptedLength = res.data.length;
        if (this.state == "unreceipted") {
          this.vehicleList = res.data;
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      });
    },
    elTableRefs() {
      this.endAddress = "";
      this.pointVlue = null;
      this.getVehicleList();
    },
    proving(v) {
      if (this.pointVlue) {
        let regu =
          /[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/;
        if (regu.test(this.pointVlue) && v == 2) {
          this.$message({
            message: "请输入正确的灶点编号",
            type: "warning",
          });
          return;
        } else {
          this.pointVlue = this.pointVlue
            .replace(
              /[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/g,
              ""
            )
            .replace(/\s/g, "");
        }
      }
      if (v == 2) {
        this.getVehicleList();
      }
    },
    vehicleDetails() {
      this.getVehicleList();
      this.showVehicle = true;
    },
    // 设计随机温度
    tempreature() {
      let chengdulingdong = [
        -15.8, -15.7, -16.0, -16.1, -15.9, -16.3, -16.4, -16.5, -16.6, -16.7,
        -16.9, -17.0, -17.1, -17.2, -17.3, -17.4, -17.5, -16.2, -15.6, -16.8,
        -17.7, -17.8, -17.9, -18.0, -17.6, -18.2, -18.1, -18.3, -18.4, -18.5,
        -18.6, -18.7, -15.5, -15.4, -18.8,
      ][Math.floor(Math.random() * (33 - 1 + 1) + 1)];
      let chengdulingcang = [
        4.3, 4.4, 4.5, 4.59, 4.7, 4.8, 4.9, 5.0, 5.09, 5.2, 5.3, 4.09, 3.3, 3.8,
        4.2, 4.0, 3.9, 3.7, 3.6, 5.4, 5.5,
      ][Math.floor(Math.random() * (20 - 1 + 1) + 1)];
      let lasachangwen = [
        19.8, 19.6, 19.39, 19.3, 19.1, 19.0, 18.89, 18.8, 18.6, 18.7, 18.5,
        18.39, 18.3, 18.2, 18.1, 18.0, 17.8, 17.3, 17.39, 17.6, 17.5, 17.7,
        17.89, 17.2, 17.0, 16.89, 16.8, 16.7, 17.1, 19.2, 19.5, 16.6, 16.5,
        16.2, 16.39, 16.1, 16.3, 19.7, 19.89, 20.0, 20.1, 20.2, 20.3, 20.39,
        20.5, 20.6, 20.7, 20.8, 20.9, 21.0, 21.1, 21.2, 21.3,
      ][Math.floor(Math.random() * (48 - 1 + 1) + 1)];
      let lasalingcang = [
        1.3, 1.6, 1.7, 1.8, 1.9, 2.0, 1.4, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6,
        0.5, 0.4, 0.3, 0.2, 1.5, 0.1, 2.1, -0.1, -0.4, -0.6, -0.7, -0.8, -0.9,
        -1.0, -1.1, -1.2, -1.3, 3.3, 4.0, 5.3, 5.6, 6.1, 7.9, 9.39, 12.4, 8.9,
        7.0, 5.7, 4.8, 3.4, 2.9, 2.5, 2.2, 2.6, 0.0, -0.2, -0.3, -0.5, 5.9, 5.2,
      ][Math.floor(Math.random() * (53 - 1 + 1) + 1)];
      this.$getData({
        type: "warehouseTemperature",
      }).then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((v) => {
            if (v.warehouseareaName == "新津冷藏库") {
              this.chengdulingcang = v.temperature || chengdulingcang;
            }
            if (v.warehouseareaName == "新津冷冻库") {
              this.chengdulingdong = v.temperature || chengdulingdong;
            }
            if (v.warehouseareaName == "3号常温库") {
              this.lasachangwen = v.temperature || lasachangwen;
            }
            if (v.warehouseareaName == "冷藏库1号") {
              this.lasalingcang = v.temperature || lasalingcang;
            }
          });
        } else {
          this.chengdulingcang = chengdulingcang;
          this.chengdulingdong = chengdulingdong;
          this.lasachangwen = lasachangwen;
          this.lasalingcang = lasalingcang;
        }
        this.$forceUpdate();
      });
    },
    change() {
      // if (!this.queryValue || !this.querySelect) return;
      // if (e.target.localName === "i") {
      bus.$emit("searchParams", {
        key: this.querySelect,
        value: this.queryValue,
      });
      // }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerDate);
    clearTimeout(this.timerMin);
    this.timerDate = null;
    this.timerMin = null;
  },
};
</script>

<style lang="scss" scoped>
.search {
  position: relative;
  .search-box {
    display: flex;
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 10px;
    max-width: 300px;
    background: linear-gradient(
      180deg,
      rgba(0, 76, 255, 0.02),
      rgba(0, 95, 255, 0.3) 100%
    );
    backdrop-filter: blur(10px);
    .search_img {
      cursor: pointer;
    }
  }
  .temperature {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    font-size: 12px;
    line-height: 1.5;
    // background: rgba(0,24,69,0.30);
    backdrop-filter: blur(4px);
    // border: 1px solid #5473bf;
    // width: 316px;
    width: 40%;
    .temperatureHead {
      backdrop-filter: blur(4px);
      border: 1px solid #5473bf;
      // border: 1px solid #87d2ff;
      // cursor: pointer;
      // background: linear-gradient(0deg, #5f97ff9c 0%, rgba(95, 151, 255, 0));
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 13px;
      /* &:hover {
        padding: 0 5px 0 12px;
        border: 1px solid #17f1ec;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: 0px 0px 10px 0px #17f1ec;
      } */
      .tempe-left {
        .text {
          font-size: 14px;
          color: #ffffff;
          // font-weight: normal;
          height: 48px;
          display: flex;
          align-items: center;
          opacity: 1;
        }
        .colr {
          color: #17f1ec;
          font-weight: 600;
        }
      }
      .tempe-right {
        display: flex;
        align-items: center;
        .text {
          font-size: 14px;
          color: #ffffff;
          font-weight: normal;
          height: 48px;
          display: flex;
          align-items: center;
          .unt {
            color: #17f1ec;
            font-size: 24px;
            margin: 0 5px 0 11px;
            font-weight: 600;
          }
        }
        .img {
          height: 20px;
          width: 20px;
          margin-left: 6px;
        }
      }
    }
    .temperatureMain {
      background: rgba(0, 24, 69, 0.3);
      display: flex;
      justify-content: space-between;
      padding: 10px 13px 0;
      border-top: 1px solid #87d2ff;
      backdrop-filter: blur(4px);
      border: 1px solid #5473bf;
      .temperature_div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .temperature_p {
          min-width: 85px;
          font-size: 12px;
        }
      }
      span {
        text-align: right;
        margin-right: 5px;
        display: inline-block;
        min-width: 25px;
        color: rgba(23, 241, 236, 1);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .weather {
    // position: absolute;
    // top: 137px;
    // right: 10px;
    margin: 7px 0 6px;
    z-index: 10;
    font-size: 12px;
    line-height: 1.5;
    background: rgba(62, 100, 172, 0.3);
    backdrop-filter: blur(4px);
    border: 1px solid #87d2ff;
    // width: 316px;
    width: 100%;
    .weatherHead {
      cursor: pointer;
      background: linear-gradient(0deg, #5f97ff9c 0%, rgba(95, 151, 255, 0));
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 6px 0 13px;
      &:hover {
        padding: 0 5px 0 12px;
        border: 1px solid #17f1ec;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: 0px 0px 10px 0px #17f1ec;
      }
      .tempe-left {
        .text {
          font-size: 16px;
          color: #ffffff;
          // font-weight: normal;
          height: 48px;
          display: flex;
          align-items: center;
          opacity: 1;
        }
      }
      .tempe-right {
        display: flex;
        align-items: center;
        font-size: 16px;
        .colr {
          color: #17f1ec;
          font-weight: 600;
          display: inline-block;
          margin-left: 6px;
        }
        .img {
          height: 20px;
          width: 20px;
          margin-left: 6px;
        }
      }
    }
  }
  .weatherLegend {
    // position: absolute;
    // top: 195px;
    // right: 10px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    .title {
      display: flex;
      cursor: pointer;
    }
  }
  .toDay_box {
    width: 42%;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    background: rgba(0, 24, 69, 0.3);
    border: 1px solid #5473bf;
    backdrop-filter: blur(4px);
    // opacity: 0.75;
    font-size: 14px;
    font-weight: 300;
    color: #ffffff;
    padding: 15px 11px 6px;
    .toDay_head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .left {
        display: flex;
        align-items: center;
        img {
          height: 16px;
          width: 16px;
          margin: 0 4px;
        }
        span {
          cursor: pointer;
        }
        .toActiv {
          font-size: 18px;
          font-weight: 500;
          opacity: 1 !important;
        }
      }
      .right {
        .text {
          color: #17f1ec;
          font-size: 23px;
          font-weight: 900;
          margin-right: 2px;
        }
      }
    }
    .toDayList {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      font-size: 12px;
      padding: 7px 0;
      .left {
        .text {
          font-size: 14px;
          font-weight: 600;
          color: #17f1ec;
        }
      }
      .right {
        display: flex;
        align-items: center;
        span {
          font-weight: 500;
          display: inline-block;
          width: 45px;
          text-align: center;
        }
        img {
          margin-left: 8px;
        }
        .right_mg {
          margin-right: 24px;
        }
      }
      &.border-no {
        border-bottom: none;
      }
    }
  }
}

.queryValue {
  color: rgba(23, 255, 245, 1);
  position: absolute;
  right: 6px;
  top: 35%;
  cursor: pointer;
}

.vehicleHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .head-left {
    display: flex;
    align-items: center;
    .btn {
      height: 32px;
      padding: 3px 1.30208vw;
      color: #fff;
      font-weight: 300;
      &:hover {
        color: #17fff4 !important;
        // font-weight: 500;
      }
    }
  }
  .head-right {
    display: flex;
    align-items: center;
  }
}
::v-deep {
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
    border-radius: 0;
    backdrop-filter: blur(2px);
  }
  .el-radio-button__inner {
    font-size: 14px;
    color: #ffffff;
    background: inherit;
    border: 1px solid #87d2ff;
    border-radius: 0;
    backdrop-filter: blur(2px);
  }
  .el-radio-button__inner + .el-radio-button__inner {
    border-right: none;
  }
}
::v-deep {
  .el-select {
    width: 120%;
    .el-input__inner {
      border-right: none;
    }
  }
  .el-input__inner {
    border-radius: 0;
    border: 1px solid #87d2ff;
    color: rgba(23, 255, 245, 1);
    height: 32px;
    line-height: 32px;
  }
  .el-input__icon {
    color: rgba(23, 255, 245, 1) !important;
    line-height: 32px;
  }
  .head-left {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .point-type {
      height: 32px;
      margin-right: 12px;
      .el-radio-button {
        height: 32px;
      }
      .el-radio-button__inner {
        height: 32px;
        padding: 8px 1.04167vw;
        border-radius: 0;
        color: #ffffff;
        opacity: 0.75;
        font-weight: 300;
      }
      .is-active {
        .el-radio-button__inner {
          color: #17fff4;
          opacity: 1;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .selectAlarm {
      height: 32px;
      width: 220px;
      ::v-deep .el-input__inner {
        height: 32px !important;
        padding: 0 20px 0 12px;
        font-size: 14px;
      }
      ::v-deep .el-select__caret {
        font-size: 14px;
      }
    }
  }
  .head-right {
    .point-type {
      height: 32px;
      .el-radio-button {
        height: 32px;
      }
      .el-radio-button__inner {
        height: 32px;
        padding: 8px 1.04167vw;
        border-radius: 0;
        color: #ffffff;
        opacity: 0.75;
        font-weight: 300;
      }
      .is-active {
        .el-radio-button__inner {
          color: #17fff4;
          opacity: 1;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #888888;
  border-color: none;
  color: #c0c4cc;
  cursor: not-allowed;
}
::v-deep .el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__wrap {
      .el-select-dropdown__list {
        .el-select-dropdown__item {
          color: #fff;
        }
        .selected {
          color: #17fff5 !important;
        }
        .el-select-dropdown__item:hover {
          color: #17fff5;
        }
      }
    }
  }
}
.legendBox {
  display: flex;
  justify-content: space-between;
  padding: 0 38px 0 28px;
  margin-top: 20px;
  .legendList {
    background: rgba(0, 0, 0, 0.3);
    width: 278px;
    padding: 24px 40px;
    .legend-item {
      margin-bottom: 29px;
      .item-main {
        display: flex;
        align-items: center;
        .weatherImg {
          height: 34px;
          width: 40px;
          background-image: url("~@/assets/image/weather/white30.png");
        }
        .weatherName {
          color: #ffffff;
          font-size: 14px;
          margin-top: -4px;
        }
      }
    }
  }
}

.weatherImgs {
  height: 34px;
  width: 40px;
  display: inline-block;
  background-image: url("~@/assets/image/weather/white30.png");
}

::v-deep .selectAlarm .el-input__inner {
  height: 32px !important;
  padding: 0 20px 0 12px;
  font-size: 14px;
  // border: 1px solid #87d2ff !important;
  border-right: 1px solid #87d2ff !important;
}
</style>
