<template>
  <div class="care">
    <span class="nameCare">视频监控</span>
    <div class="enlarge" @click="enlargeClick">
      <img class="img" :src="imgScreen" alt="" width="16px" height="16px" />
      <span>放大</span>
    </div>
    <iframe
      v-if="minMonitorIframeSrc"
      :src="minMonitorIframeSrc"
      frameborder="0"
      class="iframe"
    ></iframe>
    <div class="careText" v-if="!monitorIframeSrc&&!minMonitorIframeSrc">
      <img :src="imgSrc" alt="" width="32px" height="32px" />
      <div style="padding-left:5px;">暂无监控信息</div>
    </div>
    <!-- <video
            v-for="item in videos"
            :key="item.channelId"
            :id="item.channelId"
            poster="@/assets/image/monitorPoster.png"
          ></video> -->
    <!-- <div class="monitor-right-content"
      >
       <el-carousel trigger="click" :autoplay="false" class="monitor-right-Carousel">
        <el-carousel-item v-for="item in 4" :key="item" class="Carousel-item">
          <video
            v-for="(item,index) in 4"
            :key="index + 1"
            :id="item"
            poster="@/assets/image/monitorPoster.png"
          ></video>
        </el-carousel-item>
      </el-carousel>
      </div> -->
    <el-dialog
      :title="'视频监控'"
      width="90%"
      :append-to-body="true"
      :visible.sync="enlargeMapIframe"
    >
      <div>
        <iframe
          :src="maxMonitorIframeSrc"
          frameborder="0"
          class="iframeDialog"
        ></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import flvjs from "flv.js";
import {
  getOpenVideo,
  createMonitorIframeSrc,
  getVideoList,
} from "@/api/e6yun.js";

export default {
  name: "vehiclePosition",
  data() {
    return {
      imgSrc: require("@/assets/image/jiankong.png"),
      imgScreen: require("@/assets/image/fullScreen.png"),
      monitorIframeSrc: "", // 监控iframe的src
      minMonitorIframeSrc: "", // 监控iframe的src
      maxMonitorIframeSrc: "", // 监控iframe的src
      enlargeMapIframe: false,
      flag: false,
      videos: [],
    };
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  watch: {
    vehicle: {
      handler(value) {
        if (Object.keys(value).length !== 0) {
          if (value.plateNo){
            // 获取视频监控
            this.flag = Boolean(Object.keys(value).length);
            // 藏A6U668
            // 藏A9D758
            // this.getVideoList('藏A9D758'); //获取车辆摄像头个数
            this.getVideoList(value.plateNo); //获取车辆摄像头个数
          }else{
            this.monitorIframeSrc = "";
            this.minMonitorIframeSrc = ""
            this.maxMonitorIframeSrc = ""
          }
        }
      },
      immediate: true,
    },
    enlargeMapIframe:{
      handler(value) {
         if (value) {
           this.minMonitorIframeSrc = ''
           setTimeout(()=>{
             this.getVideoList(this.vehicle.plateNo); //获取车辆摄像头个数
           },1000)
         } else {
           this.maxMonitorIframeSrc = ''
           setTimeout(()=>{
             this.getVideoList(this.vehicle.plateNo); //获取车辆摄像头个数
           },1000)
         }
      },
      immediate: false
    }
  },
  methods: {
    //获取视频链路接口（有几个监控摄像头）
    async getVideoList(vehicle) {
      let res = await getVideoList({ vehicleNos: vehicle });
      if (res.result.code === "1") {
        let videoNum = res.result.data[0].camera.toString(2); //10进制改成2进制，有多少个1就有几个摄像头（比如111就有三路摄像头）
        let videoNumArr = videoNum.split('')
        let videoNumArrs = [...videoNumArr].reverse()
        if (videoNum != 0) {
          let arr = [];
          for (let i = 0; i < videoNumArrs.length; i++) {
            if(videoNumArrs[i] === '1'){
              arr.push(i + 1);
            }
          }
          // console.log('几路摄像头',arr.toString())
          this.createMonitorIframeSrc(vehicle, arr.toString());
        } else {
          // this.createMonitorIframeSrc(vehicle, "0");
            this.monitorIframeSrc = ""
            this.minMonitorIframeSrc = ""
            this.maxMonitorIframeSrc = ""
        }
      } else {
        this.monitorIframeSrc = ""
        this.minMonitorIframeSrc = ""
        this.maxMonitorIframeSrc = ""
        if(res.result.code !== "42"){
          this.$message({
            message: res.result.message,
            type: "error",
          });
        }
      }
    },
    // 生成monitorIframeSrc
    async createMonitorIframeSrc(vehicle, channelIds = "1") {
        this.minMonitorIframeSrc = ''
        this.maxMonitorIframeSrc = ''
      if (this.flag) {
        this.monitorIframeSrc = await createMonitorIframeSrc({
          vehicle,
          channelIds,
          // effectivetime: "120",
          expiry: "100",
          videoQuality:'1',
          format:'json',
          method:'getOpenVideoUrl'
        });
      //  console.log('视频链接',this.monitorIframeSrc)
       if(!this.enlargeMapIframe){
          this.minMonitorIframeSrc = this.monitorIframeSrc
        }else{
          this.maxMonitorIframeSrc = this.monitorIframeSrc
        }
      } else {
        this.monitorIframeSrc = "";
        this.minMonitorIframeSrc = ""
        this.maxMonitorIframeSrc = ""
      }
      // console.log('视频链接',this.monitorIframeSrc)
      // if (!this.monitorIframeSrc) {
      //   this.$message({
      //     message: '当前车辆没有安装车载视频温控定位设备，或者设备被关闭，请联系业务运营人员。',
      //     type: 'error'
      //   })
      // }
    },
    // 打开播放视频
    getOpenVideo(vehicle) {
      getOpenVideo({
        vehicle,
      }).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: "error",
            duration: 5 * 1000,
          });
        } else {
          this.videos = res.data || [];
          this.$nextTick(() => {
            this.videos.map((item) => {
              this.playFlv(item);
            });
          });
        }
      });
    },
    // 播放
    playFlv(item) {
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById(item.channelId);
        var flvPlayer = flvjs.createPlayer({
          type: "flv",
          url: item.httpUrl,
          isLive: true,
          hasAudio: false,
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
    //点击放大车辆定位弹框
    enlargeClick() {
      this.enlargeMapIframe = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.care {
  width: 100%;
  height: 100%;
  // position: relative;
  .nameCare {
    position: absolute;
    top: 10px;
    left: 8px;
    color: #17fff4;
    height: 16px;
    line-height: 16px;
  }
  .enlarge {
    position: absolute;
    top: 10px;
    right: 8px;
    display: flex;
    align-items: center;
    color: #17fff4;
    cursor: pointer;
    .img {
      // position: relative;
      // top: 3px;
      margin-right: 4px;
    }
  }
  .iframe {
    width: 100%;
    height: 100%;
  }
  .careText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25% auto;
  }
}
.iframeDialog {
  width: 100%;
  height: 670px;
}
::v-deep {
  .el-dialog__title {
    color: #17fff4;
  }
}
</style>
