import request from "@/utils/request";

export function exportCategorySupplyOrderAmount(data) {
  return request({
    url: `/export/categorySupplyOrderAmount`,
    method: "post",
    data,
    responseType: "blob",
  });
}
export function exportCategorySupplyQuantity(data) {
  return request({
    url: `/export/categorySupplyQuantity`,
    method: "post",
    data,
    responseType: "blob",
  });
}
export function exportFocalPoint(data) {
  return request({
    url: `/export/focalPointDetail/${data}`,
    method: "post",
    responseType: "blob",
  });
}
export function exportProcess(data) {
  return request({
    url: `export/orderStatusMonitorDetail`,
    method: "post",
    data,
    responseType: "blob",
  });
}
export function exportOrderQuantity(data) {
  return request({
    url: `/monitor/exportOrderInfo`,
    method: "post",
    data,
    responseType: "blob",
  });
}
/* export function exportQualityMonitoring(data) {
  return request({
    url: `/monitor/exportFocalPoint`,
    method: "post",
    data,
    responseType: "blob",
  });
} */
export function exportQualityMonitoring(data) {
  return request({
    url: `/export/focalPoint`,
    method: "post",
    data,
    responseType: "blob",
  });
}
export function exportSupplier(data) {
  return request({
    url: `/export/exportVendorRatioChart`,
    method: "post",
    data,
    responseType: "blob",
  });
}
export function exportCategory(data) {
  return request({
    url: `/export/categorySupply`,
    method: "post",
    data,
    responseType: "blob",
  });
}

export function exportTodayRefund(data) {
  return request({
    url: `monitor/exportTodayRefund`,
    method: "post",
    data,
    responseType: "blob",
  });
}

