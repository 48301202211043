<template>
  <div>
    <component v-bind:is="border" :borderTitle="'今日下单播报'">
      <div :ref="tbRef" class="leftContent">
        <!-- 列表主信息 -->
        <el-table
            class="o-scroll-tb"
            :data="tableData"
            size="mini"
            @row-click="rowClick"
        >
          <el-table-column
              v-for="column in columns"
              v-bind="column"
              :key="column.prop"
              :align="column.align || 'center'"
              :width="column.width || ''"
              :show-overflow-tooltip="column.showOverflowTooltip">
            <template slot-scope="scope" resizable>
              <span v-if="column.prop === 'orderDate'">{{ scope.row[column.prop] | formatTime }}</span>
              <span v-else-if="column.prop === 'focalPointNo'">{{ scope.row[column.prop].substr(0,2) + '***' }}</span>
              <span v-else>{{ scope.row[column.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="detail">
        <div slot="detail">
          <span @click="openDetails">查看详情</span>
        </div>
      </div>
    </component>

    <Details
            ref="orderDetails"
            v-if="showDetails"
            :dialogTitle="'订单全过程监控详情'"
            :stripe="false"
            :loading="false"
            :columns="columns2"
            :tableData="tableData2"
            :pageList="pageList"
            :textMap="textMap"
            :getDetailsPage="getDetailsPage"
            @exportTion="exportTion"
            @close:closeDetails="closeDetails"
    >
    </Details>
  </div>
</template>
<script>
import Border1 from "@/components/Border1";
import {uuid, parseTime, formatDate} from "@/utils";
import Details from "@/views/home/warehouse/hubei/viewsComponents/Details/FDetails.vue";
import {exportProcess} from "@/api/home";

export default {
  name: "orderScroll",
  components: {
      Details,
    Border1
  },
  filters: {
    formatTime(val) {
      return parseTime(val, '{h}:{i}:{s}');
    },
    FormatToFixed(val) {
      if (val) {
        let result = (Math.round(val * 100) / 100).toString();
        let dotPosition = result.indexOf(".");
        if (dotPosition < 0) {
          dotPosition = result.length;
          result += ".";
        }
        while (result.length <= dotPosition + 2) {
          result += "0";
        }
        return result;
      } else {
        return 0;
      }
    }
  },
  watch:{
    tooltipVisible(val) {
      if (val) {
        this.cleanTimer();
      } else {
        this.dynamicAdd()
      }
    }
  },
  props: {
    border: {
      type: String,
      default: "Border1",
    },
    tooltipVisible:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tbRef: uuid(),
      size: 20,
      timer: null,
      columns: [
        {
          label: '订单号',
          prop: 'soid',
          width: 90,
          align: 'center'
        },
        {
          label: '灶点',
          prop: 'focalPointNo',
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '订单金额(元)',
          prop: 'soAmt',
          width: 80,
          align: 'center'
        },
        {
          label: '订单重量(斤)',
          prop: 'tweight',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        },
        {
          label: '下单时间',
          prop: 'orderDate',
          width: 80,
          align: 'center',
          showOverflowTooltip: true
        }
      ],
      columns2: [
          {
              label: "订单编号",
              prop: "orderNo",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: true,
              width: 100
          },
          {
              label: "下单时间",
              prop: "orderDate",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              type: "time",
              form: true,
              width: 180
          },
          {
              label: "订单金额(元)",
              prop: "soAmt",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: 'right',
              width: 100
          },
          {
              label: "状态",
              prop: "status",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              type: "select",
              form: true,
              options: [
                  { name: "待审核", value: "00" },
                  { name: "已审核", value: "10" },
                  { name: "入库中", value: "20" },
                  { name: "待出库", value: "30" },
                  { name: "已出库", value: "40" },
                  { name: "已签收", value: "50" },
                  { name: "部分退货", value: "60" },
              ]
          },
          {
              label: "灶点编号",
              prop: "focalPointNo",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: true
          },
          /*{
            label: "灶点名称",
            prop: "focalPointName",
            feildType: "STRING",
            queryType: "LIKE",
            isnull: false,
            query: true,
            form: true
          },*/
          {
              label: "配送日期",
              prop: "deliveryDate",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              type: "time",
              form: true,
              width: 130
          },
          {
              label: "一级单位编码",
              prop: "focalName1",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: false,
              width: 130
          },
          {
              label: "二级单位编码",
              prop: "focalName2",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: false,
              width: 130
          },
          {
              label: "三级单位编码",
              prop: "focalName3",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: true,
              form: false,
              width: 130
          },
          {
              label: "商品重量(斤)",
              prop: "tWeight",
              feildType: "STRING",
              queryType: "LIKE",
              isnull: false,
              query: false,
              form: true,
              align: 'right',
              width: 100
          }
      ],
      showDetails: false,
      pageList: {
          page: 1,
          rows: 20,
          count: 0
      },
      sysNo: 0,
      textMap: {
          ["00"]: "待审核",
          ["10"]: "已审核",
          ["20"]: "入库中",
          ["30"]: "待出库",
          ["40"]: "已出库",
          ["50"]: "已签收",
          ["60"]: "部分退货",
          ["-1"]: "已作废",
          ["0"]: "未处理",
          ["1"]: "已处理",
          ["2"]: "待处理",
          ["3"]: "处理中",
          ["4"]: "已处理",
          ["5"]: "待发还",
          ["6"]: "RMA完成",
          ["7"]: "财务部已审核",
          ["8"]: "商务部已审核"
      },
      tableData: [], // 当前下单播报数据
      tableData2: [], // 查看详情 里层数据
      triggerRef: {
        getBoundingClientRect() {
          return this.position
        }
      },
      position: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
      clickOrder: {},
      //订单类型(0正常单 1调整单 2紧急单(取消) 3临时单(取消) 4补货单)
      orderTypeMap: {
        0: '正常订单',
        1: '调整订单',
        2: '紧急订单',
        3: '临时订单',
        4: '补货订单'
      }
    };
  },
  methods: {
    doNothing() {

    },
    rowClick(row, column, event) {
      this.$emit('showTodayOrder', [row, this.$refs.tbRef]);
      /*let that = this;
      that.tooltipVisible = true;
      that.clickOrder = row;
      that.position = DOMRect.fromRect({
        width: 0,
        height: 0,
        x: event.clientX,
        y: event.clientY,
      });*/
    },
    openDetails() {
      // 详情 弹窗(订单监控全过程)
      this.showDetails = true;
      this.pageList.page = 1
      this.$nextTick(()=>{
          this.$refs.orderDetails.allOrderBtn(1)
      })
    },
    getDetailsPage(entitys, searchButton = 0) {
        //查询 0
        //重置 0
        //换页 2
        //换条 1
        if (searchButton == 0) {
            this.pageList.page = 1
            this.pageList.rows = 20
        }else if (searchButton == 1){
            this.pageList.page = 1
        }
        this.$nextTick(async () => {
            let params = Object.assign(
                {
                    // type: "orderStatusMonitorDetail",
                    type: "orderStatusMonitorDetailNew",
                    date: formatDate(this.month),
                    page: this.pageList.page,
                    rows: this.pageList.rows
                    // organizationDebug: 1
                },
                this.$refs && this.$refs.orderDetails.getEntitys().params
            );
            let res = await this.$getData(params);
            this.tableData2 = res.data;
            this.pageList.count = res.count;
        });
    },
    exportTion(){
        const mothod = exportProcess
        let params = Object.assign(
            {
                // type: "orderStatusMonitorDetail",
                date: formatDate(this.month),
            },
            this.$refs && this.$refs.orderDetails.getEntitys().params
        );
        mothod(
            params
        ).then((res) => {
            const link = document.createElement('a')
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
            link.style.display = 'none'
            link.href = window.URL.createObjectURL(blob)
            link.download = '订单全过程详情导出.xlsx'
            document.body.appendChild(link)
            link.click()
            // 兼容火狐浏览器
            document.body.removeChild(link)
            // console.log(res)
        })
    },
    closeDetails() {
        this.showDetails = false;
    },
    // datePickerChange(value) {
    //   console.log(value);
    // },
    /**
     * 获取颜色
     */
    filterStatus(val) {
        return this.textMap[val];
    },
    /**
     * 获取表格信息
     * @param next
     */
    getTableData(next) {
      let param = {
        size: this.size,
        type: "todayOrderScroll"
      };
      if (next) {
        param.needNext = next;
      }
      //let res = await this.$getData(params);
        this.$getData(param).then((res) => {
        if (res.code === 0) {
          if (res.data && next) {
            this.tableData.unshift(res.data[0]);
            this.tableData = this.tableData.slice(0, 20)
          } else if (res.data) {
            this.tableData = res.data;
          }
        }
      });
    },
    /**
     * 设置定时任务,2秒获取并处理下数据
     */
    dynamicAdd() {
      let that = this;
      that.getTableData(true);
      /*this.timer = setInterval(() => {
        that.getTableData(true);
      }, 2000);*/
    },
    cleanTimer() {
      clearTimeout(this.timer);
      this.timer = null;
    },
  },
  mounted() {
    this.getTableData();
    this.dynamicAdd();
  },
  destroyed() {
    this.cleanTimer();
  }
};
</script>
<style lang="scss" scoped>
.leftContent {
  height: 100%;
  width: 100%;
  padding-top: 40px;
  position: relative;
  //overflow: hidden;
  overflow: visible !important;

  .o-scroll-tb {
    height:  calc(100% - 25px);;
    background: transparent;
  }

  $cd_width: 400px;
  // 453px
  &-dialog {
    position: absolute;
    width: $cd_width;
    //height: $cd_height;
    left: ($cd_width*+1)+60px;
    top: 0px;
    opacity: 1;
    border: 1px solid #17fff5;
    background: rgba(6, 22, 49, 0.8);
    color: rgba(255, 255, 255, 0.8);

    &-title {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }

    &-close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .close {
        width: 15px;
      }
    }

    &-body {
      padding: 10px;
      font-size: 14px;

      .dialog-body {
        &-ul {
          margin-left: 10px;
        }

        &-li {
          line-height: 24px;
          display: flex;
          flex-direction: row;

          img {
            width: 100px;
          }
        }
      }
    }
  }
}

.detail {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: rgba(23, 241, 236, 1);
  font-size: 14px;
  cursor: pointer;
}

::v-deep .el-table .cell {
  line-height: 14px;
  padding-right: 0px;
  padding-left: 0px;
}

::v-deep .el-table th, .el-table tr {
  background: transparent;
}

::v-deep .el-table__empty-text {
  line-height: 212px;
}

::v-deep {
  .el-table th, .el-table tr {
    background-color: transparent !important;
  }

  .el-table__empty-block, .el-table__body-wrapper {
    background-color: transparent !important;
  }
}

</style>
