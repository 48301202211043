<template>
  <div class="popover">
    <div class="box">
      <img class="box_img" src="@/assets/image/triangleImg.svg" alt />
      <ul>
        <li class="box_li" @click.stop="logout">退 出</li>
        <li class="box_li" @click.stop="dialogFormVisible = true">修改密码</li>
<!--        <li class="box_li" @click.stop="upPassWord">修改密码</li>-->
        <li class="box_li" @click.stop="toUser" v-if="username === 'admin'">
          用户管理
        </li>
        <!-- <li
          class="box_li"
          @click.stop="e6UserVisible = true"
          v-if="username === 'admin'">
          接口配置
        </li> -->
        <li class="box_li" v-if="username === 'admin'" @click.stop="toJournal">
          登录日志
        </li>
        <!-- <li class="box_li" v-if="username === 'admin'" @click.stop="toAccOpen">
          ACC开关
        </li> -->
        <!-- <li class="box_li" v-if="username === 'admin'" @click.stop="openWeather()">天气区域</li> -->
      </ul>
    </div>

    <el-dialog
      title="修改密码"
      :visible="dialogFormVisible"
      @close="handleCancel"
      :append-to-body="true"
      width="34%">
      <el-form
        ref="dataForm"
        :model="temp"
        :rules="rules"
        label-position="left"
        label-width="100px">
        <el-form-item label="旧密码：" prop="oldPwd">
          <el-input
            type="text"
            value=""
            :class="showEyeOne ? '' : 'passwedText'"
            autocomplete="off"
            v-model.trim="temp.oldPwd">
          </el-input>
          <i @click="showEyeOne = !showEyeOne" class="el-icon-view visib" :style="{color:(showEyeOne?'blue':'')}"></i>
        </el-form-item>
        <el-form-item label="新密码：" prop="newPwd">
          <el-input
            type="text"
            value=""
            :class="showEyeTwo ? '' : 'passwedText'"
            autocomplete="off"
            v-model.trim="temp.newPwd"
            placeholder="密码要求最少10位，必须包含数字、大写或小写字母和特殊字符的组合">
          </el-input>
          <i @click="showEyeTwo = !showEyeTwo" class="el-icon-view visib" :style="{color:(showEyeTwo?'blue':'')}"></i>
        </el-form-item>
        <el-form-item label="确认密码：" prop="repeatPwd">
          <el-input
            type="text"
            value=""
            :class="showEyeTwo ? '' : 'passwedText'"
            autocomplete="off"
            v-model.trim="temp.repeatPwd"
            placeholder="密码要求最少10位，必须包含数字、大写或小写字母和特殊字符的组合">
          </el-input>
          <i @click="showEyeTwo = !showEyeTwo" class="el-icon-view visib" :style="{color:(showEyeTwo?'blue':'')}"></i>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleUpdatePwd()"> 确认 </el-button>
        <el-button @click="handleCancel()"> 取消 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="易流管理"
      :visible="e6UserVisible"
      @close="e6UserCancel"
      :append-to-body="true"
      width="30%">
      <el-dialog
        width="30%"
        title="警告"
        :visible.sync="e6UserInnerVisible"
        append-to-body>
        <div class="warningAffirm">
          <i class="el-icon-warning"></i>
          <span> 请确认本次密钥更新，更新后即刻生效! </span>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="e6UserAffirmUpda()">
            确认
          </el-button>
          <el-button @click="e6UserAffirmCancel()"> 取消 </el-button>
        </div>
      </el-dialog>
      <div class="warning">
        <i class="el-icon-warning-outline"></i>
        <span> 修改配置信息将会导致发运详情地图、监控及温度功能不可用！ </span>
      </div>
      <el-form
        ref="e6Form"
        :model="e6Temp"
        :rules="e6Rules"
        label-position="left"
        label-width="100px">
        <el-form-item label="appKey" prop="appKey">
          <el-input
            v-model.trim="e6Temp.appKey"
            type="text"
            autocomplete="off"
            placeholder="请输入" />
        </el-form-item>
        <el-form-item label="appSecret" prop="appSecret">
          <el-input
            v-model.trim="e6Temp.appSecret"
            type="text"
            autocomplete="off"
            placeholder="请输入" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="e6UserUpda()"> 确认 </el-button>
        <el-button @click="e6UserCancel()"> 取消 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="车辆管理"
      :visible="toAccOpenshow"
      @close="toAccCancel"
      :append-to-body="true"
      width="800px"
      top="calc((100vh - 736PX)/2)"
      >
      <div class="allContent">
        <div class="headTop">
          <el-button class="el-icon-plus" type="primary" @click="addAccOpening">新增</el-button>
          <el-input
            style="width:264px"
            placeholder="请车牌进行搜索"
            v-model="input4">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="toAccOpen"></i>
          </el-input>
        </div>
        <div class="accTable">
          <Table
            class="accOpen"
            :popperClass="`paginaClass`"
            :searchShow="false"
            :loading="false"
            :total="pageList.count"
            :page.sync="pageList.page"
            :rows.sync="pageList.rows"
            :table-data="tableData"
            :searchLeftShow="true"
            :flag="false"
            :leftShow="false"
            @pagination="toAccOpen"
            :max-height="`527px`"
            :columns="columns">
            <template slot="columnEspecial" slot-scope="{ column, row }">
              <span v-if="column.prop === 'accSwitch'" :style="{color: row[column.prop] == 1 ? '#3AAF00' : '#333'}">
                <el-switch
                  active-color="#3AAF00"
                  :active-value="1"
                  :inactive-value="0"
                  @change="outAccOpenStatus(row)"
                  v-model.trim="row.accSwitch">
                </el-switch> {{ row[column.prop] === 1 ? "开" : "关"}}
              </span>
              <span v-else>{{ row[column.prop] }}</span>
            </template>
            <template slot="columnRight">
              <el-table-column
                label="操作"
                fixed="right"
                width="160"
                align="center">
                <template slot-scope="{ row }">
                  <div ref="columnRight">
                    <el-button type="text" size="mini" @click="accEdit(row)" class="btnZoom">编辑</el-button>
                    <el-button type="text" size="mini" @click="accDeleteing(row)" class="btnZoom">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </template>
          </Table>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="isAdd ? '新增' : '编辑'"
      :visible="accEditShow"
      @close="closeAccOpen"
      :append-to-body="true"
      width="462px">
      <el-form
        ref="accForm"
        :model="accForm"
        label-position="left"
        label-width="85px">
        <el-form-item label="车牌:" prop="plateNo">
          <el-input
            v-model.trim="accForm.plateNo"
            type="text"
            placeholder="请输入" />
        </el-form-item>
        <el-form-item label="ACC开关:" prop="accSwitch">
          <el-switch
            active-color="#52C41A"
            :active-value="1"
            :inactive-value="0"
            v-model.trim="accForm.accSwitch ">
          </el-switch>{{accForm.accSwitch === 1 ? '开' : '关'}}
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="accConfirm()"> 确认 </el-button>
        <el-button @click="closeAccOpen"> 取消 </el-button>
      </div>
    </el-dialog>
    <Warning
      ref="Warning"
      v-if="showWeatherWarning"
      :dialogTitle="'天气预警区域'"
      :stripe="false"
      :loading="false"
      :columns="columnsTwo"
      :tableData="warningList"
      :pageList="pageListTwo"
      :modal="false"
      :searchShow="false"
      :entitysShow="[]"
      :WarningProcessingProp="{}"
      :getDetailsPage="getAlarmInfoList"
      @refreshWeather="refreshWeather"
      @clos:closeDetails="closeAlarmInfoList">
      <div slot="left-top" class="left-top">
        <el-cascader
            v-model="regionValue"
            :options="regionOptions"
            :props="{ checkStrictly: true }"
            clearable
            @change="handleChange"></el-cascader>
            <el-button
              class="BlueCustomButton btn"
              @click="addWeather()">新增</el-button>
      </div>
    </Warning>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getRSApassword } from "@/utils/data-transform";
import { mapGetters } from "vuex";
import { logout } from "@/api/login";
import { getPublicKey } from "@/api/login";
import { updatePwd, e6Update, accGetPage, accUpdate, accAdd, accDelete } from "@/api/users";
import bus from "@/utils/bus";
import { weatherAreaGetPage } from "@/api/weather";
import cityJson from "@/assets/json/湖北省.json";
import { cityRecursion } from "@/utils";
import Warning from "./weatherWarning.vue";
import { toSso } from '@/utils/auth'

export default {
  name: "App",
  components: {
    Table,
    Warning
  },
  data() {
    const checkPwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.temp.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const checkPwdwithOld = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value === this.temp.oldPwd) {
        callback(new Error("新密码不要与旧密码相同"));
      } else {
        callback();
      }
    };
    return {
      warningList:[],
      regionValue: [],
      regionOptions: [],
      alarmType:'0',
      alarmStatus:0,
      pageListTwo: {
        page: 1,
        rows: 20,
        count: 0,
      },
      showWeatherWarning:false,
       columnsTwo: [
        {
          label: "区域代码",
          prop: "areaCode",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width: 100,
        },
        {
          label: "省份",
          prop: "province",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 100,
        },
        {
          label: "城市",
          prop: "city",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 100,
          align: "left",
        },
        {
          label: "区/县",
          prop: "county",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
          align: "left",
        },
        {
          label: "是否有效",
          prop: "isValid",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
          align: "left",
        },
          {
          label: "更新人",
          prop: "modifierName",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
          align: "left",
        },
          {
          label: "更新时间",
          prop: "modifyTm",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
        },
      ],
      tableData: [],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      columns: [
        {
          type: 'tableType',
          width: '1'
        },
        {
          label: "车牌",
          prop: "plateNo",
        },
        {
          label: "ACC开关",
          prop: "accSwitch",
        }
      ],
      input4: '',
      showEyeOne: false,
      showEyeTwo: false,
      username: "",
      loading: "", // 全局的loading
      dialogFormVisible: false,
      e6UserVisible: false,
      toAccOpenshow: false,
      accEditShow: false, //acc编辑弹框
      accAddShow: false, // acc新增弹框
      e6UserInnerVisible: false,
      publicKey: "",
      temp: {
        oldPwd: "",
        newPwd: "",
        repeatPwd: "",
      },
      e6Temp: {
        appKey: "",
        appSecret: "",
      },
      accForm: {
        plateNo: "",
        accSwitch: 0
      },
      accRowData: {},
      oldPwdType: true,
      newPwdType: true,
      repeatPwdType: true,
      isAdd: 1,
      rules: {
        oldPwd: [
          { required: true, message: "请输入当前账户密码", trigger: "blur" },
        ],
        newPwd: [
          {
            required: true,
            // 包含大写(?=.*[A-Z])
            // 包含小写(?=.*[a-z])
            // 包含数字(?=.*[0-9])
            // 包含特殊字符(?=.*[?=.*[`·~!@#$%^&*()_+}{|:;'",<.>/?\=\[\]\-\\])
            pattern:
              /((?=^.{10,}$)(?=.*[a-z])(?=.*[0-9])(?=.*[?=.*[`·~!@#$%^&*()_+}{|:;'",<.>/?=[\]\-\\]))|((?=^.{10,}$)(?=.*[A-Z])(?=.*[0-9])(?=.*[?=.*[`·~!@#$%^&*()_+}{|:;'",<.>/?=[\]\-\\]))|((?=^.{10,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[?=.*[`·~!@#$%^&*()_+}{|:;'",<.>/?=[\]\-\\]))|((?=^.{10,}$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]))/g,
            message: "密码由10位以上数字、大写、小写、字符中的三种组合而成",
            trigger: "change",
          },
          { required: true, validator: checkPwdwithOld, trigger: "change" },
        ],
        repeatPwd: [{ required: true, validator: checkPwd, trigger: "change" }],
      },
      e6Rules: {
        appKey: [{ required: true, message: "请输入appKey", trigger: "blur" }],
        appSecret: [
          { required: true, message: "请输入appSecret", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["isInitial", "isExpire"]),
  },
  watch: {
    isInitial: {
      handler: function (val) {
        this.dialogFormVisible = Boolean(val || this.isExpire);
      },
      immediate: true,
    },
    isExpire: {
      handler: function (val) {
        this.dialogFormVisible = Boolean(val || this.isInitial);
      },
      immediate: true,
    },
    dialogFormVisible: {
      handler: function () {
        this.temp = {
          oldPwd: "",
          newPwd: "",
          repeatPwd: "",
        };
      },
    },
    e6UserVisible: {
      handler() {
        this.e6Temp = {
          appKey: "",
          appSecret: "",
        };
      },
    },
    // websocket: {
    //   handler: function(res) {
    //     this.websocketNotify(res);
    //   }
    // }
  },
  mounted() {
    bus.$on("handleEditPwd", this.handleEditPwd);
    this.username = localStorage.getItem("username");
  },
  beforeDestroy() {
    bus.$off("handleEditPwd", this.handleEditPwd);
  },
  methods: {
    addWeather(){
      this.$refs.Warning.isAdd = true
      this.$refs.Warning.signForPictureDia =true
    },
    handleChange(value) {
        console.log(value);
        this.pageListTwo.page = 1
        this.weatherDetails()
      },
      refreshWeather(v){
        if (v) {
          this.pageListTwo.page = 1
        }
        this.weatherDetails()
      },
      getAlarmInfoList(_, v = 0){
      if (v == 1) {
        this.pageListTwo.page = 1
      }
      this.weatherDetails()
    },
    openWeather(){
      this.regionOptions = cityRecursion(cityJson)
      this.showWeatherWarning = true
      this.weatherDetails()
    },
    weatherDetails(){
      let obj = {
        page: this.pageListTwo.page,
        rows: this.pageListTwo.rows,
        entity:{}
      }
      if(this.regionValue.length){
        obj.entity.province = this.regionValue[0]
        obj.entity.city = this.regionValue[1]
        obj.entity.county = this.regionValue[2]
      }
      weatherAreaGetPage(obj).then(res=>{
        this.warningList = res.data
        this.pageListTwo.count = res.count
      })
    },
     closeAlarmInfoList() {
      this.showWeatherWarning = false;
      this.pageListTwo.page = 1
      this.pageListTwo.rows = 20
      this.regionValue = []
    },
    toJournal() {
      this.$router.push("/journal");
    },
    toUser() {
      this.$router.push("/user");
    },
    upPassWord() {
      // location.href = "http://localhost:9528/#/?sysCode=screen_hb"
      toSso();
    },
    logout() {
      this.$confirm("确定退出登录？", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await logout();
        if (!res.code) {
          this.$store.dispatch("global/changeGlobal", { key: "warehouseCode", value: '' }); // 退出时重置为湖北仓
          this.$store.dispatch("user/resetToken");
          this.$router.push("/login");
          // toSso();
        }
      });
    },
    websocketNotify(res) {
      if (String(res.type) === "0") {
        // const data = res.data
        // if (String(data.bizType) === '0') { // bizType表示批次
        //     this.$notify({
        //         title: `${data.fileName}上传${data.status === 2 ? '成功' : '失败'}`,
        //         message: `批次号:${data.bizNo}, 提交人:${data.submitter}`,
        //         type: data.status === 2 ? 'success' : 'error',
        //         duration: 0,
        //         onClick: () => {
        //             this.$router.push('/message/list')
        //         }
        //     })
        // }
        this.$store.dispatch("user/setMessage");
      }
      if (String(res.type) === "2") {
        const data = res.data;
        if (data.message) {
          this.$notify({
            title: `未读消息`,
            message: `${data.message}`,
            type: "info",
            duration: 0,
            dangerouslyUseHTMLString: true,
            onClick: () => {
              this.$router.push("/message/list");
            },
          });
        }
        this.$store.dispatch("user/setMessage");
      }
    },
    handleEditPwd() {
      this.temp = {
        oldPwd: "",
        newPwd: "",
        repeatPwd: "",
      };
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdatePwd() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          getPublicKey({}).then((res) => {
            this.publicKey = res.data;
            let params = {
              oldPassword: getRSApassword(this.publicKey, this.temp.oldPwd),
              newPassword: getRSApassword(this.publicKey, this.temp.newPwd),
            };
            updatePwd(params).then(() => {
              this.dialogFormVisible = false;
              console.log(this.$notify);
              this.$notify({
                title: "成功",
                message: "修改密码成功，即将重新登录！",
                type: "success",
                duration: 1500,
              });
              let time = setTimeout(() => {
                this.$store.dispatch("user/resetToken");
                this.$router.push(`/login`);
                clearTimeout(time);
              }, 1500);
            });
          });
        }
      });
    },
    e6UserUpda() {
      this.$refs["e6Form"].validate((valid) => {
        if (valid) {
          this.e6UserInnerVisible = true;
        }
      });
    },
    e6UserAffirmUpda() {
      let params = {
        id: "1",
        接入系统: "易流云",
        appKey: this.e6Temp.appKey,
        appSecret: this.e6Temp.appSecret,
      };
      e6Update(params).then(() => {
        this.e6UserVisible = false;
        this.e6UserInnerVisible = false;
        this.$notify({
          title: "成功",
          message: "修改易流密钥信息成功！",
          type: "success",
          duration: 1500,
        });
      });
    },
    handleCancel() {
      if (this.isInitial) {
        this.$notify({
          title: "提示",
          message: "请注意：您当前登录的账号密码为初始密码，请先修改密码！",
          type: "warning",
          duration: 2000,
        });
      } else if (this.isExpire) {
        this.$notify({
          title: "提示",
          message: "请注意：您当前登录的账号密码已过期，请先修改密码！",
          type: "warning",
          duration: 2000,
        });
      } else {
        this.dialogFormVisible = false;
      }
    },
    e6UserCancel() {
      this.e6UserVisible = false;
    },
    toAccCancel() {
      this.toAccOpenshow = false;
    },
    e6UserAffirmCancel() {
      this.e6UserInnerVisible = false;
    },
    showPass() {
      //点击图标是密码隐藏或显示
      if (this.passw == "text") {
        this.passw = "password";
        //更换图标
        this.icon = "el-input__icon el-icon-view";
      } else {
        this.passw = "text";
        this.icon = "el-input__icon el-icon-loading";
      }
    },
    //ACC开关
    toAccOpen(v,f) {
      this.toAccOpenshow = true
      var params = {}
      if(f === 1){
        this.pageList.page = 1
      }
      params.rows = this.pageList.rows
      params.page = this.pageList.page
      params.entity = {
        plateNo: this.input4 || undefined
      } || undefined
      accGetPage(params).then((res) => {
        this.tableData = res.data
        this.pageList.count = res.count
      })
    },
    //编辑
    accEdit(row) {
      this.isAdd = 0
      this.accEditShow = true
      this.accRowData = row
      this.accForm.plateNo = row.plateNo
      this.accForm.accSwitch = row.accSwitch
    },
    //编辑新增确认
    accConfirm() {
      const method = this.isAdd === 1 ? accAdd : accUpdate
      method({
        plateNo: this.accForm.plateNo,
        accSwitch: this.accForm.accSwitch,
        id: this.accRowData.id,
        createTm: this.accRowData.createTm,
        modifyTm: this.accRowData.modifyTm
      }).then((res) => {
        if (res) {
          this.$message({
            message: res.data,
            type: "success",
          })
          this.accEditShow = false
          this.toAccOpen()
        }
      })
    },
    outAccOpenStatus(row) {
      accUpdate({
        plateNo: row.plateNo,
        accSwitch: row.accSwitch,
        id: row.id,
        createTm: row.createTm,
        modifyTm: row.modifyTm
      }).then((res) => {
        if (res) {
          this.$message({
            message: res.data,
            type: "success",
          })
          this.toAccOpen()
        }
      })
    },
    closeAccOpen() {
      this.accEditShow = false
    },
    //新增
    addAccOpening() {
      this.accEditShow = true
      this.accForm = {
        plateNo: "",
        accSwitch: 0
      }
    },
    //删除
    accDeleteing(row) {
      this.$confirm("确定要删除么？", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await accDelete(row.id);
        if (!res.code) {
          this.$message({
            message: res.data,
            type: "success",
          });
          this.toAccOpen()
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.popover {
  position: absolute;
  right: 0;
  top: 30px;
}
.box {
  background: linear-gradient(
    0deg,
    rgba(10, 28, 57, 1) 0%,
    rgba(10, 28, 57, 0.8) 95%
  );
  position: relative;
  z-index: 10;
  border: 1px solid #17fff5;
}
.box_img {
  transform: rotate(180deg) translate(-30px, 9px);
  position: absolute;
}
.box_li {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
.box_li:hover {
  color: #17f1ec;
  transform: scale(1.1);
  background: rgba(255, 255, 255, 0.2);
}
.warning {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  i {
    font-size: 24px;
    color: red;
    margin-left: 12px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    color: red;
  }
}
.warningAffirm {
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  i {
    font-size: 36px;
    color: red;
    margin-left: 12px;
    margin-right: 8px;
  }
  span {
    font-size: 22px;
    color: red;
  }
}
.allContent {
  display: flex;
  flex-direction: column;
  .headTop {
    display: flex;
    justify-content: space-between;
  }
  .accTable {
    margin-top: 10px;
    .accOpen {
      ::v-deep {
        .el-table th,
        .el-table tr {
          background-color: #fff !important;
          color: rgba(0, 0, 0, 0.65);
        }
        .el-table__empty-block,
        .el-table__body-wrapper {
          background-color: #fff !important;
        }
        .el-table th.is-leaf {
          border-bottom: 1px solid rgba(233, 233, 233, 1) !important;
        }
        .el-table__body tr:hover > td {
          background-color: rgb(226, 226, 226) !important;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          color: #fff !important;
          background-color: #1890ff !important;
          border: 1px solid #d9d9d9;
        }
        .el-pagination.is-background .el-pager li {
          background-color: transparent;
          color: rgba(0, 0, 0, 0.65) !important;
          border: 1px solid #d9d9d9;
        }
        .el-pagination.is-background .el-pager li:hover,
        .el-table__body tr.hover-row > td {
          background-color: #fff !important;
        }
        .el-pagination.is-background .btn-prev,
        .el-pagination.is-background .btn-next {
          background-color: transparent;
          color: #d9d9d9;
        }
        .el-pagination__total {
          color: rgba(0, 0, 0, 0.65) !important;
        }
        .el-input__inner,
        .el-pagination__jump {
          color: rgba(0, 0, 0, 0.65) !important;
          // border: none;
          border-radius: 1px;
          position: relative;
          padding: 0 5px !important;
        }
        .el-switch__core {
          width: 42px !important;
          height: 20px !important;
        }
      }
    }
  }
}
.passwedText {
  -webkit-text-security: disc;
}
::v-deep .el-dialog__body {
  padding: 30px 55px;
}

::v-deep .el-input__inner {
  border: 1px solid #dcdfe6;
}
::v-deep .el-input__inner {
  padding-right: 24px;
  &::placeholder {
    font-size: 12px;
  }
}
::v-deep .el-form-item__content {
  position: relative;
  .visib {
    position: absolute;
    right: 8px;
    top: 14px;
    &:hover {
      color: blue;
      cursor: pointer;
    }
  }
}
.left-top {
  margin-bottom: 25px;
  display: flex;
  .btn{
      height: 32PX;
      padding: 3PX 25PX;
      color: #fff;
       font-weight: 300;
      &:hover{
        color: #17fff4 !important;
        // font-weight: 500;
      }
    }
   ::v-deep .el-cascader {
    height: 32PX !important;
    line-height: 30PX !important;
    font-size: 14PX;
    margin-right: 12PX;
  }
  ::v-deep .el-input__inner {
  border: 1px solid #87d2ff;
}
  ::v-deep .el-input--suffix {
     height: 32PX !important;
    font-size: 14PX;
  }
  ::v-deep .el-input__suffix{
    height: 32PX !important;
    font-size: 14PX;
  }
}
</style>
<style lang="scss">
.paginaClass {
  background: #fff !important;
  border: none;
  .selected {
    color: #409eff !important;
  }
  .el-select-dropdown__item {
    color: #606266;
  }
}
</style>
