<template>
  <div>
    <Charts-border borderTitle="订单全过程" :option="option">
      <div slot="leftBottom">
        <span @click="openDetails">查看详情</span>
      </div>
    </Charts-border>
    <Details
      ref="orderDetails"
      v-if="showDetails"
      :dialogTitle="'订单全过程监控详情'"
      :stripe="false"
      :loading="false"
      :columns="columns"
      :tableData="tableData"
      :pageList="pageList"
      :textMap="textMap"
      :getDetailsPage="getDetailsPage"
      @exportTion="exportTion"
      @close:closeDetails="closeDetails"
    >
    </Details>
  </div>
</template>
<script>
import ChartsBorder from "../viewsComponents/ChartsBorder";
import watchMonth from "@/mixins/watchMonth";
import { format, formatDate } from "@/utils";
import Details from "../viewsComponents/Details/FDetails.vue";
import { mapState } from "vuex";
import { exportProcess } from "@/api/home";
import bus from "@/utils/bus";

export default {
  name: "orderAll",
  mixins: [watchMonth],
  components: {
    ChartsBorder,
    Details
  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'
          },
          formatter: params => {
            let dataArr = params[0].data;
            return `${dataArr[0]
              } <br/> <span style="font-weight: bold;">${format(
                dataArr[1]
              )}</span> 单`;
          }
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "8%",
          bottom: "13%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)"
            }
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)" // 分割线颜色
            }
          }
        },
        // yAxis: {
        //   type: "category",
        //   // data: ["已签收", "配送中", "已出库", "采购中", "接单中"],
        //   axisLabel: {
        //     color: "rgba(255,255,255,0.8)"
        //   }
        // },
        yAxis: {
          // name:"伙食单位",
          type: "category", // 不设置类目轴，抽离的dataset数据展示不出来
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.5)",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "已完成",
            type: "bar",
            color: "rgba(0,186,255,1)",
            stack: "total",
            label: {
              show: true,
              position: "center",
              offset: [10, 0],
              color: "#fff",
              style: {
                fill: "#fff"
              },
              formatter: params => {
                return format(params.data[1]);
              }
            },
            emphasis: {
              focus: "series"
            },
            // data: [121, 828, 144, 368, 390],
            barWidth: 12,
            itemStyle: {
              label: {
                show: true
              },
              labelLine: {
                show: false
              },
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "rgba(23,241,236,0)" },
                { offset: 1, color: "rgba(23,241,236,1)" }
              ]),
              borderColor: "#99E3FF",
              shadowBlur: 16,
              shadowColor: "rgba(0,186,255,1)"
            }
          }
        ]
      },
      params: {
        type: 1
      },
      showDetails: false,
      tableData: [],
      columns: [
        {
          label: "订单编号",
          prop: "orderNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100
        },
        {
          label: "下单时间",
          prop: "orderDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          type: "time",
          form: true,
          width: 180
        },
        {
          label: "订单金额(元)",
          prop: "soAmt",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          align: 'right',
          width: 100
        },
        {
          label: "状态",
          prop: "status",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          type: "select",
          form: true,
          options: [
            { name: "待审核", value: "00" },
            { name: "已审核", value: "10" },
            { name: "入库中", value: "20" },
            { name: "待出库", value: "30" },
            { name: "已出库", value: "40" },
            { name: "已签收", value: "50" },
            { name: "部分退货", value: "60" },
          ]
        },
        {
          label: "灶点编号",
          prop: "focalPointNo",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true
        },
        /*{
          label: "灶点名称",
          prop: "focalPointName",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true
        },*/
        {
          label: "配送日期",
          prop: "deliveryDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          type: "time",
          form: true,
          width: 130
        },
        {
          label: "一级单位编码",
          prop: "focalName1",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: false,
          width: 130
        },
        {
          label: "二级单位编码",
          prop: "focalName2",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: false,
          width: 130
        },
        {
          label: "三级单位编码",
          prop: "focalName3",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: false,
          width: 130
        },
        {
          label: "商品重量(斤)",
          prop: "tWeight",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          align: 'right',
          width: 100
        }
      ],
      pageList: {
        page: 1,
        rows: 20,
        count: 0
      },
      sysNo: 0,
      textMap: {
        ["00"]: "待审核",
        ["10"]: "已审核",
        ["20"]: "入库中",
        ["30"]: "待出库",
        ["40"]: "已出库",
        ["50"]: "已签收",
        ["60"]: "部分退货",
        ["-1"]: "已作废",
        ["0"]: "未处理",
        ["1"]: "已处理",
        ["2"]: "待处理",
        ["3"]: "处理中",
        ["4"]: "已处理",
        ["5"]: "待发还",
        ["6"]: "RMA完成",
        ["7"]: "财务部已审核",
        ["8"]: "商务部已审核"
      },
      colorMap: {
        0: "#63af12",
        1: "#02a7f0",
        2: "#f59a23",
        3: "#b8741a",
        4: "#c280ff",
        5: "#c9caca",
        6: "#ff4949"
      }
    };
  },
  computed: {
    ...mapState('global', ['month','dateModel'])
  },
  created() {
    this.timer = setInterval(() => {
      this.getData();
    }, 1000 * 60 * 5);
  },
  mounted(){
    bus.$on('confirmRejection',this.handleConfirm)
  },
  methods: {
    handleConfirm(v){
      this.showDetails = true
      this.$nextTick(()=>{
        this.$refs.orderDetails.rejection(v)
       })
    },
    async getData(month) {
      let res = await this.$getData({
        // type: "orderStatusMonitor",
        // type: "orderStatusMonitorNew",
        // date: month
        type: "orderOnProcess",
        day: this.dateModel.replace(/-/g, ''),
        warehouseCode: this.warehouseCode
      });
      let arrData = [];
      if (res && res.data) {
        res.data.forEach(item => {
          let { key, value } = item;
          if(!['已签收'].includes(key)){
            let arr = ([value, key] = [key, value]);
            arrData.unshift(arr);
          }
        });
        this.option = {
          dataset: {
            source: arrData
          }
        };
      }
    },
    openDetails() {
      this.showDetails = true;
       this.pageList.page = 1
       this.$nextTick(()=>{
         this.$refs.orderDetails.allOrderBtn(1)
       })
      // this.getDetailsPage();
    },
    getDetailsPage(entitys, searchButton = 0) {
      //查询 0
      //重置 0
      //换页 2
      //换条 1
      if (searchButton == 0) {
        this.pageList.page = 1
        this.pageList.rows = 20
      }else if (searchButton == 1){
         this.pageList.page = 1
      }
      this.$nextTick(async () => {
        let params = Object.assign(
          {
            // type: "orderStatusMonitorDetail",
            type: "orderStatusMonitorDetailNew",
            date: formatDate(this.month),
            page: this.pageList.page,
            rows: this.pageList.rows
            // organizationDebug: 1
          },
          this.$refs && this.$refs.orderDetails.getEntitys().params
        );
        let res = await this.$getData(params);
        this.tableData = res.data;
        this.pageList.count = res.count;
      });
    },
    exportTion(){
      const mothod = exportProcess
       let params = Object.assign(
          {
            // type: "orderStatusMonitorDetail",
            date: formatDate(this.month),
          },
          this.$refs && this.$refs.orderDetails.getEntitys().params
        );
      mothod(
        params
      ).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(blob)
        link.download = '订单全过程详情导出.xlsx'
        document.body.appendChild(link)
        link.click()
        // 兼容火狐浏览器
        document.body.removeChild(link)
        // console.log(res)
      })
    },
    closeDetails() {
      this.showDetails = false;
    },
    // datePickerChange(value) {
    //   console.log(value);
    // },
    /**
     * 获取颜色
     */
    filterStatus(val) {
      return this.textMap[val];
    },
    beforeDestroy() {
      clearInterval(this.timer);
      this.timer = null
    }
  }
};
</script>
<style lang="scss" scoped></style>
