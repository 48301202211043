<template>
  <div class="dialog">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1284px"
      :append-to-body="true"
      custom-class="Dialog"
      top="calc((100vh - 750PX)/2)"
      @close="closeDialog">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <slot name="left-top"></slot>
          <div class="left-content">
            <Table
              ref="tableComponent"
              :columns="columns"
              :tableData="tableData"
              :total="pageList.count"
              :page.sync="pageList.page"
              :rows.sync="pageList.rows"
              :loading="loading"
              :stripe="stripe"
              :showOverflowTooltip="true"
              :align="'left'"
              :maxHeight="550"
              :indexWidth="70"
              :entitysShow="entitysShow"
              :searchShow="searchShow"
              @pagination="getDetailsPage">
              <template
                slot="columnEspecial"
                slot-scope="{ column, row }"
                resizable>
                <span v-if="column.prop === 'modifyTm'">{{
                  row[column.prop] | formatUTCTime
                }}</span>
                
                <span v-else-if="column.prop === 'isValid'">{{
                  row[column.prop] | isValid
                }}</span>
                <span v-else>{{ row[column.prop] }}</span>
              </template>

              <template slot="columnRight">
                <el-table-column
                  label="操作"
                  fixed="right"
                  width="160"
                  align="center">
                  <template slot-scope="{ row }">
                    <div ref="columnRight">
                      <el-button type="text" size="mini" @click="treated(row)" class="btnZoom">编辑</el-button>
                    </div>
                  </template>
                </el-table-column>
              </template>
            </Table>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="isAdd?'新增区域':'编辑区域'"
      width="500PX"
      height="264px"
      top="calc((100vh - 280PX)/2)"
      :append-to-body="true"
      custom-class="weatherRegion"
      :visible.sync="signForPictureDia">
        <div class="weatherRegionMain">
          <div class="regionCode">区域代码：{{areaCode?areaCode:'-'}}</div>
          <div class="regionForm">
            <div class="form-l" v-if="isAdd">
              <el-cascader
            v-model="regionValue"
            :options="regionOptions"
            @change="handleChange"></el-cascader>
            </div>
            <div class="form-l" v-else>
              {{regionValue|regionFiltr}}
            </div>
            <div class="form-r">
              是否有效：<el-switch
              :width="42"
              v-model="isValid"
              active-color="#00d6cd"
              inactive-color="#9b9b9b">
            </el-switch>
            <span style="margin-left:6px;">
              {{isValid?'是':'否'}}
            </span>
            </div>
          </div>
          <div class="first_box">
            是否显示在城市首位：<el-switch
              :width="42"
              v-model="isSort"
              active-color="#00d6cd"
              inactive-color="#9b9b9b">
            </el-switch>
            <span style="margin-left:6px;">
              {{isSort?'是':'否'}}
            </span>
          </div>
           <div class="popover-b">
              <div
                class="right-tab-item margin_r"
                @click="close"
              >
                取消
              </div>
              <div
                class="right-tab-item right-tab-active"
                @click="confirm"
              >
                确定
              </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { formatFloat,cityRecursion,cityCode } from "@/utils";
import cityJson from "@/assets/json/湖北省.json";
import { weatherAreaAdd,weatherAreaUpdate } from "@/api/weather";

export default {
  name: "detailsPopup",
  components: {
    Table
  },
  props: {
    entitysShow: {
      type: Array,
      default: function () {
        return []
      }
    },
    WarningProcessingProp: {
      default: function () {
        return {};
      }
    },
    searchShow: {
      type: Boolean
    },
    modal: {
      type: Boolean,
      default: true
    },
    pageList: {
      default: function () {
        return {};
      }
    },
    getDetailsPage: {
      type: Function
    },
    dialogTitle: {
      type: String
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    total: {
      type: Number,
      default() {
        return 0;
      }
    },
    page: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      default: 20
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    stripe: {
      type: Boolean,
      default: true
    }
  },
  watch:{
    // addWeather: {
    //   handler(val) {
    //       this.isAdd = true
    //       this.signForPictureDia =true
    //   },
    //   deep: true,
    //   immediate: true,
    // }
  },
  filters: {
    isValid(val) {
      if (val == 0) {
        return '否'
      } else {
        return '是'
      }
    },
    regionFiltr(val){
      let reg = val[0]+'/'+val[1]+'/'+val[2]
      return reg
    }
  },
  data() {
    return {
      updateId:null,
      areaCode:null, //区域代码
      isAdd:true,
      isValid:false,
      isSort:false,
      regionValue: [],
      regionOptions: [],
      focalPointName: '',
      alarmContent: '',
      dialogVisible: true,
      signForPictureDia: false,
      fullscreenLoading: false,
      signingPics: [],
      imgActiveIndex: 0,
      imageSrc: require('@/assets/image/noTitlePic.png'),
    };
  },
  mounted(){
    this.regionOptions = cityRecursion(cityJson)
  },
  methods: {
    handleChange(value) {
      this.areaCode =  cityCode(value[2],cityJson)
    },
    confirm(){
      if(!this.regionValue.length){
        this.$message({
              message: '请选择区域',
              type: 'warning'
            });
        return false
      }
      const methods = this.isAdd ? weatherAreaAdd:weatherAreaUpdate
      let obj ={
      province: this.regionValue[0],      //【必填】省
      city:this.regionValue[1],           //【必填】市
      county: this.regionValue[2],        //【必填】区域/县
      areaCode: this.areaCode,            //【必填】地区代码
      isValid: this.isValid?1:0,           //【必填】是否有效
      sort: this.isSort?0:999,           //【必填】是否排在首位
      }
      if(!this.isAdd){
        obj.id = this.updateId
      }
      methods(obj).then(res=>{
        if(res.code === 0){
           this.$message({
              message: res.data,
              type: 'success'
            });
            this.$emit('refreshWeather',this.isAdd)
          this.close()
        }else{
           this.$message({
              message: res.resmessages,
              type: 'error'
            });
        }
      })
    },
    close(){
      this.signForPictureDia = false
      this.regionValue = []
      this.areaCode = null
      this.updateId = null
      this.isValid = false
      this.isSort = false
    },
    handleImgClick(index) {
      this.imgActiveIndex = index;
      this.$refs.carousel.setActiveItem(index);
    },
    carouselChange(v) {
      this.imgActiveIndex = v
    },
    closeDialog() {
      this.$emit("clos:closeDetails");
    },
    FomatFloat(num, n = 2) {
      let m = formatFloat(num, n);
      let res = "";
      if (m && m.split(".")[1] === "00") {
        res = m.split(".")[0];
      } else {
        res = m;
      }
      return res;
    },
    treated(val) {
      this.areaCode = val.areaCode
      this.isValid = val.isValid?true:false
      this.isSort = val.sort === 0?true:false
      this.regionValue = [val.province,val.city,val.county]
      this.updateId = val.id
      this.isAdd = false
      this.signForPictureDia = true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.btnZoom{
  font-size: 14PX;
  padding: 4PX 6PX;
}

::v-deep .el-dialog__wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
}
::v-deep .el-table__body-wrapper {
  height: 510px !important;
}

::v-deep .el-dialog {
  // height: 75%;
  height: 750px;
  border: 2px solid #5473bf;
  background: linear-gradient(
    180deg,
    rgba(0, 15, 40, 0.5),
    rgba(0, 82, 218, 0.5) 100%
  );
}

//分页样式
::v-deep .el-pagination__total {
  color: rgba(255, 255, 255, 0.8);
}

.pagination-container {
  padding: 12px 0 0 0;

  ::v-deep .el-input__inner,
  ::v-deep .el-pagination__jump {
    color: rgba(255, 255, 255, 0.8);
    border: none;
    position: relative;
  }
}

// 下面是pagination
.pagination-container {
  background: transparent;
  padding: 32px 16px;
  display: flex;
  flex-direction: row-reverse;
}

.pagination-container.hidden {
  display: none;
}

::v-deep .el-pagination.is-background .btn-prev,
::v-deep .el-pagination.is-background .btn-next {
  background-color: transparent;
  color: #17fff4;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(62, 100, 172, 0.3);
  color: #17fff4;
  border: 1px solid #87d2ff;
}

::v-deep .el-pagination.is-background .el-pager li {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}

::v-deep .el-pagination.is-background .el-pager li:hover,
::v-deep .el-table__body tr.hover-row > td {
  background-color: rgba(62, 100, 172, 0.25);
}

::v-deep {
    .weatherRegion{
      // height: 280PX;
      height: auto;
    .el-dialog__header {
      //  height: 0 !important;
      //  padding: 0 !important;
      .el-dialog__title{
       color: #17F1EC; 
       font-size: 14PX;
      }
    }
    .el-dialog {
      height: inherit !important;
    }
    .el-dialog__body {
      padding: 16px !important;
    }
  }
}

.img-item {
  width: 32PX;
  height: 32PX;
  margin-right: 4PX;
}
.img-box {
  width: 32PX;
  height: 32PX;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    width: 16PX;
    height: 16PX;
  }
}
.weatherRegionMain{
  padding: 0 40PX;
  .regionCode{
    color: #FFFFFF;
    font-size: 14PX;
    height: 20PX;
    line-height: 20PX;
  }
  .regionForm{
    color: #FFFFFF;
    font-size: 14PX;
    margin-top: 20PX;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .form-r{
      display: flex;
      align-items: center;
    }
  }
  .first_box{
    color: #FFFFFF;
    font-size: 14PX;
    margin-top: 20PX;
    display: flex;
    align-items: center;
  }
}
.popover-b {
  display: flex;
  justify-content: flex-end;
  margin-top: 60PX;
}
.margin_r {
  margin-right: 10PX;
}
.right-tab-item {
  padding: 7PX 18PX;
  border: 1PX solid #87d2ff;
  cursor: pointer;
  color: #FFFFFF;
  backdrop-filter: blur(2PX);
}
</style>
