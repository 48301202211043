<template>
  <div class="home-wrap">
    <div v-if="isChoose" class="home-select">
      <img class="top-img" src="@/assets/login/headSplit_02.png" alt />
      <div class="main">
        <div class="border-horn-top"></div>
        <div class="border-horn-bottom"></div>
        <div class="title">请选择仓库</div>
        <div class="list">
          <div
            :class="['item',index === activeIndex ? 'active': '']"
            v-for="(item,index) in warehouseList"
            :key="index"
            @click="handleClickItem(item, index)"
          >{{item.warehouse}}</div>
        </div>
        <el-button class="btn" @click="enterScreen">进入大屏</el-button>
      </div>
      <img class="bottom-img" src="@/assets/login/bgimg.png" alt />
      <div class="lg-foot ft-12 ft-oppo">
        <span>湖北农业发展集团有限公司</span>
        <span><a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="color: #00D2C9">鄂公安网备:42011102004083号</a></span>
      </div>
    </div>
    <Screen v-else :warehouseList="warehouseList" :activeItem="activeItem"></Screen>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Screen from './layout/index.vue'
export default {
  name: 'home-select',
  components: {
    Screen
  },
  computed: {
    ...mapGetters(["global"]),
  },
  data() {
    return {
      isChoose: true,
      warehouseList: [
        {
          id: '0',
          warehouse: '湖北大屏',
          warehouseCode: '',
          warehouseName: ''
        }
      ],
      activeIndex: 0,
      activeItem: null
    }
  },
  mounted() {
    this.isChoose = localStorage.getItem('isChooseFlag') === 'false' ? false : true
    this.getWarehouse()
  },
  methods: {
    async getWarehouse() {
      const res = await this.$getData({ type: 'warehouse' })
      if (res.code !== 0) return
      this.warehouseList = [...this.warehouseList, ...res.data]
      this.activeItem = this.global.warehouseCode ? this.warehouseList.find(item => item.warehouseCode === this.global.warehouseCode) : this.warehouseList[0]
    },
    enterScreen() {
      this.isChoose = false
      localStorage.setItem('isChooseFlag', this.isChoose)
      this.$store.dispatch("global/changeGlobal", { key: "warehouseCode", value: this.activeItem.warehouseCode });
    },
    handleClickItem(item, i) {
      // console.log(item, 'handleClickItem')
      this.activeIndex = i
      this.activeItem = item
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
@mixin border-horn-top {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  top: -2px;
  border-top: 2px solid #17f1ec;
}
@mixin border-horn-bottom {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: -2px;
  border-bottom: 2px solid #17f1ec;
}

.home-wrap {
  height: 100%;
  .home-select {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    background: url('~@/assets/login/indexbg.jpg') no-repeat center center;
    background-size: 100% 100%;
    .top-img,
    .bottom-img {
      width: 100%;
    }
    .top-img {
      position: absolute;
      top: 0;
      left: 0;
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .main {
      position: relative;
      padding: 40px 30px;
      width: 642px;
      background: linear-gradient(
        180deg,
        rgba(0, 76, 255, 0.02),
        rgba(0, 95, 255, 0.3) 100%
      );
      border: 2px solid #5473bf;
      backdrop-filter: blur(10px);
      .border-horn-top {
        &::before {
          @include border-horn-top;
          left: -2px;
          border-left: 2px solid #17f1ec;
        }

        &::after {
          @include border-horn-top;
          right: -2px;
          border-right: 2px solid #17f1ec;
        }
      }
      .border-horn-bottom {
        &::before {
          @include border-horn-bottom;
          left: -2px;
          border-left: 2px solid #17f1ec;
        }

        &::after {
          @include border-horn-bottom;
          right: -2px;
          border-right: 2px solid #17f1ec;
        }
      }
      .title {
        font-size: 24px;
        color: #fff;
        font-weight: 500;
        line-height: 33px;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          margin-top: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 176px;
          height: 56px;
          background: rgba(62, 100, 172, 0.3);
          // background: rgb(143 145 155 / 80%);
          border: 1px solid #87d2ff;
          cursor: pointer;
          // cursor: not-allowed;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &.active {
            cursor: pointer;
            background: linear-gradient(
              0deg,
              #5f97ff 0%,
              rgba(95, 151, 255, 0)
            );
            border: 2px solid #17fff4;
            box-shadow: 0px 0px 10px 0px #18fff4;
          }
        }
      }
      .btn {
        margin-top: 40px;
        width: 100%;
        height: 56px;
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        background: #00d2c9;
        backdrop-filter: blur(2px);
        border-color: #00d2c9;
        &:hover {
          background: #63e9e3;
        }
      }
    }
  }
}

.lg-foot {
  width: 100%;
  position: fixed;
  bottom: 1vw;
  display: flex;
  justify-content: center;

  span {
    margin-right: 5px;
  }
}
</style>