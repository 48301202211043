<template>
  <div class="dialog">
    <el-dialog
      :title="WarningProcessingProp.title"
      :visible.sync="WarningProcessingProp.showDetails"
      width="600PX"
      :modal="false"
      :append-to-body="true"
      @close="closeDialog"
      top="calc((100vh - 400PX)/2)"
    >
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <slot name="left-top"></slot>
          <div class="left-content">
            <div class="input-box">
              <el-input
                maxlength="1000"
                type="textarea"
                placeholder="请输入内容"
                :rows="11"
                v-model="textarea"
              >
              </el-input>
              <div class="inputLen"><span class="dynamic">{{inputLength}}</span>/<span>1000</span></div>
            </div>
            <div class="popover-b">
              <div
                class="right-tab-item margin_r"
                :class="!isConfirm ? 'right-tab-active' : ''"
                @click="close"
              >
                取消
              </div>
              <div
                class="right-tab-item"
                :class="isConfirm ? 'right-tab-active' : ''"
                @click="confirm"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "detailsPopup",
  props: {
    WarningProcessingProp: {
      default: function() {
        return {};
      }
    }
  },
  watch: {
    textarea: {
      handler(val) {
        this.inputLength = val.length;
      },
      deep: true
    }
  },
  data() {
    return {
      textarea: "",
      isConfirm: true,
      inputLength:0
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close:closeDetails");
    },
    close() {
      this.WarningProcessingProp.showDetails = false;
    },
    confirm() {
      this.isConfirm =true;
      if (this.textarea) {
        this.$emit('setAlarmInfoHandle', this.textarea)
        this.close();
      } else {
        this.$message({
          message: '请填写处理信息！',
          center: true
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.dialog-content {
    .left {
      padding: 10PX 0 0 0;
    }

    .right {
      padding: 66PX 0 0 0;
    }

    .el-table {
      color: rgba(255, 255, 255, 0.8);
    }

    .el-table::before {
      height: 0;
    }
  }

  ::v-deep {
    .el-textarea__inner{
      padding: 6PX 8PX;
      font-size: 14PX;
    }
  }

::v-deep .el-table thead {
  color: #fff;
}
.el-dialog__wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
}

::v-deep .el-dialog {
  height: 400PX !important;
}
.el-dialog__body {
  height: 85%;
}

.popover {
  padding: 15PX;
}
.input-box{
  position: relative;
  .inputLen{
    position: absolute;
    right: 16PX;
    bottom: 12PX;
    margin-top: 12PX;
    font-size: 12PX;
    color: rgba(255,255,255,0.7);
    .dynamic{
      color: #17F1EC;
    }
  }
}
.popover-b {
  display: flex;
  justify-content: flex-end;
  margin-top: 24PX;
}
.margin_r {
  margin-right: 10PX;
}
.right-tab-item {
  padding: 7PX 18PX;
  border: 1PX solid #87d2ff;
  cursor: pointer;
  backdrop-filter: blur(2PX);
}
.popover-t {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24PX;
}
.popover-t-r {
  color: #ffffff;
}
.popover-t-l {
  margin-right: 12PX;
  position: relative;
  top: 3PX;
}
::v-deep .el-textarea__inner::-webkit-scrollbar {
  width: 10PX;
}
// 设置滚动条的背景色和圆角
::v-deep .el-textarea__inner::-webkit-scrollbar-thumb  {
  background-color: rgba(62,100,172,0.3);
}
::v-deep .el-textarea__inner::-webkit-resizer {
  // background-color: rgba(62,100,172,0.3);
  background: #061631;
}
::v-deep .el-textarea__inner::-webkit-scrollbar-corner {
  background-color: rgba(62,100,172,0.3);
}
</style>
