<template>
  <Dialog
    v-bind="$attrs" v-on="$listeners">
    <div class="content">
      <div class="right-tab-box" style="background: #132F62;">
        <div
          class="right-tab-item"
          :class="goodsIndex === '' ? 'right-tab-active' : ''"
          @click="goodsIndex = ''">
          全部({{ goodsTotalQty }})
        </div>
         <div
          class="right-tab-item"
          :class="goodsIndex === 40 ? 'right-tab-active' : ''"
          @click="goodsIndex = 40">
          未入库({{ goodsTotalNotStockIn }})
        </div>
        <div
          class="right-tab-item"
          :class="goodsIndex === 50 ? 'right-tab-active' : ''"
          @click="goodsIndex = 50">
          已入库({{ goodsTotalRecQty }})
        </div>
          <div
          class="right-tab-item"
          :class="goodsIndex === 20 ? 'right-tab-active' : ''"
          @click="goodsIndex = 20">
          已上架({{ goodsTotalOnTheShelf }})
        </div>
        <div
          class="right-tab-item"
          :class="goodsIndex === 10 ? 'right-tab-active' : ''"
          @click="goodsIndex = 10">
          已分拣({{ goodsTotalHasSorted }})
        </div>
        <div
          class="right-tab-item"
          :class="goodsIndex === 30 ? 'right-tab-active' : ''"
          @click="goodsIndex = 30">
          已出库({{ goodsTotalHasStockOut }})
        </div>
      </div>
      <!-- <div class="right-tab-box">
        <div class="right-tab-item" :class="goodsIndex === 2 ? 'right-tab-active':''" @click="goodsIndex = 2">
          全部({{goodsTotalQty}})
        </div>
        <div class="right-tab-item" :class="goodsIndex === 0 ? 'right-tab-active':''" @click="goodsIndex = 0">
          入库中({{goodsDiffQty}})
        </div>
        <div class="right-tab-item" :class="goodsIndex === 1 ? 'right-tab-active':''" @click="goodsIndex = 1">
          已完成({{ goodsTotalRecQty}})
        </div>
      </div> -->
      <el-table-admin-page
        class="table"
        ref="elTableAdminPage"
        :search-show="false"
        :columns="columns"
        :table-data="tableDataOther"
        v-bind.sync="pages"
        @size-change="sizeChangeTableDetail"
        @current-change="currentChangeTableDetail"
        @close:closeDetails="closeDetails"
        :paginationShow="paginationShow2"
        highlight-current-row
        :height="545">
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <span v-if="column.prop === 'recQty'">{{
                      row[column.prop] | Format
                      }}</span>
          <span v-else-if="column.prop === 'qty'">{{
                      row[column.prop] | Format
                      }}</span>
          <span v-else>{{ row[column.prop] }}</span>
        </template>

      </el-table-admin-page>
    </div>
  </Dialog>
</template>
 <script>

import Dialog from "@/components/Dialog/index.vue";

export default {
  name: 'goodsDetails',
  components: {
    Dialog,
  },
  props: {
    tableDataOther: {
      type: Array,
      default: function () {
        return []
      }
    },
    pages: {
      type: Object
    },
    goodsTotalQty: {
      type: Number
    },
    goodsTotalRecQty: {
      type: Number
    },
    goodsDiffQty: {
      type: Number
    },
    goodsTotalHasSorted: {
      type: Number
    },
    goodsTotalOnTheShelf: {
      type: Number
    },
    goodsTotalNotStockIn: {
      type: Number
    },
    goodsTotalHasStockOut: {
      type: Number
    },
  },
  watch: {
    goodsIndex(val) {
       if(val === ''){
        this.columns[2].label = '已入库重量(斤)'
      }else if(val === 40){
        this.columns[2].label = '未入库重量(斤)'
      }else if(val === 50){
        this.columns[2].label = '已入库重量(斤)'
      }else if(val === 20){
        this.columns[2].label = '已上架重量(斤)'
      }else if(val === 10){
        this.columns[2].label = '已分拣重量(斤)'
      }else if(val === 30){
        this.columns[2].label = '已出库重量(斤)'
      }
      this.$emit('change-event', -1, 1, val)
    }
  },
  data() {
    return {
      paginationShow2: true,
      goodsIndex: '',
      columns: [
        {
          label: "灶点编码",
          prop: "stationCode",
          align: "center",
        },
        {
          label: "汇总单号",
          // prop: "logisticNo",
          prop: "orderNo",
          align: "center",
          width: 250
        },
        {
          label: "已入库重量(斤)",
          prop: "recQty",
          align: "right",
          headerAlign: 'right'
        },
        {
          label: "预计入库重量(斤)",
          prop: "qty",
          align: "right",
          headerAlign: 'right'
        },
      ]
    }
  },
  methods: {
    closeDetails() {
    },
    sizeChangeTableDetail(value) {
      if (typeof type === "number") {
        this.pages.pageSize = value
      }
      this.$emit('get-size', value, '1')
    },
    currentChangeTableDetail(value) {
      if (typeof type === "number") {
        this.pages.currentPage = value
      }
      this.$emit('get-size', value, '2')
    },
  }
}
 </script>

 <style lang="scss" scoped>
.content {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  .right-tab-box {
    display: flex;
    .right-tab-item {
      padding: 7px 12px;
      border: 1px solid #87d2ff;
      cursor: pointer;
      backdrop-filter: blur(2px);
    }
  }
  .table {
    padding-top: 16px;
  }
  ::v-deep {
    .el-dialog {
      width: 700px !important;
    }
  }
}
::v-deep {
  .el-dialog__body {
    // height: 700px !important;
  }
}
</style>