<template>
  <div class="border-box">
    <div class="border-border-container">
      <div class="right-top"></div>
      <div class="right-bottom"></div>
      <div class="left-bottom"></div>
      <div class="title" :style="{ width: titleBgWidth }">{{ borderTitle }}</div>
      <div class="border-box-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BorderBox",
  props: {
    borderTitle: {
      type: String,
    },
    titleBgWidth: {
      type: String,
      default: '180px'
    },
  },
};
</script>

<style lang="scss" scoped>
.border-box {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  z-index: 10;
  .border-border-container {
    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 76, 255, 0.02),
    //   rgba(0, 95, 255, 0.3) 100%
    // );
    background: linear-gradient(180deg,rgba(0,15,40,0.50), rgba(0,82,218,0.50) 100%);
    border: 1px solid #5473bf;
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 10px;
    left: 10px;
    .right-top {
      position: absolute;
      right: -1px;
      top: -1px;
      border-top: 2px solid rgba(23, 241, 236, 1);
      border-right: 2px solid rgba(23, 241, 236, 1);
      width: 15px;
      height: 15px;
    }
    .right-bottom {
      position: absolute;
      right: -1px;
      bottom: -1px;
      border-bottom: 2px solid rgba(23, 241, 236, 1);
      border-right: 2px solid rgba(23, 241, 236, 1);
      width: 15px;
      height: 15px;
    }
    .left-bottom {
      position: absolute;
      left: -1px;
      bottom: -1px;
      border-bottom: 2px solid rgba(23, 241, 236, 1);
      border-left: 2px solid rgba(23, 241, 236, 1);
      width: 15px;
      height: 15px;
    }
    .title {
      position: absolute;
      top: 0;
      left: 0;
      height: 48px;
      background: url("~@/assets/image/border-box-14-title.png") no-repeat;
      background-size: 100% 100%;
      padding-left: 30px;
      line-height: 41px;
    }
  }
  .border-box-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
