<template>
  <div class="monitor">
    <div class="monitor-left">
      <vehiclePosition :vehicle="vehicle"></vehiclePosition>
      
    </div>
    <div class="monitor-right" style="margin-left: 10px;">
      <vehicleVideo  :vehicle="vehicle"></vehicleVideo>
    </div>
  </div>
</template>
<script>
import vehiclePosition from "./vehiclePosition.vue"
import vehicleVideo from "./vehicleVideo.vue"


export default {
  name: "monitor",
  components: {
    vehiclePosition,
    vehicleVideo
  },
  data() {
    return {};
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.monitor {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .monitor-left,
  .monitor-right {
    background: rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    height: 100%;
  }
  .monitor-right {
    overflow: hidden;
    .monitor-right-content {
      height: 100%;
      .monitor-right-Carousel{
        height: 100%;
        ::v-deep {
          .el-carousel__container{
            height: 100%;
          }
          }
      }
      video {
        width: calc(50% - 10px);
        height: calc(50% - 10px);
        margin: 5px;
        box-sizing: border-box;
        object-fit:fill;
      }
    }
  }
}
</style>
