<template>
  <RegionDialog
    custom-class="region_dialog"
    v-if="showRegionDialog"
    :title="title"
    :show.sync="showRegionDialog"
    :dialog-width="'1384PX'"
    :margin-top="`calc((100vh - 730Px) / 2)`"
    :append-to-body="true"
    @clearValue="beforeCloseFnc"
    @close:closeDetails="handleCloseFnc"
  >
    <div class="region_wrap">
      <div class="region_left">
        <div class="left_item">
          <div class="text">灶点统计数</div>
          <div class="value">{{pageList.count}}</div>
        </div>
        <div class="left_item">
          <div class="text">时间</div>
          <div class="value">{{getShowTime}}</div>
        </div>
      </div>
      <div class="region_right">
        <Table
          :tableData="tableData"
          :columns="columns"
          :total="pageList.count"
          :page.sync="pageList.page"
          :rows.sync="pageList.rows"
          :loading="false"
          :indexShow="false"
          :stripe="false"
          :searchShow="false"
          tableHeight="560"
          @pagination="getTableData"
        >
          <template #searchBottom>
            <div class="right_header">
              <div class="h_title">区域灶点日数据统计</div>
              <div class="h_search_wrap">
                <el-select
                  class="s_unit"
                  v-model="unitValue"
                  clearable
                  placeholder="请选择"
                  @change="changeUnitValue"
                >
                  <el-option
                    v-for="item in unitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <el-input
                  class="s_input"
                  placeholder="请输入内容"
                  v-model="inputVal"
                  clearable
                  @change="changeInputVal"
                ></el-input>
                <el-date-picker
                  class="month-icon-style"
                  v-model="monthValue"
                  value-format="yyyyMM"
                  type="month"
                  placeholder="选择月"
                  :picker-options="monthPickerOptions"
                  @change="changeInputVal"
                ></el-date-picker>
              </div>
            </div>
          </template>
          <template v-slot:columnEspecial="{ column, row }">
            <div
              v-if="['orderAmountHb','orderQuantityHb'].includes(column.prop)"
            >{{ formatFloat(row[column.prop], 2) + "%" }}</div>
            <div v-else-if="['orderAmount'].includes(column.prop)">{{ row[column.prop] | Format}}</div>
            <div v-else-if="['customerAccount'].includes(column.prop)">{{ '*****' }}</div>
            <div v-else-if="['orderQuantity','skuQuantity'].includes(column.prop)">{{ row[column.prop] | Format(0)}}</div>
            <div v-else>{{ `${row[column.prop] || ''}` }}</div>
          </template>
        </Table>
      </div>
    </div>
  </RegionDialog>
</template>

<script>
import RegionDialog from '@/components/Dialog/index.vue'
import Table from '@/components/Table/index.vue'
import { formatFloat } from '@/utils'
export default {
  name: 'RegionFocalPoint',
  components: {
    RegionDialog,
    Table
  },
  props: {
    title: {
      type: String,
      require: true
    },
    isShowDialog: {
      type: Boolean,
      default: false
    },
    zdTotal: {
      type: Number,
      default: 0
    },
    timeVal: {
      type: String,
      default: new Date()
    },
    divisionCode: {
      tyep: String,
      default: ''
    }
  },
  data() {
    return {
      showRegionDialog: false,
      tableData: [
        {
          zaodian: 'ZQ32-灶点',
          kehu: 'ZQ32-0005',
          brdds: '5',
          sku: '80',
          xsjehb: '43%',
          ddshb: '31.87%',
          xsje: '72849.03'
        },
        {
          zaodian: 'ZQ33-灶点',
          kehu: 'ZQ33-0006',
          brdds: '6',
          sku: '82',
          xsjehb: '41%',
          ddshb: '33.87%',
          xsje: '73849.03'
        },
        {
          zaodian: 'ZQ36-灶点',
          kehu: 'ZQ36-0008',
          brdds: '7',
          sku: '86',
          xsjehb: '46%',
          ddshb: '36.87%',
          xsje: '73869.03'
        }
      ],
      columns: [
        /*{
          label: '灶点名称',
          prop: 'focalPointName'
        },*/
        {
          label: '客户账号编码',
          prop: 'customerAccount'
        },
        {
          label: '本日订单数',
          prop: 'orderQuantity'
        },
        {
          label: '本日SKU数',
          prop: 'skuQuantity'
        },
        {
          label: '销售金额环比',
          prop: 'orderAmountHb'
        },
        {
          label: '订单数环比',
          prop: 'orderQuantityHb'
        },
        {
          label: '销售金额',
          prop: 'orderAmount'
        }
      ],
      pageList: {
        page: 1,
        rows: 20,
        count: 0
      },
      monthValue: '',
      demoMonth: '',
      inputVal: '',
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date('2000-1-1 00:00:00').getTime()
          )
        }
      },
      unitValue: '',
      unitOptions: [
        {
          value: 'customerAccount',
          label: '客户账号编码'
        },
        /*{
          value: 'focalPointName',
          label: '灶点名称'
        }*/
      ]
    }
  },
  computed: {
    getShowTime() {
      if(this.demoMonth){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.columns[2].label = '本月订单数'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.columns[3].label = '本月SKU数'
        let arr = this.demoMonth.split('')
        arr.splice(4,0,'-')
        arr = arr.join('')
        return arr
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.columns[2].label = '本日订单数'
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.columns[3].label = '本日SKU数'
      return this.timeVal
    }
  },
  watch: {
    isShowDialog(newValue) {
      this.showRegionDialog = newValue
      newValue && this.getTableData()
    }
  },
  methods: {
    changeInputVal() {
      if (!this.unitValue && this.inputVal) {
        return this.$message({
          message: '请选择下拉搜索项',
          type: 'warning'
        })
      }
      this.pageList.page = 1
      this.getTableData()
    },
    formatFloat(num, n = 2) {
      return formatFloat(num, n)
    },
    changeUnitValue(option) {
      if (!option) {
        this.inputVal = ''
        this.pageList.page = 1
        this.getTableData()
      }
      if (this.inputVal) {
        this.pageList.page = 1
        this.getTableData()
      }
    },
    async getTableData(arg1,arg2) {
      if(arg2 === 1){
        this.pageList.page = 1
      }
      let paramsObj = {
        type: 'regionFocalPointDetail',
        day: this.timeVal.replace(/-/g, ''),
        month: this.demoMonth,
        divisionCode: this.divisionCode + '',
        // focalPointName: this.inputVal,
        //"customerAccount": "0", // 客户账号
        page: this.pageList.page,
        rows: this.pageList.rows
      }
      if (this.unitValue) {
        paramsObj[this.unitValue] = this.inputVal
      }
      const res = await this.$getData(paramsObj)
      console.log(res, 'getTableData')
      if (res.code !== 0) return
      this.tableData = res.data.data
      this.pageList.count = res.data.count
    },
    resetData(){
      this.unitValue = ''
      this.inputVal = ''
      this.monthValue = ''
    },
    beforeCloseFnc() {
      this.resetData()
      this.$emit('update:isShowDialog', false)
    },
    handleCloseFnc() {
      this.$emit('update:isShowDialog', false)
    }
  }
}
</script>

<style lang="scss">
.region_dialog {
  .dialog-content {
    height: 100%;
    padding-right: 20px !important;
  }
  .region_wrap {
    padding-top: 16px;
    display: flex;
    height: 100%;
    .region_left {
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      .left_item {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .text {
          margin-bottom: 24px;
        }
      }
    }
    .region_right {
      flex: 7;
      .right_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .h_search_wrap {
          display: flex;
          .s_unit,
          .month-icon-style {
            width: 160px;
          }
          .s_input {
            width: 160px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
</style>