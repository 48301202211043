<template>
  <div class="dialog">
    <el-dialog
      v-bind="$attrs"
      v-on="$listeners"
      :visible.sync="show"
      :before-close="handleClose"
      :show-close="false"
      :close-on-click-modal="true"
      @close="closeDetails"
      :width="dialogWidth"
      :top="marginTop"
      :destroy-on-close="true">
      <div class="right-top"></div>
      <div class="right-bottom"></div>
      <div class="left-bottom"></div>
      <div class="dialog-header">
        <div class="dialog-header-content">
          <div class="dialog-header-left">
            <div
              class="dialog-header-left-content"
              :style="{ width: titleBgWidth }">
              {{ title }}
            </div>
          </div>
          <div class="dialog-header-right">
            <i class="el el-icon-close" @click="handleClose"></i>
          </div>
        </div>
      </div>
      <div class="dialog-content">
        <slot />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    marginTop:{
      type: String,
      default: "calc((100vh - 720Px) / 2)",
    },
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    dialogWidth: {
      type:String,
      default: "70%",
    },
  },
  computed: {
    titleBgWidth() {
      let flexWidth = 0;
      for (const char of this.title) {
        if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
          // 如果是英文字符，为字符分配8个单位宽度
          flexWidth += 8;
        } else if (char >= "\u4e00" && char <= "\u9fa5") {
          // 如果是中文字符，为字符分配22个单位宽度
          flexWidth += 22;
        } else {
          // 其他种类字符，为字符分配5个单位宽度
          flexWidth += 5;
        }
      }
      return flexWidth < 170 ? "170Px" : flexWidth + "Px";
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("clearValue", false);
      this.$emit("update:show", false);
      // this.$parent.handleCloses();
    },
    closeDetails() {
      this.$emit('close:closeDetails');
    }
  },
};
</script>
<style lang="scss" scoped>
.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.9 );
  overflow: hidden;
}
.dialog-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .dialog-header-content {
    display: flex;
    justify-content: space-between;
    .dialog-header-left {
      background: url("~@/assets/image/titlebg.png") no-repeat;
      background-size: 100% 100%;
      height: 48Px;
      line-height: 45Px;
      .dialog-header-left-content {
        padding-left: 20Px;
        margin-top: -3Px;
      }
    }
    .dialog-header-right {
      padding: 19Px 19Px;
      font-size: 18Px;
      cursor: pointer;
      color: #17f1ec;
    }
  }
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-corner {
  background-color: rgba(62, 100, 172, 0.3);
}
::v-deep {
  .el-dialog {
    width: 70%;
    // margin-top: calc((100vh - 720Px) / 2) !important;
    background: inherit;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 730Px;
      overflow: auto;
      color: white;
      border: 2Px solid #5473bf;
      opacity: 1;
      background: linear-gradient(
        180deg,
        rgba(0, 15, 40, 0.5),
        rgba(0, 82, 218, 0.5) 100%
      );
      .right-top {
        position: absolute;
        right: -1Px;
        top: -1Px;
        border-top: 2Px solid rgba(23, 241, 236, 1);
        border-right: 2Px solid rgba(23, 241, 236, 1);
        width: 15Px;
        height: 15Px;
      }
      .right-bottom {
        position: absolute;
        right: -1Px;
        bottom: -1Px;
        border-bottom: 2Px solid rgba(23, 241, 236, 1);
        border-right: 2Px solid rgba(23, 241, 236, 1);
        width: 15Px;
        height: 15Px;
      }
      .left-bottom {
        position: absolute;
        left: -1Px;
        bottom: -1Px;
        border-bottom: 2Px solid rgba(23, 241, 236, 1);
        border-left: 2Px solid rgba(23, 241, 236, 1);
        width: 15Px;
        height: 15Px;
      }
      .dialog-content {
        padding: 48Px 10Px 0 20Px;
      }
    }
  }
  .el-dialog__wrapper::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
  }
  .el-table__body-wrapper {
    // height: 1000Px !important;
  }

  * {
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-color: rgba(62, 100, 172, 0.3) #011434;
    /* 滚动条宽度有三种：thin、auto、none */
    scrollbar-width: thin;
  }
  // 设置滚动条的宽度
  .el-dialog__body::-webkit-scrollbar {
    width: 10Px;
  }
  // 设置滚动条的背景色和圆角
  .el-dialog__body::-webkit-scrollbar-thumb {
    background-color: rgba(62, 100, 172, 0.3);
  }
}
</style>
