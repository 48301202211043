<template>
  <div class="care">
    <span class="nameCare">车辆定位</span>
    <div class="enlarge" @click="enlargeClick">
      <img class="img" :src="imgScreen" alt="" width="16px" height="16px">
      <span>放大</span>
    </div>
    <iframe
      v-if="mapTag"
      :src="mapIframeSrc"
      ref="mapIframe"
      frameborder="0"
      class="iframe"></iframe>
    <div class="careText" v-else>
      <img :src="imgUrl" alt="" width="32px" height="32px" />
      <div style="padding-left:5px;">暂无位置信息</div>
    </div>
    <el-dialog
      :title="'车辆定位'"
      width="90%"
      :append-to-body="true"
      :visible.sync="enlargeMapIframe">
      <div>
        <iframe
          :src="mapIframeSrc"
          frameborder="0"
          class="iframeDialog"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  createMapIframeSrc,
} from "@/api/e6yun.js";

export default {
  name: 'vehiclePosition',
  data() {
    return {
      imgUrl: require("@/assets/image/dingwei.png"),
      imgScreen: require("@/assets/image/fullScreen.png"),
      mapTag:false,
      mapIframeSrc: "", // 地图iframe的src
      enlargeMapIframe: false
    }
  },
  props: {
    vehicle: {
      type: Object,
    },
  },
  watch: {
    vehicle: {
      handler(value) {
        if (Object.keys(value).length !== 0) {
          if (value.plateNo){
            this.mapTag = false
            // 获取地图的iframe的src
            this.createMapIframeSrc(
              value.plateNo,
              // '藏A9D758',
              // Boolean(Object.keys(value).length)
            );
          }else{
             this.mapTag = false
             this.mapIframeSrc = ''
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 生成mapIframeSrc
    async createMapIframeSrc(vehicle) {
      this.mapTag = true
      this.mapIframeSrc = ''
      let mapIframeSrc = await createMapIframeSrc({ vehicle });
      this.$nextTick(()=>{
        this.mapIframeSrc = mapIframeSrc
        // console.log('地图refs',this.$refs.mapIframe)
      })
      // console.log('车辆定位链接',this.mapIframeSrc)
    },
    //点击放大车辆定位弹框
    enlargeClick() {
      this.enlargeMapIframe = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";
.care {
  width: 100%;
  height: 100%;
  // position: relative;
  .nameCare {
    position: absolute;
    top: 10px;
    left: 8px;
    color: #17fff4;
    height: 16px;
    line-height: 16px;
  }
  .enlarge {
    position: absolute;
    top: 10px;
    right: 8px;
    color: #17fff4;
    cursor: pointer;
    display: flex;
    align-items: center;
    .img {
      // position: relative;
      // top: 3px;
      margin-right: 4px;
    }
  }
  .iframe {
    width: 100%;
    height: 100%;
  }
  .careText {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22% auto;
  }
}
.iframeDialog {
  width: 100%;
  // height: 100%;
  height: 670px;
  // padding: 10px 0;
}
::v-deep {
  .el-dialog__title {
    color: #17fff4;
  }
}
</style>