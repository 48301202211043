<template>
  <div class="dialog">
    <el-dialog
      title="品类供应占比详情"
      width="1284PX"
      :visible="show"
      top="calc((100vh - 750Px) / 2)"
      @close="closeDetail">
      <div class="dialog-content">
        <div class="dialog-title-bg"></div>
        <div class="dialog-border-TL"></div>
        <div class="dialog-border-B"></div>
        <div class="left">
          <div class="left-top">
            <div class="selected">
              <el-cascader
                ref="cascaderRef"
                v-model="selectFood"
                clearable
                :options="selectOptions"
                :props="{
                  expandTrigger: 'hover',
                  checkStrictly: true
                }"
                @change="handleChangeSelectFood"
              >
              </el-cascader>
            </div>
            <div class="buttons">
              <el-button
                class="tab-item"
                :class="{
                  'tab-active': isAmountTab,
                }"
                @click="changeCategoryType('categorySupplyAmount')">
                订单金额
              </el-button>
              <el-button
                class="tab-item"
                :class="{
                  'tab-active': categoryType === 'categorySupplyQuantity',
                }"
                @click="changeCategoryType('categorySupplyQuantity')">
                订单数量
              </el-button>
<!--              <el-button
                v-permission="'isCanDownload'"
                class="tab-other"
                style="height: 32PX"
                @click="openExportClick">
                导出
              </el-button>-->
            </div>
          </div>
          <el-date-picker
            class="month-icon-style"
            v-model="monthValue"
            value-format="yyyyMM"
            type="month"
            placeholder="选择月"
            :picker-options="monthPickerOptions"
            @change="changeMonthValue">
          </el-date-picker>
          <Charts :option="categoryOption" />
        </div>
        <div class="right">
          <Table
            ref="tableComponent"
            :columns="columns"
            :tableData="tableData"
            :total="pageList.count"
            :loading="false"
            :searchShow="false"
            :stripe="false"
            :tableHeight="620"
            :page.sync="pageList.page"
            :rows.sync="pageList.rows"
            @pagination="getDetailsPage">
            <template
              slot="columnEspecial"
              slot-scope="{ column, row }"
              resizable>
              <span v-if="['ratio'].includes(column.prop)">
                {{ formatFloat(row[column.prop]*100, 2) + "%" }}
              </span>
              <span v-else-if="['amount'].includes(column.prop)">
                {{ row[column.prop] | Format }}
              </span>
              <span v-else-if="['quantity'].includes(column.prop)">
                {{ row[column.prop] | Format(0) }}
              </span>
              <!-- <template v-else-if="['operation'].includes(column.prop)">
                <el-button
                  type="text"
                  size="mini"
                  @click="openTrendDetail(row)"
                >
                  查看趋势
                </el-button>
              </template> -->
              <span v-else>{{ row[column.prop] }}</span>
            </template>

            <template slot="columnRight">
              <el-table-column
                label="操作"
                fixed="right"
                width="250"
                align="center">
                <template slot-scope="{ row }">
                  <div ref="columnRight">
                    <el-button
                      v-permission="'isCanDownload'"
                      v-if="row.categoryLevel === 1"
                      type="text"
                      size="mini"
                      class="btnZoom"
                      @click="exportOrderDetail(row)">
                      下载订单详情
                    </el-button>
                    <el-button
                      type="text"
                      size="mini"
                      class="btnZoom"
                      @click="openTrendDetail(row)">
                      查看趋势
                    </el-button>
                    <el-button
                      type="text"
                      size="mini"
                      class="btnZoom"
                      v-show="row.categoryLevel === 1"
                      @click="openCategorySuppliers(row)">
                      供应商占比
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </template>
          </Table>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="lineDetail"
      ref="lineDetail"
      :title="lineDetailTitle"
      :visible.sync="showLineDetail"
      @before-close="showLineDetail = false">
      <div class="dialog-content">
        <div class="tabDay">
          <div class="buttons">
            <el-button
              class="tab-item"
              :class="{
                  'tab-active': trendType === 'month',
                }"
              @click="trendType = 'month'">
              月
            </el-button>
            <el-button
              class="tab-item"
              :class="{
                  'tab-active': trendType === 'day',
                }"
              @click="trendType = 'day'">
              日
            </el-button>
          </div>
          <Charts class="charts" :option="trendOptions" />
        </div>
      </div>
    </el-dialog>

    <CategorySuppliers
      :dayVal="dayVal"
      :monthValue="demoMonth"
      :rows="rows"
      :categoryType="categoryType"
      :CategorySuppliersProps="CategorySuppliersProps"
      @update:showCategorySuppliersProps="CategorySuppliersProps.show = false" />
  </div>
</template>

<script>
import Table from "@/components/Table";
import CategorySuppliers from "./CategorySuppliers.vue";
import { formatDate, formatFloat, treeFunc2, deepClone, formatInt, format } from "@/utils";
import Charts from "@/components/Charts";
import { Format } from "@/filters";
import { exportCategory } from "@/api/home";
import { mapState } from 'vuex'

export default {
  name: "categoryDetail",
  components: {
    Table,
    Charts,
    CategorySuppliers
  },
  props: {
    show: {
      type: Boolean,
    },
    goryType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      filterType: '1',
      selectOptions: [],
      exportName: "导出模板",
      rows: {},
      trendChart: undefined,
      lineDetailTitle: "",
      showLineDetail: false,
      columns: [],
      myChart: undefined,
      chartData: [],
      tableData: [],
      queryType: 1,
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
        ["rgba(93,112,146,0)", "rgba(93,112,146,1)", "#BAD2FF"],
      ],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      categoryOption: {},
      categoryType: "categorySupplyAmount",
      trendType: 'month',
      selectFood: [],
      trendOptions: {},
      type: 1,
      CategorySuppliersProps: {
        show: false
      },
      superGroupName: "",
      categoryLevelName: '',
      rowCategoryName: '',
      monthValue:'',
      demoMonth: '',
      monthPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
      othersParams:{
        superGroupName: '',
        categoryLevelName: '一级品类',
        categoryName: ''
      }
    };
  },
  computed: {
    ...mapState('global',['month','dateModel']),
    monthVal(){
      return formatDate(this.month)
    },
    dayVal(){
      return this.dateModel.replace(/-/g, '')
    },
    isAmountTab() {
      return this.categoryType === "categorySupplyAmount"
    }
  },
  watch: {
    /* categoryType: {
      handler(value) {
        this.selectFood = []
        this.othersParams.superGroupName = ''
        this.othersParams.categoryLevelName = '一级品类'
        this.othersParams.categoryName = ''
        if (value) {
          this.getDetailsPage();
        }
        this.$nextTick(()=>{
          this.getPieData();
        })
        this.getSelectOption();
        if (value === "categorySupplyAmount") {
          this.columns = [
            {
              prop: "categoryLevelName",
              label: "级别",
            },
            {
              prop: "categoryName",
              label: "品类名称",
              align: "left",
              // width: 120
            },
            {
              prop: "amount",
              label: "订单金额(元)",
              align: "right",
              width: 110,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
            }
          ];
        } else {
          this.columns = [
            {
              prop: "categoryLevelName",
              label: "级别",
            },
            {
              prop: "categoryName",
              label: "品类名称",
              align: "left",
              width: 100
            },
            {
              prop: "quantity",
              label: "订单数量(单)",
              align: "right",
              width: 110,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
            }
          ];
        }
      },
      immediate: true,
    }, */
    trendType: {
      handler(val) {
        if (val === 'month') {
          this.queryType = 1
          this.drawtrendCharts()
        } else {
          this.queryType = 2
          this.drawtrendCharts()
        }
      }
    },
    goryType: {
      handler(val) {
        if (val == 1) {
          this.categoryType = 'categorySupplyAmount'
        } else if (val == 0) {
          this.categoryType = 'categorySupplyQuantity'
        }
        this.changeCategoryType(this.categoryType)
      },
      immediate: true
    },
  },
  methods: {
    changeCategoryType(value){
        this.categoryType = value
        this.selectFood = []
        this.othersParams.superGroupName = ''
        this.othersParams.categoryLevelName = '一级品类'
        this.othersParams.categoryName = ''
        if (value) {
          this.getDetailsPage();
        }
        this.$nextTick(()=>{
          this.getPieData();
        })
        this.getSelectOption();
        if (value === "categorySupplyAmount") {
          this.columns = [
            {
              prop: "categoryLevelName",
              label: "级别",
            },
            {
              prop: "categoryName",
              label: "品类名称",
              align: "left",
              // width: 120
            },
            {
              prop: "amount",
              label: "订单金额(元)",
              align: "right",
              width: 110,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
            }
          ];
        } else {
          this.columns = [
            {
              prop: "categoryLevelName",
              label: "级别",
            },
            {
              prop: "categoryName",
              label: "品类名称",
              align: "left",
              width: 100
            },
            {
              prop: "quantity",
              label: "订单数量(单)",
              align: "right",
              width: 110,
            },
            {
              prop: "ratio",
              label: "占比",
              align: "right",
            }
          ];
        }

    },
    handleChangeSelectFood(val){
      if(val.length){
        const obj = this.$refs['cascaderRef'].getCheckedNodes() // 获取当前选中节点对象
        const optionItem = obj[0].data
        if(optionItem.children){
          this.othersParams.superGroupName = optionItem.children[0].superGroupName
          this.othersParams.categoryLevelName = optionItem.children[0].categoryLevelName
          this.othersParams.categoryName = ''
        }else{
          this.othersParams.superGroupName = optionItem.superGroupName
          this.othersParams.categoryLevelName = optionItem.categoryLevelName
          this.othersParams.categoryName = optionItem.categoryName
        }
      }else{
          this.othersParams.superGroupName = ''
          this.othersParams.categoryLevelName = '一级品类'
          this.othersParams.categoryName = ''
      }

      this.getPieData();
      this.getDetailsPage();
    },
    changeMonthValue(){
      this.selectFood = []
      this.othersParams.superGroupName = ''
      this.othersParams.categoryLevelName = '一级品类'
      this.othersParams.categoryName = ''
      this.getDetailsPage()
      this.$nextTick(()=>{
        this.getPieData();
      })
      this.getSelectOption();
    },
    // 获得分类列表
    getSelectOption() {
      const paramsObj = {
        type: "categorySupplyTreeNew",
        day: this.dayVal,
        month: this.demoMonth,
        queryType: this.isAmountTab ? 'amount' : 'quantity',
        warehouseCode: this.warehouseCode,
        // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
        filterType: this.filterType
      }
      this.$getData(paramsObj).then((res) => {
        let source = res.data;
        treeFunc2(source);
        this.selectOptions = source;
      });
    },
    closeDetail() {
      this.$emit("update:show", false);
    },
    formatFloat(num, n = 2) {
      return formatFloat(num, n);
    },
    getLastDay(){
      /* const month = formatDate(this.month);
      let date = new Date();
      let monthDay = month.slice(-2)
      let currentMonth = parseFloat(monthDay)
      let nextMonthFirstDay = new Date(date.getFullYear(), currentMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = '0' + day
      } */
      
      let year = this.demoMonth.slice(0,4)
      let monthDay = this.demoMonth.slice(-2)
      let currentMonth = parseFloat(monthDay)
      let nextMonthFirstDay = new Date(year, currentMonth, 1);
      let oneDay = 1000 * 60 * 60 * 24;
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = '0' + day
      }
      return this.demoMonth + day
    },
    //下载订单详情
    exportOrderDetail(row) {
      const startTime = this.demoMonth ? this.demoMonth + '01' : this.dayVal
      const endTime = this.demoMonth ? this.getLastDay() : this.dayVal
      this.$getData({
        type: "crfRequestForward",
        // url: `/ICRFS/GetCrfsSOlistbyC1Name?c1name=${row.categoryName}&start=${month}01&end=${month}${day}`,
        url: `/ICRFS/GetCrfsSOlistbyC1Name?c1name=${row.categoryName}&start=${startTime}&end=${endTime}`,
        method: "GET",
        download: 1
      }).then((res) => {
        if (res.data) {
          window.location.href = res.data
        } else {
          this.$message({
            message: '下载路径为空!',
            center: true
          })
        }
      })
    },
    openTrendDetail(row) {
      this.superGroupName = row.superGroupName ? row.superGroupName : ''
      this.categoryLevelName = row.categoryLevelName
      this.rowCategoryName = row.categoryName
      this.lineDetailTitle = `${row.categoryName}商品趋势`;
      this.showLineDetail = true;
      this.drawtrendCharts();
    },
    openCategorySuppliers(row) {
      this.rows = row
      this.CategorySuppliersProps.show = true
    },
    openExportClick() {
      const paramsObj = {
        queryType: this.isAmountTab ? 'amount' : 'quantity',
        day: this.dayVal,
        month: this.demoMonth,
        warehouseCode: this.warehouseCode,
        // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
        filterType: this.filterType,
        ...this.othersParams
      }
      exportCategory(paramsObj).then((res) => {
        const link = document.createElement('a')
        const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(blob)
        link.download = this.isAmountTab ? '品类供应占比金额导出.xlsx' : '品类供应占比数量导出.xlsx'
        document.body.appendChild(link)
        link.click()
        // 兼容火狐浏览器
        document.body.removeChild(link)
        console.log(res)
      })
    },
    // 获取详情数据   列表
    getDetailsPage() {
      this.$nextTick(async () => {
        const paramsObj = {
          type: "categorySupplyNew",
          isPage:"page",
          queryType: this.isAmountTab ? 'amount' : 'quantity',
          day: this.dayVal,
          month: this.demoMonth,
          warehouseCode: this.warehouseCode,
          page: this.pageList.page,
          rows: this.pageList.rows,
          // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
          filterType: this.filterType,
          ...this.othersParams
        }
        let res = await this.$getData(paramsObj);
        console.log(res, 'restable996')
        this.tableData = res.data;
        this.pageList.count = res.count;
      });
    },
    // 获取图表详情   图标
    async getPieData() {
      const paramsObj = {
        type: "categorySupplyNew",
        queryType: this.isAmountTab ? 'amount' : 'quantity',
        day: this.dayVal,
        month: this.demoMonth,
        warehouseCode: this.warehouseCode,
        // 过滤 日用百货、洗化/日化、粮油米、宣传物料、盒饭 类目的数据 开关
        filterType: this.filterType,
        ...this.othersParams
      }
      let res = await this.$getData(paramsObj);
      console.log(res, '996Pieres')
      document.querySelectorAll('.el-popper.el-cascader__dropdown')[1].style.display = 'none'
      this.chartData = res.data;
      // 计算每一个data的起始角度和末了角度 θ1和θ2
      this.chartData.reduce((o, v) => {
        v.delta1 = o;
        // v.delta2 = o + v.amount / v.totalAmt;
        v.delta2 = o + v.ratio;
        return v.delta2;
      }, 0);
      // 添加渐变
      this.chartData.forEach((v) => {
        var ops = this.calc(v.delta1 * 2 * Math.PI, v.delta2 * 2 * Math.PI);
        if (v.categoryName) {
          v.itemStyle = {
            borderWidth: 2,
            borderColor: v.color1 || "#ADABFFFF", // 边框颜色
            shadowColor: v.color0 || "#ADABFF00", // 投影样式
            shadowBlur: 10, // 投影模糊
            color: {
              type: "radial",
              x: ops.center[0],
              y: ops.center[1],
              r: ops.radius,
              colorStops: [
                { offset: 0, color: v.color0 },
                { offset: 0.5, color: v.color0 },
                { offset: 0.3, color: v.color1 },
                { offset: 1, color: v.color1 },
              ],
              globalCoord: false, // 缺省为 falses
            },
          };
          // 引线
          v.label = {
            normal: {
              show: false,
              formatter: "{b}\n\n{d}%",
              textStyle: {
                fontSize: 12,
                color: v.color1,
              },
              position: "outside",
            },
            emphasis: {
              show: true,
            },
          };
        }
      });
      this.categoryOption = {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(params.value, this.isAmountTab ? 2 : 0)}${this.isAmountTab ? "元" : "单"} (${Format(params.data.ratio*100)
              }%)`;
          },
          confine: true,
        },
        legend: {
          orient: "vertical",
          type: "scroll",
          x: "70%",
          y: "22%",
          icon: "Rect",
          itemWidth: 14,
          itemHeight: 14,
          top: '8%',
          align: "left",
          selectedMode: false,
          textStyle: {
            color: "#fff",
            fontSize: 11,
            rich: {
              b: {
                fontSize: 12,
              },
            },
          },
        },
        series: [
          {
            name: "品类供应商占比",
            type: "pie",
            radius: ["35%", "60%"],
            center: ["35%", "50%"],

            data: this.chartData.map((item, index) => ({
              ...item,
              value: this.isAmountTab ? item.amount : item.quantity,
              name: item.categoryName,
              label: {
                show: false,
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: this.colors[index > 10 ? 10 : index][0],
                  },
                  {
                    offset: 1,
                    color: this.colors[index > 10 ? 10 : index][1],
                  },
                ]),
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: this.colors[index > 10 ? 10 : index][1],
                borderColor: this.colors[index > 10 ? 10 : index][2],
              },
            })),
          },
        ],
      };
    },
    // 根据ID返回相关请求参数
    getTreeById(list,id){
       let _this=this
      for (let i = 0; i < list.length; i++) {
        let a=list[i]
        if(a.id===id){
          return a
        }else{
          if(a.children && a.children.length>0){
            let res=_this.getTreeById(a.children,id)
            if(res){
              return res
            }
          }
        }
      }
    },
    handleOptionItem(optionItem){
      const obj = {}
      if(optionItem){
        obj.superGroupName = optionItem.superGroupName === 'TOP' && (!optionItem.children)? null : optionItem.categoryLevel === 3 ? optionItem.superGroupName: optionItem.categoryName
        obj.categoryLevel = optionItem.children ? optionItem.categoryLevel + 1: optionItem.categoryLevel
        obj.categoryName = optionItem.children ? null : optionItem.categoryName
      }else{
        obj.superGroupName = null
        obj.categoryLevel = 1
        obj.categoryName = null
      }
      return obj
    },
    // 计算渐变中心以及渐变半径
    calc(delta1, delta2) {
      var r = 0.5; // 半径是0.5 其实表示0.5个直径
      var inner = 0.6; // 里面镂空部分占60%  option中radius为[33%, 55%]  55 * 0.6 == 33

      var cos = Math.cos;
      var sin = Math.sin;
      var PI = Math.PI;
      var min = Math.min;
      var max = Math.max;

      var bottom = 0;
      var left = 2 * r;
      var right = 0;

      // y0: θ1对应的外部点的y值
      // y1: θ2对应的外部点的y值
      // _y0: θ1对应的内部点的y值
      // _y1: θ2对应的内部点的y值
      // x0: θ1对应的外部点的x值
      // x1: θ2对应的外部点的x值
      // _x0: θ1对应的内部点的x值
      // _x1: θ2对应的内部点的x值
      var y0 = r * (1 - cos(delta1));
      var y1 = r * (1 - cos(delta2));

      var _y0 = r * (1 - inner * cos(delta1));
      var _y1 = r * (1 - inner * cos(delta2));

      // 如果这个弧经过θ == PI的点  则bottom = 2PI
      // bottom用于之后的max计算中
      if (delta1 < PI && delta2 > PI) {
        bottom = 2 * r;
      }
      // 计算这几个点的最大最小值
      var ymin = min(_y0, _y1, y0, y1);
      var ymax = max(_y0, _y1, y0, y1, bottom);

      var x0 = r * (1 + sin(delta1));
      var x1 = r * (1 + sin(delta2));

      var _x0 = r * (1 + inner * sin(delta1));
      var _x1 = r * (1 + inner * sin(delta2));

      // 如果这个弧经过θ == PI / 2的点  则right = 2PI
      if (delta1 < PI / 2 && delta2 > PI / 2) {
        right = 2 * r;
      }
      // 如果这个弧经过θ == PI / 2 * 3的点  则left = 0
      if (delta1 < (PI / 2) * 3 && delta2 > (PI / 2) * 3) {
        left = 0;
      }
      var xmin = min(_x0, _x1, x0, x1, left);
      var xmax = max(_x0, _x1, x1, x0, right);

      return {
        // 计算圆心以及半径
        center: [(r - xmin) / (xmax - xmin), (r - ymin) / (ymax - ymin)],
        radius: r / min(xmax - xmin, ymax - ymin),
      };
    },
    async drawtrendCharts() {
      const paramsObj = {
        type: "categorySupplyTrendNew",
        queryType: this.isAmountTab ? 'amount' : 'quantity',
        day: this.demoMonth ? this.demoMonth + '01' : this.dayVal,
        // month: this.monthValue ? this.monthValue : this.dayVal.slice(0,6),
        superGroupName: this.superGroupName,
        categoryLevelName: this.categoryLevelName,
        categoryName: this.rowCategoryName,
        warehouseCode: this.warehouseCode
      }
      if(this.trendType === 'month'){
        paramsObj.month = this.demoMonth ? this.demoMonth : this.dayVal.slice(0,6)
      }
      let res = await this.$getData(paramsObj);
      this.trendOptions = {
        grid: {
          left: 80,
          right: 15
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let fl = this.isAmountTab ? true : false;
            return `${this.trendType === 'month' ? '月份' : '日期'}：<span style="font-weight: bold;">${params[0].axisValue.replace(/\//, "-")}</span> <br/> ${fl ? '订单金额' : '订单数量'}：<span style="font-weight: bold;">${Format(params[0].data, fl ? 2 : 0)}${fl ? '元' : '单'}</span>`;
          },
        },
        dataZoom: [
          {
            show: true,
            showDataShadow: false,
            showDetail: false,
            handleStyle: {
              color: "#17F1EC"
            },
            backgroundColor: "rgba(0,0,0,0.20)",
            borderColor: "rgba(0, 0, 0, 0)",
            // yAxisIndex: [0],
            start: 100,
            end: 80
          },
          {
            type: "inside",
            color: "#fff",
            // yAxisIndex: [0],
            start: 100,
            end: 80
          }
        ],
        xAxis: {
          data: res.data.map((item) => item.dateId),
          type: "category",
          boundaryGap: true,
          nameTextStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)",
            },
          },
          axisLabel: {
            color: "rgba(255,255,255,0.7)",
            interval: 0,
            // rotate: 30, // 倾斜30°
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: "value",
          scale: true, //自适应
          // minInterval: 0.1, //分割刻度
          splitNumber: 4,
          axisLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.7)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(255,255,255,0.3)", // 分割线颜色
            },
          },
        },
        series: [
          {
            type: "line",
            color: "#00f2f1", //折线颜色
            smooth: true, //折线是否带有弧度
            symbolSize: 6,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 1,
                  color: "rgba(23,241,236,1)",
                },
                {
                  offset: 0,
                  color: "rgba(23,241,236,0.1)",
                },
              ]),
            },
            data: res.data.map(
              (item) =>
                item[this.isAmountTab ? "amount" : "quantity"]
            ),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/detailScss.scss";

.btnZoom {
  padding: 0PX 0PX;
  font-size: 12PX;
}
.dialog {
  ::v-deep .el-button + .el-button {
    margin-left: 12PX;
  }
  .dialog-content {
    display: flex;
    width: 100%;
    height: 90%;
    .tabDay {
      // display: flex;
      width: 100%;
      .charts {
        padding: 0 0 30PX 0;
      }
    }
    .left {
      width: 40%;
      height: 100%;
      .month-icon-style{
        position: absolute;
        width: 200px;
        top: 75px;
        right: 48px;
        ::v-deep .el-input__icon{
          line-height: 32px;
        }
      }
    }

    .right {
      width: 60%;
      padding-top: 63px;
      .table {
        height: 100%;
      }
    }
    .right-tab-box-b {
      display: flex;
      align-content: center;
      margin-left: 10PX;
      position: relative;
      top: 1PX;
    }
    .buttons {
      display: flex;
      margin-top: 1PX;
      .tab-item {
        // width: 38PX;
        cursor: pointer;
        margin: 8PX 0;
        padding: 12PX;
        font-size: 14PX;
        // line-height: 1PX;
        border: 1PX solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.75);
        background: rgba(62, 100, 172, 0.3);
      }
      .tab-other {
        font-size: 14PX;
        width: 60PX;
        cursor: pointer;
        margin: 4PX 10PX;
        padding: 15PX;
        line-height: 1PX;
        border: 1PX solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255);
        background: rgba(62, 100, 172, 0.3);
      }

      .tab-item:first-child {
        border-right: none;
      }

      .tab-active {
        color: #17fff4;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: #5f97ff 0 0 10PX inset;
        font-size: 14PX;
        // padding: 0PX 0PX;
      }
    }
  }

  .el-table::before {
    height: 0;
  }

  ::v-deep .el-table thead {
    color: #ffffff;
  }
  ::v-deep .el-cascader .el-input .el-icon-arrow-down {
    width: 20PX;
    height: 32PX;
    padding: 0PX 1PX;
    line-height: 32PX;
  }

  .left-top {
    display: flex;
    height: 33PX;
    align-items: center;
    .selected {
      width: 200PX;
      ::v-deep .el-cascader .el-input__inner {
        width: 200PX;
        height: 32PX;
        font-size: 14PX;
      }
    }
    ::v-deep .el-input__inner {
      &::placeholder {
        font-size: 14PX;
        padding: 0PX 5PX;
      }
    }

    .buttons {
      display: flex;
      margin-top: 1PX;
      padding-left: 28PX;
      .tab-item {
        width: 75PX;
        cursor: pointer;
        margin: 4PX 0;
        padding: 8PX;
        // line-height: 2PX;
        border: 1PX solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255, 0.75);
        background: rgba(62, 100, 172, 0.3);
      }
      .tab-other {
        width: 72PX;
        cursor: pointer;
        margin: 4PX 10PX;
        padding: 15PX;
        line-height: 1PX;
        border: 1PX solid #87d2ff;
        border-radius: 0;
        color: rgba(255, 255, 255);
        background: rgba(62, 100, 172, 0.3);
      }

      .tab-item:first-child {
        border-right: none;
      }

      .tab-active {
        color: #17fff4;
        background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
        box-shadow: #5f97ff 0 0 10PX inset;
      }
    }
  }
  .DEchart {
    width: 100%;
    height: 100%;
    // border: 1PX solid #ff0000;
  }
  .lineDetail {
    z-index: 999;
    ::v-deep .el-dialog .el-dialog__title {
      line-height: 2.5;
      font-size: 16PX;
      color: #17f1ec;
    }
  }
  .trendChart {
    padding: 10PX;
    width: 100%;
    height: 100%;
  }
}
</style>
