var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('el-dialog',{attrs:{"title":"品类供应占比详情","width":"1284PX","visible":_vm.show,"top":"calc((100vh - 750Px) / 2)"},on:{"close":_vm.closeDetail}},[_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"dialog-title-bg"}),_c('div',{staticClass:"dialog-border-TL"}),_c('div',{staticClass:"dialog-border-B"}),_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-top"},[_c('div',{staticClass:"selected"},[_c('el-cascader',{ref:"cascaderRef",attrs:{"clearable":"","options":_vm.selectOptions,"props":{
                  expandTrigger: 'hover',
                  checkStrictly: true
                }},on:{"change":_vm.handleChangeSelectFood},model:{value:(_vm.selectFood),callback:function ($$v) {_vm.selectFood=$$v},expression:"selectFood"}})],1),_c('div',{staticClass:"buttons"},[_c('el-button',{staticClass:"tab-item",class:{
                  'tab-active': _vm.isAmountTab,
                },on:{"click":function($event){return _vm.changeCategoryType('categorySupplyAmount')}}},[_vm._v(" 订单金额 ")]),_c('el-button',{staticClass:"tab-item",class:{
                  'tab-active': _vm.categoryType === 'categorySupplyQuantity',
                },on:{"click":function($event){return _vm.changeCategoryType('categorySupplyQuantity')}}},[_vm._v(" 订单数量 ")])],1)]),_c('el-date-picker',{staticClass:"month-icon-style",attrs:{"value-format":"yyyyMM","type":"month","placeholder":"选择月","picker-options":_vm.monthPickerOptions},on:{"change":_vm.changeMonthValue},model:{value:(_vm.monthValue),callback:function ($$v) {_vm.monthValue=$$v},expression:"monthValue"}}),_c('Charts',{attrs:{"option":_vm.categoryOption}})],1),_c('div',{staticClass:"right"},[_c('Table',{ref:"tableComponent",attrs:{"columns":_vm.columns,"tableData":_vm.tableData,"total":_vm.pageList.count,"loading":false,"searchShow":false,"stripe":false,"tableHeight":620,"page":_vm.pageList.page,"rows":_vm.pageList.rows},on:{"update:page":function($event){return _vm.$set(_vm.pageList, "page", $event)},"update:rows":function($event){return _vm.$set(_vm.pageList, "rows", $event)},"pagination":_vm.getDetailsPage},scopedSlots:_vm._u([{key:"columnEspecial",fn:function(ref){
                var column = ref.column;
                var row = ref.row;
return [(['ratio'].includes(column.prop))?_c('span',[_vm._v(" "+_vm._s(_vm.formatFloat(row[column.prop]*100, 2) + "%")+" ")]):(['amount'].includes(column.prop))?_c('span',[_vm._v(" "+_vm._s(_vm._f("Format")(row[column.prop]))+" ")]):(['quantity'].includes(column.prop))?_c('span',[_vm._v(" "+_vm._s(_vm._f("Format")(row[column.prop],0))+" ")]):_c('span',[_vm._v(_vm._s(row[column.prop]))])]}}])},[_c('template',{slot:"columnRight"},[_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"250","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var row = ref.row;
return [_c('div',{ref:"columnRight"},[(row.categoryLevel === 1)?_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:('isCanDownload'),expression:"'isCanDownload'"}],staticClass:"btnZoom",attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.exportOrderDetail(row)}}},[_vm._v(" 下载订单详情 ")]):_vm._e(),_c('el-button',{staticClass:"btnZoom",attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.openTrendDetail(row)}}},[_vm._v(" 查看趋势 ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.categoryLevel === 1),expression:"row.categoryLevel === 1"}],staticClass:"btnZoom",attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.openCategorySuppliers(row)}}},[_vm._v(" 供应商占比 ")])],1)]}}])})],1)],2)],1)])]),_c('el-dialog',{ref:"lineDetail",staticClass:"lineDetail",attrs:{"title":_vm.lineDetailTitle,"visible":_vm.showLineDetail},on:{"update:visible":function($event){_vm.showLineDetail=$event},"before-close":function($event){_vm.showLineDetail = false}}},[_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"tabDay"},[_c('div',{staticClass:"buttons"},[_c('el-button',{staticClass:"tab-item",class:{
                  'tab-active': _vm.trendType === 'month',
                },on:{"click":function($event){_vm.trendType = 'month'}}},[_vm._v(" 月 ")]),_c('el-button',{staticClass:"tab-item",class:{
                  'tab-active': _vm.trendType === 'day',
                },on:{"click":function($event){_vm.trendType = 'day'}}},[_vm._v(" 日 ")])],1),_c('Charts',{staticClass:"charts",attrs:{"option":_vm.trendOptions}})],1)])]),_c('CategorySuppliers',{attrs:{"dayVal":_vm.dayVal,"monthValue":_vm.demoMonth,"rows":_vm.rows,"categoryType":_vm.categoryType,"CategorySuppliersProps":_vm.CategorySuppliersProps},on:{"update:showCategorySuppliersProps":function($event){_vm.CategorySuppliersProps.show = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }