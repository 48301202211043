<template>
  <div class="main-header">
    <div class="left">
      <!-- <el-date-picker
        v-model="month"
        type="month"
        :clearable="false"
        @change="datePickerChange"
        format="yyyy-MM"
        :picker-options="pickerOptions"
      >
      </el-date-picker> -->
      <el-date-picker
        popper-class="heater_date"
        type="date"
        v-model="dateModel"
        value-format="yyyy-MM-dd"
        :clearable="false"
        :picker-options="dateModelPickerOptions"
        @change="datePickerChange">
      </el-date-picker>
      <el-dropdown trigger="click" placement="bottom"  @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-house dropdown-icon"></i>
          {{dropdownText}}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in $attrs.warehouseList" :key="item.id"  :command="item">{{item.warehouse}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="analysis" @click="businessAnalysis">
        <img class="img" src="@/assets/image/weather/analysis.svg" alt="" />
        <span class="text">业务数据分析</span>
      </div> -->
    </div>
    <div class="right">
      <!-- <div class="right alarm" @click="unitBusiness">
        <img
          class="main_header_img"
          src="@/assets/image/yewuchaxun.png"
          alt=""
        />
        <span>单位业务查询</span>
      </div>
      <div class="right alarm" @click="progressDetails">
        <img class="main_header_img" src="@/assets/image/jindu.png" alt="" />
        <span>供应商送货进度</span>
      </div>
      <div class="right alarm" @click="onClickNotify">
        <img class="main_header_img" src="@/assets/image/warnIcon2.svg" />
        <el-badge :value="alarmNum" class="item">
          <span>报警信息</span>
        </el-badge>
      </div> -->
      <div class="right username" @click.stop="popoverShow = !popoverShow">
        <img class="main_header_img" src="@/assets/login/user.svg" />
        {{ usernameTwo }}
        <img
          class="main_header_img2_all"
          :class="popoverShow ? 'main_header_img2' : 'main_header_img2_'"
          src="@/assets/image/triangleImg.svg"
          alt
        />
      </div>
    </div>
    <transition name="el-zoom-in-top">
      <Popover ref="Popover" v-show="popoverShow" />
    </transition>
    <WarningProcessing
      :WarningProcessingProp="WarningProcessingProp"
      v-if="WarningProcessingProp.showDetails"
      @setAlarmInfoHandle="setAlarmInfoHandle"
    />
    <Details
      ref="Details"
      v-if="showAlarmDetails"
      :dialogTitle="'预警总览'"
      :stripe="false"
      :loading="false"
      :columns="columns"
      :tableData="tableData"
      :pageList="pageList"
      :modal="false"
      :searchShow="false"
      :alarmStatus="alarmStatus"
      :entitysShow="entitysShow"
      :WarningProcessingProp="WarningProcessingProp"
      :getDetailsPage="getAlarmInfoList"
      @getAlarmID="getAlarmID"
      @clos:closeDetails="closeAlarmInfoList"
    >
      <div slot="left-top" class="left-top">
        <div class="right-tab-box3">
          <div
            class="right-tab-item3"
            :class="!alarmStatus ? 'right-tab-active' : ''"
            @click="alarmStatus = 0"
          >
            未处理
          </div>
          <div
            class="right-tab-item3"
            :class="alarmStatus ? 'right-tab-active' : ''"
            @click="alarmStatus = 1"
          >
            已处理
          </div>
        </div>
        <el-select
          class="selectAlarm"
          v-model="alarmType"
          placeholder="报警类型"
          :popper-append-to-body="false"
          clearable
        >
          <el-option label="订单" value="0" />
          <el-option label="运输" value="1" />
          <el-option label="温度" value="2" />
          <el-option label="拒收" value="3" />
        </el-select>
        <el-date-picker
          v-model="warningDate"
          range-separator="~"
          value-format="yyyy-MM-dd"
          type="daterange"
          :clearable="false"
          @change="changeWarningDate"
        >
        </el-date-picker>
      </div>
    </Details>
    <Dialog
      :title="title"
      v-if="deliveryProgress"
      :show.sync="deliveryProgress"
      class="progressDialog"
      :dialogWidth="dialogWidth"
      @clearValue="handleCloses"
      :marginTop="`calc((100vh - 730Px) / 2)`"
      :append-to-body="true"
    >
      <div v-bind="$attrs" v-on="$listeners" class="allTable">
        <div class="timeUpdate">
          <span>更新时间：{{ updateTime ? updateTime : "---" }}</span>
        </div>
        <div class="tableHead">
          <div class="tableMain">
            <el-date-picker
              v-model="dateVals"
              range-separator="~"
              value-format="yyyy-MM-dd"
              type="daterange"
              :clearable="false"
              @change="changeDataPicker"
            >
            </el-date-picker>
            <el-button
              style="line-height: 10px; margin-left: 10px"
              class="BlueCustomButton searchBottom btnZoom"
              @click.native="updateData(entitys, true)"
              >刷新</el-button
            >
          </div>
          <div class="right-tab-box1">
            <div
              class="right-tab-item1"
              :class="overview === 0 ? 'right-tab-active' : ''"
              @click="overview = 0"
            >
              总览
            </div>
            <div
              class="right-tab-item1"
              :class="overview === 1 ? 'right-tab-active' : ''"
              @click="overview = 1"
            >
              明细
            </div>
          </div>
        </div>
        <!-- <proceTotalCharts v-show="overview === 0" :echartsProce="echartsProce" :loadingProce="loadingProce"></proceTotalCharts> -->
        <!-- <proceTotalChartsTwo v-show="overview === 0" :echartsProce="echartsProce" :loadingProce="loadingProce"></proceTotalChartsTwo> -->
        <proceTotalChartsThree
          v-show="overview === 0"
          :echartsProce="echartsProce"
          :loadingProce="loadingProce"
        ></proceTotalChartsThree>
        <div v-show="overview === 1" class="tableOther">
          <div class="tableLeft">
            <el-table-admin-page
              class="tableOne"
              ref="singTable"
              :search-show="false"
              :columns="columnsGoods"
              :table-data="tableDataGoods"
              :isLoading="loadingProgress"
              v-bind.sync="pageParams"
              :pageSize="pageParams.pageSize"
              @searchList="getSupplierDeliverSchedule"
              @size-change="sizeChange"
              highlight-current-row
              @current-change="handleCurrentChange"
              :paginationShow="paginationShow"
              :lay="1"
              :height="545"
            >
              <template slot="columnEspecial" slot-scope="{ column, row }">
                <span v-if="column.prop === 'recQty'">{{
                  row[column.prop] | Format
                }}</span>
                <span v-else-if="column.prop === 'qty'">{{
                  row[column.prop] | Format
                }}</span>
                <span v-else>{{ row[column.prop] }}</span>
              </template>
            </el-table-admin-page>
          </div>
          <div class="tableRight">
            <div class="rightTop">
              <div class="right-tab-boxs" style="background: #132f62">
                <div
                  class="right-tab-item2"
                  :class="typeOf === '' ? 'right-tab-active' : ''"
                  @click="typeOf = ''"
                >
                  全部({{ totalCount || 0 }})
                </div>
                <div
                  class="right-tab-item2"
                  :class="typeOf === 40 ? 'right-tab-active' : ''"
                  @click="typeOf = 40"
                >
                  未入库({{ notStockIn || 0 }})
                </div>
                <div
                  class="right-tab-item2"
                  :class="typeOf === 50 ? 'right-tab-active' : ''"
                  @click="typeOf = 50"
                >
                  已入库({{ hasStockIn || 0 }})
                </div>
                <div
                  class="right-tab-item2"
                  :class="typeOf === 20 ? 'right-tab-active' : ''"
                  @click="typeOf = 20"
                >
                  已上架({{ onTheShelf || 0 }})
                </div>
                <div
                  class="right-tab-item2"
                  :class="typeOf === 10 ? 'right-tab-active' : ''"
                  @click="typeOf = 10"
                >
                  已分拣({{ hasSorted || 0 }})
                </div>
                <div
                  class="right-tab-item2"
                  :class="typeOf === 30 ? 'right-tab-active' : ''"
                  @click="typeOf = 30"
                >
                  已出库({{ hasStockOut || 0 }})
                </div>
              </div>
              <tableAdmin
                class="tableTwo"
                ref="elTableAdmin"
                :search-show="false"
                :columns="columnsDetails"
                :table-data="tableDataGoodsDetails"
                v-bind.sync="pageLeft"
                :isLoading="loadingProLeft"
                :pageSize="pageLeft.pageSize"
                @searchList="getPage"
                @size-change="sizeChangeTable"
                @current-change="currentChangeTable"
                highlight-current-row
                :paginationShow="paginationShow2"
                :height="497"
              >
                <template slot="columnEspecial" slot-scope="{ column, row }">
                  <div v-if="column.prop === 'operation'">
                    <span class="clickButton" @click="checkGoodsDetails(row)"
                      >查看详情</span
                    >
                  </div>
                  <span v-if="column.prop === 'recQty'">{{
                    row[column.prop] | Format
                  }}</span>
                  <span v-else-if="column.prop === 'qty'">{{
                    row[column.prop] | Format
                  }}</span>
                  <span v-else>{{ row[column.prop] }}</span>
                </template>
              </tableAdmin>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <GoodsDetails
      class="detail"
      ref="GoodsDetails"
      v-if="goodsDetails"
      :append-to-body="true"
      :show.sync="goodsDetails"
      :tableDataOther="tableDataOther"
      :pages="pages"
      :title="detailsTitle"
      :goodsTotalQty="goodsTotalQty"
      :goodsTotalRecQty="goodsTotalRecQty"
      :goodsTotalHasSorted="goodsTotalHasSorted"
      :goodsTotalOnTheShelf="goodsTotalOnTheShelf"
      :goodsTotalHasStockOut="goodsTotalHasStockOut"
      :goodsTotalNotStockIn="goodsTotalNotStockIn"
      :goodsDiffQty="goodsDiffQty"
      @change-event="getPageDetail"
      @get-size="getPageDetail"
      :row="rowData"
    ></GoodsDetails>
    <sreenDialog
      :title="'单位业务查询'"
      v-if="unitBusinessDetail"
      :append-to-body="true"
      @close:closeDetails="closeSreen"
      :show.sync="unitBusinessDetail"
    >
      <div class="containerHeader">
        <div class="right-tab-box">
          <div
            class="right-tab-item"
            :class="business === 0 ? 'right-tab-active' : ''"
            @click="business = 0"
          >
            <div class="listImg">
              <img
                class="imgUrl"
                v-if="business === 0"
                :src="imgUrl1"
                alt=""
                width="16"
                height="16"
              />
              <img
                v-else
                class="imgUrl"
                :src="imgUrl"
                alt=""
                width="16"
                height="16"
              />
              <span class="text"> 信息总览 </span>
            </div>
          </div>
          <div
            class="right-tab-item"
            :class="business === 1 ? 'right-tab-active' : ''"
            @click="business = 1"
          >
            <div class="listImg">
              <img
                class="imgUrl"
                v-if="business === 1"
                :src="imgSrc"
                alt=""
                width="16"
                height="16"
              />
              <img
                v-else
                class="imgUrl"
                :src="imgSrc1"
                alt=""
                width="16"
                height="16"
              />
              <span class="text"> 下级单位数据统计 </span>
            </div>
          </div>
        </div>
        <el-date-picker
          style="margin: 0 12px"
          v-model="unitMonth"
          type="month"
          :clearable="false"
          @change="datePickerChangeTwo"
          format="yyyy-MM"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-select
          v-model="entitys[0].querySelect"
          placeholder="请选择单位层级"
          value-key="prop"
          :popper-append-to-body="false"
          clearable
          @change="selectChange"
        >
          <el-option
            v-for="item in selectOptions"
            :key="item.prop"
            :label="item.label"
            :value="item.prop"
          />
        </el-select>
        <el-input
          v-model.trim="entitys[0].queryValue"
          placeholder="请输入单位代码"
          style="margin: 0 12px 0 0"
          :disabled="inputDisab"
          @keyup.native="proving(1)"
          clearable
        />
        <el-button
          style="line-height: 10px"
          class="BlueCustomButton searchBottom"
          @click.native="seachList(entitys, true)"
          >查询</el-button
        >
        <el-button
          style="line-height: 10px"
          class="BlueCustomButton searchBottom"
          @click.native="elTableRefs"
          >重置</el-button
        >
        <el-button
          style="line-height: 10px"
          class="BlueCustomButton searchBottom"
          @click.native="seachList(entitys, true)"
          >刷新</el-button
        >
      </div>
      <div class="containerMain">
        <unitBusinessEcharts
          v-if="business === 0"
          :bunitTime="bunitTime"
          :money1Two="money1Two"
          :money2Two="money2Two"
          :money3Two="money3Two"
          :makeUpTwo="makeUpTwo"
        ></unitBusinessEcharts>
        <unitBusinessTable
          v-if="business === 1"
          :pageCategory="pageCategory"
          :pageVendor="pageVendor"
          :pageOrder="pageOrder"
          :columnsVendor="columnsVendor"
          :columnsCategory="columnsCategory"
          :tableDataCategory="tableDataCategory"
          :tableDataVendor="tableDataVendor"
          :tableData="tableDataOrder"
          @get-sizes="getOederAmountTable"
          @size-category="getOrderViewTable"
          @size-vendor="getOrderGoodsTable"
          :unitMonth="unitMonth"
          :loadingVendor="loadingVendor"
          :loadingCategory="loadingCategory"
          :loadingOrder="loadingOrder"
          :show="show"
          v-bind="$attrs"
          v-on="$listeners"
        ></unitBusinessTable>
      </div>
    </sreenDialog>
    <Analysis
      ref="Details"
      v-if="showBusinessAnalysis"
      @close:closeBusinessAnalysis="closeBusinessAnalysis"
      :dialogTitle="'业务数据分析'"
    ></Analysis>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog/index.vue";
import sreenDialog from "@/components/sreenDialog/index.vue";
import tableAdmin from "@/components/tableAdmin/index.vue";
import Popover from "./popover.vue";
import WarningProcessing from "./warningProcessing.vue";
import Details from "./AlarmDetails.vue";
import GoodsDetails from "./goodsDetails.vue";
// import proceTotalCharts from "./proceTotalCharts.vue";
// import proceTotalChartsTwo from "./proceTotalChartsTwo.vue";
import proceTotalChartsThree from "./proceTotalChartsThree.vue";
import unitBusinessEcharts from "./unitBusinessEchrts.vue";
import unitBusinessTable from "./unitBusinessTable.vue";
import Analysis from "./businessAnalysis.vue";
import { Format } from "@/filters";
import bus from "@/utils/bus";
import { formatDate, formatDateVal } from "@/utils";
import { rmaOrderSetHandled } from "@/api/weather";

export default {
  name: "MainHeader",
  components: {
    Popover,
    WarningProcessing,
    Details,
    Dialog,
    GoodsDetails,
    sreenDialog,
    unitBusinessEcharts,
    unitBusinessTable,
    // proceTotalCharts,
    // proceTotalChartsTwo,
    proceTotalChartsThree,
    tableAdmin,
    Analysis,
  },
  inject: ['reload'],
  computed: {
    ...mapGetters(["global"]),
  },
  data() {
    return {
      demoDate: "2024-07-01",
      rejectionWarningId: null,
      timer: null,
      dynamicWatch: false,
      warningDate: [],
      showBusinessAnalysis: false,
      notifyLength: 0,
      dialogWidth: "1280PX",
      loadingProce: true,
      timeNum: 1,
      timeId: null,
      overview: 0,
      alarmType: null,
      params: {},
      inputDisab: true,
      imgUrl: require("@/assets/image/zonglan.png"),
      imgUrl1: require("@/assets/image/zongliang.png"),
      imgSrc: require("@/assets/image/danweishuju.png"),
      imgSrc1: require("@/assets/image/danweiliang.png"),
      bunitTime: "",
      money1Two: {},
      money2Two: {},
      money3Two: {},
      makeUpTwo: {},
      isFinishedTag: 2,
      paginationShow: true,
      paginationShow2: true,
      unitBusinessDetail: false,
      radioTag: "0",
      typeOf: "",
      business: 0,
      month: "",
      stripe: false,
      detailsTitle: "",
      title: "供应商送货进度查看",
      popoverShow: false,
      goodsDetails: false,
      totalCount: 0,
      totalRecQty: 0,
      goodsTotalQty: 0,
      goodsTotalRecQty: 0,
      goodsTotalHasSorted: 0,
      goodsTotalOnTheShelf: 0,
      goodsTotalHasStockOut: 0,
      goodsTotalNotStockIn: 0,
      goodsDiffQty: 0,
      diffQty: "",
      usernameTwo: "",
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageLeft: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageOrder: {
        //订单金额分页
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageCategory: {
        //品类占比分页
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      pageVendor: {
        //供应商占比分页
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      isConfirm: true,
      visible: false,
      showAlarmDetails: false,
      deliveryProgress: false,
      entitysShow: ["handleResult", "handleDate"],
      tableDataGoods: [],
      tableDataGoodsDetails: [],
      tableDataOther: [],
      columnsGoods: [
        {
          type: "radio",
        },
        {
          label: "供应商",
          prop: "supplierName",
          align: "left",
          width: "180",
        },
        {
          label: "实际入库重量(斤)",
          prop: "recQty",
          align: "right",
          headerAlign: "right",
          width: "129",
        },
        {
          label: "预计入库重量(斤)",
          prop: "qty",
          align: "right",
          headerAlign: "right",
          width: "129",
        },
      ],
      columnsDetails: [
        {
          label: "商品编码",
          prop: "skuNo",
          align: "center",
          width: "120",
        },
        {
          label: "商品名称",
          prop: "skuName",
          align: "center",
          width: "160",
        },
        {
          label: "已入库重量(斤)",
          prop: "recQty",
          align: "right",
          headerAlign: "right",
        },
        {
          label: "预计入库重量(斤)",
          prop: "qty",
          align: "right",
          headerAlign: "right",
        },
        {
          label: "操作",
          prop: "operation",
          align: "center",
        },
      ],
      columns: [
        {
          label: "报警日期",
          prop: "alarmDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
          width: 100,
        },
        {
          label: "类型",
          prop: "type",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 50,
        },
        {
          label: "报警内容",
          prop: "alarmContent",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: true,
          width: 300,
          align: "left",
        },
        {
          label: "处理结果",
          prop: "handleResult",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
        },
        {
          label: "处理时间",
          prop: "handleDate",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: true,
          form: true,
          width: 100,
        },
      ],
      tableData: [],
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      alarmID: "",
      rmaType: 0,
      goodsName: "",
      alarmStatus: 0,
      alarmNum: 0,
      WarningProcessingProp: {
        title: "预警处理",
        showDetails: false,
      },
      selectOptions: [
        {
          label: "一级单位编码",
          prop: "focalName1",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
          align: "center",
        },
        {
          label: "二级单位编码",
          prop: "focalName2",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
        },
        {
          label: "三级单位编码",
          prop: "focalName3",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
        },
        {
          label: "伙食单位编码",
          prop: "customerKey",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
        },
        {
          label: "灶点编号",
          prop: "focalPointNo",
          align: "center",
          feildType: "NUMBER",
          queryType: "EQ",
          isnull: false,
          query: false,
          form: false,
        },
      ],
      entitys: [
        {
          querySelect: "",
          queryValue: "",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
      dateModelPickerOptions: {
        disabledDate(time) {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < new Date("2000-1-1 00:00:00").getTime()
          );
        },
      },
      alarmImg: require("@/assets/image/warnIcon.svg"),
      orderformAlarm: require("@/assets/image/orderWarn.svg"),
      transportationAlarm: require("@/assets/image/transportWarn.svg"),
      weatherAlarm: require("@/assets/image/temperatureWarn.svg"),
      rejectionAlarm: require("@/assets/image/rejectWarnImg.svg"),
      rowData: {},
      dateVals: [],
      colors: [
        ["rgba(99,116,255,0.00)", "rgba(99,116,255,1)", "#C0C7FF"],
        ["rgba(0,186,255,0.00)", "rgba(0,186,255,1)", "#99E3FF"],
        ["rgba(23,241,236,0.00)", "rgba(23,241,236,1)", "#A2F9F7"],
        ["rgba(33,223,86,0.00)", "rgba(33,223,86,1)", "#B4F3B6"],
        ["rgba(209,218,0,0.00)", "rgba(209,218,0,1)", "#ECF099"],
        ["rgba(241,161,36,0.00)", "rgba(241,161,36,1)", "#F9D9A7"],
        ["rgba(255,122,80,0.00)", "rgba(255,122,80,1)", "#FFC9B9"],
        ["rgba(255,89,130,0.00)", "rgba(255,89,130,1)", "#FFBCCD"],
        ["rgba(241,95,210,0.00)", "rgba(241,95,210,1)", "#F9BFED"],
        ["rgba(173,91,255,0.00)", "rgba(173,91,255,1)", "#DEBDFF"],
        ["rgba(93,112,146,0.00)", "rgba(93,112,146,1)", "#BAD2FF"],
      ],
      tableDataOrder: [],
      columnsCategory: [],
      columnsVendor: [],
      tableDataCategory: [],
      tableDataVendor: [],
      loadingOrder: false,
      loadingCategory: false,
      loadingVendor: false,
      loadingProgress: false,
      loadingProLeft: false,
      unitMonth: null,
      width: "100%",
      height: "100%",
      hasSorted: 0,
      onTheShelf: 0,
      hasStockOut: 0,
      notStockIn: 0,
      hasStockIn: 0,
      echartsProce: [],
      updateTime: "",
      arr3: [],
      paginationName: "",
      paginationType: "",
      // show: false
      dateModel: '',
      dropdownText: ''
    };
  },
  created() {
    this.dropdownText = this.$attrs.activeItem?.warehouse
    this.month = this.global.month;
    this.unitMonth = this.month;
    // this.dateModel = this.global.dateModel;
    this.dateModel = this.getToday()
    this.$store.dispatch("global/changeGlobal", { key: "dateModel", value: this.demoDate});
  },
  async mounted() {
    this.usernameTwo = localStorage.getItem("usernameTwo");
    bus.$on("closePopover", this.closePopover);
    // this.warningTimeInit(); // 禁用接口
  },
  watch: {
    alarmStatus(val) {
      if (this.dynamicWatch) {
        this.pageList.rows = 20;
        this.getAlarmInfoList("", 1);
        if (val) {
          this.entitysShow = [];
        } else {
          this.entitysShow = ["handleResult", "handleDate"];
        }
      }
    },
    alarmType() {
      if (this.dynamicWatch) {
        this.getAlarmInfoList("", 1);
      }
    },
    typeOf(val) {
      if (val === "") {
        this.columnsDetails[2].label = "已入库重量(斤)";
      } else if (val === 40) {
        this.columnsDetails[2].label = "未入库重量(斤)";
      } else if (val === 50) {
        this.columnsDetails[2].label = "已入库重量(斤)";
      } else if (val === 20) {
        this.columnsDetails[2].label = "已上架重量(斤)";
      } else if (val === 10) {
        this.columnsDetails[2].label = "已分拣重量(斤)";
      } else if (val === 30) {
        this.columnsDetails[2].label = "已出库重量(斤)";
      }
      this.pageLeft.currentPage = 1;
      this.paginationShow2 = false;
      this.$nextTick(() => {
        this.paginationShow2 = true;
      });
      this.getPage();
    },
    overview: {
      handler(val) {
        if (val === 1) {
          this.checked();
        }
      },
    },
    paginationName: {
      handler(val) {
        if (val) {
          if (this.typeOf) {
            this.typeOf = "";
          } else {
            this.getPage();
          }
        } else {
          this.tableDataGoodsDetails = [];
          this.totalCount = 0;
          this.hasSorted = 0; //已分拣
          this.onTheShelf = 0; //已上架
          this.hasStockOut = 0; //已出库
          this.notStockIn = 0; //未入库
          this.hasStockIn = 0; //已入库
          this.pageLeft.total = 0;
        }
      },
    },
    // goodsName: {
    //   handler(val) {
    //      if (val) {
    //       this.getPage()
    //     }
    //   }
    // },
    unitBusinessDetail: {
      handler(value) {
        if (!value) {
          this.entitys[0].querySelect = undefined;
          this.entitys[0].queryValue = undefined;
          this.inputDisab = true;
          this.business = 0;
        } else {
          this.unitMonth = this.month;
        }
      },
      deep: true,
      immediate: false,
    },
    '$attrs.activeItem':{
      handler(newV,oldV){
        if(newV.warehouse){
          this.dropdownText = this.$attrs.activeItem.warehouse
        }
      },
      deep: true,
    }
  },
  methods: {
    getToday() {
  let date = new Date()
  let year = date.getFullYear().toString()
  let monthDay =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString()
  let da =
    date.getDate() < 10
      ? "0" + date.getDate().toString()
      : date.getDate().toString()
  let today = year + "-" + monthDay + "-" + da //当天
  return today
},
    // 下拉选中仓
    handleCommand(command) {
      this.dropdownText = command.warehouse
      
      this.$store.dispatch("global/changeGlobal", { key: "warehouseCode", value: command.warehouseCode });
      this.reload() //根据仓库变换重新加载路由视图
    },
    warningTimeInit(v = 0) {
      let dete2 = formatDate(this.month);
      let deteYear = dete2.slice(0, 4);
      let deteMonth = dete2.slice(-2);
      let currentMonth = parseFloat(deteMonth);
      let oneDay = 1000 * 60 * 60 * 24;
      let nextMonthFirstDay = new Date(deteYear, currentMonth, 1);
      let lastTime = new Date(nextMonthFirstDay - oneDay);
      let day = lastTime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      //字符串拼接，开始时间，结束时间
      let beg = deteYear + "-" + deteMonth + "-01"; //当月第一天
      let end = deteYear + "-" + deteMonth + "-" + day; //当月最后一天
      this.warningDate = [beg, end]; //将值设置给插件绑定的数据
      if (v == 0) {
        this.getAlarmInfoList("", 0, 1);
      } else if (v == 1) {
        this.getAlarmInfoList("", 1);
      } else if (v == 2) {
        this.getAlarmInfoList("", 1);
      } else if (v == 3) {
        this.getAlarmInfoList("", 0, 0);
      }
    },
    changeWarningDate() {
      if (this.dynamicWatch) {
        this.getAlarmInfoList("", 1);
      }
    },
    businessAnalysis() {
      this.showBusinessAnalysis = true;
    },
    closeBusinessAnalysis() {
      this.showBusinessAnalysis = false;
    },
    selectChange() {
      this.inputDisab = false;
      this.entitys[0].queryValue = "";
      this.$forceUpdate();
    },
    proving(v) {
      if (this.entitys[0].querySelect) {
        if (this.entitys[0].querySelect == "customerKey") {
          // let regu = /[^\\.\d]/
          let regu = /[^\d]/;
          if (regu.test(this.entitys[0].queryValue) && v == 2) {
            this.$message({
              message: "请输入正确的单位编码",
              type: "warning",
            });
            return;
          } else {
            this.entitys[0].queryValue = this.entitys[0].queryValue.replace(
              /[^\d]/g,
              ""
            );
            this.entitys[0].queryValue = this.entitys[0].queryValue.replace(
              ".",
              ""
            );
            // this.$set(this.entitys[0],'queryValue',this.entitys[0].queryValue.replace(/[^\d]/g, ''))
            // this.$set(this.entitys[0],'queryValue',this.entitys[0].queryValue.replace('.', ''))
          }
        } else if (this.entitys[0].querySelect == "focalPointNo") {
          let regu =
            /[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/;
          if (regu.test(this.entitys[0].queryValue) && v == 2) {
            this.$message({
              message: "请输入正确的灶点编号",
              type: "warning",
            });
            return;
          } else {
            this.entitys[0].queryValue = this.entitys[0].queryValue
              .replace(
                /[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/g,
                ""
              )
              .replace(/\s/g, "");
            // this.$set(this.entitys[0],'queryValue',this.entitys[0].queryValue.replace(/[`～*~!@#$%^&*()_\\+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\\+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, ""))
          }
        } else {
          let regu =
            /[`～*~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/;
          if (regu.test(this.entitys[0].queryValue) && v == 2) {
            this.$message({
              message: "请输入正确的单位编码",
              type: "warning",
            });
            return;
          } else {
            this.entitys[0].queryValue = this.entitys[0].queryValue
              .replace(
                /[`～*~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g,
                ""
              )
              .replace(/\s/g, "");
            // this.$set(this.entitys[0],'queryValue',this.entitys[0].queryValue.replace(/[`～*~!@#$%^&*()_+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g, '').replace(/\s/g, ""))
          }
        }
      }
      if (v == 2) {
        let params = {};
        if (this.entitys[0].querySelect) {
          if (this.entitys[0].querySelect == "customerKey") {
            let num = Number(this.entitys[0].queryValue);
            if (num > 2147483647) {
              this.$message({
                message: "请输入正确的单位编码",
                type: "warning",
              });
              this.entitys[0].queryValue = undefined;
              return;
            }
          }
          params[this.entitys[0].querySelect] =
            this.entitys[0].queryValue || "";
        }
        this.params = params;
        this.allInfo(this.entitys[0].queryValue);
        this.getFontiled();
        this.getOederAmountTable();
      }
      this.$forceUpdate();
    },
    elTableRefs() {
      this.entitys[0].querySelect = undefined;
      this.entitys[0].queryValue = undefined;
      this.inputDisab = true;
      this.seachList(this.entitys);
    },
    datePickerChangeTwo() {
      // this.unitMonth = this.unitMonth.getTime()
      this.seachList(this.entitys);
    },
    seachList() {
      this.proving(2);
    },
    //获取表头分页
    getFontiled() {
      this.show = false;
      //品类表头
      this.$getData({
        ...this.params,
        type: "monitorPictureTableHeader",
        date: formatDate(this.unitMonth),
        tableType: "category",
        headerAlign: "center",
        align: "right",
        width: "180",
      }).then((res) => {
        this.columnsCategory = res.data;
        this.show = true;
        this.getOrderViewTable();
      });
      //供应商表头
      this.$getData({
        ...this.params,
        type: "monitorPictureTableHeader",
        date: formatDate(this.unitMonth),
        tableType: "vendor",
        headerAlign: "right",
        align: "right",
        width: "260",
      }).then((res) => {
        this.columnsVendor = res.data;

        this.getOrderGoodsTable();
      });
    },
    getOrderViewTable(page, v = 0) {
      this.loadingCategory = true;
      if (v === 0) {
        this.pageCategory.pageSize = 10;
        this.pageCategory.currentPage = 1;
      } else {
        if (v === 1) {
          this.pageCategory.currentPage = page;
        } else if (v === 2) {
          this.pageCategory.currentPage = 1;
          this.pageCategory.pageSize = page;
        }
      }
      //品类table
      this.$getData({
        ...this.params,
        type: "categoryFocalPointAmountRatio",
        date: formatDate(this.unitMonth),
        page: this.pageCategory.currentPage,
        rows: this.pageCategory.pageSize,
      }).then((res) => {
        this.loadingCategory = false;
        this.tableDataCategory = res.data;
        this.pageCategory.total = res.count;
      });
    },
    getOederAmountTable(page, v = 0) {
      this.loadingOrder = true;
      if (v === 0) {
        this.pageOrder.pageSize = 10;
        this.pageOrder.currentPage = 1;
      } else {
        if (v === 1) {
          this.pageOrder.currentPage = page;
        } else if (v === 2) {
          this.pageOrder.currentPage = 1;
          this.pageOrder.pageSize = page;
        }
      }
      //订单金额
      this.$getData({
        ...this.params,
        type: "subOrganizationOrderAmountSummary",
        date: formatDate(this.unitMonth),
        page: this.pageOrder.currentPage,
        rows: this.pageOrder.pageSize,
      }).then((res) => {
        this.loadingOrder = false;
        this.pageOrder.total = res.count;
        this.tableDataOrder = res.data;
      });
    },
    //供应商占比table
    getOrderGoodsTable(page, v = 0) {
      this.loadingVendor = true;
      if (v === 0) {
        this.pageVendor.pageSize = 10;
        this.pageVendor.currentPage = 1;
      } else {
        if (v === 1) {
          this.pageVendor.currentPage = page;
        } else if (v === 2) {
          this.pageVendor.currentPage = 1;
          this.pageVendor.pageSize = page;
        }
      }
      this.$getData({
        ...this.params,
        type: "vendorFacalPointAmountRatio",
        date: formatDate(this.unitMonth),
        page: this.pageVendor.currentPage,
        rows: this.pageVendor.pageSize,
      }).then((res) => {
        this.loadingVendor = false;
        this.pageVendor.total = res.count;
        this.tableDataVendor = res.data;
      });
    },
    allInfo(f = "") {
      // console.log('年月日', formatDate(this.unitMonth))
      this.bunitTime = `${formatDateVal(this.unitMonth).replace(
        "/",
        "年"
      )}月 - ${f ? f : "000"}`;
      this.$getData({
        ...this.params,
        type: "orderAmountTrendPicture",
        date: formatDate(this.unitMonth),
      }).then((res) => {
        let source = res.data.map((v) => {
          let obj = {
            date: v.monthId,
            orderAmt: v.ddAmt / 10000,
          };
          return obj;
        });
        this.money1Two = {
          dataset: {
            source: source,
          },
        };
        this.$forceUpdate();
      });
      this.$getData({
        ...this.params,
        // type: "orderStatusTrendPicture",
        type: "orderStatusTrendPictureNew",
        date: formatDate(this.unitMonth),
      }).then((res) => {
        let source = [];
        source = res.data.map((item) => {
          return [item.key, item.value];
        });
        this.money2Two = {
          dataset: {
            source: source.reverse(),
          },
        };
        this.$forceUpdate();
      });
      this.$getData({
        ...this.params,
        type: "vendorFocalPointPicture",
        date: formatDate(this.unitMonth),
      }).then((res) => {
        let arr = res.data;
        this.money3Two = {
          title: {
            text: "供应商占比",
            subtext: this.bunitTime,
            left: "3%",
            top: "2%",
            textStyle: {
              color: "#17fff5",
              fontSize: 14,
            },
            subtextStyle: {
              color: "#ffffff",
              fontSize: 12,
            },
          },
          // graphic:{
          //       type:"text",
          //       left:"24%",
          //       top:"49%",
          //       style:{
          //           text:`TOP${arr.length}`,
          //           textAlign:"center",
          //           fill:"#ffffff",
          //           fontSize:20,
          //           fontWeight:700
          //       }
          //   },
          tooltip: {
            trigger: "item",
            formatter: (params) => {
              return `TOP${params.dataIndex + 1}<br/>${params.name}:${Format(
                params.value
              )}元(${Format(params.data.ddAmtRatio * 100)}%)`;
            },
            confine: true,
          },
          legend: {
            orient: "vertical",
            type: "scroll",
            right: "5%",
            y: 0,
            icon: "Rect",
            itemWidth: 10,
            itemHeight: 10,
            align: "left",
            // formatter: (p) => {
            //   const index = this.chartData.findIndex(item => item.venderName === p);
            //   if(index > 10){
            //     return
            //   }else {
            //     return p
            //   }
            //   // const leng = this.chartData
            //   //   .concat(this.percentList)
            //   //   .find((val.align = 'center'.venderName);
            //   // let { venderName } = leng;
            //   // return `${venderName}`;
            // },
            selectedMode: false,
            textStyle: {
              color: "#fff",
              fontSize: 11,
              rich: {
                b: {
                  fontSize: 12,
                },
              },
            },
            pageTextStyle: {
              color: "#fff", //翻页数字颜色
            },
            // pageIconColor: '#6495ed', //翻页下一页的三角按钮颜色
            // pageIconInactiveColor: '#aaa', //翻页（即翻页到头时）
          },
          series: [
            {
              name: "供应商占比",
              type: "pie",
              radius: ["30%", "60%"],
              center: ["30%", "50%"],
              label: {
                show: false,
                formatter: () => {},
              },
              data: arr.map((item, index) => ({
                ...item,
                value: item.ddAmt,
                name: item.vendorName,
                itemStyle: {
                  color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: this.colors[index > 10 ? 10 : index][0],
                    },
                    {
                      offset: 1,
                      color: this.colors[index > 10 ? 10 : index][1],
                    },
                  ]),
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: this.colors[index > 10 ? 10 : index][1],
                  borderColor: this.colors[index > 10 ? 10 : index][2],
                },
              })),
            },
          ],
        };
        this.$forceUpdate();
      });
      this.$getData({
        ...this.params,
        type: "categoryFocalPointPicture",
        date: formatDate(this.unitMonth),
      }).then((res) => {
        let arr = res.data.map((v) => {
          return [v.superGroupName, v.ddAmtRatio * 100, v.ddAmt];
        });
        this.makeUpTwo = {
          dataset: {
            source: arr,
          },
        };
        this.$forceUpdate();
      });
    },
    //获取默认日期
    defaultDate() {
      let date = new Date();
      let year = date.getFullYear().toString();
      let monthDay =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1).toString()
          : (date.getMonth() + 1).toString();
      let da =
        date.getDate() < 10
          ? "0" + date.getDate().toString()
          : date.getDate().toString();
      //字符串拼接，开始时间，结束时间
      let end = year + "-" + monthDay + "-" + da; //当天
      // let beg = year + "-" + monthDay + "-01"; //当月第一天
      this.dateVals = [end, end]; //将值设置给插件绑定的数据
    },

    closeSreen() {
      this.unitBusinessDetail = false;
      this.entitys[0] = {};
      // this.entitys[0].querySelect = undefined
      // this.entitys[0].queryValue = undefined
      this.seachList(this.entitys);
    },
    setNotify() {
      let i = 0;
      let time = null;
      if (this.tableData.length) {
        if (this.tableData.length > 3) {
          this.notifyLength = 3;
        } else {
          this.notifyLength = this.tableData.length;
        }
        time = setInterval(() => {
          let img = "";
          if (this.tableData[i].type == 0) {
            img = this.orderformAlarm;
          } else if (this.tableData[i].type == 1) {
            img = this.transportationAlarm;
          } else if (this.tableData[i].type == 2) {
            img = this.weatherAlarm;
          } else if (this.tableData[i].type == 3) {
            img = this.rejectionAlarm;
          }
          this["notify" + i] = this.$notify({
            duration: 5000,
            offset: 90,
            dangerouslyUseHTMLString: true,
            customClass: "notifyCss",
            onClick: this.onClickNotify,
            message: `<div class="popover">
                          <img src="${img}" class="popover_img" alt>
                          <div class="popover-t-r">
                              ${
                                this.tableData[i].type == 3
                                  ? this.tableData[i].preString
                                  : ""
                              }  ${this.tableData[i].alarmContent}
                          </div>
                        </div>`,
          });
          i++;
          if (i === this.notifyLength) {
            clearInterval(time);
            time = null;
          }
        }, 500);
      }
    },
    setNotifyTwo(arr) {
      let i = 0;
      let time = null;
      if (arr.length) {
        if (arr.length > 3) {
          this.notifyLength = 3;
        } else {
          this.notifyLength = arr.length;
        }
        time = setInterval(() => {
          let img = "";
          img = this.rejectionAlarm;
          this["notify" + i] = this.$notify({
            duration: 5000,
            offset: 90,
            dangerouslyUseHTMLString: true,
            customClass: "notifyCss",
            onClick: this.onClickNotify,
            message: `<div class="popover">
                          <img src="${img}" class="popover_img" alt>
                          <div class="popover-t-r">
                              ${arr[i].type == 3 ? arr[i].preString : ""}  ${
              arr[i].alarmContent
            }
                          </div>
                        </div>`,
          });
          i++;
          if (i === this.notifyLength) {
            clearInterval(time);
            time = null;
          }
        }, 500);
      }
    },
    onClickNotify() {
      this.dynamicWatch = true;
      for (let i = 0; i < this.notifyLength; i++) {
        this["notify" + i].close();
      }
      this.showAlarmDetails = true;
      this.warningTimeInit(2);
    },
    //单位业务查询
    unitBusiness() {
      this.unitMonth = this.month;
      this.unitBusinessDetail = true;
      this.allInfo();
      this.getOrderViewTable();
      this.getFontiled();
      this.getOederAmountTable();
      this.$forceUpdate();
    },
    handleCloses() {
      this.paginationName = null;
      this.deliveryProgress = false;
      this.goodsDetails = false;
      clearInterval(this.timeId);
    },
    sizeChange(value) {
      this.pageParams.currentPage = 1;
      this.pageParams.pageSize = value;
      this.pageLeft.currentPage = 1;
      this.paginationShow = false;
      this.paginationShow2 = false;
      this.$nextTick(() => {
        this.paginationShow = true;
        this.paginationShow2 = true;
      });
      this.getSupplierDeliverSchedule();
    },
    sizeChangeTable(value) {
      this.pageLeft.currentPage = 1;
      this.pageLeft.pageSize = value;
      this.paginationShow2 = false;
      this.$nextTick(() => {
        this.paginationShow2 = true;
      });
      this.getPage();
    },
    currentChangeTable(value) {
      if (typeof value === "number") {
        this.pageLeft.currentPage = value;
        // this.paginationShow2 = false
        //     this.$nextTick(() => {
        //       this.paginationShow2 = true
        //     })
        this.getPage();
      }
    },
    //时间选择切换事件
    changeDataPicker(val) {
      this.dateVals = val;
      if (this.dateVals) {
        // if (this.overview === 0) {
        this.getEchartsData();
        // } else {
        this.pageParams.currentPage = 1;
        this.pageParams.pageSize = 10;
        this.pageLeft.currentPage = 1;
        this.pageLeft.pageSize = 10;
        this.paginationShow = false;
        this.paginationShow2 = false;
        this.$nextTick(() => {
          this.paginationShow = true;
          this.paginationShow2 = true;
        });
        this.getSupplierDeliverSchedule();
        // this.getPage()
        // }
      }
    },
    getPage() {
      if (!this.paginationName) {
        return;
      }
      this.loadingProLeft = true;
      this.$getData({
        page: this.pageLeft.currentPage,
        rows: this.pageLeft.pageSize,
        type: "supplierDeliveryTaskWlyd",
        level: 2,
        supplierName: this.paginationName,
        states: this.typeOf,
        startDate: this.dateVals[0],
        endDate: this.dateVals[1],
      }).then((res) => {
        this.loadingProLeft = false;
        this.tableDataGoodsDetails = res.data;
        if (res.data.length > 0) {
          this.totalCount = res.data[0].totalCount;
          this.hasSorted = res.data[0].hasSorted; //已分拣
          this.onTheShelf = res.data[0].onTheShelf; //已上架
          this.hasStockOut = res.data[0].hasStockOut; //已出库
          this.notStockIn = res.data[0].notStockIn; //未入库
          this.hasStockIn = res.data[0].hasStockIn; //已入库
        } else {
          if (!this.typeOf) {
            this.totalCount = 0;
            this.hasSorted = 0; //已分拣
            this.onTheShelf = 0; //已上架
            this.hasStockOut = 0; //已出库
            this.notStockIn = 0; //未入库
            this.hasStockIn = 0; //已入库
          }
        }
        this.pageLeft.total = res.count;
        // this.$forceUpdate()
      });
    },
    handleCurrentChange(value) {
      if (typeof value === "number") {
        this.pageParams.currentPage = value;
        this.pageParams.pageSize = 10;
        this.pageLeft.currentPage = 1;
        this.pageLeft.pageSize = 10;
        this.paginationShow2 = false;
        this.$nextTick(() => {
          this.paginationShow2 = true;
        });
        this.getSupplierDeliverSchedule();
      } else if (Object.prototype.toString.call(value) === "[object Object]") {
        if (this.radioTag == "0") {
          // //去掉默认的radio高亮
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .classList.remove("current-row");
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .getElementsByTagName("td")[0]
            .getElementsByTagName("label")[0]
            .classList.remove("is-checked");
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .getElementsByTagName("td")[0]
            .getElementsByTagName("label")[0]
            .getElementsByTagName("span")[0]
            .classList.remove("is-checked");
        }
        if (this.radioTag == "1") {
          //只是做单选radio默认第一行高亮
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .classList.add("current-row");
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .getElementsByTagName("td")[0]
            .getElementsByTagName("label")[0]
            .classList.add("is-checked");
          document
            .getElementsByClassName("tableOne")[0]
            .getElementsByTagName("tbody")[0]
            .getElementsByTagName("tr")[0]
            .getElementsByTagName("td")[0]
            .getElementsByTagName("label")[0]
            .getElementsByTagName("span")[0]
            .classList.add("is-checked");
          this.radioTag = "0";
        }
        this.$forceUpdate();
        //查看供应商下商品情况
        this.pageLeft.currentPage = 1;
        this.paginationName = value.supplierName;
        // this.getPage();
        if (value) {
          this.paginationShow2 = false;
          this.$nextTick(() => {
            this.paginationShow2 = true;
          });
        }
      }
    },
    getPageDetail(type, pages, fin) {
      if (!type && !pages) {
        this.isFinishedTag = "";
        this.$getData({
          page: this.pages.currentPage,
          rows: this.pages.pageSize,
          // type: "supplierDeliverSchedule",
          type: "supplierDeliveryTaskWlyd",
          level: 3,
          supplierName: this.paginationName,
          skuName: this.rowData.skuName,
          states: "",
          startDate: this.dateVals[0],
          endDate: this.dateVals[1],
        }).then((res) => {
          this.tableDataOther = res.data;
          this.detailsTitle = this.rowData.skuName;
          this.goodsTotalQty = res.data[0].totalCount; //全部
          this.goodsTotalHasSorted = res.data[0].hasSorted; //已分拣
          this.goodsTotalOnTheShelf = res.data[0].onTheShelf; //已上架
          this.goodsTotalHasStockOut = res.data[0].hasStockOut; //已出库
          this.goodsTotalNotStockIn = res.data[0].notStockIn; //未入库
          this.goodsTotalRecQty = res.data[0].hasStockIn; //已入库
          this.pages.total = res.count;
        });
      }
      if (typeof type === "number") {
        if (type == -1) {
          this.isFinishedTag = fin;
        }
        if (pages == 1) {
          this.pages.currentPage = 1;
          this.$refs.GoodsDetails.paginationShow2 = false;
          this.$nextTick(() => {
            this.$refs.GoodsDetails.paginationShow2 = true;
          });
        }
        this.$getData({
          page: this.pages.currentPage,
          rows: this.pages.pageSize,
          type: "supplierDeliveryTaskWlyd",
          level: 3,
          supplierName: this.paginationName,
          skuName: this.rowData.skuName,
          states: this.isFinishedTag,
          startDate: this.dateVals[0],
          endDate: this.dateVals[1],
        }).then((res) => {
          this.tableDataOther = res.data;
          this.detailsTitle = this.rowData.skuName;
          if (res.data.length > 0) {
            this.goodsTotalQty = res.data[0].totalCount; //全部
            this.goodsTotalHasSorted = res.data[0].hasSorted; //已分拣
            this.goodsTotalOnTheShelf = res.data[0].onTheShelf; //已上架
            this.goodsTotalHasStockOut = res.data[0].hasStockOut; //已出库
            this.goodsTotalNotStockIn = res.data[0].notStockIn; //未入库
            this.goodsTotalRecQty = res.data[0].hasStockIn; //已入库
          }
          this.pages.total = res.count;
        });
      }
    },
    //查看商品详情按钮
    checkGoodsDetails(row) {
      this.goodsDetails = true;
      this.rowData = row;
      this.pages.currentPage = 1;
      this.pages.pageSize = 10;
      this.getPageDetail();
    },
    //送货进度
    progressDetails() {
      this.overview = 0;
      this.pageParams.currentPage = 1;
      this.pageParams.pageSize = 10;
      this.pageLeft.currentPage = 1;
      this.pageLeft.pageSize = 10;
      this.deliveryProgress = true;
      this.defaultDate();
      this.getSupplierDeliverSchedule(1);
      this.getEchartsData();
      this.timeNum = 1;
      this.timeId = setInterval(() => {
        this.getEchartsData(2);
        this.timeNum++;
        if (this.timeNum > 4) {
          this.timeNum = 1;
          clearInterval(this.timeId);
        }
      }, 1000 * 60 * 3);
    },
    getEchartsData(v = 1) {
      if (v === 1) {
        this.loadingProce = true;
      }
      this.$getData({
        type: "supplierDeliveryTaskWlyd",
        level: 4,
        startDate: this.dateVals[0],
        endDate: this.dateVals[1],
      }).then((res) => {
        if (v === 1) {
          this.loadingProce = false;
          this.echartsProce = res.data;
        }
        this.updateTime = res.data[0].updateTime;
      });
    },
    // 刷新
    updateData() {
      if (this.overview === 0) {
        this.getEchartsData();
      } else if (this.overview === 1) {
        this.pageParams.currentPage = 1;
        this.pageParams.pageSize = 10;
        this.pageLeft.currentPage = 1;
        this.pageLeft.pageSize = 10;
        this.paginationShow = false;
        this.paginationShow2 = false;
        this.$nextTick(() => {
          this.paginationShow = true;
          this.paginationShow2 = true;
        });
        // this.paginationName = ''
        this.getSupplierDeliverSchedule();
        // this.getPage()
      }
    },
    getSupplierDeliverSchedule() {
      this.loadingProgress = true;
      this.radioTag = "1";
      this.paginationName = "";
      this.$getData({
        page: this.pageParams.currentPage,
        rows: this.pageParams.pageSize,
        // type: "supplierDeliverSchedule",
        type: "supplierDeliveryTaskWlyd",
        level: 1,
        startDate: this.dateVals[0],
        endDate: this.dateVals[1],
      }).then((res) => {
        this.loadingProgress = false;
        this.tableDataGoods = res.data;
        this.pageParams.total = res.count;
        if (this.tableDataGoods.length > 0) {
          this.paginationName = this.tableDataGoods[0].supplierName;
        } else {
          this.paginationName = "";
        }
        if (this.overview === 1) {
          if (this.radioTag == "0") {
            // //去掉默认的radio高亮
            document
              .getElementsByClassName("tableOne")[0]
              .getElementsByTagName("tbody")[0]
              .getElementsByTagName("tr")[0]
              .classList.remove("current-row");
            document
              .getElementsByClassName("tableOne")[0]
              .getElementsByTagName("tbody")[0]
              .getElementsByTagName("tr")[0]
              .getElementsByTagName("td")[0]
              .getElementsByTagName("label")[0]
              .classList.remove("is-checked");
            document
              .getElementsByClassName("tableOne")[0]
              .getElementsByTagName("tbody")[0]
              .getElementsByTagName("tr")[0]
              .getElementsByTagName("td")[0]
              .getElementsByTagName("label")[0]
              .getElementsByTagName("span")[0]
              .classList.remove("is-checked");
          }
          if (this.radioTag == "1") {
            if (this.tableDataGoods.length > 0) {
              //只是做单选radio默认第一行高亮
              this.$nextTick(() => {
                document
                  .getElementsByClassName("tableOne")[0]
                  .getElementsByTagName("tbody")[0]
                  .getElementsByTagName("tr")[0]
                  .classList.add("current-row");
                document
                  .getElementsByClassName("tableOne")[0]
                  .getElementsByTagName("tbody")[0]
                  .getElementsByTagName("tr")[0]
                  .getElementsByTagName("td")[0]
                  .getElementsByTagName("label")[0]
                  .classList.add("is-checked");
                document
                  .getElementsByClassName("tableOne")[0]
                  .getElementsByTagName("tbody")[0]
                  .getElementsByTagName("tr")[0]
                  .getElementsByTagName("td")[0]
                  .getElementsByTagName("label")[0]
                  .getElementsByTagName("span")[0]
                  .classList.add("is-checked");
              });
              this.radioTag = "0";
            }
          }
        }
        // if (this.paginationName) {
        //   this.getPage()
        // }
        // this.$nextTick(() => {
        //   if (this.overview === 1) {
        //     this.checked();
        //   }
        // })
      });
    },
    checked() {
      this.$refs.singTable.$children[0].setCurrentRow(this.tableDataGoods[0]);
    },
    datePickerChange(value) {
      value = this.demoDate
      let mon = value.slice(0,7)
      this.$store.dispatch("global/changeGlobal", { key: "month", value: mon });
      this.$store.dispatch("global/changeGlobal", { key: "dateModel", value });
      this.warningTimeInit(1);
    },
    closePopover() {
      this.$nextTick(() => {
        if (
          this.popoverShow &&
          this.$refs.Popover &&
          !this.$refs.Popover.dialogFormVisible
        ) {
          this.popoverShow = false;
        }
      });
    },
    close() {
      this.isConfirm = false;
      this.visible = false;
      let T = setTimeout(() => {
        this.isConfirm = true;
        clearTimeout(T);
        T = null;
      }, 250);
    },
    confirm() {
      this.visible = false;
      this.isConfirm = true;
      this.WarningProcessingProp.showDetails = true;
    },
    async getAlarmInfoList(_, v = 0, f = 0) {
      this.tableData = [];
      // this.pageList.count = 0;
      if (v == 1) {
        this.pageList.page = 1;
        clearInterval(this.timer);
        this.timer = null;
        // this.timer = setInterval(this.timingWarning, 1000 * 60);
      }
      let obj = {
        type: "alarmInfoList",
        status: this.alarmStatus,
        page: this.pageList.page,
        rows: this.pageList.rows,
        // date: formatDate(this.month),
      };
      if (this.warningDate.length) {
        obj.startDate = this.warningDate[0] + " 00:00:00";
        obj.endDate = this.warningDate[1] + " 23:59:59";
      }
      if (this.alarmType && this.showAlarmDetails === true) {
        obj.alarmType = parseInt(this.alarmType);
      }
      /* let res = await this.$getData(obj);
      if (res && res.data) {
        this.tableData = res.data;
        this.pageList.count = res.count;
        if (!this.alarmStatus) {
          this.alarmNum = res.count;
        }
        if (f == 1) {
          // this.setNotify();
          // this.timer = setInterval(this.timingWarning, 1000 * 60);
          // this.timingWarning()
        }
      } */
      // if (_.page) {
      //   this.pageList.page = _.page;
      // }
      // if (_.rows) {
      //   this.pageList.rows = _.rows;
      // }
    },
    timingWarning() {
      let obj = {
        type: "alarmInfoList",
        status: 0, //未处理
        alarmType: 3, //拒收
        page: 1,
        rows: 10,
      };
      if (this.warningDate.length) {
        obj.startDate = this.warningDate[0] + " 00:00:00";
        obj.endDate = this.warningDate[1] + " 23:59:59";
      }
      /* this.$getData(obj).then((res) => {
        if (res && res.data) {
          if (this.rejectionWarningId !== res?.data[0]?.id) {
            this.rejectionWarningId = res.data && res.data[0] && res.data[0].id;
            this.setNotifyTwo(res.data);
          }
        }
      }); */
    },
    closeAlarmInfoList() {
      this.dynamicWatch = false;
      this.alarmType = null;
      this.alarmStatus = 0;
      this.pageList.page = 1;
      this.pageList.rows = 20;
      this.showAlarmDetails = false;
      this.warningTimeInit(3);
    },
    async setAlarmInfoHandle(msg) {
      if (this.rmaType === 3) {
        rmaOrderSetHandled({
          rmaId: this.alarmID,
          handleResult: msg,
        }).then((res) => {
          if (res.code === 0) {
            this.$message({
              message: res.messages,
              type: "success",
            });
          } else {
            this.$message({
              message: res.messages,
              type: "error",
            });
          }
          this.getAlarmInfoList("", 1);
        });
      } else {
        await this.$getData({
          type: "alarmInfoHandle",
          id: this.alarmID,
          handleResult: msg,
        });
        this.$message({
          message: "已处理",
          center: true,
        });
        this.getAlarmInfoList("", 1);
      }
    },
    getAlarmID(id, type) {
      this.WarningProcessingProp.showDetails = true;
      this.alarmID = id;
      this.rmaType = type;
    },
  },
  beforeDestroy() {
    clearInterval(this.timeId);
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.main-header {
  position: absolute;
  bottom: 0px;
  left: 10px;
  right: 20px;
  display: flex;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .left {
    display: flex;
    align-items: center;
    .el-input {
      width: 110px;
      margin-right: 6px;
    }
    ::v-deep .el-input--prefix .el-input__inner {
      cursor: pointer;
      border: none;
      font-size: 14px;
      padding: 0px 2px 0 28px;
      line-height: 14px;
    }
    .analysis {
      display: flex;
      align-items: center;
      cursor: pointer;
      .text {
        margin-left: 4px;
      }
    }

    .el-dropdown-link{
      color: #fff;
      cursor: pointer;
      .dropdown-icon{
        margin-right: 4px;
        color: #17fff5;
      }
    }
  }
  .alarm {
    margin-right: 30px;
    height: 32px;
  }
  .username {
    -webkit-touch-callout: none; /*系统默认菜单被禁用*/
    -webkit-user-select: none; /*webkit浏览器*/
    -khtml-user-select: none; /*早期浏览器*/
    -moz-user-select: none; /*火狐*/
    -ms-user-select: none; /*IE10*/
    user-select: none;
  }
  .main_header_img {
    margin-right: 4px;
    width: 20px;
  }
  .main_header_img2_all {
    width: 10px;
    margin-left: 3px;
    transition: all 0.2s;
  }
  .main_header_img2 {
    transform: rotate(180deg);
  }
  .main_header_img2_ {
    transform: rotate(0deg);
  }

  .detail {
    width: 50%;
  }
}

.tableOther {
  display: flex;
  justify-content: space-between;
  // padding-top: 25PX;
  .tableLeft {
    width: 45%;
    .tableOne {
      padding-top: 16px;
    }
  }
  .tableRight {
    width: 55%;
    margin-top: 16px;
    .rightTop {
      // background: #132f62;
      .tableTwo {
        // padding-top: 16PX;
        .clickButton {
          color: #17fff4;
          cursor: pointer;
        }
        ::v-deep {
          .el-table__empty-block,
          .el-table__body-wrapper {
            background: #132f62;
          }
          .el-table {
            th,
            tr {
              background-color: #132f62 !important;
              color: white;
              // border-bottom: none;
            }
          }
        }
      }
    }
  }
}
.containerHeader {
  display: flex;
  flex-direction: row;
  height: 32px;
  ::v-deep {
    .el-input {
      width: 250px;
    }
    .el-input--suffix .el-input__inner {
      border-radius: 0;
      height: 32px;
      border: 1px solid #87d2ff;
      color: #17fff5;
      background-color: transparent;
    }
    .el-input--prefix .el-input__inner {
      height: 32px;
      font-size: 14px;
      // padding: 11PX 0 19PX 30PX;
      border: 1px solid #87d2ff;
      border-radius: 0;
    }
  }
  .listImg {
    vertical-align: bottom;
    .imgUrl {
      position: relative;
      top: 3px;
    }
  }
  .button {
    color: #17fff4;
    line-height: 16px;
    background: linear-gradient(
      0deg,
      #5f97ff 0%,
      rgba(95, 151, 255, 0)
    ) !important;
  }
}
.allTable {
  padding: 0 48px;
}
.timeUpdate {
  display: flex;
  justify-content: flex-end;
  color: #17f1ec;
  font-size: 12px;
}
::v-deep {
  .el-range-editor.el-input__inner {
    border: 1px solid #87d2ff;
    border-radius: 1px;
    height: 32px;
    width: 250px;
  }

  .el-date-editor .el-range-input {
    width: 100%;
    color: #17f1ec;
  }
  .el-date-editor .el-range-separator {
    line-height: 25px !important;
    padding: 0;
  }
  .el-date-table tr th {
    color: #fff !important;
  }
}
.tableHead {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  .btnZoom {
    height: 32px;
    width: 72px;
    font-size: 14px;
  }
}
.tableMain {
  display: flex;
  align-items: center;
  ::v-deep {
    .el-icon-date:before {
      color: rgba(23, 255, 245, 1);
      // font-family: "iconfont";
      font-size: 24px;
      padding: 0px 0px;
      line-height: 20px;
      content: url("~@/assets/image/date.png");
    }
    .el-date-editor .el-range-input {
      padding: 0px 5px !important;
    }
    .el-date-editor .el-range-separator {
      color: rgba(23, 255, 245, 1);
    }
  }
}
.containerMain {
  height: calc(100% - 41px);
}
::v-deep {
  .pagination {
    float: center;
  }
}
::v-deep {
  .el-input--prefix .el-input__inner {
    height: 32px;
    // width: 200PX;
    line-height: 23px;
    border: 1px solid #87d2ff;
    border-radius: 0px;
    color: #ffffff;
  }
  .el-input__icon {
    line-height: 25px;
    font-size: 12px;
    margin-left: 6px;
    width: 24px;
  }
  .el-date-editor--daterange > .el-input__icon {
    margin-top: -6px !important;
  }
  .el-range-editor--mini.el-input__inner {
    border: none;
  }
  .el-badge__content {
    color: #ffffff;
    line-height: 15px;
    height: 15px;
    border: none;
  }
  .el-icon-date:before {
    color: rgba(23, 255, 245, 1);
    // font-family: "iconfont";
    font-size: 23px;
    padding: 0px 0px;
    line-height: 30px;
    content: url("~@/assets/image/date.png");
  }
}

.margin_r {
  margin-right: 10px;
}
.text {
  margin-left: 0px;
}

.right-tab-item {
  display: flex;
  padding: 0 12px;
  border: 1px solid #87d2ff;
  cursor: pointer;
  backdrop-filter: blur(2px);
  line-height: 32px;
  color: #ffffff;
  opacity: 0.75;
}
.right-tab-item1 {
  display: flex;
  padding: 0 24px;
  border: 1px solid #87d2ff;
  cursor: pointer;
  backdrop-filter: blur(2px);
  line-height: 32px;
  color: #ffffff;
  opacity: 0.75;
}
.right-tab-item2 {
  display: flex;
  padding: 0 15px;
  border: 1px solid #87d2ff;
  cursor: pointer;
  background: linear-gradient(0deg, #5f97ff 0%, rgba(95, 151, 255, 0));
  line-height: 32px;
  color: #ffffff;
  opacity: 0.75;
}
.right-tab-item3 {
  display: flex;
  padding: 0 12px;
  border: 1px solid #87d2ff;
  cursor: pointer;
  backdrop-filter: blur(2px);
  line-height: 32px;
  color: #ffffff;
  opacity: 0.75;
}

.right-tab-box {
  display: flex;
  // flex-direction: row-reverse;
  height: 32px;
}
.right-tab-box3 {
  display: flex;
  // flex-direction: row-reverse;
  height: 32px;
}
.right-tab-box1 {
  display: flex;
  // flex-direction: row-reverse;
  height: 32px;
}
.right-tab-boxs {
  display: flex;
  // flex-direction: row-reverse;
  height: 48px;
  padding-top: 16px;
  padding-left: 10px;
}
.selectAlarm {
  margin-left: 12px;
  margin-right: 12px;
  height: 32px;
  width: 116px;
  ::v-deep .el-input__inner {
    height: 32px !important;
    padding: 0 20px 0 12px;
    font-size: 14px;
  }
  ::v-deep .el-input__suffix {
    // width: 20PX;
    // height: 32PX;
    // display: flex;
    // align-items: center;
  }
  ::v-deep .el-select__caret {
    font-size: 14px;
  }
}

::v-deep .el-table thead {
  color: #fff;
}
::v-deep .el-input__prefix {
  margin-left: -5px;
}

.left-top {
  margin-bottom: 25px;
  display: flex;
}
</style>
<style lang="scss">
  .heater_date{
    .el-date-table{
      .disabled{
        >div{
          background-color: transparent;
          color: rgba(192,196,204,.4);

        }
      }
    }
  }
</style>